import React, { useEffect, useState, useRef } from 'react';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import './Reports.css';
import { useApolloClient } from '@apollo/client';
import logo from '../../../assets/images/hc_login_logo.png';
import { FaSave, FaFileAlt } from 'react-icons/fa';
import LoadingSpinner from '../../../components/layout/LoadingSpinner';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_REPORT, CREATE_CLIENTFIX2, UPDATE_CLIENTFIX2 } from '../../../graphql/mutations'; // Updated to use CREATE_CLIENTFIX2
import { listClientFix2s, listUsersByTeamOne, clientsByName } from '../../../graphql/queries'; // Updated to use ClientFix2 queries
import { useNavigate } from 'react-router-dom';
import Section2 from '../Sections/Section2';
import Section3 from '../Sections/Section3';
import Section4 from '../Sections/Section4';
import Section5 from '../Sections/Section5';
import Section6 from '../Sections/Section6';
import Section7 from '../Sections/Section7';
import Section8 from '../Sections/Section8';
import Section9 from '../Sections/Section9';
import Section10 from '../Sections/Section10';
import Section11 from '../Sections/Section11';
import Section27 from '../Sections/Section27';
import Section28 from '../Sections/Section28';
import Section29 from '../Sections/Section29';
import Section30 from '../Sections/Section30';
import Section31 from '../Sections/Section31';
import Section32 from '../Sections/Section32';
import Section33 from '../Sections/Section33';
import Section34 from '../Sections/Section34';
import Section35 from '../Sections/Section35';
import Section36 from '../Sections/Section36';
import Section37 from '../Sections/Section37';
import Section38 from '../Sections/Section38';
import Section39 from '../Sections/Section39';
import Section55 from '../Sections/Section55';
import Section56 from '../Sections/Section56';

const InitializeFormData = () => {
  const currentDate = new Date();
  currentDate.setUTCHours(currentDate.getUTCHours() - 6); // Adjusts directly to CST without changing the day
  const formattedDate = currentDate.toISOString().split('T')[0];
  console.log(currentDate.toISOString())

  // const utcOffsetInMinutes = currentDate.getTimezoneOffset(); // Get the UTC offset in minutes
  // const cstOffsetInMinutes = 360; // CST is UTC-6 hours, 6*60 = 360 minutes
  // currentDate.setMinutes(currentDate.getMinutes() + utcOffsetInMinutes - cstOffsetInMinutes);
  // const formattedDate = currentDate.toISOString().split('T')[0];
  // console.log("Calculated CST Date:", formattedDate); // Log the CST-adjusted date

  let initialData = {
    Timestamp: new Date().toISOString(),
    Username: '',
    Date: formattedDate,
    Navigator: '',
    outreachEvent: '',
    flyers: '',
    year_contract: 'Contract 2 Year 1', // Set the default value here
    Number_FollowUp_Attempt_Contact_NoResponse: '',
    MACtouches: '',
    name: '',
    lastName: '',
    phone: '',
    address: '',
    city: '',
    county: '',
    email: '',
    prefered_lang: [],
    otherLanguage: '',
    commitCard: '',
    commitcardNo: '',
    signature: '',
    bestTimeToCall: '',
    CommitCardNotes: '',
    no_show: '',
    ConsumerAuthOnFile: '',
    employerCoverage: '',
    cost_to_employee: '',
    familyCovered: '',
    amountFamCoverage: '',
    NumPeopleHousehold: '',
    NumPeopleApplying: '',
    NumAdults: '',
    NumChildren: '',
    EmployeeTool: '',
    IncomeType: '',
    IncomeAmount: '',
    PayFrequency: '',
    coverageAppliedFor: '',
    OtherCoverage: '',
    New_Re_Enrollment: '',
    clientEnroll: '',
    QHP_Amount: '',
    plans: [],
    ClientCompleteMMC: '',
    AppealQuestion: '',
    RescheduleQuestion: '',
    reschedule_reason: [],
    otherRescheduleChange: '',
    one2oneInquiry: '',
    HealthInsuranceLiteracy: '',
    Locating_Providers: '',
    Billing_Payment_Questions: '',
    EvaluateHealtcareOptionsUsingTools: '',
    AccessingPreventativeHealthServices: '',
    HealtcareGovAcc: '',
    createdAccounts: '',
    troubleshootedAccounts: '',
    MarketplaceEligibility: '',
    MarketPlanCompare: '',
    qhp_enrollment_hcgov: '',
    elctronic_qhp_enrollment_marketplaceCallCenter: '',
    written_qhp_enrollment_hcgov: '',
    medicaid_chip_app_ref: '',
    complex_case_help_center: '',
    data_matchingIssues: '',
    data_matchingIssues_other: '',
    SEP_eligibility: '',
    SEP_eligibility_other: '',
    Employer_sponsored_coverage_issues: '',
    Employer_sponsored_coverage_issues_other: '',
    APTC_CSR_assistance: '',
    APTC_CSR_assistance_other: '',
    Complex_Cases_Other: '',
    Answering_questions_about: [],
    Answering_questions_about_other: '',
    Marketplace_tax_forms: '',
    Filing_Marketplace: '',
    Submitting_Marketplace: '',
    Assisted_with_other_consumer_information: '',
    pertinent_info_tracking: '',
    hear_from_us: '',
    event_hear_abt_us: '',
    facebook_hear_abt_us: '',
    insta_hear_abt_us: '',
    SAC_hear_abt_us: '',
    twitter_hear_abt_us: '',
    tv_hear_abt_us: '',
    hospital_hear_abt_us: '',
    library_opt: '',
    workforce_hear_abt_us: '',
    tablepresentation_hear_abt_us: '',
    referral_hear_abt_us: '',
    newspaper_hear_abt_us: '',
    radio_hear_abt_us: '',
    flyer_hear_abt_us: '',
    county2: '',
    other_county: '',
    zip_code: '',
    finalNotes: '',
  };

  for (let i = 0; i < 15; i++) {
    initialData[`plan_ClientName_${i}`] = '';
    initialData[`plan_ClientLastName_${i}`] = '';
    initialData[`Insurer_${i}`] = '';
    initialData[`PlanMetal_${i}`] = '';
    initialData[`PriceofPlan_${i}`] = '';
    initialData[`APCT_Subsidy_${i}`] = '';
    initialData[`DentalConfirm${i}`] = '';
    initialData[`Dentalplan_ClientName_${i}`] = '';
    initialData[`Dentalplan_ClientLastName_${i}`] = '';
    initialData[`Dentalplan_Name_${i}`] = '';
    initialData[`Dentalplan_Cost_${i}`] = '';
  }
  return initialData;
};


const Reports = () => {
  const client = useApolloClient();
  // Load formData from localStorage or initialize if not present
  const initialFormData = () => {
    const savedData = localStorage.getItem('formData');
    return savedData ? JSON.parse(savedData) : InitializeFormData();
  };

  const [formData, setFormData] = useState(initialFormData); // Initialize formData
  const [clientSearchTerm, setClientSearchTerm] = useState(formData.name || ''); // Initialize clientSearchTerm to formData.name if it exists

  const [currentSection, setCurrentSection] = useState(1);
  const [history, setHistory] = useState([1]);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);
  const [matchingClients, setMatchingClients] = useState([]);
  const [isFetchingClients, setIsFetchingClients] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const [selectedClient, setSelectedClient] = useState(null);

  // State to track user's group
  const [userGroup, setUserGroup] = useState(null);

  useEffect(() => {
    // Fetch the user's group when the component mounts
    const fetchUserGroup = async () => {
      try {
        const session = await fetchAuthSession();
        const groups = session.tokens.accessToken.payload["cognito:groups"]; // Get the user's groups
        if (groups) {
          setUserGroup(groups); // Assuming a user belongs to one group. Adjust as needed.
        }
      } catch (error) {
        console.error('Error fetching user group:', error);
      }
    };

    fetchUserGroup();
  }, []);
  
  const { loading: usersLoading, error: usersError, data: usersData } = useQuery(listUsersByTeamOne, {
    variables: {
      filter: {
        navigator_id: { ne: "" } // 'ne' stands for 'not equal', so this filters out null values
      },
      limit: 100, // Or any desired limit
    }
  });

  const navigatorOptions = usersData?.listUsers?.items?.map(user => ({
    id: user.id,
    name: `${user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1).toLowerCase()} ${user.last_name.charAt(0).toUpperCase() + user.last_name.slice(1).toLowerCase()}`
  })) || [];

  const [createReport, { loading: loadingReport }] = useMutation(CREATE_REPORT);
  const [createClientFix, { loading: loadingClient }] = useMutation(CREATE_CLIENTFIX2); // Updated to use CREATE_CLIENTFIX2
  const [updateClientFix, { loading: updatingClient }] = useMutation(UPDATE_CLIENTFIX2); // Updated to use UPDATE_CLIENTFIX2
  const navigate = useNavigate();

  // Load saved form data from localStorage when component mounts
  useEffect(() => {
    const savedData = localStorage.getItem('formData');
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const { signInDetails } = await getCurrentUser();
        const [firstName, lastNameWithDomain] = signInDetails.loginId.split('.');
        const firstNameCapitalized = firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
        const [lastName, domain] = lastNameWithDomain.split('@');
        const lastNameCapitalized = lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase();
        const nav_name = firstNameCapitalized + " " + lastNameCapitalized
        // console.log(firstNameCapitalized + " " + lastNameCapitalized)

        setFormData((prevData) => ({
          ...prevData,
          Username: signInDetails.loginId,
          Navigator: nav_name,
        }));
      } catch (error) {
        console.log('User not authenticated', error);
      }
    };
    fetchUserDetails();
  }, []);

  const fetchClientsOnSearch = async (searchTerm) => {
    setIsFetchingClients(true);
    try {
      const { data } = await client.query({
        query: clientsByName, // Use the new query name
        variables: {
          name: searchTerm,
          limit: 100
        },
      });

      const clients = data.clientsByName.items;
      setMatchingClients(clients);
    } catch (error) {
      console.error('Error fetching clients:', error);
    } finally {
      setIsFetchingClients(false);
    }
  };
  const handleClientSearchChange = (e) => {
    const displayedTerm = e.target.value; // Keep original casing for display
    const searchTerm = displayedTerm.toLowerCase(); // Convert to lowercase for search

    setClientSearchTerm(displayedTerm); // Set the displayed term

    if (searchTerm === '') {
      setMatchingClients([]);
      setIsDropdownVisible(false);
    } else {
      setIsDropdownVisible(true);
      fetchClientsOnSearch(searchTerm); // Search using lowercase term
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClientSelect = (client) => {
    // Update the form data with the selected client's information
    setSelectedClient(client);

    setFormData({
      ...formData,
      name: client.name,
      lastName: client.last_name,
      phone: client.phone,
      address: client.address,
      city: client.city,
      county: client.county,
      email: client.email,
      prefered_lang: client.prefered_lang || [],
      otherLanguage: client.otherLanguage || '',
      zip_code: client.zip_code
    });

    // Clear the client search term to replace what was typed by the user
    setClientSearchTerm(client.name);

    // Hide the dropdown after selection
    setMatchingClients([]);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const numericValue = value.replace(/[^0-9]/g, '');

    if (name === 'QHP_Amount') {
      const amount = value === "" ? 0 : parseInt(value, 10);

      setFormData((prevData) => ({
        ...prevData,
        QHP_Amount: value,
        plans: amount > 0 ? new Array(amount).fill(null) : [],
      }));
      return;
    }

    if (type === 'checkbox') {
      if (name === 'prefered_lang') {
        let updatedLanguages = [...formData.prefered_lang];
        if (checked) {
          updatedLanguages.push(value);
        } else {
          updatedLanguages = updatedLanguages.filter((lang) => lang !== value);
        }
        setFormData({
          ...formData,
          prefered_lang: updatedLanguages,
        });
      } else if (name === 'Answering_questions_about') {
        let updatedQuestions = [...formData.Answering_questions_about];
        if (checked) {
          updatedQuestions.push(value);
        } else {
          updatedQuestions = updatedQuestions.filter((question) => question !== value);
        }
        setFormData({
          ...formData,
          Answering_questions_about: updatedQuestions,
        });
      } else if (name === 'reschedule_reason') {
        let updatedReasons = [...formData.reschedule_reason];
        if (checked) {
          updatedReasons.push(value);
        } else {
          updatedReasons = updatedReasons.filter((reason) => reason !== value);
        }
        setFormData({
          ...formData,
          reschedule_reason: updatedReasons,
        });
      }
    } else if (name === 'county') {
      setFormData({
        ...formData,
        county: value,
        other_county: '', // Clears other_county when county is modified
      });
    } else if (name === 'other_county') {
      setFormData({
        ...formData,
        [name]: value.toLowerCase(), // Force lowercase for other_county
      });
    } else {
      // Limit numeric fields for 'phone' and 'zip_code'
      if (name === 'phone' && numericValue.length > 10) return;
      if (name === 'zip_code' && numericValue.length > 5) return;

      let updatedValue = name === 'phone' ? numericValue : value;

      // **Trim leading/trailing whitespace for 'name' and 'lastName' fields**
      if (name === 'name' || name === 'lastName') {
        updatedValue = updatedValue.trim();
      }

      setFormData({
        ...formData,
        [name]: updatedValue,
      });
    }
  };

  const skippedSections = [
    7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
    18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
    28, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55
  ];

  const handleNext = () => {
    if (!formRef.current.checkValidity()) {
      formRef.current.reportValidity();
      return;
    }

    let nextSection = currentSection;

    // Conditional navigation based on specific form values
    if (currentSection === 1) {
      nextSection = formData.outreachEvent === 'yes' ? 2 : 3;
    } else if (currentSection === 2) {
      nextSection = 29;
    } else if (currentSection === 3) {
      nextSection = formData.commitCard === 'yes' ? 4 : 5;
    } else if (currentSection === 4) {
      nextSection = 56;
    } else if (currentSection === 5) {
      nextSection = formData.no_show === 'yes' ? 56 : 6;
    } else if (currentSection === 6) {
      nextSection = formData.ConsumerAuthOnFile === 'yes' ? 7 : 56;
    } else {
      nextSection++;
    }

    // Check if the next section is in the skipped sections list
    while (skippedSections.includes(nextSection)) {
      nextSection++;
    }

    setHistory((prevHistory) => [...prevHistory, nextSection]);
    setCurrentSection(nextSection);
  };

  const handleBack = () => {
    if (history.length > 1) {
      const prevHistory = history.slice(0, -1);
      setHistory(prevHistory);
      setCurrentSection(prevHistory[prevHistory.length - 1]);
    }
  };

  const handleSaveProgress = () => {
    // Save formData to localStorage
    localStorage.setItem('formData', JSON.stringify(formData));
    setClientSearchTerm(formData.name)
    alert('Your progress has been saved.');
  };

  // Function to handle releasing (clearing saved progress)
  const handleRelease = () => {
    // Clear form data from localStorage
    localStorage.removeItem('formData');

    // Reset the form to its initial empty state
    setFormData(InitializeFormData());
    setClientSearchTerm('')

    alert('The form has been cleared. You can start a new report.');
    // Reset the current section to the first step
    setCurrentSection(1);
    // Reset the navigation history
    setHistory([1]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    // Validate required fields
   /* if (!formData.name || !formData.lastName) {
      alert('Please provide both first name and last name before submitting.');
      setIsLoading(false);
      return;
    }*/
  
    const safeName = formData.name;
    const safeLastName = formData.lastName;
  
    // Update the Timestamp with the current time
    const updatedFormData = {
      ...formData,
      Timestamp: new Date().toISOString(), // Update the timestamp to the current time
    };
  
    // If outreachEvent is "yes", skip client creation/updating and only submit the report
    if (formData.outreachEvent === "yes") {
      try {
        await createReport({ variables: { input: updatedFormData } });
        alert('Report submitted successfully');
        handleRelease();
      } catch (err) {
        console.error('Error submitting report:', err);
        alert('Error submitting report');
      }
      setIsLoading(false);
      return;
    }
  
    // If a client was selected, update it
    if (selectedClient) {
      try {
        await updateClientFix({
          variables: {
            input: {
              id: selectedClient.id,
              name: safeName,
              last_name: safeLastName,
              email: formData.email.toLowerCase() || '',
              address: formData.address || '',
              city: formData.city.toLowerCase() || '',
              county: formData.county.toLowerCase() || '',
              zip_code: formData.zip_code || '',
              insurance_picked: formData.Insurer_0 || '',
              last_contacted_date: formData.Date,
              navigator: formData.Navigator || '',
              payment_per_month: formData.PriceofPlan_0 || 0,
              phone: formData.phone || '',
              plan: formData.PlanMetal_0 || '',
            },
          },
        });
        console.log('Client updated successfully');
      } catch (err) {
        console.error('Error updating client:', err);
        alert('Error updating client, proceeding with report submission.');
      }
    } else {
      // Create a new client if no client is selected
      const newClient = {
        name: safeName,
        last_name: safeLastName,
        email: formData.email.toLowerCase() || '',
        address: formData.address || '',
        city: formData.city.toLowerCase() || '',
        county: formData.county.toLowerCase() || '',
        zip_code: formData.zip_code || '',
        insurance_picked: formData.Insurer_0 || '',
        last_contacted_date: formData.Date,
        navigator: formData.Navigator || '',
        payment_per_month: formData.PriceofPlan_0 || 0,
        phone: formData.phone || '',
        plan: formData.PlanMetal_0 || '',
      };
      try {
        await createClientFix({ variables: { input: newClient } });
      } catch (err) {
        console.error('Error creating client:', err);
        alert('Error creating client, proceeding with report submission.');
      }
    }
  
    // Submit the report
    try {
      await createReport({ variables: { input: updatedFormData } });
      alert('Report submitted successfully');
      window.location.reload(); // Reload the page after submission
    } catch (err) {
      console.error('Error submitting report:', err);
      alert('Error submitting report');
    }
  
    setIsLoading(false);
  };
  
  { usersLoading && <p>Loading navigators...</p> }
  { usersError && <p>Error loading navigators: {usersError.message}</p> }

  return (
    <div className="reports-container">
      <header className="reports-header">
        <h1>Hybrid Report</h1>
      </header>
      <div className="buttons-container-reports">
        <button
          type="button"
          onClick={handleSaveProgress}
          className="save-progress-button"
          aria-label="Save Progress"
          title="Save Progress"
        >
          <FaSave />
          <span className="save-progress-text"> Save Progress</span>
        </button>

        <button
          type="button"
          onClick={handleRelease}
          className="release-button"
          aria-label="Release"
          title="Release"
        >
          <FaFileAlt />
          <span className="release-text"> Release Progress</span>
        </button>
      </div>

      <div className="report-box">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <div className="report-box">
        <h1>Consumer Form Flow</h1>
        {userGroup?.includes('dev') && (
          <p>{currentSection}</p>
        )}
        <p>Fill out for EVERY client appointment to include No-Shows and Reschedules.</p>
        <p>For example:</p>
        <p>11/01/2021 @ 9:00 AM Mr. Garcia, Applied but did not Enroll  (1st Form/Report)</p>
        <p>11/03/2021 @ 3:00 PM Mr. Garcia, Enrolled in plan (2nd Form/Report)</p>
        <button onClick={() => navigate('/reports-list')} className="list-reports-button">
          View Previous Reports
        </button>
      </div>

      <form ref={formRef} onSubmit={handleSubmit} className="report-form">
        {/* Loading screen */}
        {isLoading && <LoadingSpinner />} {/* Use the reusable LoadingSpinner component */}
        {currentSection === 1 && (
          <div className="form-section">
            <label className="required-asterisk">Date:</label>
            <input
              type="Date"
              name="Date"
              value={formData.Date}
              onChange={handleInputChange}
              required
            />
            <label className="required-asterisk">Navigator Name:</label>
            <select
              name="Navigator"
              value={formData.Navigator}
              onChange={handleInputChange}
              required
            >
              <option value="" disabled>Select Navigator</option>
              {navigatorOptions.map(option => (
                <option key={option.id} value={option.name}>{option.name}</option>
              ))}
            </select>

            <label>Is this an outreach/colocation/phone bank event? (one-on-one interaction report)</label>
            <div className="radio-group">
              <label>
                <input
                  type="radio"
                  name="outreachEvent"
                  value="yes"
                  checked={formData.outreachEvent === 'yes'}
                  onChange={handleInputChange}
                />
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  name="outreachEvent"
                  value="no"
                  checked={formData.outreachEvent === 'no'}
                  onChange={handleInputChange}
                  required
                />
                No
              </label>
            </div>
            <label className="required-asterisk">Contract number and Year Number:</label>
            <select
              name="year_contract"
              value={formData.year_contract}
              onChange={handleInputChange}
              required
            >
              {/* <option value="Contract 1 Year 3">Contract 1 Year 3</option> */}
              <option value="Contract 2 Year 1">Contract 2 Year 1</option>
            </select>
          </div>
        )}
        {currentSection === 2 && <Section2 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 3 && (
          <Section3
            formData={formData}
            handleInputChange={handleInputChange}
            clientSearchTerm={clientSearchTerm}
            handleClientSearchChange={handleClientSearchChange}
            matchingClients={matchingClients}
            handleClientSelect={handleClientSelect}
            isFetchingClients={isFetchingClients}
          />
        )}
        {currentSection === 4 && <Section4 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 5 && <Section5 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 6 && <Section6 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 7 && <Section7 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 8 && <Section8 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 9 && <Section9 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 10 && <Section10 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 11 && <Section11 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 27 && <Section27 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 28 && <Section28 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 29 && <Section29 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 30 && <Section30 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 31 && <Section31 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 32 && <Section32 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 33 && <Section33 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 34 && <Section34 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 35 && <Section35 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 36 && <Section36 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 37 && <Section37 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 38 && <Section38 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 39 && <Section39 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 55 && <Section55 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 56 && <Section56 formData={formData} handleInputChange={handleInputChange} />}

        <div className="navigation-buttons">
          {currentSection > 1 && (
            <button type="button" onClick={handleBack}>
              Back
            </button>
          )}
          {currentSection < 56 && (
            <button type="button" onClick={handleNext}>
              Next
            </button>
          )}
          {currentSection === 56 && (
            <button type="submit">
              Submit
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default Reports;
