import React, { useState, useEffect } from 'react';
import "./Issue_Ticket.css"; // Optional: External CSS file for styles
import logo from '../../assets/images/BCH Logo Final.png';
import { useQuery, useMutation } from '@apollo/client';
import { listGeneralInventories, listUsers, listPowerBanks, listLaptops, listPrinters, listTabletRemarkables, listSpeakers, listExternalDisks, listScanners, listDockingStations, listPhones, listHeadsets, listMice, listKeyboards, listMonitors, listExtensionCords, listBackupBatteries, listBags, listUSBS, listTickets } from '../../graphql/queries';
import { CREATE_TICKET } from '../../graphql/mutations';
import { capitalize } from '../../components/common/capitalize';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';


const Issue_Ticket = () => {

  const { loading1, error: userError, data: userData } = useQuery(listUsers);
  const [checkedEquipment, setCheckedEquipment] = useState([]);
  const navigate = useNavigate();
  const [softwareList, setSoftwareList] = useState([{ id: 1, selected: "", name: "" }]);
  const availableSoftware = ["Microsoft Office", "3CX", "Compass", "Adobe", "Heartland", "Outlook"];
  const [quantityError, setQuantityError] = useState('');
  const [priorityError, setPriorityError] = useState('');
  const [dateError, setDateError] = useState('');
  const [programError, setProgramError] = useState('');
  const [userGroups, setUserGroups] = useState([]);
  const [userDetails, setUserDetails] = useState(null);

  const [newIssue_Ticket, setNewIssue_Ticket] = useState({
    id: '',
    type: '',
    name: '',
    location: '',
    time: '',
    date: '',
    dateTimeSupervisor: new Date().toISOString(),
    dateTimeAdmin: new Date().toISOString(),
    supervisor_name: '',
    admin_name: '',
    type_issue: '',
    program_name: '',
    other_program_name: "",  // Para almacenar el valor cuando se selecciona "Other"
    other_request_item: "",// Para almacenar el valor cuando se selecciona "Other"
    equipment_name: '',
    equipment_tag: '',
    status: 'new',
    completed_date: '',
    request_item: '',
    quantity: '1',
    tried_troubleshooting: true,
    priority: '1',
    supervisor_approval: '',
    troubleshooting_problem1: '',
    troubleshooting_problem2: '',
    admin_troubleshooting_problem: '',
    troubleshooting_steps_taken1: '',
    troubleshooting_steps_taken2: '',
    admin_troubleshooting_steps_taken: '',
    troubleshooting_resolution1: '',
    troubleshooting_resolution2: '',
    admin_troubleshooting_resolution: '',
    date_needed: '',
    purchase_link: '',

  });

  const [addTicket] = useMutation(CREATE_TICKET, {
    refetchQueries: [{ query: listTickets, variables: { limit: 100 } }]
  });


  const users = React.useMemo(() => {
    return userData?.listUsers?.items || [];
  }, [userData]);

  async function fetchUserDetails() {
    try {
      const { signInDetails } = await getCurrentUser();
      const session = await fetchAuthSession();
      const groups = session.tokens.idToken.payload['cognito:groups'] || [];
      setUserGroups(groups);
      const [firstName, lastNameWithDomain] = signInDetails.loginId.split('.');
      const lastName = lastNameWithDomain.split('@')[0];
      setUserDetails({ firstName: capitalize(firstName), lastName: capitalize(lastName) });
    } catch (error) {
      console.log('User not authenticated', error);
    }
  }

  useEffect(() => {
    const foundUser = users?.find(
      (u) =>
        `${u.first_name} ${u.last_name}`.trim().toLowerCase() ===
        newIssue_Ticket.name.trim().toLowerCase()
    );

    if (foundUser) {
      setNewIssue_Ticket((prev) => ({
        ...prev,
        location: foundUser.location,
      }));
    }
  }, [newIssue_Ticket.name, users, setNewIssue_Ticket]);

  useEffect(() => {
    fetchUserDetails();
  }, []);


  //Roles
  const isManager = userGroups?.some(group => group === 'Manager');
  const isAdmin = userGroups?.some(group => group === 'Admin');

  // Listas de email de los supervisores
  const supervisorEmails = {
    "Dalia Mohamed": "dalia.mohamed@healthcollaborative.net",
    "Elizabeth Wong": "elizabeth.wong@healthcollaborative.net",
    "Ileana Avila": "ileana.avila@healthcollaborative.net",
  };
  /*"Fuji Walker": "fuji.walker@healthcollaborative.net",*/
  /* "Lisa Ortega": "lisa.ortega@healthcollaborative.net",*/
  /* "Gavriel Gutierrez": "gavriel.gutierrez@healthcollaborative.net",*/

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "program_name" && value === "other") {
      setNewIssue_Ticket({
        ...newIssue_Ticket,
        [name]: value,  // Almacenamos "other" en program_name
        other_program_name: "", // Limpiamos el valor de "other_program_name" si se cambia
      });
    } else {
      setNewIssue_Ticket({
        ...newIssue_Ticket,
        [name]: value,
      });
    }
    setCheckedEquipment([]); // Reset selected equipment on employee change
  };

  const handleEquipmentCheck = (equipment, e) => {
    const equipmentId = `${equipment.tagid || equipment.tagId || equipment.brand}-${equipment.__typename || 'unknown'}-${equipment.id || 'unknown'}`;

    setCheckedEquipment((prevChecked) => {
      const alreadyChecked = prevChecked.includes(equipmentId);
      let updatedChecked;

      if (alreadyChecked) {
        updatedChecked = prevChecked.filter((id) => id !== equipmentId);
        setNewIssue_Ticket((prevTicket) => ({
          ...prevTicket,
          equipment_name: '',
          equipment_tag: '',
        }));
      } else {
        updatedChecked = [...prevChecked, equipmentId];
        setNewIssue_Ticket((prevTicket) => ({
          ...prevTicket,
          equipment_name: equipment.__typename || '',
          equipment_tag: equipment.tagid || equipment.tagId || '',
        }));
      }
      return updatedChecked;
    });
  };

  const clearNewIssue_Ticket = () => {
    setNewIssue_Ticket({
      id: '',
      type: '',
      name: '',
      location: '',
      time: '',
      date: '',
      dateTimeSupervisor: '',
      dateTimeAdmin: '',
      supervisor_name: '',
      admin_name: '',
      type_issue: '',
      program_name: '',
      equipment_name: '',
      equipment_tag: '',
      status: 'new',
      completed_date: '',
      request_item: '',
      other_program_name: "",
      other_request_item: "",
      quantity: '1',
      tried_troubleshooting: true,
      priority: '1',
      supervisor_approval: '',
      troubleshooting_problem1: '',
      troubleshooting_problem2: '',
      admin_troubleshooting_problem: '',
      troubleshooting_steps_taken1: '',
      troubleshooting_steps_taken2: '',
      admin_troubleshooting_steps_taken: '',
      troubleshooting_resolution1: '',
      troubleshooting_resolution2: '',
      admin_troubleshooting_resolution: '',
      date_needed: '',
      purchase_link: '',
    });
  };


  const handleAddTicket = async () => {
    try {
      if (!newIssue_Ticket) {
        console.error("No ticket data available.");
        return;
      }

      const program_new = newIssue_Ticket.program_name === "other"
        ? newIssue_Ticket.other_program_name
        : newIssue_Ticket.program_name;

      const request_item_new = newIssue_Ticket.request_item === "other"
        ? newIssue_Ticket.other_request_item
        : newIssue_Ticket.request_item;

      const formattedTime = newIssue_Ticket.time || null;
      const formattedDate = newIssue_Ticket.date
        ? new Date(newIssue_Ticket.date).toISOString().split('T')[0]
        : null;
      const formattedDate_Needed = newIssue_Ticket.date_needed
        ? new Date(newIssue_Ticket.date_needed).toISOString().split('T')[0]
        : null;
      const formattedCompleted_Date = newIssue_Ticket.completed_date
        ? new Date(newIssue_Ticket.completed_date).toISOString().split('T')[0]
        : null;

      if (!Number.isInteger(parseInt(newIssue_Ticket.quantity, 10))) {
        setQuantityError('The Quantity must be an integer');
        return;
      } else {
        setQuantityError('');
      }

      if (!newIssue_Ticket.priority || !Number.isInteger(parseInt(newIssue_Ticket.priority, 10))) {
        setPriorityError('The Priority must be an integer and cannot be empty.');
        return;
      } else {
        setPriorityError('');
      }

      if (!newIssue_Ticket.date) {
        setDateError('The Date cannot be empty.');
        return;
      } else {
        setDateError('');
      }

      if (newIssue_Ticket.type_issue === "software") {
        if (!newIssue_Ticket.program_name || newIssue_Ticket.program_name.trim() === "") {
          setProgramError("The Software cannot be empty.");
          return;
        }
      } 
      setProgramError("");
      
      const updatedFormData = {
        type: newIssue_Ticket.type,
        name: newIssue_Ticket.name,
        location: newIssue_Ticket.location,
        time: formattedTime,
        date: formattedDate,
        dateTimeSupervisor: null,
        dateTimeAdmin: null,
        supervisor_name: newIssue_Ticket.supervisor_name,
        admin_name: newIssue_Ticket.admin_name,
        type_issue: newIssue_Ticket.type_issue,
        program_name: program_new,
        equipment_name: newIssue_Ticket.equipment_name,
        equipment_tag: newIssue_Ticket.equipment_tag,
        status: newIssue_Ticket.status,
        completed_date: formattedCompleted_Date,
        request_item: request_item_new,
        quantity: newIssue_Ticket.quantity,
        tried_troubleshooting: newIssue_Ticket.tried_troubleshooting,
        priority: newIssue_Ticket.priority,
        date_needed: formattedDate_Needed,
        supervisor_approval: newIssue_Ticket.supervisor_approval,
        troubleshooting_problem1: newIssue_Ticket.troubleshooting_problem1,
        troubleshooting_problem2: newIssue_Ticket.troubleshooting_problem2,
        admin_troubleshooting_problem: newIssue_Ticket.admin_troubleshooting_problem,
        troubleshooting_steps_taken1: newIssue_Ticket.troubleshooting_steps_taken1,
        troubleshooting_steps_taken2: newIssue_Ticket.troubleshooting_steps_taken2,
        admin_troubleshooting_steps_taken: newIssue_Ticket.admin_troubleshooting_steps_taken,
        troubleshooting_resolution1: newIssue_Ticket.troubleshooting_resolution1,
        troubleshooting_resolution2: newIssue_Ticket.troubleshooting_resolution2,
        admin_troubleshooting_resolution: newIssue_Ticket.admin_troubleshooting_resolution,
        purchase_link: newIssue_Ticket.purchase_link,

      };

      // Enviar ticket a la base de datos
      const { data } = await addTicket({
        variables: { input: updatedFormData }
      });

      const ticketId = data?.createTicket?.id; // ID del Ticket
      const displayTicketId = ticketId.toString(36).toUpperCase().slice(-6);

      clearNewIssue_Ticket();
      alert("The ticket was added successfully.");

      console.log(newIssue_Ticket.priority);

      const supervisorName = newIssue_Ticket.supervisor_name;
      const supervisorEmail = supervisorEmails[supervisorName];

      const base64Content = newIssue_Ticket.errorPhoto
      ? newIssue_Ticket.errorPhoto.replace(/^data:image\/\w+;base64,/, "")
      : null;

      if (newIssue_Ticket.errorPhoto) {
        sessionStorage.setItem("temporaryImage", base64Content);
      }


      if (!supervisorEmail) {
        console.error("No email found for the selected supervisor:", supervisorName);
        alert("Invalid supervisor selected. Please check.");
        return;
      }
      else {
        const emailContent = {
          to: supervisorEmail,
          subject: `New Ticket Created by ${newIssue_Ticket.supervisor_name}`,
          body: `
          <html>
            <body style="font-family: Arial, sans-serif; margin: 0; padding: 0;">
              <table width="100%" cellspacing="0" cellpadding="0" border="0" >
                <tr>
                  <td align="center">
                    <table width="600" cellspacing="0" cellpadding="0" border="0" bgcolor="#ffffff" style="border: 1px solid #ddd;">
                      <tr>
                        <td align="center" bgcolor="#1a2a6c" style="padding: 20px;">
                          <h1 style="color: white; margin: 0; font-size: 24px;">New Ticket Notification</h1>
                        </td>
                      </tr>
        
                      <tr>
                        <td style="padding: 30px;">
                          <p style="margin: 0 0 10px; font-size: 16px;">A ticket requires your attention. Please review the details below:</p>
                          
                          <table width="100%" border="1" cellpadding="10" cellspacing="0" style="border-collapse: collapse;">
                            <tr>
                              <th align="left" bgcolor="#1a2a6c" style="color: white;">Ticket ID</th>
                              <td>${displayTicketId}</td>
                            </tr>
                            <tr>
                              <th align="left" bgcolor="#1a2a6c" style="color: white;">Employee Name:</th>
                              <td>${newIssue_Ticket.name}</td>
                            </tr>
                            <tr>
                              <th align="left" bgcolor="#1a2a6c" style="color: white;">Location:</th>
                              <td>${newIssue_Ticket.location}</td>
                            </tr>
                            <tr>
                              <tr>
                              <th align="left" bgcolor="#1a2a6c" style="color: white;">Date:</th>
                              <td>${newIssue_Ticket.date}</td>
                            </tr>
                              <th align="left" bgcolor="#1a2a6c" style="color: white;">Priority:</th>
                              <td>
                                ${newIssue_Ticket.priority === "1" ? 'Low Priority' :
              newIssue_Ticket.priority === "2" ? 'Medium Priority' :
                newIssue_Ticket.priority === "3" ? 'High Priority' :
                  'Unknown Priority'
            }
                              </td>
                            </tr>
                            <tr>
                              <th align="left" bgcolor="#1a2a6c" style="color: white;">Report Type:</th>
                              <td>${newIssue_Ticket.type}</td>
                            </tr>
                             ${newIssue_Ticket.type_issue ? `
                          <tr>
                            <th align="left" bgcolor="#1a2a6c" style="color: white;">Issue Type:</th>
                            <td>${capitalize(newIssue_Ticket.type_issue)}</td>
                          </tr>
                           ` : ''}
                            ${newIssue_Ticket.equipment_name ? `
                              <tr>
                                <th align="left" bgcolor="#1a2a6c" style="color: white;">Equipment:</th>
                                <td>${capitalize(newIssue_Ticket.equipment_name)}</td>
                              </tr>
                              ` : ''}
                              
                            ${newIssue_Ticket.program_name ? `
                              <tr>
                                <th align="left" bgcolor="#1a2a6c" style="color: white;">Software/WebSite:</th>
                                <td>${capitalize(newIssue_Ticket.program_name)}</td>
                              </tr>
                              ` : ''}
                                 ${newIssue_Ticket.request_item ? `
                              <tr>
                                <th align="left" bgcolor="#1a2a6c" style="color: white;">Request a Item:</th>
                                <td>${capitalize(newIssue_Ticket.request_item)}</td>
                              </tr>
                              ` : ''}
                                 ${newIssue_Ticket.status ? `
                              <tr>
                                <th align="left" bgcolor="#1a2a6c" style="color: white;">Status:</th>
                                <td>${capitalize(newIssue_Ticket.status)}</td>
                              </tr>
                              ` : ''}
                              
                          </table>
                       

                          <p style="margin-top: 30px; font-size: 14px;">
                            Click the link below to update the ticket:
                          </p>
        
                          <table cellspacing="0" cellpadding="0" border="0" width="100%">
                            <tr>
                              <td align="center">
                                <a href="https://compasshealthcollaborative.org/issue-ticket-list?ticketId=${ticketId}"
                                   style="display: inline-block; padding: 12px 25px; font-size: 16px; color: white; background-color: #1a2a6c; text-decoration: none; border: 1px solid #007bff;">
                                  Open Ticket #${displayTicketId}
                                </a>
                              </td>
                            </tr>
                          </table>
                           ${base64Content ? `
                             <p style="margin-top: 20px; font-size: 16px;">Attached Image:</p>
                <img src="data:image/png;base64,${base64Content}" alt="Error Photo" style="max-width: 100%; height: auto;"/>
           ` : ''}
                          <p style="margin-top: 40px; font-size: 12px; color: #666; text-align: center;">
                            This is an automated email. Please do not reply.
                             &copy; 2025 Compass Health Collaborative. All Rights Reserved.
                          </p>
                          
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </body>
          </html>
          `,
        };


        await invokeLambdaFunction(emailContent);
        console.log("Notification sent to supervisor.");
      }

    } catch (error) {
      console.error("Error adding ticket:", error);
      alert("Failed to add the ticket.");
    }
  };


  const { data: dataLaptops } = useQuery(listLaptops);
  const { data: dataPhones } = useQuery(listPhones);
  const { data: dataPrinters } = useQuery(listPrinters);
  const { data: dataScanners } = useQuery(listScanners);
  const { data: dataMonitors } = useQuery(listMonitors);
  const { data: dataHeadsets } = useQuery(listHeadsets);
  const { data: dataMice } = useQuery(listMice);
  const { data: dataKeyboards } = useQuery(listKeyboards);
  const { data: dataPowerBanks } = useQuery(listPowerBanks);
  const { data: dataTablet } = useQuery(listTabletRemarkables);
  const { data: dataSpeakers } = useQuery(listSpeakers);
  const { data: dataExternalDisk } = useQuery(listExternalDisks);
  const { data: dataDockingStations } = useQuery(listDockingStations);
  const { data: dataExtensionCords } = useQuery(listExtensionCords);
  const { data: dataBackupBatteries } = useQuery(listBackupBatteries);
  const { data: dataUSB } = useQuery(listUSBS);
  const { data: dataBag } = useQuery(listBags);
  const { data: dataGeneralInventory } = useQuery(listGeneralInventories);


  const laptops = dataLaptops?.listLaptops || [];
  const printers = dataPrinters?.listPrinters || [];
  const monitors = dataMonitors?.listMonitors || [];
  const phones = dataPhones?.listPhones || [];
  const scanners = dataScanners?.listScanners || [];
  const headsets = dataHeadsets?.listHeadsets || [];
  const mice = dataMice?.listMice || [];
  const keyboards = dataKeyboards?.listKeyboards || [];
  const powerbanks = dataPowerBanks?.listPowerBanks || [];
  const tablets = dataTablet?.listTabletRemarkables || [];
  const speakers = dataSpeakers?.listSpeakers || [];
  const externalDisks = dataExternalDisk?.listExternalDisks || [];
  const dockingStations = dataDockingStations?.listDockingStations || [];
  const extensionCords = dataExtensionCords?.listExtensionCords || [];
  const backupBaterries = dataBackupBatteries?.listBackupBatteries || [];
  const usb = dataUSB?.listUSBS || [];
  const bags = dataBag?.listBags || [];
  const otherEquipment = dataGeneralInventory?.listGeneralInventories || [];


  const filterAssignedEquipment = (equipmentType) => {
    const employeeFullName = newIssue_Ticket.name;


    const items = {
      laptops: laptops?.items,
      printers: printers?.items,
      monitors: monitors?.items,
      phones: phones?.items,
      scanners: scanners?.items,
      headsets: headsets?.items,
      mice: mice?.items,
      keyboards: keyboards?.items,
      powerbanks: powerbanks?.items,
      speakers: speakers?.items,
      dockingStations: dockingStations?.items,
      extensionCords: extensionCords?.items,
      backupBaterries: backupBaterries?.items,
      tablets: tablets?.items,
      externalDisks: externalDisks?.items,
      usb: usb?.items,
      bags: bags?.items,
      otherEquipment: otherEquipment?.items,
    }[equipmentType];

    if (!Array.isArray(items)) {
      console.log(`No items found for ${equipmentType}.`);
      return [];
    }

    // Filtrar por empleado
    const filteredItems = items.filter(
      (item) =>
        item.assigned_to.trim().toLowerCase() ===
        employeeFullName.trim().toLowerCase()
    );

    // Debugging: Mostrar el resultado del filtro
    console.log(`Filtered ${equipmentType}:`, filteredItems);

    return filteredItems;
  };


  const allFilteredEquipment = React.useMemo(() => {
    return {
      laptops: filterAssignedEquipment("laptops"),
      printers: filterAssignedEquipment("printers"),
      monitors: filterAssignedEquipment("monitors"),
      phones: filterAssignedEquipment("phones"),
      scanners: filterAssignedEquipment("scanners"),
      headsets: filterAssignedEquipment("headsets"),
      mice: filterAssignedEquipment("mice"),
      keyboards: filterAssignedEquipment("keyboards"),
      powerbanks: filterAssignedEquipment("powerbanks"),
      speakers: filterAssignedEquipment("speakers"),
      dockingStations: filterAssignedEquipment("dockingStations"),
      extensionCords: filterAssignedEquipment("extensionCords"),
      backupBaterries: filterAssignedEquipment("backupBaterries"),
      tablets: filterAssignedEquipment("tablets"),
      externalDisks: filterAssignedEquipment("externalDisks"),
      usb: filterAssignedEquipment("usb"),
      bags: filterAssignedEquipment("bags"),
      otherEquipment: filterAssignedEquipment("otherEquipment"),

    };
  }, [newIssue_Ticket.name, laptops, printers, monitors, phones, scanners, headsets, mice, keyboards, powerbanks, speakers, dockingStations, extensionCords, backupBaterries, tablets, externalDisks, usb, bags, otherEquipment]);

  const friendlyNames = {
    laptops: "Laptops",
    printers: "Printers",
    monitors: "Monitors",
    phones: "Phones",
    scanners: "Scanners",
    headsets: "Headsets",
    mice: "Mice",
    keyboards: "Keyboards",
    powerbanks: "Power Banks",
    speakers: "Speakers",
    dockingStations: "Docking Stations",
    extensionCords: "Extension Cords",
    backupBaterries: "Backup Baterries",
    tablets: "Tablets",
    externalDisks: "External Disks",
    usb: "USB Drive",
    bags: "Bags",
    otherEquipment: "Other Equipment",
  };

  // Lambda que enviará un correo al supervisor usando Amazon SES 
  const invokeLambdaFunction = async (emailData) => {
    const url = "https://buykqjzjjh.execute-api.us-east-2.amazonaws.com/compassdev/sendemail";

    try {
      // Obtener token de autenticación (si es necesario)
      const session = await fetchAuthSession();
      if (!session || !session.tokens || !session.tokens.idToken) {
        throw new Error("User is not authenticated or token is missing.");
      }
      const idToken = session.tokens.idToken.toString();

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailData),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to send email. Error: ${errorText}`);
      }

      const data = await response.json();
      console.log("Lambda function response:", data);
      alert("Notification email sent successfully.");
    } catch (error) {
      console.error("Error invoking Lambda:", error.message);
      alert("Failed to send notification email.");
    }
  };

  return (
    <div className="issue-container">
      <header className="reports-header">
        <h1>System Issue Report - FY 23</h1>
      </header>
      <div className="issue-header">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      {/* View Previous*/}
      <div className="previous-report">
        <button onClick={() => navigate('/issue-ticket-list')} >View Previous System Issue Report</button>
      </div>

      {/* Select Report Type */}
      <div className="issue-section">
        <h3>Select Report Type:</h3>
        <select
          id="type"
          name="type"
          value={newIssue_Ticket.type || ""}
          onChange={handleInputChange}
        >
          <option value="" disabled hidden>
            Select an option
          </option>
          <option value="problem">Issue Ticket</option>
          <option value="request">Request Ticket</option>

        </select>
      </div>

      {/* Conditionally Render Select Issue Type */}
      {newIssue_Ticket.type === "problem" && (
        <div className="issue-section">
          <h3>Issue Type:</h3>
          <select
            id="type_issue"
            name="type_issue"
            value={newIssue_Ticket.type_issue}
            onChange={handleInputChange}  // aqui me quede 
          >
            <option value="" disabled hidden>
              Select an option
            </option>
            <option value="equipment">Equipment</option>
            <option value="software">Software</option>
          </select>
        </div>
      )}
      {/* Conditionally Render Sections */}
      {newIssue_Ticket.type === "problem" && (newIssue_Ticket.type_issue === "software" || newIssue_Ticket.type_issue === "equipment") && (
        <>

          {/* Employee Section */}
          <div className="issue-section">
            <div className="input-issue">
              <label htmlFor="employee-name">Employee Name:</label>
              <select
                id="name"
                name="name"
                value={newIssue_Ticket.name}
                onChange={handleInputChange}
              >
                <option value="">Select an Employee</option>

                {users.length > 0 ? (
                  [...users]

                    .sort((a, b) => {
                      const nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
                      const nameB = `${b.first_name} ${b.last_name}`.toLowerCase();
                      return nameA.localeCompare(nameB);
                    })
                    .map((user) => (
                      <option
                        key={user.id}
                        value={`${user.first_name} ${user.last_name}`}
                        data-location={user.location} // Guarda la ubicación como atributo
                      >
                        {`${capitalize(user.first_name)} ${capitalize(user.last_name)}`}
                      </option>
                    ))
                ) : (
                  <option value="">No users available</option>
                )}
                <option value="n/a">N/A</option>
              </select>
            </div>

            <div className="input-issue">
              <label htmlFor="location">Location:</label>
              <input
                type="text"
                id="location"
                name="location"
                value={newIssue_Ticket.location}
                onChange={handleInputChange}
              />

            </div>

            <div className="input-issue">
              <label htmlFor="supervisor">Supervisor Name:</label>
              <select
                id="supervisor_name"
                name="supervisor_name"
                value={newIssue_Ticket.supervisor_name}
                onChange={(e) => handleInputChange(e)}

              >
                <option value="" disabled hidden>Select a Name</option>
                <option value="Dalia Mohamed">Dalia Mohamed</option>
                <option value="Elizabeth Wong">Elizabeth Wong</option>
                <option value="Ileana Avila">Ileana Avila</option>
              </select>

            </div>

            <div className="input-issue">
              <label htmlFor="time-submitted">Time Submitted:</label>
              <input type="time" id="time" name="time" value={newIssue_Ticket.time} onChange={handleInputChange} />

              <label htmlFor="date-submitted">Date:</label>
              <input type="date" id="date" name="date" value={newIssue_Ticket.date} onChange={handleInputChange} />
            </div>
          </div>

          {/* Lista de Equipos Asignados */}
          {newIssue_Ticket.type_issue === "equipment" && (
            <div className="issue-section">
              {newIssue_Ticket.name && (
                <div className="assigned-equipment">
                  <h3>Equipment Assigned:</h3>
                  <div className="equipment-container">
                    {Object.entries(allFilteredEquipment).map(([equipmentType, items]) =>
                      items.length > 0 ? (
                        <div key={equipmentType} className="equipment-category">
                          <label className="equipment-label">
                            {friendlyNames[equipmentType] || equipmentType}
                          </label>
                          <ul>
                            {items.map((equipment) => {
                              const equipmentId = `${equipment.tagid || equipment.tagId || equipment.brand}-${equipment.__typename || 'unknown'}-${equipment.id || 'unknown'}`;

                              return (
                                <li key={equipment.id || equipmentId}>
                                  <label className="equipment-item">
                                    <input
                                      type="checkbox"
                                      checked={checkedEquipment.includes(equipmentId)}
                                      onChange={(e) => handleEquipmentCheck(equipment, e)}
                                    />
                                    <span>{equipment.tagid || equipment.tagId || equipment.type}</span>
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      ) : null
                    )}

                  </div>
                </div>
              )}
            </div>
          )}

          {newIssue_Ticket.type_issue === "software" && (
            <div className="issue-section">
              <h3>Software/Website</h3>
              <ul>
                {softwareList.map((software, index) => (
                  <li key={software.id || index} className="software-item">
                    <label className="software-row">
                      {/* Select Software */}
                      <select
                        value={newIssue_Ticket.program_name || ""}
                        name="program_name"
                        onChange={(e) => handleInputChange(e)}
                      >
                        <option value="" disabled hidden>
                          Select software/website
                        </option>
                        {availableSoftware.map((softwareOption) => (
                          <option key={softwareOption} value={softwareOption}>
                            {softwareOption}
                          </option>
                        ))}
                        <option value="other">Other</option>
                      </select>
                    </label>

                    {/* Show input if "Other" is selected */}
                    {newIssue_Ticket.program_name === "other" && (
                      <input
                        type="text"
                        placeholder="Enter software/website name"
                        value={newIssue_Ticket.other_program_name || ""}
                        name="other_program_name"
                        onChange={(e) => {
                          // Actualiza el estado para "Other"
                          setNewIssue_Ticket({
                            ...newIssue_Ticket,
                            other_program_name: e.target.value, // Actualizamos el valor solo cuando "Other" es seleccionado
                          });
                        }}
                      />
                    )}
                  </li>
                ))}

              </ul>
              {/* Image Upload Section */}
              <div className="upload-photo-section">
                <label htmlFor="errorPhoto">Upload a photo of the error (optional):</label>
                <input
                  type="file"
                  id="errorPhoto"
                  accept="image/*"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      // Handle file upload (store in state or process it)
                      const reader = new FileReader();
                      reader.onload = () => {
                        setNewIssue_Ticket({
                          ...newIssue_Ticket,
                          errorPhoto: reader.result, // Base64 representation of the image
                          errorPhotoName: file.name,
                        });
                      };
                      reader.readAsDataURL(file);
                    }
                  }}
                />
                {newIssue_Ticket.errorPhoto && (
                  <div className="photo-preview">
                    <h4>Preview:</h4>
                    <img
                      src={newIssue_Ticket.errorPhoto}
                      alt="Error Preview"
                      style={{ maxWidth: "200px", maxHeight: "200px", marginTop: "10px" }}
                    />
                  </div>
                )}
              </div>

            </div>
          )}
          {/* Troubleshooting Section */}
          {newIssue_Ticket.type === "problem" && (
            <>
              <div className="issue-section">
                <h3>1st Troubleshoot</h3>
                <label>Tried Troubleshooting:</label>
                <select
                  name="tried_troubleshooting"
                  placeholder="tried_troubleshooting"
                  value={newIssue_Ticket.tried_troubleshooting}
                  onChange={handleInputChange}
                >
                  <option value="" disabled hidden className="placeholder-option">Select</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>

                <label htmlFor="priority">Priority:</label>
                <select
                  id="priority"
                  name="priority"
                  value={newIssue_Ticket.priority}
                  onChange={handleInputChange}
                  aria-label="Select ticket priority"
                >
                  <option value="" disabled hidden>Select Priority</option>
                  <option value="1">1 - Low Priority</option>
                  <option value="2">2 - Medium Priority</option>
                  <option value="3">3 - High Priority</option>
                </select>


                <label htmlFor="problem">Problem:</label>
                <textarea
                  id="troubleshooting_problem1"
                  name="troubleshooting_problem1"
                  placeholder="Describe the problem"
                  value={newIssue_Ticket.troubleshooting_problem1}
                  onChange={handleInputChange}
                ></textarea>

                <label htmlFor="steps-taken">Steps Taken:</label>
                <textarea
                  id="troubleshooting_steps_taken1"
                  name="troubleshooting_steps_taken1"
                  placeholder="Describe the steps taken"
                  value={newIssue_Ticket.troubleshooting_steps_taken1}
                  onChange={handleInputChange}
                ></textarea>

                <label htmlFor="resolution">Resolution:</label>
                <textarea
                  id="troubleshooting_resolution1"
                  name="troubleshooting_resolution1"
                  placeholder="Describe the resolution"
                  value={newIssue_Ticket.troubleshooting_resolution1}
                  onChange={handleInputChange}
                ></textarea>
              </div>
              {/* Mostrar esta sección solo si el usuario es Supervisor */}
              {isManager && (
                <div className="issue-section">
                  <h3>2nd Troubleshoot (Only for supervisors)</h3>
                  <label htmlFor="priority">Priority:</label>
                  <select
                    id="priority"
                    name="priority"
                    value={newIssue_Ticket.priority}
                    onChange={handleInputChange}
                    aria-label="Select ticket priority"
                  >
                    <option value="" disabled hidden>Select Priority</option>
                    <option value="1">1 - Low Priority</option>
                    <option value="2">2 - Medium Priority</option>
                    <option value="3">3 - High Priority</option>
                  </select>


                  <label htmlFor="problem">Problem:</label>
                  <textarea
                    id="troubleshooting_problem2"
                    name="troubleshooting_problem2"
                    placeholder="Describe the problem"
                    value={newIssue_Ticket.troubleshooting_problem2}
                    onChange={handleInputChange}
                  ></textarea>

                  <label htmlFor="steps-taken">Steps Taken:</label>
                  <textarea
                    id="troubleshooting_steps_taken2"
                    name="troubleshooting_steps_taken2"
                    placeholder="Describe the steps taken"
                    value={newIssue_Ticket.troubleshooting_steps_taken2}
                    onChange={handleInputChange}
                  ></textarea>

                  <label htmlFor="resolution">Resolution:</label>
                  <textarea
                    id="troubleshooting_resolution2"
                    name="troubleshooting_resolution2"
                    placeholder="Describe the resolution"
                    value={newIssue_Ticket.troubleshooting_resolution2}
                    onChange={handleInputChange}
                  ></textarea>
                  <label htmlFor="status">Status:</label>
                  <select
                    name="status"
                    id="status"
                    value="new"
                    onChange={handleInputChange}
                    aria-label="Select ticket status"
                    disabled
                  >
                    <option value="new">New</option>
                    <option value="in_progress">In Progress</option>
                    <option value="approved">Approved</option>
                    <option value="rejected">Rejected</option>
                    <option value="completed">Completed</option>
                  </select>
                </div>
              )}

              {/* Mostrar esta sección solo si el usuario es Admin */}
              {isAdmin && (
                <div className="issue-section">
                  <h3>Admin Section (Only for Administration)</h3>
                  <label htmlFor="priority">Priority:</label>
                  <select
                    id="priority"
                    name="priority"
                    value={newIssue_Ticket.priority}
                    onChange={handleInputChange}
                    aria-label="Select ticket priority"
                  >
                    <option value="" disabled hidden>Select Priority</option>
                    <option value="1">1 - Low Priority</option>
                    <option value="2">2 - Medium Priority</option>
                    <option value="3">3 - High Priority</option>
                  </select>


                  <label htmlFor="problem">Problem Found:</label>
                  <textarea
                    id="admin_troubleshooting_problem"
                    name="admin_troubleshooting_problem"
                    placeholder="Describe the problem"
                    value={newIssue_Ticket.admin_troubleshooting_problem}
                    onChange={handleInputChange}
                  ></textarea>

                  <label htmlFor="steps-taken">Steps Taken:</label>
                  <textarea
                    id="admin_troubleshooting_steps_taken"
                    name="admin_troubleshooting_steps_taken"
                    placeholder="Describe the steps taken"
                    value={newIssue_Ticket.admin_troubleshooting_steps_taken}
                    onChange={handleInputChange}
                  ></textarea>

                  <label htmlFor="resolution">Resolution:</label>
                  <textarea
                    id="admin_troubleshooting_resolution"
                    name="admin_troubleshooting_resolution"
                    placeholder="Describe the resolution"
                    value={newIssue_Ticket.admin_troubleshooting_resolution}
                    onChange={handleInputChange}
                  ></textarea>

                  <label htmlFor="status">Status:</label>
                  <select
                    name="status"
                    id="status"
                    value={newIssue_Ticket.status || ""}
                    onChange={handleInputChange}
                    aria-label="Select ticket status"
                  >
                    <option value="" disabled hidden className="placeholder-option">Select Status</option>
                    <option value="new">New</option>
                    <option value="in_progress">In Progress</option>
                    <option value="approved">Approved</option>
                    <option value="rejected">Rejected</option>
                    <option value="completed">Completed</option>
                  </select>

                  {newIssue_Ticket.status === "completed" && (
                    <div className="date-completed_date">
                      <label htmlFor="completed_date">Completed Date:</label>
                      <input
                        type="date"
                        id="completed_date"
                        name="completed_date"
                        value={newIssue_Ticket.completed_date || ""}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  )}
                </div>
              )}
            </>
          )}


        </>
      )
      }

      {
        newIssue_Ticket.type === "request" && (
          <>
            {/* Supplies Request Section */}
            <div className="issue-section">
              <div className="issue-section">
                <div className="input-issue">
                  <label htmlFor="employee-name">Employee Name:</label>
                  <select
                    id="name"
                    name="name"
                    value={newIssue_Ticket.name}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select an Employee</option>

                    {users.length > 0 ? (
                      [...users]

                        .sort((a, b) => {
                          const nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
                          const nameB = `${b.first_name} ${b.last_name}`.toLowerCase();
                          return nameA.localeCompare(nameB);
                        })
                        .map((user) => (
                          <option
                            key={user.id}
                            value={`${user.first_name} ${user.last_name}`}
                            data-location={user.location} // Guarda la ubicación como atributo
                          >
                            {`${capitalize(user.first_name)} ${capitalize(user.last_name)}`}
                          </option>
                        ))
                    ) : (
                      <option value="">No users available</option>
                    )}
                    <option value="n/a">N/A</option>
                  </select>
                </div>

                <div className="input-issue">
                  <label htmlFor="location">Location:</label>
                  <input
                    type="text"
                    id="location"
                    name="location"
                    value={newIssue_Ticket.location}
                    onChange={handleInputChange}

                  />

                </div>

                <div className="input-issue">
                  <label htmlFor="supervisor">Supervisor Name:</label>
                  <select
                    id="supervisor_name"
                    name="supervisor_name"
                    value={newIssue_Ticket.supervisor_name}
                    onChange={(e) => handleInputChange(e)}
                    required

                  >
                    <option value="" disabled hidden>Select a Name</option>
                    <option value="Dalia Mohamed">Dalia Mohamed</option>
                    <option value="Elizabeth Wong">Elizabeth Wong</option>
                    <option value="Ileana Avila">Ileana Avila</option>

                  </select>
                </div>


                <div className="input-issue">
                  <label htmlFor="time-submitted">Time Submitted:</label>
                  <input type="time" id="time" name="time" value={newIssue_Ticket.time} onChange={handleInputChange} required />

                  <label htmlFor="date-submitted">Date:</label>
                  <input type="date" id="date" name="date" value={newIssue_Ticket.date} onChange={handleInputChange} required />
                </div>
              </div>


              <h3>Request Supplies</h3>
              <label htmlFor="request_item">Select Item Type:</label>
              <select id="request_item" name="request_item" value={newIssue_Ticket.request_item} onChange={(e) => handleInputChange(e)}>
                <option value="" disabled hidden>
                  Select an item
                </option>
                <option value="Monitor Charger Cable">Monitor Charger</option>
                <option value="Laptop Charger Cable">Laptop Charger</option>
                <option value="Phone Charger Cable">Phone Charger</option>
                <option value="HDMI">HDMI Cable</option>
                <option value="C-Cable">C-C Cable</option>
                <option value="Wireless usb">Wireless Receiver USB</option>
                <option value="Ink black">Ink Black</option>
                <option value="Ink color">Ink Color</option>
                <option value="Supplies">Office Supplies</option>
                <option value="other">Other</option> {/* Opción para agregar algo personalizado */}
              </select>

              {/* Mostrar el campo de entrada si se selecciona "Other" */}
              {newIssue_Ticket.request_item === "other" && (
                <div style={{ marginTop: "10px" }}>
                  <label htmlFor="custom-item">Specify your item:</label>
                  <input
                    type="text"
                    id="other_request_item"
                    name="other_request_item"
                    value={newIssue_Ticket.other_request_item || ""}
                    onChange={(e) => {
                      // Actualiza el estado para "Other"
                      setNewIssue_Ticket({
                        ...newIssue_Ticket,
                        other_request_item: e.target.value, // Actualizamos el valor solo cuando "Other" es seleccionado
                      });
                    }}
                  />
                </div>
              )}
              <label htmlFor="quantity">Quantity:</label>
              <input
                type="number"
                id="quantity"
                name="quantity"
                placeholder="Enter quantity"
                value={newIssue_Ticket.quantity}
                onChange={handleInputChange}
              />
              <label htmlFor="date-needed">Date Needed:</label>
              <input
                type="date"
                id="date-needed"
                name="date_needed"
                value={newIssue_Ticket.date_needed}
                onChange={handleInputChange}
              />
              <label htmlFor="purchase-link">Suggested Purchase Link:</label>
              <input
                type="url"
                id="purchase-link"
                name="purchase_link"
                placeholder="Enter a purchase link (optional)"
                value={newIssue_Ticket.purchase_link}
                onChange={handleInputChange}

              />
              <label htmlFor="notes">Additional Notes:</label>
              <textarea
                id="notes"
                placeholder="Provide additional details"
              ></textarea>
            </div>
          </>
        )
      }


      {priorityError && <p className="error">{priorityError}</p>} {/* Display priorityError error message */}
      {quantityError && <p className="error">{quantityError}</p>} {/* Display quantityError error message */}
      {dateError && <p className="error">{dateError}</p>} {/* Display dateError error message */}
      {programError && <p className="error">{programError}</p>} {/* Display programError error message */}
      <button type="submit" onClick={handleAddTicket}>Submit Report</button>
    </div >
  );
};

export default Issue_Ticket;
