import React, { useState, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import * as XLSX from 'xlsx';
import {
  GET_PROMOTIONAL_REPORTS_BY_DATE,
  CHECK_FILE_EXISTENCE_BY_NAME,
  GET_ALL_UNIQUE_FILENAMES
} from '../../../graphql/queries';
import { CREATE_PROMOTIONAL_REPORT } from '../../../graphql/mutations';
import LoadingSpinner from '../../../components/layout/LoadingSpinner'; // Adjust the path based on your folder structure
import {
  FaUpload, FaInstagram, FaNewspaper, FaBookReader
}
  from 'react-icons/fa';
import { FaRadio } from "react-icons/fa6";
import './Promo_Activities.css';

const PromoActivities = () => {
  const [dateRange, setDateRange] = useState({ start: '', end: '' });
  const [reports, setReports] = useState([]);
  const [file, setFile] = useState(null);
  const [showUploadOverlay, setShowUploadOverlay] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uniqueFileNames, setUniqueFileNames] = useState([]);
  const [totalCustomersTargeted, setTotalCustomersTargeted] = useState(0);
  const [totalPromotionalActivities, setTotalPromotionalActivities] = useState(0);
  const [totalFlyers, setTotalFlyers] = useState(0); // Add a state for Flyers count
  const [countyData, setCountyData] = useState([]); // State to hold county-wise data
  const [totalSocialMedia, setTotalSocialMedia] = useState(0); // Add a state for Social Media count
  const [totalRadio, setTotalRadio] = useState(0); // Add a state for Radio count

  const [checkFileExistenceByName] = useLazyQuery(CHECK_FILE_EXISTENCE_BY_NAME, {
    fetchPolicy: "network-only"
  });

  const [fetchUniqueFileNames, { data: fileNameData, loading: fileNamesLoading }] = useLazyQuery(
    GET_ALL_UNIQUE_FILENAMES,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (data) {
          const allFileNames = data.listPromotionalReports.items.map(
            item => item.UploadedFileName
          );
          const uniqueNames = Array.from(new Set(allFileNames));

          // Sort by the trailing number
          // If a filename ends with a number, we parse that number; otherwise default to 0
          const sortedByNumberEnd = uniqueNames.sort((a, b) => {
            const pattern = /(\d+)$/;
            const aMatch = a.match(pattern);
            const bMatch = b.match(pattern);
            const aNum = aMatch ? parseInt(aMatch[1], 10) : 0;
            const bNum = bMatch ? parseInt(bMatch[1], 10) : 0;
            return aNum - bNum;
          });

          setUniqueFileNames(sortedByNumberEnd);
        }
      }
    }
  );

  // Fetch unique file names on load (or based on your specific logic)
  useEffect(() => {
    fetchUniqueFileNames();
  }, [fetchUniqueFileNames]);

  const [fetchReports] = useLazyQuery(GET_PROMOTIONAL_REPORTS_BY_DATE, {
    onCompleted: (data) => {
      const reports = data.listPromotionalReports.items;
      setReports(reports);

      // Calculate totals
      const countyAggregatedData = {};
      reports.forEach((report) => {
        const county = checkForCountyInActivityName(report.activityName);
        if (county) {
          if (!countyAggregatedData[county]) {
            countyAggregatedData[county] = { totalCount: 0, activityCount: 0 };
          }
          countyAggregatedData[county].totalCount += report.numberOfConsumersTargeted || 0;
          countyAggregatedData[county].activityCount += 1; // Count each activity
        }
      });

      // Convert aggregated data into an array
      const countyDataArray = Object.keys(countyAggregatedData).map(county => ({
        county: county,
        totalCount: countyAggregatedData[county].totalCount,
        activityCount: countyAggregatedData[county].activityCount
      }));

      setCountyData(countyDataArray);

      const totalCustomersTargeted = reports.reduce(
        (acc, report) => acc + (report.numberOfConsumersTargeted || 0),
        0
      );
      const totalPromotionalActivities = reports.length;

      // Calculate total Flyers
      const totalFlyers = reports.reduce((acc, report) => {
        if (report.mediaType?.toLowerCase().includes("flyers")) {
          return acc + (report.numberOfConsumersTargeted || 0);
        }
        return acc;
      }, 0);

      // Calculate total Social Media
      const totalSocialMedia = reports.reduce((acc, report) => {
        const mediaType = report.mediaType?.toLowerCase() || "";
        if (
          mediaType.includes("social media") ||
          mediaType.includes("facebook") ||
          mediaType.includes("twitter") ||
          mediaType.includes("instagram")
        ) {
          return acc + (report.numberOfConsumersTargeted || 0);
        }
        return acc;
      }, 0);

      // Calculate total Radio
      const totalRadio = reports.reduce((acc, report) => {
        const mediaType = report.mediaType?.toLowerCase() || "";
        if (mediaType.includes("radio") || mediaType === "facebook live, am radio") {
          return acc + (report.numberOfConsumersTargeted || 0);
        }
        return acc;
      }, 0);

      setTotalCustomersTargeted(totalCustomersTargeted);
      setTotalPromotionalActivities(totalPromotionalActivities);
      setTotalFlyers(totalFlyers);
      setTotalSocialMedia(totalSocialMedia);
      setTotalRadio(totalRadio);
    },
    onError: (error) => {
      console.error('Error fetching reports:', error);
    },
  });

  const [createReport] = useMutation(CREATE_PROMOTIONAL_REPORT, {
    onError: (error) => {
      console.error('Error uploading data:', error);
    },
  });

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!file) return alert("Please select an Excel file.");

    setIsLoading(true);
    // Check if file with same name was uploaded
    const { data } = await checkFileExistenceByName({
      variables: { UploadedFileName: file.name }
    });
    const fileExists = data?.listPromotionalReports?.items?.length > 0;

    if (fileExists) {
      alert("This file has already been uploaded.");
      setIsLoading(false);
      return;
    }

    const reader = new FileReader();
    reader.onload = async (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      // Determine file type based on sheet name
      let isFileType1 = false;
      let isFileType2 = false;

      if (workbook.Sheets['Promotional Activities ']) {
        isFileType1 = true;
      } else if (workbook.Sheets['Marketing_Promotions']) {
        isFileType2 = true;
      } else {
        alert(
          "Unknown sheet name. Please ensure the sheet is named 'Promotional Activities ' or 'Marketing_Promotions'."
        );
        setIsLoading(false);
        return;
      }

      const promoSheet = isFileType1
        ? workbook.Sheets['Promotional Activities ']
        : workbook.Sheets['Marketing_Promotions'];
      const jsonData = XLSX.utils.sheet_to_json(promoSheet, { header: 1 });
      await processExcelData(jsonData, isFileType1, file.name);
      setIsLoading(false);
    };
    reader.readAsArrayBuffer(file);
  };

  const counties = [
    "Bexar", "Aransas", "Atascosa", "Bandera", "Bee", "Brooks", "Caldwell", "Cameron",
    "Comal", "DeWitt", "Duval", "Frio", "Goliad", "Gonzalez", "Guadalupe", "Hays",
    "Hidalgo", "Jim Hogg", "Jim Wells", "Karnes", "Kenedy", "Kleberg", "La Salle",
    "Live Oak", "Maverick", "McMullen", "Medina", "Nueces", "Refugio", "San Patricio",
    "Uvalde", "Willacy", "Wilson", "Zavala"
  ];

  const checkForCountyInActivityName = (activityName) => {
    const normalizedActivityName = activityName?.toLowerCase() || "";
    return (
      counties.find(county =>
        normalizedActivityName.includes(county.toLowerCase())
      ) || null
    );
  };

  const processExcelData = async (data, isFileType1, fileName) => {
    const rows = data.slice(1); // Remove the header row

    for (const row of rows) {
      // Skip rows where all cells are empty
      if (row.every(cell => !cell)) {
        continue;
      }

      let activityName, activityDescription, activityDate, numberOfConsumersTargeted, mediaType;

      // Extract fields based on the file type
      if (isFileType1) {
        [activityName, activityDate, numberOfConsumersTargeted, mediaType, activityDescription] = row;
      } else {
        [activityName, activityDescription, activityDate, numberOfConsumersTargeted, mediaType] = row;
      }

      // Skip if all essential fields are missing
      if (
        !activityName &&
        !activityDate &&
        !numberOfConsumersTargeted &&
        !mediaType &&
        !activityDescription
      ) {
        console.warn("Skipped row due to missing fields:", row);
        continue;
      }

      try {
        let formattedDateTime;

        if (isFileType1) {
          // For File Type 1, assume date without time => default to 09:00 AM
          let formattedDate;
          if (typeof activityDate === 'number') {
            // Excel serial number handling
            const parsedDate = XLSX.SSF.parse_date_code(activityDate);
            formattedDate = new Date(parsedDate.y, parsedDate.m - 1, parsedDate.d, 9, 0, 0);
          } else {
            formattedDate = new Date(activityDate);
          }
          formattedDateTime = formattedDate.toISOString();
        } else {
          // For File Type 2, parse full date/time (e.g., 'm/d/yyyy h:mm')
          let formattedDate;
          if (typeof activityDate === 'number') {
            // Excel serial number handling
            const parsedDate = XLSX.SSF.parse_date_code(activityDate);
            formattedDate = new Date(
              parsedDate.y,
              parsedDate.m - 1,
              parsedDate.d,
              parsedDate.H,
              parsedDate.M
            );
          } else {
            formattedDate = new Date(activityDate);
          }
          formattedDateTime = formattedDate.toISOString();
        }

        // Create report in DB
        await createReport({
          variables: {
            input: {
              activityName: String(activityName).trim(),
              activityDescription: String(activityDescription).trim(),
              activityDateTime: formattedDateTime, // AWSDateTime format
              numberOfConsumersTargeted: parseInt(numberOfConsumersTargeted, 10),
              mediaType: String(mediaType).trim(),
              UploadedFileName: fileName
            }
          }
        });
      } catch (err) {
        console.error("Error uploading data:", err);
      }
    }
    setIsLoading(false);
    alert("Data uploaded successfully!");
    setShowUploadOverlay(false); // Close overlay after upload
  };

  const handleDateSelection = (start, end) => {
    setDateRange({ start, end });
    if (start && end) {
      fetchReports({
        variables: {
          startDate: new Date(start).toISOString().split('T')[0],
          endDate: new Date(end).toISOString().split('T')[0],
        },
      });
    }
  };

  const toggleUploadOverlay = () => {
    setShowUploadOverlay(!showUploadOverlay);
  };

  return (
    <div className="promo-activities-container">
      <header className="promo-activities-header">
        <h1>Promotional Activities Report</h1>
      </header>

      {isLoading && <LoadingSpinner />}
      {/* Overlay for Upload */}
      {showUploadOverlay && (
        <div className="upload-overlay">
          <div className="upload-content">
            <h2>Upload Promotional Activities</h2>
            <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
            <button onClick={handleFileUpload}>Upload Excel</button>
            <button onClick={toggleUploadOverlay}>Close</button>
          </div>
        </div>
      )}

      {/* Enhanced Date Picker */}
      <div className="date-picker-container">
        <label>Select Date Range:</label>
        <div className="date-pickers">
          <input
            type="date"
            value={dateRange.start}
            onChange={(e) => handleDateSelection(e.target.value, dateRange.end)}
          />
          <input
            type="date"
            value={dateRange.end}
            onChange={(e) => handleDateSelection(dateRange.start, e.target.value)}
          />
        </div>
        <button className="upload-btn" onClick={toggleUploadOverlay}>
          <FaUpload /> Upload Promo Activities
        </button>
      </div>

      {/* Information Display */}
      <div className="report-display">
        {/* Total Row */}
        <div className="total-row">
          <div className="card">
            <h2>Total Customers Targeted</h2>
            <p>{totalCustomersTargeted}</p>
          </div>
          <div className="card">
            <h2>Total Promotional Activities</h2>
            <p>{totalPromotionalActivities}</p>
          </div>
        </div>
        <div className="spacer-row"></div>

        {/* County Data */}
        <div className="county-data">
          {/* Card for Customers Targeted per County */}
          <div className="card county-card">
            <h2>Customers Targeted per County</h2>
            <div className="county-columns">
              {countyData.map((data, index) => (
                <div key={index} className="county-item">
                  <p className="county-name">
                    {data.county}: {data.totalCount}
                  </p>
                </div>
              ))}
            </div>
          </div>

          {/* Card for Promotional Activities per County */}
          <div className="card county-card">
            <h2>Promotional Activities per County</h2>
            <div className="county-columns">
              {countyData.map((data, index) => (
                <div key={index} className="county-item">
                  <p className="county-name">
                    {data.county}: {data.activityCount}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="spacer-row"></div>

        {/* Media Type Data */}
        <div className="card media-card">
          <h2>Customers Targeted - Media Type</h2>
          <div className="media-type-row">
            <div className="media-item">
              <FaInstagram /> Social Media
              <span>{totalSocialMedia}</span>
            </div>
            <div className="media-item">
              <FaBookReader /> Flyers
              <span>{totalFlyers}</span>
            </div>
            <div className="media-item">
              <FaRadio /> Radio
              <span>{totalRadio}</span>
            </div>
          </div>
        </div>

        {/* 
          A SELECTOR THAT DISPLAYS THE UNIQUE ITEMS (file names), 
          SORTED BY ANY TRAILING NUMBER 
        */}
        <div className="unique-filenames card">
          <h2>Unique Uploaded File Names</h2>
          {fileNamesLoading ? (
            <p>Loading file names...</p>
          ) : (
            <>
              <label htmlFor="uploadedFileNameSelect">Select an Uploaded File:</label>
              <select id="uploadedFileNameSelect">
                {uniqueFileNames.map((fileName, index) => (
                  <option key={index} value={fileName}>
                    {fileName}
                  </option>
                ))}
              </select>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PromoActivities;
