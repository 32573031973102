import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { listDeleteEquipmentHistories, listPhones, listUsers } from '../../../graphql/queries';
import { createPhone, deletePhone, updatePhone, createDeleteEquipmentHistory } from '../../../graphql/mutations';
import './Phone.css';
import XLSX from 'xlsx-js-style';
import { v4 as uuidv4 } from 'uuid';
import { FaFileExcel } from 'react-icons/fa';
import Inventory_header from '../../../components/layout/Inventory_header';
import useFilteredData from '../../../components/layout/useFilteredDate';
import SearchBar from '../../../components/layout/SearchBar';
import { capitalize } from '../../../components/common/capitalize';

const Phone = () => {
    const [phone, setPhone] = useState([]);
    const [nextToken, setNextToken] = useState(null);
    const [showPhoneAddOverlay, setShowPhoneAddOverlay] = useState(false);
    const [selectedPhone, setSelectedPhone] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    const [newPhone, setNewPhone] = useState({
        id: '',
        tagid: '',
        model: '',
        brand: '',
        serial_Number: '',
        price: '',
        store: '',
        purchase_date: '',
        cel_number: '',
        password: '',
        charger_cable: true,
        imei: '',
        pin: '',
        apple_id: '',
        apple_password: '',
        assignment_date: '',
        warranty_date: '',
        returnable_date: '',
        available: true,
        status: 'inv',
        assigned_to: '',
        EID: '',
        ICCID: '',
        Description: '',
        location: '',
        team: '',

    });
    const [sortConfig, setSortConfig] = useState(null);
    const [duplicateError, setDuplicateError] = useState('');
    const [priceError, setPriceError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [statusError, setStatusError] = useState('');
    const [PINError, setPINError] = useState('');
    const [AppleIDError, setAppleIDError] = useState('');
    const { loading1, error: userError, data: userData } = useQuery(listUsers);
    const [showPasswords, setShowPasswords] = useState(false);



    const handleSaveAsExcel = async () => {
        try {
            // Usar la lista filtrada en lugar de la lista completa
            const phonesToExport = filteredPhones.length > 0 ? filteredPhones : phone;

            if (phonesToExport.length > 0) {
                // Ordenar los teléfonos por Timestamp en orden ascendente
                const sortedPhone = [...phonesToExport].sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp));

                // Preparar los datos para exportar a Excel con títulos personalizados
                const worksheetData = sortedPhone.map((phone, index) => ({
                    '#': index + 1, // Agregar el número de fila
                    'TAG:': phone.tagid,
                    'Assigned to:': phone.assigned_to,
                    'Available:': phone.available ? 'Yes' : 'No',
                    'Serial Number:': phone.serial_Number,
                    'IMEI:': phone.imei,
                    'PIN:': phone.pin,
                    'Cel Number:': phone.cel_number,
                    'Status:': phone.status,

                    // Agregar otros campos según sea necesario
                }));


                // Convert JSON to sheet starting from row 2
                const worksheet = XLSX.utils.json_to_sheet([]);
                let endCol = 0;

                XLSX.utils.sheet_add_aoa(worksheet, [['Phones']], { origin: 'A1' });

                if (worksheetData.length > 0) {
                    // Agrega los datos a partir de la fila A2
                    XLSX.utils.sheet_add_json(worksheet, worksheetData, { origin: 'A2', skipHeader: false });

                    // Define el rango de referencia (!ref)
                    const endRow = worksheetData.length + 1; // +1 para el título en A1
                    const endCol = Object.keys(worksheetData[0]).length - 1;
                    worksheet['!ref'] = XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: endRow, c: endCol } });
                } else {
                    console.error("No hay datos para exportar.");
                    return;
                }

                // Merge title cells across the columns
                worksheet['!merges'] = worksheet['!merges'] || [];
                worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 8 } }); // A1 to H1 for the added column

                // Style the title row
                worksheet['A1'].s = {
                    font: {
                        bold: true,
                        sz: 20, // Font size
                        color: { rgb: '1F4E79' } // Dark blue text color
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center'
                    }
                };

                // Apply styling for the header row (now starting at row 2)
                const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
                for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                    const cellAddress = XLSX.utils.encode_cell({ r: 1, c: col }); // Header row is now row 1
                    if (!worksheet[cellAddress]) continue;
                    worksheet[cellAddress].s = {
                        fill: {
                            fgColor: { rgb: '1F4E79' } // Dark blue background
                        },
                        font: {
                            bold: true,
                            color: { rgb: 'FFFFFF' } // White text
                        },
                        alignment: {
                            horizontal: 'center'
                        },
                        border: {
                            top: { style: 'thin', color: { rgb: '000000' } },
                            left: { style: 'thin', color: { rgb: '000000' } },
                            bottom: { style: 'thin', color: { rgb: '000000' } },
                            right: { style: 'thin', color: { rgb: '000000' } },
                        }
                    };
                }

                // Apply alternating row colors and borders starting from row 3
                for (let row = 2; row <= headerRange.e.r; row++) {
                    const isEvenRow = row % 2 === 0;
                    for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
                        if (!worksheet[cellAddress]) continue;

                        worksheet[cellAddress].s = {
                            fill: {
                                fgColor: { rgb: isEvenRow ? 'D3D3D3' : 'FFFFFF' } // Light gray for even rows, white for odd rows
                            },
                            border: {
                                top: { style: 'thin', color: { rgb: '000000' } },
                                left: { style: 'thin', color: { rgb: '000000' } },
                                bottom: { style: 'thin', color: { rgb: '000000' } },
                                right: { style: 'thin', color: { rgb: '000000' } },
                            },
                            alignment: {
                                horizontal: 'center'
                            }
                        };
                    }
                }

                worksheet['!cols'] = [
                    { wch: 5 }, { wch: 10 }, { wch: 25 }, { wch: 10 }, { wch: 35 }, { wch: 40 },
                    { wch: 15 }, { wch: 35 }, { wch: 10 },
                ];

                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Filtered Phones');

                // Escribir archivo
                XLSX.writeFile(workbook, 'FilteredPhonesData.xlsx');
            } else {
                console.error('No data to export');
            }
        } catch (error) {
            console.error('Error exporting data to Excel:', error);
        }
    };

    const handleEditPhone = (phone) => {
        setSelectedPhone(phone);
        setIsEditing(true);
        setShowPhoneAddOverlay(true);
    };

    const { loading, data, fetchMore } = useQuery(listPhones, {
        variables: { limit: 100 },
    });

    const [addPhone] = useMutation(createPhone, {
        refetchQueries: [{ query: listPhones, variables: { limit: 100 } }]
    });

    const [removePhone] = useMutation(deletePhone, {
        refetchQueries: [{ query: listPhones, variables: { limit: 100 } }]
    });
    const [updPhone] = useMutation(updatePhone, {
        refetchQueries: [{ query: listPhones, variables: { limit: 100 } }]
    });
    const [addDeleteEquipmentHistory] = useMutation(createDeleteEquipmentHistory, {
        refetchQueries: [{ query: listDeleteEquipmentHistories, variables: { limit: 100 } }]
    });

    useEffect(() => {
        const fetchAllPhones = async () => {
            let AllPhones = [];
            let currentNextToken = null;

            do {
                const { data } = await fetchMore({
                    variables: { limit: 100, nextToken: currentNextToken },
                });

                AllPhones = [...AllPhones, ...data.listPhones.items];
                currentNextToken = data.listPhones.nextToken;
            } while (currentNextToken);

            setPhone(AllPhones);
            setNextToken(currentNextToken);
        };

        if (data) {
            fetchAllPhones();
        }
    }, [data, fetchMore]);

    const clearNewPhone = () => {
        setNewPhone({
            id: '',
            tagid: '',
            model: '',
            brand: '',
            serial_Number: '',
            price: '',
            store: '',
            purchase_date: '',
            cel_number: '',
            password: '',
            charger_cable: true,
            imei: '',
            pin: '',
            apple_id: '',
            apple_password: '',
            assignment_date: '',
            warranty_date: '',
            returnable_date: '',
            available: true,
            status: 'inv',
            assigned_to: '',
            EID: '',
            ICCID: '',
            Description: '',
            location: '',
            team: '',
        });
    };
    // Define loadMore function
    const loadMore = () => {
        fetchMore({
            variables: { limit: 100, nextToken },
            updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;
                setNextToken(fetchMoreResult.listPhones.nextToken);
                return {
                    listPhones: {
                        ...fetchMoreResult.listPhones,
                        items: [...prevResult.listPhones.items, ...fetchMoreResult.listPhones.items]
                    }
                };
            }
        });
    };

    //Filtro de Monitors empleando el componente useFilteredData
    const filteredPhones = useFilteredData(phone || [], searchTerm, sortConfig);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let formattedValue = value;

        // Convert to lowercase and trim whitespace for specific fields
        if (['serial_Number'].includes(name)) {
            formattedValue = value.toLowerCase().trim();
        }

        // Convertir a "Yes" o "No" si es el campo charger_cable
        if (name === 'charger_cable') {
            // Convertir el valor del string a booleano
            formattedValue = value === 'true' || value === true ? true : false;
        }

        if (isEditing) {
            setSelectedPhone({
                ...selectedPhone,
                [name]: formattedValue
            });
        } else {
            setNewPhone({
                ...newPhone,
                id: uuidv4(),
                [name]: formattedValue
            });
        }
    };

    const formatDateForInput = (dateString) => {
        if (!dateString) return "";  // Si la fecha es nula o indefinida, retorna una cadena vacía
        const date = new Date(dateString);
        // Ajustar la fecha para evitar la conversión a UTC
        const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        return localDate.toISOString().split('T')[0];
    };

    // Función para alternar la visibilidad de las contraseñas
    const togglePasswords = () => {
        setShowPasswords(!showPasswords);
    };

    const handleAddPhone = () => {

        const chargerCableValue = newPhone.charger_cable !== undefined && newPhone.charger_cable !== null ? newPhone.charger_cable : 'Yes';


        // Formatear la fecha en el formato YYYY-MM-DD si existe, o establecerla en null si está vacía
        const formattedPurchase = newPhone.purchase_date
            ? new Date(newPhone.purchase_date).toISOString().split('T')[0]
            : null;
        const formattedAssignment = newPhone.assignment_date
            ? new Date(newPhone.assignment_date).toISOString().split('T')[0]
            : null;

        const formattedWarranty = newPhone.warranty_date
            ? new Date(newPhone.warranty_date).toISOString().split('T')[0]
            : null;

        const formattedReturnable = newPhone.returnable_date
            ? new Date(newPhone.returnable_date).toISOString().split('T')[0]
            : null;

        // Si `newPhone.price` no está vacío, conviértelo a un número flotante
        let priceValue = null;
        if (newPhone.price !== "" && newPhone.price !== null) {
            priceValue = parseFloat(newPhone.price);

            // Verificar si el valor convertido es un número válido
            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');
                console.log('Price validation failed:', newPhone.price);
                return;
            }
        }
        // Limpiar el error si el valor es válido o el campo está vacío
        setPriceError('');

        // Validate Cell Number
        const cleanCellNumber = newPhone.cel_number ? newPhone.cel_number.replace(/\D/g, '').trim() : null;

        // Verificar que cleanCellNumber no sea null y que tenga exactamente 10 dígitos si no está vacío
        if (cleanCellNumber && cleanCellNumber.length !== 10) {
            setPhoneError('Phone number must be exactly 10 digits and contain only numbers.');
            console.log('Phone number validation failed:', newPhone.cel_number);
            return;
        } else {
            setPhoneError('');  // Limpia el error si el número es válido o está vacío
        }

        // Check for duplicates based on `tagid` and `serial_Number`
        const isDuplicate = phone.some(phone =>
            phone.tagid === newPhone.tagid &&
            phone.serial_Number === newPhone.serial_Number
        );

        if (isDuplicate) {
            setDuplicateError('A phone with the same TAG and Serial Number already exists.');
            console.log('Duplicate validation failed');
            return;
        } else {
            setDuplicateError('');
        }

        // Check availability first
        if (newPhone.available === true) {


            // If available, status must be Inventario
            if (newPhone.status !== 'inv') {
                setStatusError('If the Phone is available, its status must be "Inventario".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // If available and status is Inventario, assigned_to must be empty or "N/A"
            if (newPhone.assigned_to !== '' && newPhone.assigned_to !== 'n/a') {
                setStatusError('If the Phone is available and its status is "Inventario", the "Assigned to" field must be empty or "N/A".');
                console.log('Assigned_to validation failed');
                return; // Exit function if validation fails
            }

        } else if (newPhone.available === false) {
            const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
            if (!allowedStatuses.includes(newPhone.status)) {
                setStatusError('If the Phone is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
                console.log('Status validation failed');
                return;
            }
            // Clear error if validation passes
            setStatusError('');
        }


        //Check email
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        const isValidEmail = (email) => emailPattern.test(email);

        const validateAppleId = (apple_id) => {
            if (apple_id.trim() === '' || isValidEmail(apple_id))   // Si el campo está vacío, consideramos que es válido
            {
                setAppleIDError('');
                console.log('Apple ID is valid or empty:', apple_id);
                return true;
            } else {
                setAppleIDError('Apple ID is not a valid email address.');
                console.log('Apple ID validation failed:', apple_id);
                return false;
            }
        };

        // Obtener el email y validar
        const { apple_id } = newPhone;
        console.log('Apple ID:', apple_id); // Verifica el valor

        const isAppleIdValid = validateAppleId(apple_id);

        if (!isAppleIdValid) {
            // Continúa con el proceso si el email es válido
            console.log('Invalid Apple ID, please correct it.');
            return;
        }

        // If all validations pass, proceed with adding the phone to the database
        addPhone({
            variables: { input: { ...newPhone, price: priceValue, charger_cable: chargerCableValue, purchase_date: formattedPurchase, assignment_date: formattedAssignment, warranty_date: formattedWarranty, returnable_date: formattedReturnable, cel_number: cleanCellNumber } }
        })
            .then(() => {
                clearNewPhone();
                alert("The Phone was an added success")
                setShowPhoneAddOverlay(false);
            }).catch(error => {
                console.error("Error adding phone:", error);
            });
    };

    const removeUnnecessaryFields = (data, fieldsToRemove) => {
        fieldsToRemove.forEach((field) => {
            delete data[field];
        });
        return data;
    };

    const handleDeletePhone = async () => {
        try {
            // Remover campos innecesarios
            const PhoneData = removeUnnecessaryFields({ ...selectedPhone }, [

                'cel_number',
                'password',
                'charger_cable',
                'imei',
                'pin',
                'apple_id',
                'apple_password',
                'EID',
                'ICCID',
                'warranty_date',
                'returnable_date',
                'status',
                'team',
            ]);

            // Lista de campos permitidos según el esquema GraphQL
            const allowedFields = [
                'id',
                'Item',
                'tagId',
                'tagid', // Agregado
                'serial_Number',
                'price',
                'brand',
                'model',
                'store',
                'purchase_date',
                'assignment_date',
                'available',
                'previous_employees',
                'assigned_to', // Agregado
                'location',
                'Description',
                'notes',
            ];

            // Filtrar los campos permitidos
            const cleanedNewPhones = Object.fromEntries(
                Object.entries(PhoneData).filter(([key]) => allowedFields.includes(key))
            );


            console.log("Antes de normalizar:", cleanedNewPhones);

            // Normalizar claves alternativas
            cleanedNewPhones.tagId = cleanedNewPhones.tagId || cleanedNewPhones.tagid;
            delete cleanedNewPhones.tagid;

            cleanedNewPhones.previous_employees =
                cleanedNewPhones.previous_employees || cleanedNewPhones.assigned_to;
            delete cleanedNewPhones.assigned_to;

            cleanedNewPhones.notes =
                cleanedNewPhones.notes || cleanedNewPhones.Description;
            delete cleanedNewPhones.Description;
            console.log("Despues de normalizar:", cleanedNewPhones);

            // Añadir valores clave predeterminados
            if (!cleanedNewPhones.Item) {
                cleanedNewPhones.Item = "Phone"; // Establecer el valor predeterminado para `Item`
            }

            // Eliminar valores vacíos
            Object.keys(cleanedNewPhones).forEach((key) => {
                if (cleanedNewPhones[key] === "" || cleanedNewPhones[key] === null) {
                    delete cleanedNewPhones[key];
                }
            });

            // Agregar empleado a la tabla PreviousEmployee
            await addDeleteEquipmentHistory({
                variables: { input: cleanedNewPhones },
            });

            // Eliminar el Phone
            await removePhone({
                variables: { input: { id: cleanedNewPhones.id } },
            });

            // Resetear estados
            setShowPhoneAddOverlay(false);
            setIsEditing(false);
            setSelectedPhone(null);

        } catch (error) {
            console.error("Error deleting Phone", error);
        }
    };


    const handleUpdatePhone = async () => {

        const formattedData_purchase = selectedPhone.purchase_date ? new Date(selectedPhone.purchase_date).toISOString().split('T')[0] : null;
        const formattedData_warranty = selectedPhone.warranty_date ? new Date(selectedPhone.warranty_date).toISOString().split('T')[0] : null;
        const formattedData_returnable = selectedPhone.returnable_date ? new Date(selectedPhone.returnable_date).toISOString().split('T')[0] : null;
        const formattedData_assignment = selectedPhone.assignment_date ? new Date(selectedPhone.assignment_date).toISOString().split('T')[0] : null;

        //validate price
        let priceValue = null;

        if (selectedPhone.price !== "" && selectedPhone.price !== null) {
            priceValue = parseFloat(selectedPhone.price);

            // Verificar si el valor convertido es un número válido
            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');
                console.log('Price validation failed:', selectedPhone.price);
                return;
            }
        }
        // Validate Cel
        let cleanCellNumber = null;

        if (selectedPhone && selectedPhone.cel_number !== null && selectedPhone.cel_number !== '') {
            // Elimina caracteres no numéricos
            cleanCellNumber = selectedPhone.cel_number.replace(/\D/g, '');

            // Verifica que el número tenga exactamente 10 dígitos
            if (cleanCellNumber.length !== 10) {
                setPhoneError('Phone number must be exactly 10 digits.');
                return;
            }

            // Verifica si todos los dígitos son iguales
            if (/^(\d)\1{9}$/.test(cleanCellNumber)) {
                setPhoneError('Phone number cannot contain the same digit repeated 10 times.');
                return;
            }

            // Convertir el número limpio a un número entero
            cleanCellNumber = parseInt(cleanCellNumber, 10);

            // Verifica si la conversión fue exitosa
            if (isNaN(cleanCellNumber)) {
                setPhoneError('Phone number must be a valid integer.');
                return;
            }
        } else {
            // Si no hay número, asigna null
            cleanCellNumber = null;
        }

        // Check availability first
        if (selectedPhone.available === 'true') {

            console.log('Phone availability:', selectedPhone.available);
            // If available, status must be Inventario
            if (selectedPhone.status.trim().toLowerCase() !== 'inv') {
                setStatusError('If the Phone is available, its status must be "Inventario".');
                console.log('Status validation failed: expected "inv", got', selectedPhone.status.trim().toLowerCase());

                // OPCIONAL: Aquí puedes corregir automáticamente si lo deseas
                selectedPhone.status = 'inv'; // Corregir el estado a "Inventario"
                return; // O eliminar este return si deseas que continúe después de corregir
            }

            // If available and status is Inventario, assigned_to must be empty or "N/A"
            if (selectedPhone.assigned_to.trim() !== '' && selectedPhone.assigned_to.trim().toLowerCase() !== 'n/a') {
                setStatusError('If the Phone is available and its status is "Inventario", the "Assigned to" field must be empty or "N/A".');
                console.log('Assigned_to validation failed: expected empty or "N/A", got', selectedPhone.assigned_to);
                return;
            }

        } else if (selectedPhone.available === 'false') {
            const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
            if (!allowedStatuses.includes(selectedPhone.status.trim().toLowerCase())) {
                setStatusError('If the Phone is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
                console.log('Status validation failed: got', selectedPhone.status);
                return;
            }

            // Clear error if validation passes
            setStatusError('');
        }


        //Check email
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Función para validar si el email es válido
        const isValidEmail = (email) => emailPattern.test(email);

        // Función para validar el Apple ID
        const validateAppleId = (apple_id) => {
            if (apple_id === null || apple_id === undefined || apple_id.trim() === '') {
                setAppleIDError('Apple ID is empty or null.');
                console.log('Apple ID is empty or null:', apple_id);
                return false;
            }

            // Validar si es "N/A", una cadena vacía, o un correo electrónico válido
            if (apple_id.trim() === 'N/A' || isValidEmail(apple_id.trim())) {
                setAppleIDError('');  // Limpiar el error si es válido o vacío
                console.log('Apple ID is valid:', apple_id);
                return true;
            } else {
                setAppleIDError('Apple ID is not a valid email address.');
                console.log('Apple ID validation failed:', apple_id);
                return false;
            }
        }

        updPhone({
            variables: {
                input: {
                    id: selectedPhone.id,
                    tagid: selectedPhone.tagid,
                    model: selectedPhone.model,
                    brand: selectedPhone.brand,
                    serial_Number: selectedPhone.serial_Number,
                    price: priceValue,
                    store: selectedPhone.store,
                    purchase_date: formattedData_purchase,
                    cel_number: cleanCellNumber,
                    password: selectedPhone.password,
                    charger_cable: selectedPhone.charger_cable,
                    imei: selectedPhone.imei,
                    pin: selectedPhone.pin,
                    apple_id: selectedPhone.apple_id || null,
                    apple_password: selectedPhone.apple_password,
                    assignment_date: formattedData_assignment,
                    warranty_date: formattedData_warranty,
                    returnable_date: formattedData_returnable,
                    available: selectedPhone.available,
                    status: selectedPhone.status,
                    assigned_to: selectedPhone.assigned_to,
                    EID: selectedPhone.EID,
                    ICCID: selectedPhone.ICCID,
                    Description: selectedPhone.Description,
                    location: selectedPhone.location,
                    team: selectedPhone.team,

                }
            }
        }).then(() => {

            setIsEditing(true);
            setShowPhoneAddOverlay(false);
            // Limpiar los errores
            setPriceError('');
            setPhoneError('');
            setPINError('');
            setAppleIDError('');
            setStatusError('');
        }).catch(error => {
            console.error("Error updating Phone:", error);
        });
    };


    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    //listUsers 
    const users = React.useMemo(() => {
        if (userData && userData.listUsers) {
            return userData.listUsers.items || [];
        }
        return [];
    }, [userData]);
    if (userError) return <p>Error loading users: {userError.message}</p>;
    if (loading1) return <p>Error loading users: {userError.message}</p>;


    const formatDate = (dateString) => {
        const options = { month: 'numeric', day: 'numeric', year: 'numeric' };
        const date = new Date(dateString);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

        return localDate.toLocaleDateString(undefined, options);
    };

    const formatPrice = (price) => {
        if (price === null || price === undefined || price === '') {
            return '';
        }
        const parsedPrice = parseFloat(price);
        return isNaN(parsedPrice) ? '' : parsedPrice.toFixed(2);
    };

    return (
        <div className='phone-page'>
            <header className="inventory-header">
                <h1>Phone</h1>
            </header>
            <Inventory_header />
            <div className="inventory-search">
                <button onClick={handleSaveAsExcel} > <FaFileExcel /> Export </button>
                {/* Botón para mostrar u ocultar las contraseñas */}
                <button onClick={togglePasswords}>
                    {showPasswords ? 'Hide Passwords' : 'Show Passwords'}
                </button>
            </div>

            {/* Botón para agregar un nuevo teléfono */}
            <div className="searchBar">
                <button className="add-phone-btn" onClick={() => {
                    clearNewPhone();
                    setSelectedPhone(null);
                    setIsEditing(false);
                    setShowPhoneAddOverlay(true);
                }}>
                    + Add New Phone
                </button>
                <SearchBar searchTerm={searchTerm} onSearch={setSearchTerm} /></div>

            <div className="phone-table-container" >
                <table className="phone-table ">
                    <thead>
                        <tr>
                            <th>#</th> {/* Columna para contar las filas */}

                            <th onClick={() => requestSort('tagid')}>
                                TAG
                                {sortConfig?.key === 'tagid' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('model')}>
                                Model
                                {sortConfig?.key === 'model' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('brand')}>
                                Brand
                                {sortConfig?.key === 'brand' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('serial_Number')}>
                                Serial Number
                                {sortConfig?.key === 'serial_Number' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('price')}>
                                Price
                                {sortConfig?.key === 'price' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('store')}>
                                Store
                                {sortConfig?.key === 'store' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('purchase_date')}>
                                Purchase Date
                                {sortConfig?.key === 'purchase_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('available')}>
                                Available
                                {sortConfig?.key === 'available' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('status')}>
                                Status
                                {sortConfig?.key === 'status' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('assigned_to')}>
                                Assigned To
                                {sortConfig?.key === 'assigned_to' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('assignment_date')}>
                                Assignment Date
                                {sortConfig?.key === 'assignment_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('cel_number')}>
                                Cell Number
                                {sortConfig?.key === 'cel_number' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}

                            </th> <th onClick={() => requestSort('password')}>
                                Passcode
                                {sortConfig?.key === 'password' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('charger_cable')}>
                                Charger Cable
                                {sortConfig?.key === 'charger_cable' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('EID')}>
                                EID
                                {sortConfig?.key === 'EID' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('imei')}>
                                IMEI
                                {sortConfig?.key === 'imei' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('ICCID')}>
                                SIM / ICCID
                                {sortConfig?.key === 'ICCID' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('apple_id')}>
                                Apple ID
                                {sortConfig?.key === 'apple_id' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('apple_password')}>
                                Apple Password
                                {sortConfig?.key === 'apple_password' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>


                            <th onClick={() => requestSort('warranty_date')}>
                                Warranty Date
                                {sortConfig?.key === 'warranty_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('returnable_date')}>
                                Returnable Date
                                {sortConfig?.key === 'returnable_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('Description')}>
                                Description
                                {sortConfig?.key === 'Description' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('location')}>
                                Location
                                {sortConfig?.key === 'location' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('team')}>
                                Team
                                {sortConfig?.key === 'team' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredPhones.map((phone, index) => (
                            <tr key={phone.id} onClick={() => handleEditPhone(phone)} >
                                <td>{index + 1}</td> {/* Número de fila */}
                                <td className="phone-tagid">{capitalize(phone.tagid)}</td>
                                <td className="phone-model">{capitalize(phone.model.toLowerCase())}</td>
                                <td className="phone-brand">{capitalize(phone.brand.toLowerCase())}</td>
                                <td className="phone-serial_Number">{phone.serial_Number?.toUpperCase() || 'N/A'}</td>
                                <td className="phone-price">${formatPrice(phone.price)}</td>
                                <td className="phone-store">{capitalize(phone.store.toLowerCase())}</td>
                                <td className="phone-purchase_date">{phone.purchase_date ? formatDate(phone.purchase_date) : 'No Date'}</td>
                                <td className="phone-available">{phone.available ? 'Yes' : 'No'} </td>
                                <td className="phone-status">
                                    {phone.status === 'broken' ? 'Broken' :
                                        phone.status === 'use' ? 'Use' :
                                            phone.status === 'transfer' ? 'Transfer' :
                                                phone.status === 'lost' ? 'Lost' :
                                                    phone.status === 'inv' ? 'Inventario' :
                                                        'Unknown'} {/* Muestra 'Unknown' si el estado no coincide con ninguno de los anteriores */}
                                </td>
                                <td className="phone-assigned_to">{phone.assigned_to}</td>
                                <td className="phone-assignment_date">{phone.assignment_date ? formatDate(phone.assignment_date) : 'No Date'}</td>
                                <td className="phone-cel_number">{phone.cel_number}</td>
                                <td className="phone-password">{showPasswords ? String(phone.pin) : '******'}</td>
                                <td className="phone-charger_cable">{phone.charger_cable ? 'Yes' : 'No'}</td>
                                <td className="phone-EID">{phone.EID}</td>
                                <td className="phone-imei">{phone.imei}</td>
                                <td className="phone-ICCID">{phone.ICCID}</td>
                                <td className="phone-apple_id">{phone.apple_id ? String(phone.apple_id) : ''}</td>
                                <td className="phone-apple_password">{showPasswords ? String(phone.apple_password) : '******'}</td>
                                <td className="phone-warranty_date">{phone.warranty_date ? formatDate(phone.warranty_date) : 'No Date'}</td>
                                <td className="phone-returnable_date">{phone.returnable_date ? formatDate(phone.returnable_date) : 'No Date'}</td>
                                <td className="phone-Description">{phone.Description}</td>
                                <td className="phone-location">{phone.location}</td>
                                <td className="phone-team">{phone.team}</td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>



            {nextToken && !loading && (
                <button onClick={loadMore}>Load More</button>
            )}


            {loading && <p>Loading...</p>}

            {showPhoneAddOverlay && (

                <div className="phone_add_overlay" onClick={(e) => {
                    // Close overlay only if clicked outside the modal content
                    if (e.target.classList.contains("phone_add_overlay")) {
                        setShowPhoneAddOverlay(false);
                        setIsEditing(false);
                        setSelectedPhone(null);
                    }
                }}>
                    <div className="overlay-content-inventory">
                        <div className="header-row">
                            <h2 className="header-title">{isEditing ? 'Edit Phone' : 'Add New Phone'}</h2>
                            <button onClick={() => {
                                setShowPhoneAddOverlay(false);
                                clearNewPhone();
                                setSelectedPhone(null);
                                setIsEditing(false);
                            }}>X</button>
                        </div>

                        <label>TAG:</label><input name="tagid" placeholder="TAG" value={isEditing ? selectedPhone.tagid : newPhone.tagid} onChange={handleInputChange} required />
                        <label>Model:</label><input name="model" placeholder="Model" value={isEditing ? selectedPhone.model : newPhone.model} onChange={handleInputChange} required />

                        <label>Brand:</label><input name="brand" placeholder="Brand" value={isEditing ? selectedPhone.brand : newPhone.brand} onChange={handleInputChange} />
                        <label>Serial Number:</label><input name="serial_Number" placeholder="Serial Number" value={isEditing ? selectedPhone.serial_Number : newPhone.serial_Number} onChange={handleInputChange} />

                        <label>Price:</label><input name="price" placeholder="price" value={isEditing ? selectedPhone.price : newPhone.price} onChange={handleInputChange} />
                        <label>Store:</label><input name="store" placeholder="store" value={isEditing ? selectedPhone.store : newPhone.store} onChange={handleInputChange} />

                        <label htmlFor="purchase_date">Purchase Date:</label>
                        <input
                            type="date"
                            id="purchase_date"
                            name="purchase_date"
                            value={isEditing ? formatDateForInput(selectedPhone.purchase_date) : formatDateForInput(newPhone.purchase_date)}
                            onChange={handleInputChange}

                        />
                        <label>Cell Number:</label><input name="cel_number" placeholder="Cell Number" value={isEditing ? selectedPhone.cel_number : newPhone.cel_number} onChange={handleInputChange} />

                        <label>Passcode:</label><input type={showPasswords ? "text" : "password"} name="pin" className="phone-input_password" placeholder=" Passcode" value={isEditing ? selectedPhone.pin : newPhone.pin} onChange={handleInputChange} />

                        <label>Charger Cable:</label>
                        <select
                            name="charger_cable"
                            value={isEditing
                                ? (selectedPhone?.charger_cable !== undefined && selectedPhone?.charger_cable !== null ? selectedPhone.charger_cable : true)
                                : (newPhone?.charger_cable !== undefined && newPhone?.charger_cable !== null ? newPhone.charger_cable : true)
                            }
                            onChange={handleInputChange}
                        >
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                        </select>


                        <label>EID:</label><input name="EID" placeholder="EID" value={isEditing ? selectedPhone.EID : newPhone.EID} onChange={handleInputChange} />
                        <label>IMEI:</label><input name="imei" placeholder="IMEI" value={isEditing ? selectedPhone.imei : newPhone.imei} onChange={handleInputChange} />
                        <label>ICCID:</label><input name="ICCID" placeholder="ICCID" value={isEditing ? selectedPhone.ICCID : newPhone.ICCID} onChange={handleInputChange} />


                        <label>Apple ID:</label><input name="apple_id" placeholder="Apple ID" value={isEditing ? selectedPhone.apple_id : newPhone.apple_id} onChange={handleInputChange} />

                        <label>Apple Password:</label><input type={showPasswords ? "text" : "password"} name="apple_password" placeholder="Apple Password" value={isEditing ? selectedPhone.apple_password : newPhone.apple_password} onChange={handleInputChange} />

                        <label htmlFor="assignment_date">Assignment Date:</label>
                        <input
                            type="date"
                            id="assignment_date"
                            name="assignment_date"
                            value={isEditing ? selectedPhone.assignment_date : newPhone.assignment_date}
                            onChange={handleInputChange}

                        />
                        <label htmlFor="warranty_date">Warranty Date:</label>
                        <input
                            type="date"
                            id="warranty_date"
                            name="warranty_date"
                            value={isEditing ? selectedPhone.warranty_date : newPhone.warranty_date}
                            onChange={handleInputChange}


                        />
                        <label htmlFor="returnable_date">Returnable Date:</label>
                        <input
                            type="date"
                            id="returnable_date"
                            name="returnable_date"
                            value={isEditing ? selectedPhone.returnable_date : newPhone.returnable_date}
                            onChange={handleInputChange}

                        />

                        <label>Available:</label>

                        <select name="available" placeholder="available" value={isEditing ? selectedPhone.available : newPhone.available} onChange={handleInputChange} >
                            <option value="" disabled hidden className="placeholder-option">Select Availability</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>

                        </select>
                        <label>Status:</label>
                        <select name="status" placeholder="status" value={isEditing ? selectedPhone.status : newPhone.status} onChange={handleInputChange} >
                            <option value="" disabled hidden>Select Status</option>
                            <option value="use">Use</option>
                            <option value="broken">Broken</option>
                            <option value="transfer">Transfer</option>
                            <option value="lost">Lost</option>
                            <option value="inv">Inventario</option>
                        </select>
                        <label>Assigned To:</label>
                        <select
                            name="assigned_to"
                            value={isEditing ? selectedPhone.assigned_to : newPhone.assigned_to}
                            onChange={handleInputChange}
                        >
                            <option value="">Select an Employee</option>

                            {users.length > 0 ? (
                                [...users].sort((a, b) => {
                                    const nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
                                    const nameB = `${b.first_name} ${b.last_name}`.toLowerCase();
                                    return nameA.localeCompare(nameB);
                                }).map(user => (
                                    <option key={user.id} value={`${capitalize(user.first_name)} ${capitalize(user.last_name)}`}>
                                        {capitalize(user.first_name)} {capitalize(user.last_name)}
                                    </option>
                                ))
                            ) : (

                                <option value="">No users available</option>
                            )}
                            <option value="n/a">N/A</option> {/* Opción para N/A */}
                        </select>
                        <label>Location:</label><input name="location" placeholder="location" value={isEditing ? selectedPhone.location : newPhone.location} onChange={handleInputChange} />
                        <label>Description:</label><input name="Description" placeholder="description" value={isEditing ? selectedPhone.Description : newPhone.Description} onChange={handleInputChange} />
                        <label>Team:</label><input name="team" placeholder="team" value={isEditing ? selectedPhone.team : newPhone.team} onChange={handleInputChange} />

                        {duplicateError && <p className="error">{duplicateError}</p>} {/* Display duplicate error message */}
                        {priceError && <p className="error">{priceError}</p>} {/* Display price error message */}
                        {statusError && <p className="error">{statusError}</p>} {/* Display duplicate error message */}
                        {phoneError && <p className="error">{phoneError}</p>} {/* Display cell number error message */}
                        {PINError && <p className="error">{PINError}</p>} {/* Display PIN error message */}
                        {AppleIDError && <p className="error">{AppleIDError}</p>} {/* Display Apple error message */}

                        <div className="button-group">
                            <button className="overlay-add-phone" onClick={isEditing ? handleUpdatePhone : handleAddPhone} >
                                {isEditing ? 'Update Phone' : 'Add Phone'}
                            </button>
                            {isEditing && (
                                <button
                                    className="overlay-delet-phone"
                                    onClick={() => {
                                        if (window.confirm('Are you sure you want to delete this phone?')) {
                                            handleDeletePhone();
                                        }
                                    }}
                                >
                                    Delete Phone
                                </button>
                            )}
                            <button className="overlay-add-phone" onClick={togglePasswords}>
                                {showPasswords ? 'Hide Passwords' : 'Show Passwords'}
                            </button>
                        </div>
                    </div>
                </div>
            )}

        </div>


    );


};

export default Phone;
