import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { listBags, listDeleteEquipmentHistories, listUsers } from '../../../graphql/queries';
import { createDeleteEquipmentHistory, createBag, deleteBag, updateBag } from '../../../graphql/mutations';
import './Bag.css';
import XLSX from 'xlsx-js-style';
import { v4 as uuidv4 } from 'uuid';
import { FaFileExcel } from 'react-icons/fa';
import useFilteredData from '../../../components/layout/useFilteredDate';
import SearchBar from '../../../components/layout/SearchBar';
import Inventory_header from '../../../components/layout/Inventory_header';
import { capitalize } from '../../../components/common/capitalize';

const Bag = () => {
    const [bag, setBag] = useState([]);
    const [nextToken, setNextToken] = useState(null);
    const [showBagAddOverlay, setShowBagAddOverlay] = useState(false);
    const [selectedBag, setSelectedBag] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const [newBag, setNewBag] = useState({
        id: '',
        brand: '',
        type: '',
        price: '',
        store: '',
        purchase_date: '',
        assignment_date: '',
        returnable_date: '',
        available: true,
        status: 'inv',
        assigned_to: '',
        location: '',
        team: '',

    });
    const [sortConfig, setSortConfig] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [duplicateError, setDuplicateError] = useState('');
    const [priceError, setPriceError] = useState('');
    const [statusError, setStatusError] = useState('');
    const { loading1, error: userError, data: userData } = useQuery(listUsers);



    const handleSaveAsExcel = async () => {
        try {
            const bagToExport = filteredBags.length > 0 ? filteredBags : bag;

            if (bagToExport.length > 0) {
                const sortedbag = [...bagToExport].sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp));

                const worksheetData = sortedbag.map((bag, index) => ({
                    '#': index + 1, // Agregar el número de fila
                    'Brand': bag.brand,
                    'Type': bag.type,
                    'Assigned To': bag.assigned_to,
                    'Available': bag.available ? 'Yes' : 'No',
                    'Status': bag.status,
                    'Team': bag.team,
                }));

                // Convert JSON to sheet starting from row 2
                const worksheet = XLSX.utils.json_to_sheet([]);
                let endCol = 0;

                XLSX.utils.sheet_add_aoa(worksheet, [['Bags']], { origin: 'A1' });

                if (worksheetData.length > 0) {
                    // Agrega los datos a partir de la fila A2
                    XLSX.utils.sheet_add_json(worksheet, worksheetData, { origin: 'A2', skipHeader: false });

                    // Define el rango de referencia (!ref)
                    const endRow = worksheetData.length + 1; // +1 para el título en A1
                    const endCol = Object.keys(worksheetData[0]).length - 1;
                    worksheet['!ref'] = XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: endRow, c: endCol } });
                } else {
                    console.error("No hay datos para exportar.");
                    return;
                }

                // Merge title cells across the columns
                worksheet['!merges'] = worksheet['!merges'] || [];
                worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 6 } }); // A1 to G1 for the added column

                // Style the title row
                worksheet['A1'].s = {
                    font: {
                        bold: true,
                        sz: 20, // Font size
                        color: { rgb: '1F4E79' } // Dark blue text color
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center'
                    }
                };

                // Apply styling for the header row (now starting at row 2)
                const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
                for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                    const cellAddress = XLSX.utils.encode_cell({ r: 1, c: col }); // Header row is now row 1
                    if (!worksheet[cellAddress]) continue;
                    worksheet[cellAddress].s = {
                        fill: {
                            fgColor: { rgb: '1F4E79' } // Dark blue background
                        },
                        font: {
                            bold: true,
                            color: { rgb: 'FFFFFF' } // White text
                        },
                        alignment: {
                            horizontal: 'center'
                        },
                        border: {
                            top: { style: 'thin', color: { rgb: '000000' } },
                            left: { style: 'thin', color: { rgb: '000000' } },
                            bottom: { style: 'thin', color: { rgb: '000000' } },
                            right: { style: 'thin', color: { rgb: '000000' } },
                        }
                    };
                }

                // Apply alternating row colors and borders starting from row 3
                for (let row = 2; row <= headerRange.e.r; row++) {
                    const isEvenRow = row % 2 === 0;
                    for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
                        if (!worksheet[cellAddress]) continue;

                        worksheet[cellAddress].s = {
                            fill: {
                                fgColor: { rgb: isEvenRow ? 'D3D3D3' : 'FFFFFF' } // Light gray for even rows, white for odd rows
                            },
                            border: {
                                top: { style: 'thin', color: { rgb: '000000' } },
                                left: { style: 'thin', color: { rgb: '000000' } },
                                bottom: { style: 'thin', color: { rgb: '000000' } },
                                right: { style: 'thin', color: { rgb: '000000' } },
                            },
                            alignment: {
                                horizontal: 'center'
                            }
                        };
                    }
                }

                // Adjust columns to fit content
                // Adjust columns to fit content
                worksheet['!cols'] = [
                    { wch: 5 }, // Width for Row Number
                    { wch: 10 },
                    { wch: 15 },
                    { wch: 30 },
                    { wch: 15 },
                    { wch: 15 },
                    { wch: 15 }
                ];

                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'bags');

                // Export the file
                XLSX.writeFile(workbook, 'bagsData.xlsx');
            } else {
                console.error('No data to export');
            }
        } catch (error) {
            console.error('Error exporting data to Excel:', error);
        }
    };

    const handleEditBag = (Bag) => {
        setSelectedBag(Bag);
        setIsEditing(true);
        setShowBagAddOverlay(true);
    };

    const { loading, data, fetchMore } = useQuery(listBags, {
        variables: { limit: 100 },
    });

    const [addBag] = useMutation(createBag, {
        refetchQueries: [{ query: listBags, variables: { limit: 100 } }]
    });

    const [removeBag] = useMutation(deleteBag, {
        refetchQueries: [{ query: listBags, variables: { limit: 100 } }]
    });
    const [updBag] = useMutation(updateBag, {
        refetchQueries: [{ query: listBags, variables: { limit: 100 } }]
    });
    const [addDeleteEquipmentHistory] = useMutation(createDeleteEquipmentHistory, {
        refetchQueries: [{ query: listDeleteEquipmentHistories, variables: { limit: 100 } }]
    });

    const [fetchAllBags, { loading: countLoading, data: AllBagsData }] = useLazyQuery(listBags, {
        variables: { limit: 100 },
        fetchPolicy: "network-only",
    });


    useEffect(() => {
        const fetchAllBags = async () => {
            let AllBags = [];
            let currentNextToken = null;

            do {
                const { data } = await fetchMore({
                    variables: { limit: 100, nextToken: currentNextToken },
                });

                AllBags = [...AllBags, ...data.listBags.items];
                currentNextToken = data.listBags.nextToken;
            } while (currentNextToken);

            setBag(AllBags);
            setNextToken(currentNextToken);
        };

        if (data) {
            fetchAllBags();
        }
    }, [data, fetchMore]);

    const clearNewBag = () => {
        setNewBag({
            id: '',
            brand: '',
            type: '',
            price: '',
            store: '',
            purchase_date: '',
            assignment_date: '',
            returnable_date: '',
            available: true,
            status: 'inv',
            assigned_to: '',
            location: '',
            team: '',
        });
    };

    // Define loadMore function
    const loadMore = () => {
        fetchMore({
            variables: { limit: 100, nextToken },
            updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;
                setNextToken(fetchMoreResult.listBags.nextToken);
                return {
                    listBags: {
                        ...fetchMoreResult.listBags,
                        items: [...prevResult.listBags.items, ...fetchMoreResult.listBags.items]
                    }
                };
            }
        });
    };
    const filteredBags = useFilteredData(bag || [], searchTerm, sortConfig);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (isEditing) {
            setSelectedBag({
                ...selectedBag,
                [name]: value
            });
        } else {
            setNewBag({
                ...newBag,
                id: uuidv4(),
                [name]: value
            });
        }
    };

    const handleAddBag = () => {

        // Formatear la fecha en el formato YYYY-MM-DD si existe, o establecerla en null si está vacía
        const formattedPurchase = newBag.purchase_date
            ? new Date(newBag.purchase_date).toISOString().split('T')[0]
            : null;
        const formattedAssignment = newBag.assignment_date
            ? new Date(newBag.assignment_date).toISOString().split('T')[0]
            : null;

        const formattedReturnable = newBag.returnable_date
            ? new Date(newBag.returnable_date).toISOString().split('T')[0]
            : null;

        // Validate that the price contains only digits and is a valid number
        let priceValue = null;

        if (newBag.price !== "" && newBag.price !== null && newBag.price != undefined) {
            priceValue = parseFloat(newBag.price);

            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');
                return;
            }
        }
        setPriceError('');

        // Check availability first
        if (newBag.available === true) {

            // If available, status must be Inventario
            if (newBag.status !== 'inv') {
                setStatusError('If the Bag is available, its status must be "Inventario".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // If available and status is Inventario, assigned_to must be empty or "N/A"
            if (newBag.assigned_to !== '' && newBag.assigned_to !== 'n/a') {
                setStatusError('If the Bag is available and its status is "Inventario", the "Assigned to" field must be empty or "N/A".');
                console.log('Assigned_to validation failed');
                return; // Exit function if validation fails
            }


        } else if (newBag.available !== "") {
            // If not available, status must be one of the allowed statuses
            const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
            if (!allowedStatuses.includes(newBag.status)) {
                setStatusError('If the Bag is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // Clear error if validation passes
            setStatusError('');
        }

        // If all validations pass, proceed with adding the bag to the database
        addBag({
            variables: { input: { ...newBag, price: priceValue, purchase_date: formattedPurchase, returnable_date: formattedReturnable, assignment_date: formattedAssignment } }
        })
            .then(() => {
                clearNewBag();
                alert("The Bag was an added success")
                setShowBagAddOverlay(false);
            }).catch(error => {
                console.error("Error adding bag:", error);
            });
    };

    const removeUnnecessaryFields = (data, fieldsToRemove) => {
        fieldsToRemove.forEach((field) => {
            delete data[field];
        });
        return data;
    };
    const handleDeleteBag = async () => {
        try {
            // Remover campos innecesarios
            const BagData = removeUnnecessaryFields({ ...selectedBag }, [

                'type',
                'warranty_date',
                'returnable_date',
                'status',
                'team',
            ]);

            // Lista de campos permitidos según el esquema GraphQL
            const allowedFields = [
                'id',
                'Item',
                'tagId',
                'tagid', // Agregado
                'serial_Number',
                'price',
                'brand',
                'model',
                'store',
                'purchase_date',
                'assignment_date',
                'available',
                'previous_employees',
                'assigned_to', // Agregado
                'location',
                'notes',
            ];

            // Filtrar los campos permitidos
            const cleanedNewBag = Object.fromEntries(
                Object.entries(BagData).filter(([key]) => allowedFields.includes(key))
            );


            console.log("Antes de normalizar:", cleanedNewBag);

            // Normalizar claves alternativas
            cleanedNewBag.tagId = cleanedNewBag.tagId || cleanedNewBag.tagid;
            delete cleanedNewBag.tagid;

            cleanedNewBag.previous_employees =
                cleanedNewBag.previous_employees || cleanedNewBag.assigned_to;
            delete cleanedNewBag.assigned_to;

            console.log("Despues de normalizar:", cleanedNewBag);

            // Añadir valores clave predeterminados
            if (!cleanedNewBag.Item) {
                cleanedNewBag.Item = "Bag"; // Establecer el valor predeterminado para `Item`
            }

            // Eliminar valores vacíos
            Object.keys(cleanedNewBag).forEach((key) => {
                if (cleanedNewBag[key] === "" || cleanedNewBag[key] === null) {
                    delete cleanedNewBag[key];
                }
            });

            // Agregar empleado a la tabla PreviousEmployee
            await addDeleteEquipmentHistory({
                variables: { input: cleanedNewBag },
            });

            // Eliminar el Bag
            await removeBag({
                variables: { input: { id: cleanedNewBag.id } },
            });

            // Resetear estados
            setShowBagAddOverlay(false);
            setIsEditing(false);
            setSelectedBag(null);

        } catch (error) {
            console.error("Error deleting Bag", error);
        }
    };

    const handleUpdateBag = async () => {

        const formattedData_purchase = selectedBag.purchase_date ? new Date(selectedBag.purchase_date).toISOString().split('T')[0] : null;
        const formattedData_returnable = selectedBag.returnable_date ? new Date(selectedBag.returnable_date).toISOString().split('T')[0] : null;
        const formattedData_assignment = selectedBag.assignment_date ? new Date(selectedBag.assignment_date).toISOString().split('T')[0] : null;

        //Check Price
        let priceValue = null;
        if (selectedBag.price !== "" && selectedBag.price !== null && selectedBag.price != undefined) {
            priceValue = parseFloat(selectedBag.price);

            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');
                return;
            }
        }
        setPriceError('');


        // Check availability first
        if (selectedBag.available === 'true') {

            // If available, status must be Inventario
            if (selectedBag.status !== 'inv') {
                setStatusError('If the Bag is available, its status must be "Inventario".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // If available and status is Inventario, assigned_to must be empty or "N/A"
            if (selectedBag.assigned_to !== '' && selectedBag.assigned_to !== 'n/a') {
                setStatusError('If the Bag is available and its status is "Inventario", the "Assigned to" field must be empty or "N/A".');
                console.log('Assigned_to validation failed');
                return; // Exit function if validation fails
            }

        } else if (selectedBag.available === 'false') {
            // If not available, status must be one of the allowed statuses
            const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
            if (!allowedStatuses.includes(selectedBag.status)) {
                setStatusError('If the Bag is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // Clear error if validation passes
            setStatusError('');
        }
        updBag({
            variables: {
                input: {
                    id: selectedBag.id,
                    brand: selectedBag.brand,
                    type: selectedBag.type,
                    price: priceValue,
                    store: selectedBag.store,
                    purchase_date: formattedData_purchase,
                    assignment_date: formattedData_assignment,
                    returnable_date: formattedData_returnable,
                    available: selectedBag.available,
                    status: selectedBag.status,
                    assigned_to: selectedBag.assigned_to,
                    location: selectedBag.location,
                    team: selectedBag.team,

                }
            }
        }).then(() => {
            setIsEditing(true);
            setShowBagAddOverlay(false);
            setPriceError('');
            setStatusError('');
            setDuplicateError('');


        }).catch(error => {
            console.error("Error updating Bag:", error);
        });
    };

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };


    //listUsers 
    const users = React.useMemo(() => {
        if (userData && userData.listUsers) {
            return userData.listUsers.items || [];
        }
        return [];
    }, [userData]);
    if (userError) return <p>Error loading users: {userError.message}</p>;
    if (loading1) return <p>Error loading users: {userError.message}</p>;


    const formatDate = (dateString) => {
        const options = { month: 'numeric', day: 'numeric', year: 'numeric' };
        const date = new Date(dateString);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

        return localDate.toLocaleDateString(undefined, options);
    };

    const formatPrice = (price) => {
        if (price === null || price === undefined || price === '') {
            return '';
        }
        const parsedPrice = parseFloat(price);
        return isNaN(parsedPrice) ? '' : parsedPrice.toFixed(2);
    };

    return (
        <div className="bag-page">
            <header className="inventory-header">
                <h1>Bag</h1>
            </header>
            <Inventory_header />
            <div className="inventory-search">
                <button onClick={handleSaveAsExcel} > <FaFileExcel /> Export </button>
            </div>

            <div className="searchBar">
                <button className="add-bag-btn" onClick={() => {
                    clearNewBag();
                    setSelectedBag(null);
                    setIsEditing(false);
                    setShowBagAddOverlay(true);
                }}>+ Add New Bag</button>
                <SearchBar searchTerm={searchTerm} onSearch={setSearchTerm} />
            </div>

            <div className="bag-table-container">
                <table className="bag-table">

                    <thead>
                        <tr>
                            <th>#</th> {/* Columna para contar las filas */}

                            <th onClick={() => requestSort('brand')}>
                                Brand
                                {sortConfig?.key === 'brand' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('type')}>
                                Type
                                {sortConfig?.key === 'type' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('price')}>
                                Price
                                {sortConfig?.key === 'price' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('store')}>
                                Store
                                {sortConfig?.key === 'store' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('purchase_date')}>
                                Purchase Date
                                {sortConfig?.key === 'purchase_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('available')}>
                                Available
                                {sortConfig?.key === 'available' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('status')}>
                                Status
                                {sortConfig?.key === 'status' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('assigned_to')}>
                                Assigned To
                                {sortConfig?.key === 'assigned_to' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('assignment_date')}>
                                Assignment Date
                                {sortConfig?.key === 'assignment_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('returnable_date')}>
                                Returnable Date
                                {sortConfig?.key === 'returnable_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                          
                            <th onClick={() => requestSort('location')}>
                                Location
                                {sortConfig?.key === 'location' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('team')}>
                                Team
                                {sortConfig?.key === 'team' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {filteredBags.map((bag, index) => (
                            <tr key={bag.id} onClick={() => handleEditBag(bag)} >
                                <td>{index + 1}</td> {/* Número de fila */}
                                <td className="bag-brand">{capitalize(bag.brand.toLowerCase())}</td>
                                <td className="bag-type">{bag.type === 'rolling' ? 'Rolling Bag' : (bag.type === 'backpak' ? 'Backpak' : null)}</td>
                                <td className="bag-price">${formatPrice(bag.price)}</td>
                                <td className="bag-store">{bag.store}</td>
                                <td className="bag-purchase_date">{bag.purchase_date ? formatDate(bag.purchase_date) : 'No Date'}</td>
                                <td className="bag-available">{bag.available ? 'Yes' : 'No'} </td>
                                <td className="bag-status">
                                    {bag.status === 'broken' ? 'Broken' :
                                        bag.status === 'use' ? 'Use' :
                                            bag.status === 'transfer' ? 'Transfer' :
                                                bag.status === 'lost' ? 'Lost' :
                                                    bag.status === 'inv' ? 'Inventario' :
                                                        'Use'} {/* Muestra 'Unknown' si el estado no coincide con ninguno de los anteriores */}
                                </td>
                                <td className="bag-assigned_to">{bag.assigned_to}</td>
                                <td className="bag-assignment_date">{bag.assignment_date ? formatDate(bag.assignment_date) : 'No Date'}</td>
                                <td className="bag-returnable_date">{bag.returnable_date ? formatDate(bag.returnable_date) : 'No Date'}</td>
                                <td className="bag-location">{bag.location}</td>
                                <td className="bag-team">{bag.team}</td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {nextToken && !loading && (
                <button onClick={loadMore}>Load More</button>
            )}


            {loading && <p>Loading...</p>}

            {showBagAddOverlay && (

                <div className="bag_add_overlay" onClick={(e) => {
                    // Close overlay only if clicked outside the modal content
                    if (e.target.classList.contains("bag_add_overlay")) {
                        setShowBagAddOverlay(false);
                        clearNewBag();
                        setSelectedBag(null);
                        setIsEditing(false);
                    }
                }}>
                    <div className="overlay-content-inventory">
                        <div className="header-row">
                            <h2 className="header-title">{isEditing ? 'Edit Bag' : 'Add New Bag'}</h2>
                            <button onClick={() => {
                                setShowBagAddOverlay(false);
                                clearNewBag();
                                setSelectedBag(null);
                                setIsEditing(false);
                            }}>X</button>
                        </div>

                        <label>Brand:</label><input name="brand" placeholder="Brand" value={isEditing ? selectedBag.brand : newBag.brand} onChange={handleInputChange} required />
                        <label>Type of Bag:</label>

                        <select name="type" value={isEditing ? selectedBag.type : newBag.type} onChange={handleInputChange} required>
                            <option value="" disabled hidden>Select Type</option>
                            <option value="rolling">Rolling Bag</option>
                            <option value="backpak">Backpak</option>
                        </select>
                        <label>Price:</label><input name="price" placeholder="price" value={isEditing ? selectedBag.price : newBag.price} onChange={handleInputChange} />
                        <label>Store:</label><input name="store" placeholder="store" value={isEditing ? selectedBag.store : newBag.store} onChange={handleInputChange} />

                        <label htmlFor="purchase_date">Purchase Date:</label>
                        <input
                            type="Date"
                            id="purchase_date"
                            name="purchase_date"
                            value={isEditing ? selectedBag.purchase_date : newBag.purchase_date}
                            onChange={handleInputChange}

                        />

                        <label htmlFor="assignment_date">Assignment Date:</label>
                        <input
                            type="date"
                            id="assignment_date"
                            name="assignment_date"
                            value={isEditing ? selectedBag.assignment_date : newBag.assignment_date}
                            onChange={handleInputChange}

                        />
                        <label htmlFor="returnable_date">Returnable Date:</label>
                        <input
                            type="date"
                            id="returnable_date"
                            name="returnable_date"
                            value={isEditing ? selectedBag.returnable_date : newBag.returnable_date}
                            onChange={handleInputChange}

                        />

                        <label>Available:</label>

                        <select name="available" placeholder="available" value={isEditing ? selectedBag.available : newBag.available} onChange={handleInputChange} >
                            <option value="" disabled hidden className="placeholder-option">Select Y/N</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>

                        </select>
                        <label>Status:</label>
                        <select name="status" placeholder="status" value={isEditing ? selectedBag.status : newBag.status} onChange={handleInputChange} >
                            <option value="" disabled hidden>Select Status</option>
                            <option value="use">Use</option>
                            <option value="broken">Broken</option>
                            <option value="transfer">Transfer</option>
                            <option value="lost">Lost</option>
                            <option value="inv">Inventario</option>
                        </select>
                        <label>Assigned To:</label>
                        <select
                            name="assigned_to"
                            value={isEditing ? selectedBag.assigned_to : newBag.assigned_to}
                            onChange={handleInputChange}
                        >
                            <option value="">Select an Employee</option>

                            {users.length > 0 ? (
                                [...users].sort((a, b) => {
                                    const nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
                                    const nameB = `${b.first_name} ${b.last_name}`.toLowerCase();
                                    return nameA.localeCompare(nameB);
                                }).map(user => (
                                    <option key={user.id} value={`${capitalize(user.first_name)} ${capitalize(user.last_name)}`}>
                                        {capitalize(user.first_name)} {capitalize(user.last_name)}
                                    </option>
                                ))
                            ) : (

                                <option value="">No users available</option>
                            )}
                            <option value="n/a">N/A</option> {/*N/A */}
                        </select>
                        <label>Location:</label><input name="location" placeholder="location" value={isEditing ? selectedBag.location : newBag.location} onChange={handleInputChange} />
                        <label>Team:</label><input name="team" placeholder="team" value={isEditing ? selectedBag.team : newBag.team} onChange={handleInputChange} />

                        {duplicateError && <p className="error">{duplicateError}</p>} {/* Display duplicate error message */}
                        {priceError && <p className="error">{priceError}</p>} {/* Display price error message */}
                        {statusError && <p className="error">{statusError}</p>} {/* Display duplicate error message */}

                        <div className="button-group">
                            <button className="overlay-add-bag" onClick={isEditing ? handleUpdateBag : handleAddBag} >
                                {isEditing ? 'Update Bag' : 'Add Bag'}
                            </button>
                            {isEditing && (
                                <button
                                    className="overlay-delet-bag"
                                    onClick={() => {
                                        if (window.confirm('Are you sure you want to delete this bag?')) {
                                            handleDeleteBag();
                                        }
                                    }}
                                >
                                    Delete Bag
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}

        </div>


    );

};

export default Bag;
