// src/components/ClientForm/ClientForm.jsx
import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { listUsersByTeamOne } from '../graphql/queries';
import { CREATE_CLIENTFIX2, UPDATE_CLIENTFIX2 } from '../graphql/mutations';
import { v4 as uuidv4 } from 'uuid';
import './ClientForm.css';

const ClientForm = ({
  isEditing = false,
  selectedClient = null,
  onClientAdded,
  onClose,
  userGroups,
  handleDeleteClient,
  refetch
}) => {
  // Initialize state based on whether we're editing or adding a new client
  const [clients, setClients] = useState([]);
  const [clientData, setClientData] = useState(selectedClient || {
    id: uuidv4(),
    name: '',
    last_name: '',
    email: '',
    address: '',
    city: '',
    county: '',
    zip_code: '',
    prefered_lang: '',
    insurance_picked: '',
    last_contacted_date: '',
    navigator: '',
    payment_per_month: '',
    phone: '',
    plan: ''
  });

  const [phoneError, setPhoneError] = useState('');
  const [duplicateError, setDuplicateError] = useState('');
  const { loading: usersLoading, error: usersError, data: usersData } = useQuery(listUsersByTeamOne);
  const navigatorOptions = usersData?.listUsers?.items?.map(user => ({
    id: user.id,
    name: `${user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1).toLowerCase()} ${user.last_name.charAt(0).toUpperCase() + user.last_name.slice(1).toLowerCase()}`
  })) || [];

  const [addClient] = useMutation(CREATE_CLIENTFIX2, {
    onCompleted: () => {
      onClientAdded();
      refetch(); // trigger refetch after the mutation completes successfully
      onClose();
    },
    onError: (error) => console.error("Error adding client:", error)
  });

  const [updateClient] = useMutation(UPDATE_CLIENTFIX2, {
    onCompleted: () => {
      onClientAdded();
      refetch(); // trigger refetch after the mutation completes successfully
      onClose();
    },
    onError: (error) => console.error("Error updating client:", error)
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (['name', 'last_name', 'email'].includes(name)) {
      formattedValue = value.toLowerCase();
    }

    if (name === 'phone') {
      formattedValue = value.replace(/[^\d]/g, ''); // Remove non-numeric characters
      if (formattedValue.length > 10) {
        setPhoneError('Phone number cannot be longer than 10 digits.');
      } else {
        setPhoneError('');
      }
    }

    if (name === 'zip_code') {
      formattedValue = value.replace(/[^\d]/g, ''); // Allow only numeric input
      if (formattedValue.length > 5) {
        formattedValue = formattedValue.slice(0, 5); // Limit to 5 digits
      }
    }

    if (name === 'prefered_lang') {
      formattedValue = value.split(',').map(lang => lang.trim());
    }

    setClientData(prevState => ({
      ...prevState,
      [name]: formattedValue
    }));
  };

  const handleSubmit = () => {
    if (clientData.phone.length !== 10) {
      setPhoneError('Phone number must be exactly 10 digits.');
      return;
    }
    if (Number.isInteger(clientData.payment_per_month) && clientData.payment_per_month >= 0) {
      // Your code here
    }
    else {
      clientData.payment_per_month = 0;
    }

    const isDuplicate = clients.some(client =>
      client.name === clientData.name &&
      client.last_name === clientData.last_name &&
      client.email === clientData.email
    );

    if (isDuplicate) {
      setDuplicateError('A client with the same name, last name, and email already exists.');
      return;
    } else {
      setDuplicateError('');
    }

    // Allowed fields based on UpdateClientFix2Input
    const allowedFields = [
      'id',
      'name',
      'last_name',
      'email',
      'address',
      'city',
      'county',
      'zip_code',
      'prefered_lang',
      'insurance_picked',
      'last_contacted_date',
      'navigator',
      'payment_per_month',
      'phone',
      'plan',
    ];

    // Filter clientData to include only allowed fields
    const filteredClientData = Object.keys(clientData)
      .filter((key) => allowedFields.includes(key))
      .reduce((obj, key) => {
        obj[key] = typeof clientData[key] === 'string'
          ? clientData[key].toLowerCase() // Convert string values to lowercase
          : clientData[key];
        return obj;
      }, {});

    if (isEditing) {
      updateClient({
        variables: { input: filteredClientData }
      });
    } else {
      addClient({ variables: { input: filteredClientData } });
    }
  };

  return (
    <div>
      <button className="add_usr_close-btn" onClick={onClose}>
        X
      </button>
      <h2>{isEditing ? "Edit Client" : "Add New Client"}</h2>
      <div className="client-form-grid">
        <div className="form-group">
          <label htmlFor="name">First Name</label>
          <input
            id="name"
            name="name"
            placeholder="First Name"
            value={clientData.name}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="last_name">Last Name</label>
          <input
            id="last_name"
            name="last_name"
            placeholder="Last Name"
            value={clientData.last_name}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group full-width">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            name="email"
            placeholder="Email"
            value={clientData.email}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone Number</label>
          <input
            id="phone"
            name="phone"
            placeholder="Phone Number"
            value={clientData.phone}
            onChange={handleInputChange}
          />
          {phoneError && <p className="error-text">{phoneError}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="prefered_lang">Preferred Language</label>
          <select
            id="prefered_lang"
            name="prefered_lang"
            value={clientData.prefered_lang}
            onChange={handleInputChange}
          >
            <option value="" disabled hidden>Select Language</option>
            <option value="english">English</option>
            <option value="spanish">Spanish</option>
            <option value="french">French</option>
            <option value="german">German</option>
            <option value="chinese">Chinese</option>
            <option value="japanese">Japanese</option>
            <option value="arabic">Arabic</option>
            <option value="pashto">Pashto</option>
            <option value="turkish">Turkish</option> 
            <option value="nigerian">Nigerian</option>  
            <option value="dari">Dari</option>  
            <option value="Other">Other</option>
          </select>
        </div>
        {/* Add other form fields similarly */}
        <div className="form-group">
          <label htmlFor="address">Address</label>
          <input
            id="address"
            name="address"
            placeholder="Address"
            value={clientData.address}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="city">City</label>
          <input
            id="city"
            name="city"
            placeholder="City"
            value={clientData.city}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="county">County</label>
          <input
            id="county"
            name="county"
            placeholder="County"
            value={clientData.county}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="zip_code">Zip Code</label>
          <input
            id="zip_code"
            name="zip_code"
            placeholder="zip_code"
            value={clientData.zip_code}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="insurance_picked">Insurance Picked</label>
          <select
            id="insurance_picked"
            name="insurance_picked"
            value={clientData.insurance_picked}
            onChange={handleInputChange}
          >
            <option value="" disabled hidden>Select Insurance</option>
            <option value="Aetna CVS">Aetna CVS</option>
            <option value="Blue Advantage">Blue Advantage</option>
            <option value="Imperial Standard">Imperial Standard</option>
            <option value="MyBlue Health">MyBlue Health</option>
            <option value="United Healthcare">United Healthcare</option>
            <option value="Community First">Community First</option>
            <option value="Ambetter">Ambetter</option>
            <option value="Blue Cross">Blue Cross</option>
            <option value="Oscar">Oscar</option>
            <option value="Christus Health Plan">Christus Health Plan</option>
            <option value="Molina Healthcare">Molina Healthcare</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="plan">Plan Metal</label>
          <select
            id="plan"
            name="plan"
            value={clientData.plan}
            onChange={handleInputChange}
          >
            <option value="" disabled hidden>Select Plan</option>
            <option value="Bronze">Bronze</option>
            <option value="Silver">Silver</option>
            <option value="Gold">Gold</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="last_contacted_date">Last Contacted Date</label>
          <input
            type="date"
            id="last_contacted_date"
            name="last_contacted_date"
            value={clientData.last_contacted_date}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="navigator">Navigator</label>
          <select
            id="navigator"
            name="navigator"
            value={clientData.navigator}
            onChange={handleInputChange}
          >
            <option value="">Select Navigator...</option>
            {navigatorOptions.map(option => (
              <option key={option.id} value={option.name}>{option.name}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="payment_per_month">Payment Per Month</label>
          <input
            id="payment_per_month"
            name="payment_per_month"
            placeholder="Payment Per Month"
            value={clientData.payment_per_month}
            onChange={handleInputChange}
          />
        </div>
        {/* Add additional error messages if needed */}
        {duplicateError && <p className="error-text">{duplicateError}</p>}
      </div>
      <button className='overlay-add-client' onClick={handleSubmit}>
        {isEditing ? "Update Client" : "Add Client"}
      </button>
      {userGroups?.includes('Admin') && isEditing && (
        <button className='overlay-delete-client' onClick={handleDeleteClient}>
          Delete Client
        </button>
      )}
    </div>
  );

};

export default ClientForm;
