
import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { listDeletedUserHistories } from '../../../graphql/queries';
import { createDeletedUserHistory, deleteDeletedUserHistory, updateDeletedUserHistory } from '../../../graphql/mutations';
import './Previous_Employees.css';
import XLSX from 'xlsx-js-style';
import { FaFileExcel } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import Inventory_header from '../../../components/layout/Inventory_header';
import useFilteredData from '../../../components/layout/useFilteredDate';
import SearchBar from '../../../components/layout/SearchBar';
import { capitalize } from '../../../components/common/capitalize';


const Previous_employees = () => {
    const [previous_employees, setPrevious_employees] = useState([]);
    const [nextToken, setNextToken] = useState(null);
    const [showEmployees_PreviousAddOverlay, setShowEmployees_PreviousAddOverlay] = useState(false);
    const [showPasswords, setShowPasswords] = useState(false);
    const [selectedPrevious_employees, setSelectedPrevious_employees] = useState(null);

    const [newEmployees_Previous, setNewEmployees_Previous] = useState({
        id: '',
        ThreeCX_id: '',
        ThreeCX_password: '',
        first_name: '',
        last_name: '',
        thc_email_id: '',
        thc_email_password: '',
        gmail_email_id: '',
        gmail_email_password: '',
        apple_id: '',
        apple_password: '',
        phone_number: '',
        navigator_id: '',
        team: '',
        cancellation_date: '',
        notes: '',
    });

    const [sortConfig, setSortConfig] = useState(null);
    const [duplicateError, setDuplicateError] = useState('');
    const [threeCX_idError, setThreeCX_idError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [thcEmailIdError, setThcEmailIdError] = useState('');
    const [gmailEmailIdError, setGmailEmailIdError] = useState('');
    const [appleIdError, setAppleIdError] = useState('');
    const [CancellationDateError, setCancellationDateError] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [isEditing, setIsEditing] = useState(false);


    const handleSaveAsExcel = async () => {
        try {
            const employeesToExport = filteredEmployees_Previous.length > 0 ? filteredEmployees_Previous : previous_employees;
            if (employeesToExport.length > 0) {
                // Ordena los empleados por la marca de tiempo
                const sortedEmployees_Previous = [...employeesToExport].sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp));

                // Prepara los datos para la exportación a Excel con títulos personalizados de las columnas y columnas vacías
                const worksheetData = sortedEmployees_Previous.map((previous_employees, index) => ({
                    '#': index + 1, // Agregar el número de fila
                    'First Name': previous_employees.first_name,
                    'Last Name': previous_employees.last_name,
                    '3CX': previous_employees.ThreeCX_id,
                    '3CX Password': previous_employees.ThreeCX_password,
                    'THC Email': previous_employees.thc_email_id,
                    'Gmail': previous_employees.gmail_email_id,
                    'Apple': previous_employees.apple_id,
                    'Phone Number': previous_employees.phone_number,
                    'Navigator ID': previous_employees.navigator_id,
                    'Team': previous_employees.team,
                    'cancellation_date': previous_employees.cancellation_date,
                }));

                // Convert JSON to sheet starting from row 2
                const worksheet = XLSX.utils.json_to_sheet([]);
                let endCol = 0;

                XLSX.utils.sheet_add_aoa(worksheet, [['Employees_Previous']], { origin: 'A1' });

                if (worksheetData.length > 0) {
                    // Agrega los datos a partir de la fila A2
                    XLSX.utils.sheet_add_json(worksheet, worksheetData, { origin: 'A2', skipHeader: false });

                    // Define el rango de referencia (!ref)
                    const endRow = worksheetData.length + 1; // +1 para el título en A1
                    const endCol = Object.keys(worksheetData[0]).length - 1;
                    worksheet['!ref'] = XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: endRow, c: endCol } });
                } else {
                    console.error("No hay datos para exportar.");
                    return;
                }

                // Merge title cells across the columns
                worksheet['!merges'] = worksheet['!merges'] || [];
                worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 11 } }); // A1 to H1 for the added column

                // Style the title row
                worksheet['A1'].s = {
                    font: {
                        bold: true,
                        sz: 20, // Font size
                        color: { rgb: '1F4E79' } // Dark blue text color
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center'
                    }
                };

                // Apply styling for the header row (now starting at row 2)
                const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
                for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                    const cellAddress = XLSX.utils.encode_cell({ r: 1, c: col }); // Header row is now row 1
                    if (!worksheet[cellAddress]) continue;
                    worksheet[cellAddress].s = {
                        fill: {
                            fgColor: { rgb: '1F4E79' } // Dark blue background
                        },
                        font: {
                            bold: true,
                            color: { rgb: 'FFFFFF' } // White text
                        },
                        alignment: {
                            horizontal: 'center'
                        },
                        border: {
                            top: { style: 'thin', color: { rgb: '000000' } },
                            left: { style: 'thin', color: { rgb: '000000' } },
                            bottom: { style: 'thin', color: { rgb: '000000' } },
                            right: { style: 'thin', color: { rgb: '000000' } },
                        }
                    };
                }

                // Apply alternating row colors and borders starting from row 3
                for (let row = 2; row <= headerRange.e.r; row++) {
                    const isEvenRow = row % 2 === 0;
                    for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
                        if (!worksheet[cellAddress]) continue;

                        worksheet[cellAddress].s = {
                            fill: {
                                fgColor: { rgb: isEvenRow ? 'D3D3D3' : 'FFFFFF' } // Light gray for even rows, white for odd rows
                            },
                            border: {
                                top: { style: 'thin', color: { rgb: '000000' } },
                                left: { style: 'thin', color: { rgb: '000000' } },
                                bottom: { style: 'thin', color: { rgb: '000000' } },
                                right: { style: 'thin', color: { rgb: '000000' } },
                            },
                            alignment: {
                                horizontal: 'center'
                            }
                        };
                    }
                }


                // Ajusta el ancho de las columnas (opcional)
                worksheet['!cols'] = [
                    { wch: 5 },
                    { wch: 15 }, // Ancho de la columna 'First Name'
                    { wch: 15 }, // Ancho de la columna 'Last Name'
                    { wch: 5 }, // Ancho de la columna '3CX'
                    { wch: 15 }, // Ancho de la columna '3CX'
                    { wch: 45 }, // Ancho de la columna 'THC Email'
                    { wch: 45 }, // Ancho de la columna 'Gmail'
                    { wch: 45 }, // Ancho de la columna 'Apple'
                    { wch: 15 }, // Ancho de la columna 'Phone Number'
                    { wch: 15 },  // Ancho de la columna 'NAV'
                    { wch: 5 }, // Ancho de la columna 'Team'
                    { wch: 10 }, // Ancho de la columna 'location'
                ];

                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Employees_Previous');

                // Exporta el archivo
                XLSX.writeFile(workbook, 'Employees_PreviousData.xlsx');
            } else {
                console.error('No data to export');
            }
        } catch (error) {
            console.error('Error exporting data to Excel:', error);
        }
    };

    const { loading, data, fetchMore } = useQuery(listDeletedUserHistories, {
        variables: { limit: 100 },
    });


    const [addEmployees_Previous] = useMutation(createDeletedUserHistory, {
        refetchQueries: [{ query: listDeletedUserHistories, variables: { limit: 100 } }]
    });

    const [removeEmployees_Previous] = useMutation(deleteDeletedUserHistory, {
        refetchQueries: [{ query: listDeletedUserHistories, variables: { limit: 100 } }]
    });
    const [updEmployees_Previous] = useMutation(updateDeletedUserHistory, {
        refetchQueries: [{ query: listDeletedUserHistories, variables: { limit: 100 } }]
    });


    useEffect(() => {
        const fetchAllEmployees_Previous = async () => {
            let AllEmployees_Previous = [];
            let currentNextToken = null;


            do {
                const { data } = await fetchMore({
                    variables: { limit: 100, nextToken: currentNextToken },
                });

                AllEmployees_Previous = [...AllEmployees_Previous, ...data.listDeletedUserHistories.items];
                currentNextToken = data.listDeletedUserHistories.nextToken;
            } while (currentNextToken);


            // Ordenar los empleados por 'first_name' alfabéticamente
            AllEmployees_Previous.sort((a, b) => a.first_name.localeCompare(b.first_name));
            setPrevious_employees(AllEmployees_Previous);
            setNextToken(currentNextToken);
        };

        if (data) {
            fetchAllEmployees_Previous();
        }
    }, [data, fetchMore]);
    // Muestra un indicador de carga mientras loading sea true


    const clearNewEmployess = () => {
        setNewEmployees_Previous({
            id: '',
            ThreeCX_id: '',
            ThreeCX_password: '',
            first_name: '',
            last_name: '',
            thc_email_id: '',
            thc_email_password: '',
            gmail_email_id: '',
            gmail_email_password: '',
            apple_id: '',
            apple_password: '',
            phone_number: '',
            navigator_id: '',
            team: '',
            cancellation_date: '',
            notes: '',
        });
    };

    const loadMore = () => {
        fetchMore({
            variables: { limit: 100, nextToken },
            updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;
                setNextToken(fetchMoreResult.listDeletedUserHistories.nextToken);
                return {
                    listDeletedUserHistories: {
                        ...fetchMoreResult.listDeletedUserHistories,
                        items: [...prevResult.listDeletedUserHistories.items, ...fetchMoreResult.listDeletedUserHistories.items]
                    }
                };
            }
        });
    };

    const filteredEmployees_Previous = useFilteredData(previous_employees || [], searchTerm, sortConfig);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let formattedValue = value;

        // Formatear valores específicos
        if (['first_name', 'last_name'].includes(name)) {
            formattedValue = value.toLowerCase();
        }

        if (name === 'phone_number') {
            formattedValue = value.replace(/[^\d]/g, ''); // Eliminar caracteres no numéricos
            if (formattedValue.length !== 10) {
                setPhoneError(
                    formattedValue.length > 10
                        ? 'Phone number cannot be longer than 10 digits.'
                        : 'Phone number must be exactly 10 digits.'
                );
            } else {
                setPhoneError('');
            }
        }

        // Actualizar el estado de newEmployees_Previous
        if (isEditing) {
            setSelectedPrevious_employees({
                ...selectedPrevious_employees,
                [name]: formattedValue
            });
        } else {
            setNewEmployees_Previous({
                ...newEmployees_Previous,
                id: uuidv4(), // Generar un nuevo id si es un nuevo dispositivo
                [name]: formattedValue
            });
        }
    };

    // Función para alternar la visibilidad de las contraseñas
    const togglePasswords = () => {
        setShowPasswords(prevState => !prevState);
    };

    const formatDate = (dateString) => {
        const options = { month: 'numeric', day: 'numeric', year: 'numeric' };
        const date = new Date(dateString);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

        return localDate.toLocaleDateString(undefined, options);
    };


    const handleAddEmployees_Previous = () => {

        // Formatear la fecha en el formato YYYY-MM-DD en la zona horaria US
        const formattedCancellation = newEmployees_Previous.cancellation_date
            ? new Date(newEmployees_Previous.cancellation_date).toISOString().split('T')[0]
            : null;

        if (!formattedCancellation) {
            console.log('Cancellation date validation failed: Empty or invalid date');
            setCancellationDateError('Cancellation date cannot be empty.');
            return;
        }

        // Check for duplicates based on `ThreeCX` and `first name`

        const isDuplicate = previous_employees.some(employee =>
            String(employee.ThreeCX_id) === String(newEmployees_Previous.ThreeCX_id) &&
            employee.first_name.trim().toLowerCase() === newEmployees_Previous.first_name.trim().toLowerCase()
        );
        if (isDuplicate) {

            setDuplicateError('An employee with the same ThreeCX ID and first name already exists.');
            console.log('Duplicate validation failed');
            return;
        } else {
            setDuplicateError('');
        }


        // Check that phone_number is exactly 10 digits and contains only numbers
        const phoneNumber = newEmployees_Previous.phone_number ? parseInt(newEmployees_Previous.phone_number.trim(), 10) : null;

        if (phoneNumber !== null && !/^\d{10}$/.test(String(phoneNumber))) {
            console.log(`Número de teléfono inválido: ${phoneNumber}`);
            setPhoneError('Phone number must be exactly 10 digits and contain only numbers.');
            return;
        } else {
            setPhoneError('');
        }

        // Check ThreeCX ID must not be empty and must be an integer
        if (!newEmployees_Previous.ThreeCX_id) {
            setThreeCX_idError('The ThreeCX ID cannot be empty.');
            console.log('ThreeCX ID validation failed: Empty value');
            return;
        }

        const isThreeCXIdInteger = Number.isInteger(Number(newEmployees_Previous.ThreeCX_id));

        if (!isThreeCXIdInteger) {
            setThreeCX_idError('The ThreeCX ID must be an integer.');
            console.log('ThreeCX ID validation failed: Not an integer');
            return;
        } else {
            setThreeCX_idError('');
        }

        // Check email
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        const isValidEmail = (email) => emailPattern.test(email);

        const validateEmails = (thc_email_id, gmail_email_id, apple_id) => {
            let isValid = true;

            // Validar THC Email ID solo si no es nulo o vacío
            if (thc_email_id && thc_email_id.trim() !== '') {
                if (!isValidEmail(thc_email_id)) {
                    setThcEmailIdError('THC Email ID is not a valid email address.');
                    console.log('THC Email ID validation failed');
                    isValid = false;
                } else {
                    setThcEmailIdError('');
                }
            } else {
                setThcEmailIdError('');  // No mostrar error si está vacío o es nulo
            }

            // Validar Gmail Email ID solo si no es nulo o vacío
            if (gmail_email_id && gmail_email_id.trim() !== '') {
                if (!isValidEmail(gmail_email_id)) {
                    setGmailEmailIdError('Gmail Email ID is not a valid email address.');
                    console.log('Gmail Email ID validation failed');
                    isValid = false;
                } else {
                    setGmailEmailIdError('');
                }
            } else {
                setGmailEmailIdError('');  // No mostrar error si está vacío o es nulo
            }

            // Validar Apple ID solo si no es nulo o vacío
            if (apple_id && apple_id.trim() !== '') {
                if (!isValidEmail(apple_id)) {
                    setAppleIdError('Apple ID is not a valid email address.');
                    console.log('Apple ID validation failed');
                    isValid = false;
                } else {
                    setAppleIdError('');
                }
            } else {
                setAppleIdError('');  // No mostrar error si está vacío o es nulo
            }

            return isValid;
        };


        // Obtener los datos y validar
        const cleanedGmailEmailId = newEmployees_Previous.gmail_email_id?.trim() || null
        const cleanedThcEmailId = newEmployees_Previous.thc_email_id?.trim() || null
        const cleanedAppleId = newEmployees_Previous.apple_id?.trim() || null

        if (!validateEmails(cleanedThcEmailId, cleanedGmailEmailId, cleanedAppleId)) {
            console.log('One or more email addresses are invalid.');
            return;
        }


        // If all validations pass, proceed with adding the previous_employees to the database
        addEmployees_Previous({
            variables: { input: { ...newEmployees_Previous, phone_number: phoneNumber, gmail_email_id: cleanedGmailEmailId, thc_email_id: cleanedThcEmailId, apple_id: cleanedAppleId, cancellation_date: formattedCancellation } }
        })
            .then(() => {
                clearNewEmployess();
                alert("The Employees Previous was an added success")
                setShowEmployees_PreviousAddOverlay(false);
            })

            .catch(error => {
                console.error("Error adding previous_employees:", error);
            });
    };


    const handleUpdateEmployees_Previous = async () => {

        // Formatear la fecha en el formato YYYY-MM-DD en la zona horaria US
        const formattedCancellation = selectedPrevious_employees.cancellation_date
            ? new Date(selectedPrevious_employees.cancellation_date).toISOString().split('T')[0]
            : null;

        if (!formattedCancellation) {
            console.log('Cancellation date validation failed: Empty or invalid date');
            setCancellationDateError('Cancellation date cannot be empty.');
            return;
        } else { setCancellationDateError(''); }

        // Check that phone_number is exactly 10 digits and contains only numbers
        let phoneNumber = selectedPrevious_employees.phone_number ? String(selectedPrevious_employees.phone_number).trim() : null;

        if (phoneNumber === null || phoneNumber === '') {
            // Si está vacío, envía null a GraphQL
            phoneNumber = null;
            setPhoneError('');
        } else if (!/^\d{10}$/.test(phoneNumber)) {
            // Si el número no tiene exactamente 10 dígitos, muestra un error
            console.log(phoneNumber);
            setPhoneError('Phone number must be exactly 10 digits and contain only numbers.');
            return;
        } else {
            // Si es válido, limpia el error
            setPhoneError('');
        }

        // Check ThreeCX ID must not be empty and must be an integer
        // Obtener el valor original
        const rawValue = selectedPrevious_employees.ThreeCX_id;

        // Tratar valores vacíos o nulos como `0` o `null` si tu backend lo permite
        const threeCXId = rawValue === "" || rawValue == null ? null : Number(rawValue);

        // Validar antes de enviar
        if (threeCXId !== null && !Number.isInteger(threeCXId)) {
            setThreeCX_idError('The ThreeCX ID must be an integer.');
            console.log('ThreeCX ID validation failed: Not an integer');
            return;
        }

        // Enviar `threeCXId` como entero o null al servidor
        setThreeCX_idError('');
        console.log('ThreeCX ID validated:', threeCXId);


        // Check email
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        const isValidEmail = (email) => emailPattern.test(email);

        const validateEmails = (thc_email_id, gmail_email_id, apple_id) => {
            let isValid = true;

            if (thc_email_id === 'N/A' || thc_email_id === null || thc_email_id === '') {
                // Si el valor es 'N/A', null o una cadena vacía, no hay error
                setThcEmailIdError('');
            } else if (!isValidEmail(thc_email_id)) {
                // Si no es un email válido, muestra el error
                setThcEmailIdError('THC Email ID is not a valid email address.');
                console.log('THC Email ID validation failed');
                isValid = false;
            } else {
                // Si es un email válido, limpia el error
                setThcEmailIdError('');
            }

            if (gmail_email_id === 'N/A' || gmail_email_id === null || gmail_email_id === '') {
                // Si el valor es 'N/A', null o una cadena vacía, no hay error
                setGmailEmailIdError('');
            } else if (!isValidEmail(gmail_email_id)) {
                // Si no es un email válido, muestra el error
                setGmailEmailIdError('Gmail Email ID is not a valid email address.');
                console.log('THC Gmail ID validation failed');
                isValid = false;
            } else {
                // Si es un email válido, limpia el error
                setGmailEmailIdError('');
            }

            if (apple_id && !isValidEmail(apple_id)) {
                setAppleIdError('Apple ID is not a valid email address.');
                console.log('Apple ID validation failed');
                isValid = false;
            } else {
                setAppleIdError('');
            }

            return isValid;
        };

        // Obtener los datos y validar
        const { thc_email_id, gmail_email_id, apple_id } = selectedPrevious_employees;
        const areEmailsValid = validateEmails(thc_email_id, gmail_email_id, apple_id);

        if (areEmailsValid) {
            // Continúa con el proceso de envío
            console.log('All email addresses are valid.');
        } else {
            // Maneja el error
            console.log('One or more email addresses are invalid.');
        }


        updEmployees_Previous({
            variables: {
                input: {
                    id: selectedPrevious_employees.id || '',
                    ThreeCX_id: threeCXId,
                    ThreeCX_password: selectedPrevious_employees.ThreeCX_password || '',
                    first_name: selectedPrevious_employees.first_name || '',
                    last_name: selectedPrevious_employees.last_name || '',
                    thc_email_id: selectedPrevious_employees.thc_email_id || null,
                    thc_email_password: selectedPrevious_employees.thc_email_password || '',
                    gmail_email_id: selectedPrevious_employees.gmail_email_id || null,
                    gmail_email_password: selectedPrevious_employees.gmail_email_password || '',
                    apple_id: selectedPrevious_employees.apple_id || null,
                    apple_password: selectedPrevious_employees.apple_password || '',
                    phone_number: phoneNumber || '',
                    navigator_id: selectedPrevious_employees.navigator_id || "",
                    team: selectedPrevious_employees.team || '',
                    cancellation_date: formattedCancellation || '',
                    notes: selectedPrevious_employees.notes || '',

                }
            }
        }
        ).then(() => {
            setIsEditing(true);
            setShowEmployees_PreviousAddOverlay(false);
            setThreeCX_idError('');
            setDuplicateError('');
            setThcEmailIdError('');
            setGmailEmailIdError('');
            setAppleIdError('');
            setPhoneError('');

        }).catch(error => {
            console.error("Error updating employees:", error);
        });
    };

    const handleDeleteEmployees_Previous = () => {
        removeEmployees_Previous({
            variables: { input: { id: selectedPrevious_employees.id } }
        }).then(() => {
            setShowEmployees_PreviousAddOverlay(false);
            setIsEditing(false)
        }).catch(error => {
            console.error("Error deleting previous_employees", error);
        });
    };


    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const handleEditPrevious_Employees = (previous_employees) => {
        setSelectedPrevious_employees(previous_employees);
        setIsEditing(true);
        setShowEmployees_PreviousAddOverlay(true);
    };

    return (
        <div className="previous_employees-page">
            <div className="previous_employees-header">
                <h1> Previous Employees</h1>
            </div>
            <Inventory_header />
            <div className="previous_employees-search">
                <button onClick={handleSaveAsExcel}>
                    <FaFileExcel /> Export
                </button>
                {/* Botón para mostrar u ocultar las contraseñas */}
                <button onClick={togglePasswords}>
                    {showPasswords ? 'Hide Passwords' : 'Show Passwords'}
                </button>
            </div>
            <div className="searchBar">
                <button className="add-previous_employees-btn" onClick={() => {
                    setSelectedPrevious_employees(null);
                    setIsEditing(false);
                    setShowEmployees_PreviousAddOverlay(true);
                    clearNewEmployess();
                }}>+ Add Previous Employee</button>
                <SearchBar searchTerm={searchTerm} onSearch={setSearchTerm} />
            </div>

            <div className="previous_employees-table-container">

                <table className="previous_employees-table">
                    <thead>
                        <tr>
                            <th>#</th> { }

                            <th onClick={() => requestSort('ThreeCX_id')}>
                                3CX ID
                                {sortConfig?.key === 'ThreeCX_id' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('ThreeCX_password')}>
                                3CX Password
                                {sortConfig?.key === 'ThreeCX_password' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('first_name')}>
                                First_name
                                {sortConfig?.key === 'first_name' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('last_name')}>
                                Last_name
                                {sortConfig?.key === 'last_name' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('thc_email_id')}>
                                Thc email ID
                                {sortConfig?.key === 'thc_email_id' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('thc_email_password')}>
                                Thc email Password
                                {sortConfig?.key === 'thc_email_password' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('gmail_email_id')}>
                                Gmail email Id
                                {sortConfig?.key === 'gmail_email_id' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('gmail_email_password')}>
                                Gmail email Password
                                {sortConfig?.key === 'gmail_email_password' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('apple_id')}>
                                Apple ID
                                {sortConfig?.key === 'apple_id' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('apple_password')}>
                                Apple Password
                                {sortConfig?.key === 'apple_password' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('phone_number')}>
                                Phone Number
                                {sortConfig?.key === 'phone_number' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('navigator_id')}>
                                Navigator ID
                                {sortConfig?.key === 'navigator_id' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('team')}>
                                Team
                                {sortConfig?.key === 'team' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('previous_employees-cancellation')}>
                                Cancellation Date
                                {sortConfig?.key === 'previous_employees-cancellation' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('notes')}>
                                Notes
                                {sortConfig?.key === 'notes' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {filteredEmployees_Previous.map((previous_employees, index) => (
                            <tr key={previous_employees.id} onClick={() => handleEditPrevious_Employees(previous_employees)}>
                                <td>{index + 1}</td> {/* Número de fila */}
                                <td className="previous_employees-ThreeCX_id">{previous_employees.ThreeCX_id}</td>
                                <td className="previous_employees-ThreeCX-password">{showPasswords ? String(previous_employees.ThreeCX_password) : '******'}</td>
                                <td className="previous_employees-first_name">{capitalize(previous_employees.first_name.toLowerCase())}</td>
                                <td className="previous_employees-last_name">{capitalize(previous_employees.last_name)}</td>
                                <td className="previous_employees-thc_email_id">{previous_employees.thc_email_id ? String(previous_employees.thc_email_id).toLowerCase() : 'N/A'}</td>
                                {/* Mostrar contraseña o asteriscos según showPasswords */}
                                <td className="previous_employees-thc_email_password">
                                    {showPasswords ? String(previous_employees.thc_email_password) : '******'}
                                </td>
                                <td className="previous_employees-gmail_email_id">{previous_employees.gmail_email_id ? String(previous_employees.gmail_email_id).toLowerCase() : 'N/A'}</td>
                                {/* Mostrar la contraseña de Gmail o puntos según showPasswords */}
                                <td className="previous_employees-gmail_email_password">
                                    {showPasswords ? String(previous_employees.gmail_email_password) : '******'}
                                </td>
                                <td className="previous_employees-apple_id">{previous_employees.apple_id ? String(previous_employees.apple_id).toLowerCase() : 'N/A'}</td>
                                <td className="previous_employees-apple_password">{showPasswords ? String(previous_employees.apple_password) : '******'}</td>
                                <td className="previous_employees-phone_number">{previous_employees.phone_number}</td>
                                <td className="previous_employees-navigator_id">{String(previous_employees.navigator_id)}</td>
                                <td className="previous_employees-team">{previous_employees.team}</td>
                                <td className="previous_employees-cancellation">{previous_employees.cancellation_date ? formatDate(previous_employees.cancellation_date) : 'No Date'} </td>
                                <td className="notes">{previous_employees.notes}</td>
                            </tr>

                        ))}
                    </tbody>
                </table>
            </div>


            {
                nextToken && !loading && (
                    <button onClick={loadMore}>Load More</button>
                )
            }

            {loading && <p>Loading...</p>}

            {showEmployees_PreviousAddOverlay && (

                <div className="employees_add_overlay" onClick={(e) => {
                    // Close overlay only if clicked outside the modal content
                    if (e.target.classList.contains("employees_add_overlay")) {
                        setShowEmployees_PreviousAddOverlay(false);
                        clearNewEmployess();
                        setSelectedPrevious_employees(null);
                        setIsEditing(false);
                    }
                }}>
                    <div className="overlay-content-previous_employees">
                        <div className="header-row">
                            <h2 className="header-title">{isEditing ? 'Edit Previous Employee' : 'Add Previous Employee'}</h2>
                            <button onClick={() => {
                                setShowEmployees_PreviousAddOverlay(false);
                                clearNewEmployess();
                                setSelectedPrevious_employees(null);
                                setIsEditing(false);

                            }}>X</button>
                        </div>
                        <label>3CX ID</label>
                        <input name="ThreeCX_id" placeholder="3CX ID" value={isEditing ? selectedPrevious_employees.ThreeCX_id : newEmployees_Previous.ThreeCX_id} onChange={handleInputChange} />

                        <label>3CX Password</label>
                        <input
                            type={showPasswords ? "text" : "password"} // Alternar entre "text" y "password"
                            name="ThreeCX_password"
                            placeholder="3CX Password"
                            value={isEditing ? selectedPrevious_employees.ThreeCX_password : newEmployees_Previous.ThreeCX_password}
                            onChange={handleInputChange}
                            required
                        />
                        <label>First name </label>
                        <input name="first_name" placeholder="First name" value={isEditing ? selectedPrevious_employees.first_name : newEmployees_Previous.first_name} onChange={handleInputChange} required />
                        <label>Last name</label>
                        <input name="last_name" placeholder="Last Name" value={isEditing ? selectedPrevious_employees.last_name : newEmployees_Previous.last_name} onChange={handleInputChange} required />
                        <label>THC Email ID</label>
                        <input name="thc_email_id" placeholder="THC Email ID" value={isEditing ? selectedPrevious_employees.thc_email_id : newEmployees_Previous.thc_email_id} onChange={handleInputChange} required />
                        <label>THC Email Password</label>
                        <input type={showPasswords ? "text" : "password"} name="thc_email_password" placeholder="THC Email Password" value={isEditing ? selectedPrevious_employees.thc_email_password : newEmployees_Previous.thc_email_password} onChange={handleInputChange} required />
                        <label>Gmail Email ID</label><input name="gmail_email_id" placeholder="Gmail Email ID" value={isEditing ? selectedPrevious_employees.gmail_email_id : newEmployees_Previous.gmail_email_id} onChange={handleInputChange} required />
                        <label>Gmail Email Password</label>
                        <input type={showPasswords ? "text" : "password"} name="gmail_email_password" placeholder="Gmail Email Password" value={isEditing ? selectedPrevious_employees.gmail_email_password : newEmployees_Previous.gmail_email_password} onChange={handleInputChange} required />
                        <label>Apple ID</label><input name="apple_id" placeholder="Apple ID" value={isEditing ? selectedPrevious_employees.apple_id : newEmployees_Previous.apple_id} onChange={handleInputChange} required />
                        <label>Apple Password</label><input type={showPasswords ? "text" : "password"} name="apple_password" placeholder="Apple Password" value={isEditing ? selectedPrevious_employees.apple_password : newEmployees_Previous.apple_password} onChange={handleInputChange} required />
                        <label>Phone Number</label><input name="phone_number" placeholder="phone_number" value={isEditing ? selectedPrevious_employees.phone_number : newEmployees_Previous.phone_number} onChange={handleInputChange} required />
                        <label>Navigator ID</label><input name="navigator_id" placeholder="navigator_id" value={isEditing ? selectedPrevious_employees.navigator_id : newEmployees_Previous.navigator_id} onChange={handleInputChange} />
                        <label>Team</label><input name="team" placeholder="team" value={isEditing ? selectedPrevious_employees.team : newEmployees_Previous.team} onChange={handleInputChange} />
                        <label htmlFor="cancellation_date">Cancellation Date:</label>
                        <input
                            type="date"
                            id="cancellation_date"
                            name="cancellation_date"
                            value={isEditing ? selectedPrevious_employees.cancellation_date : newEmployees_Previous.cancellation_date}
                            onChange={handleInputChange} />
                        <input name="notes" placeholder="notes" value={isEditing ? selectedPrevious_employees.notes : newEmployees_Previous.notes} onChange={handleInputChange} />

                        {duplicateError && <p className="error">{duplicateError}</p>} {/* Display duplicate error message */}
                        {phoneError && <p className="error">{phoneError}</p>} {/* Display price error message */}
                        {threeCX_idError && <p className="error">{threeCX_idError}</p>} {/* Display  error message */}
                        {thcEmailIdError && <p className="error">{thcEmailIdError}</p>} {/* Display  error message */}
                        {gmailEmailIdError && <p className="error">{gmailEmailIdError}</p>} {/* Display  error message */}
                        {appleIdError && <p className="error">{appleIdError}</p>} {/* Display  error message */}
                        {CancellationDateError && <p className="error">{CancellationDateError}</p>} {/* Display  error message */}


                        <div className="button-group">
                            <button className="overlay-add-previous_employees" onClick={isEditing ? handleUpdateEmployees_Previous : handleAddEmployees_Previous}>
                                {isEditing ? 'Update Previous Employee' : 'Add Previous Employee'}
                            </button>
                            {isEditing && (
                                <button
                                    className="overlay-delete-previous_employees"
                                    onClick={() => {
                                        if (window.confirm('Are you sure you want to delete this employee?')) {
                                            handleDeleteEmployees_Previous();
                                        }
                                    }}
                                >
                                    Delete Previous Employee
                                </button>
                            )}

                            <button className="overlay-add-previous_employees" onClick={togglePasswords}>
                                {showPasswords ? 'Hide Passwords' : 'Show Passwords'}
                            </button>
                        </div>

                    </div>
                </div>
            )
            }

        </div >

    );
};

export default Previous_employees;
