import React, { useState, useEffect, useRef } from 'react';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { FixedSizeGrid as Grid } from "react-window";
import moment from 'moment';
import * as XLSX from 'xlsx';

import LoadingSpiner from '../../components/layout/LoadingSpinner';
import ClientForm from '../../components/ClientForm';
import DuplicatesModal from './components/DuplicatesModal'; // ADDED FOR DUPLICATES
import { listClientFix2s, listUsersByTeamOne, clientsByNavigator } from '../../graphql/queries';
import { CREATE_CLIENTFIX2, UPDATE_CLIENTFIX2, DELETE_CLIENTFIX2 } from '../../graphql/mutations';
import { capitalize } from '../../components/common/capitalize';
import './Clients.css';

const ClientsTable = () => {
  // --------------------------------------
  // State and Refs
  // --------------------------------------
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [nextToken, setNextToken] = useState(null);
  const [showClientAddOverlay, setShowClientAddOverlay] = useState(false);
  const [fullName, setFullName] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [sortConfig, setSortConfig] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchField, setSearchField] = useState('name');
  const [isLoading, setIsLoading] = useState(false);
  const [userGroups, setUserGroups] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const headerRef = useRef(null);

  // For adding/editing new clients
  const [newClient, setNewClient] = useState({
    id: '',
    name: '',
    last_name: '',
    email: '',
    address: '',
    city: '',
    county: '',
    zip_code: '',
    prefered_lang: '',
    insurance_picked: '',
    last_contacted_date: '',
    navigator: '',
    payment_per_month: '',
    phone: '',
    plan: ''
  });

  // For checkboxes
  const [selectedClientIds, setSelectedClientIds] = useState([]);

  // ADDED FOR DUPLICATES
  const [duplicateGroups, setDuplicateGroups] = useState([]); // Will store grouped duplicates
  const [showDuplicatesModal, setShowDuplicatesModal] = useState(false);

  // Grid settings
  const headers = [
    "Select",
    "ID",
    "Name",
    "Last Name",
    "Phone",
    "Email",
    "Preferred Language",
    "Address",
    "City",
    "County",
    "Insurance",
    "Plan",
    "Last Contacted Date",
    "Navigator",
    "Payment Per Month"
  ];
  const columnWidth = 180;
  const rowHeight = 30;
  const columnCount = 15;
  const [gridWidth, setGridWidth] = useState(window.innerWidth * 0.97);
  const ALLOWED_USER_GROUPS = ['Admin', 'Manager'];
  const userHasAccess = () => userGroups.some(group => ALLOWED_USER_GROUPS.includes(group));

  // Queries and Mutations
  useQuery(listUsersByTeamOne); // As requested, not removed
  const [fetchAllClients] = useLazyQuery(listClientFix2s, {
    variables: { limit: 200 },
    fetchPolicy: "network-only",
  });

  const { loading, data, fetchMore, refetch } = useQuery(
    userHasAccess() ? listClientFix2s : clientsByNavigator,
    {
      variables: userHasAccess()
        ? { limit: 1000 }
        : { navigator: fullName || "Unknown Navigator!", limit: 1000 },
      skip: !userDetails && !userHasAccess(),
    }
  );

  const [deleteClient] = useMutation(DELETE_CLIENTFIX2, {
    refetchQueries: [{ query: listClientFix2s, variables: { limit: 100 } }]
  });

  // --------------------------------------
  // Derived values
  // --------------------------------------
  const sortedClients = React.useMemo(() => {
    let sortableClients = [...clients];
    if (sortConfig !== null) {
      sortableClients.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableClients;
  }, [clients, sortConfig]);

  const filteredClients = sortedClients.filter(client => {
    const fieldValue = client[searchField] !== null && client[searchField] !== undefined
      ? client[searchField].toString().toLowerCase()
      : '';

    const searchValue = searchQuery ? searchQuery.toString().toLowerCase() : '';
    return fieldValue.includes(searchValue);
  });

  const dataRowCount = filteredClients.length;
  const [gridHeight, setGridHeight] = useState(350); // Default height

  // --------------------------------------
  // Effects
  // --------------------------------------
  useEffect(() => {
    fetchUserDetails();
  }, []);

  useEffect(() => {
    if (userDetails) {
      let fullNameVar = `${userDetails.firstName} ${userDetails.lastName}`;
      if (fullNameVar === "Darwish Ahmadi") {
        fullNameVar = "Mohammad darwish Ahmadi";
      } else if (fullNameVar === "Mariae Andrade") {
        fullNameVar = "Maria elizabeth  Andrade";
      } else if (fullNameVar === "Anakaren Cavazos") {
        fullNameVar = "Ana karen  Cavazos";
      }
      setFullName(fullNameVar);
    }
  }, [userDetails]);

  useEffect(() => {
    if (data) {
      const fetchedClients = userHasAccess() ? data.listClientFix2s.items : data.clientsByNavigator.items;
      setClients(fetchedClients);
    }
  }, [data, userGroups]);

  useEffect(() => {
    const fetchAllClientsData = async () => {
      let allClients = [];
      let currentNextToken = null;
      do {
        const response = await fetchMore({
          variables: userGroups.includes('Admin')
            ? { limit: 1000, nextToken: currentNextToken }
            : { navigator: fullName, limit: 1000, nextToken: currentNextToken },
        });

        const fetchedData = userGroups.includes('Admin')
          ? response?.data?.listClientFix2s
          : response?.data?.clientsByNavigator;

        if (fetchedData && fetchedData.items) {
          allClients = [...allClients, ...fetchedData.items];
          currentNextToken = fetchedData.nextToken;
        } else {
          console.warn("Unexpected response structure from fetchMore:", response);
          break;
        }
      } while (currentNextToken);

      setClients(allClients);
      setNextToken(currentNextToken);
    };

    if (data) {
      fetchAllClientsData();
    }
  }, [data, fetchMore, userGroups, fullName]);

  useEffect(() => {
    const calculateHeight = () => {
      const headerHeight = document.querySelector('.clients-header')?.offsetHeight || 50;
      const footerHeight = 20; // Adjust if you have additional margins or footers
      const availableHeight = window.innerHeight - headerHeight - footerHeight; // Remaining space
      const visibleRows = Math.min(dataRowCount, Math.floor(availableHeight / rowHeight));
      setGridHeight(visibleRows * rowHeight);
    };

    window.addEventListener('resize', calculateHeight);
    calculateHeight(); // Initial calculation
    return () => window.removeEventListener('resize', calculateHeight);
  }, [dataRowCount, rowHeight]);

  useEffect(() => {
    const handleResize = () => {
      setGridWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // --------------------------------------
  // Functions
  // --------------------------------------
  const fetchUserDetails = async () => {
    try {
      const { signInDetails } = await getCurrentUser();
      const session = await fetchAuthSession();
      const groups = session.tokens.idToken.payload['cognito:groups'] || [];
      setUserGroups(groups);

      const [firstName, lastNameWithDomain] = signInDetails.loginId.split('.');
      const lastName = lastNameWithDomain.split('@')[0];

      setUserDetails({
        firstName: capitalize(firstName),
        lastName: capitalize(lastName),
        userId: signInDetails.loginId
      });
    } catch (error) {
      console.log('User not authenticated', error);
    }
  };

  const handleEditClient = (client) => {
    setSelectedClient(client);
    setIsEditing(true);
    setShowClientAddOverlay(true);
  };

  const handleDeleteClient = () => {
    setIsLoading(true);
    deleteClient({
      variables: {
        input: { id: selectedClient.id }
      },
      update: (cache) => {
        const existingClients = cache.readQuery({ query: listClientFix2s, variables: { limit: 1000 } });
        const updatedClients = existingClients.listClientFix2s.items.filter(
          client => client.id !== selectedClient.id
        );

        cache.writeQuery({
          query: listClientFix2s,
          variables: { limit: 1000 },
          data: {
            listClientFix2s: {
              ...existingClients.listClientFix2s,
              items: updatedClients
            }
          }
        });
      }
    }).then(() => {
      setSelectedClient(null);
      setShowClientAddOverlay(false);
    }).catch(error => {
      console.error("Error deleting client:", error);
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleNavigateToOptOutClients = () => {
    navigate('/opt-out-clients');
  };

  const clearNewClient = () => {
    setNewClient({
      id: '',
      name: '',
      last_name: '',
      email: '',
      address: '',
      city: '',
      county: '',
      zip_code: '',
      prefered_lang: '',
      insurance_picked: '',
      last_contacted_date: '',
      navigator: '',
      payment_per_month: '',
      phone: '',
      plan: ''
    });
  };

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };

  const downloadExcel = async () => {
    if (!userHasAccess()) {
      console.error("Access denied: You do not have permission to download data.");
      return;
    }

    setIsDownloading(true);

    try {
      let allClients = [];
      let currentNextToken = null;

      do {
        const response = await fetchAllClients({
          variables: { limit: 1000, nextToken: currentNextToken },
          fetchPolicy: "network-only",
        });

        const fetchedClients = response.data.listClientFix2s.items;
        allClients = [...allClients, ...fetchedClients];
        currentNextToken = response.data.listClientFix2s.nextToken;
      } while (currentNextToken);

      if (allClients.length === 0) {
        alert("No clients available to download.");
        setIsDownloading(false);
        return;
      }

      const formattedClients = allClients.map(client => ({
        ID: client.id,
        Name: capitalize(client.name),
        "Last Name": capitalize(client.last_name),
        Email: client.email.toLowerCase(),
        Address: client.address,
        City: client.city,
        County: client.county,
        zip_code: client.zip_code,
        "Preferred Language": Array.isArray(client.prefered_lang) ? client.prefered_lang.join(', ') : client.prefered_lang,
        "Insurance Picked": client.insurance_picked,
        Plan: client.plan,
        "Last Contacted Date": client.last_contacted_date,
        Navigator: client.navigator,
        "Payment Per Month": client.payment_per_month,
        Phone: formatPhoneNumber(client.phone)
      }));

      const worksheet = XLSX.utils.json_to_sheet(formattedClients);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Clients");
      XLSX.writeFile(workbook, "clients.xlsx");
    } catch (error) {
      console.error("Error fetching all clients:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  // Checkbox selection
  const handleCheckboxChange = (clientId) => {
    setSelectedClientIds((prevSelected) => {
      if (prevSelected.includes(clientId)) {
        return prevSelected.filter(id => id !== clientId);
      } else {
        return [...prevSelected, clientId];
      }
    });
  };

  // --------------------------------------
  // Duplicates Logic
  // --------------------------------------
  const handleFindDuplicates = () => {
    // We’ll group clients by phone number (cleaned), for example:
    const mapByPhone = new Map();

    filteredClients.forEach((client) => {
      const phoneKey = (client.phone || '').replace(/\D/g, '');
      if (!phoneKey) return;

      if (!mapByPhone.has(phoneKey)) {
        mapByPhone.set(phoneKey, []);
      }
      mapByPhone.get(phoneKey).push(client);
    });

    // Now, create an array of groups, but only include those with duplicates
    const groups = [];
    for (const [phoneNumber, clientsArr] of mapByPhone.entries()) {
      if (clientsArr.length > 1) {
        groups.push({
          groupKey: phoneNumber,
          items: clientsArr,
        });
      }
    }

    setDuplicateGroups(groups);
    setShowDuplicatesModal(true); // open the modal
  };

  // Example: Merge duplicates logic
  // This is a simple example that prints which clients user wants to merge.
  // You might call a backend mutation or handle actual data merging differently.
  const handleMergeDuplicates = (groupKey, chosenClient, otherClients) => {
    console.log("Merging groupKey:", groupKey);
    console.log("Chosen master client:", chosenClient);
    console.log("Other duplicates:", otherClients);

    // Example: You could call an AWS AppSync mutation here to update
    // the chosenClient with combined data, and then delete the others.

    // For now, just closing the modal after logging.
    setShowDuplicatesModal(false);
    setDuplicateGroups([]);
  };

  // --------------------------------------
  // Cell Renderers
  // --------------------------------------
  const HeaderCell = ({ columnIndex, style }) => (
    <div
      style={{
        ...style,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#23395d',
        color: 'white',
        fontWeight: 'bold',
        borderBottom: '1px solid #ddd',
        borderRight: '1px solid #ddd',
      }}
    >
      {headers[columnIndex]}
    </div>
  );

  const Cell = ({ columnIndex, rowIndex, style }) => {
    const client = filteredClients[rowIndex];
    const clientFields = [
      client.id,
      capitalize(client.name),
      capitalize(client.last_name),
      formatPhoneNumber(client.phone),
      client.email ? client.email.toLowerCase() : "N/A",
      Array.isArray(client.prefered_lang) ? client.prefered_lang.join(", ") : client.prefered_lang || "N/A",
      client.address,
      client.city,
      client.county,
      client.insurance_picked,
      client.plan,
      moment(client.last_contacted_date).format("MM-DD-YYYY"),
      client.navigator,
      `$${client.payment_per_month !== null
        ? Number(client.payment_per_month).toLocaleString("en-US", { minimumFractionDigits: 1, maximumFractionDigits: 1 })
        : "0.0"}`
    ];

    const handleRowClick = () => {
      setSelectedRow(client.id);
      handleEditClient(client);
    };

    if (columnIndex === 0) {
      return (
        <div
          style={{
            ...style,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderBottom: "1px solid #ddd",
            borderRight: "1px solid #ddd",
            backgroundColor:
              selectedRow === client.id
                ? "#3f4f69"
                : rowIndex % 2 === 0
                  ? "#f9f9f9"
                  : "#e0e0e0",
          }}
        >
          <input
            type="checkbox"
            checked={selectedClientIds.includes(client.id)}
            onChange={() => handleCheckboxChange(client.id)}
          />
        </div>
      );
    }

    const fieldData = clientFields[columnIndex - 1];

    return (
      <div
        style={{
          ...style,
          width: columnWidth,
          height: rowHeight,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderBottom: "1px solid #ddd",
          borderRight: "1px solid #ddd",
          backgroundColor:
            selectedRow === client.id
              ? "#3f4f69"
              : rowIndex % 2 === 0
                ? "#f9f9f9"
                : "#e0e0e0",
          color: selectedRow === client.id ? "white" : "black",
          cursor: "pointer",
        }}
        onClick={handleRowClick}
        className="grid-cell"
      >
        {fieldData}
      </div>
    );
  };

  const DataCell = Cell;

  // --------------------------------------
  // Render
  // --------------------------------------
  return (
    <div className="clients">
      <header className="clients-header">
        <h1>Clients</h1>
      </header>

      <div className="clients-search">
        <input
          type="text"
          placeholder="Client Name, Email, Address"
          value={searchQuery}
          onChange={handleSearch}
        />
        <select onChange={(e) => setSearchField(e.target.value)}>
          <option value="name">Name</option>
          <option value="last_name">Last Name</option>
          <option value="email">Email</option>
          <option value="address">Address</option>
          <option value="insurance_picked">Insurance Picked</option>
          <option value="last_contacted_date">Last Contacted Date</option>
          <option value="navigator">Navigator</option>
          <option value="payment_per_month">Payment Per Month</option>
          <option value="phone">Phone</option>
          <option value="plan">Plan</option>
        </select>
      </div>

      <button
        className="add-user-btn"
        onClick={() => {
          clearNewClient();
          setSelectedClient(null);
          setIsEditing(false);
          setShowClientAddOverlay(true);
        }}
      >
        + Add User
      </button>

      {userGroups?.includes('Admin') && (
        <button className="download-excel-btn" onClick={downloadExcel} disabled={isDownloading}>
          {isDownloading ? <LoadingSpiner /> : "Download Clients Excel"}
        </button>
      )}

      <button
        className="navigate-opt-out-btn"
        onClick={handleNavigateToOptOutClients}
      >
        View Opt-Out Clients
      </button>

      {/* ADDED: Detect Duplicates */}
      <button
        className="find-duplicates-btn"
        style={{ marginLeft: '10px' }}
        onClick={handleFindDuplicates}
      >
        Detect Duplicates
      </button>

      <div
        className='grid-header'
        style={{ position: 'sticky', top: 0, zIndex: 10, overflowX: 'auto' }}
        ref={headerRef}
      >
        <Grid
          columnCount={columnCount}
          columnWidth={columnWidth}
          height={rowHeight}
          rowCount={1}
          rowHeight={rowHeight}
          width={Math.max(gridWidth, columnWidth * columnCount)}
          className='header2'
        >
          {HeaderCell}
        </Grid>
      </div>

      <Grid
        columnCount={columnCount}
        columnWidth={columnWidth}
        height={gridHeight}
        rowCount={dataRowCount}
        rowHeight={rowHeight}
        width={gridWidth}
        className='grid-clients'
        onScroll={({ scrollLeft }) => {
          if (headerRef.current) {
            headerRef.current.scrollLeft = scrollLeft;
          }
        }}
      >
        {DataCell}
      </Grid>

      <div className="item-count">
        <p>Total Clients: {filteredClients.length}</p>
      </div>

      {showClientAddOverlay && (
        <div
          className="client_add_overlay"
          onClick={(e) => {
            if (e.target.classList.contains("client_add_overlay")) {
              setShowClientAddOverlay(false);
              setSelectedClient(null);
              setIsEditing(false);
            }
          }}
        >
          <div
            className="overlay-content-clients"
            onClick={(e) => e.stopPropagation()}
          >
            <ClientForm
              isEditing={isEditing}
              selectedClient={selectedClient}
              onClientAdded={() => {
                setShowClientAddOverlay(false);
                setSelectedClient(null);
                setIsEditing(false);
              }}
              onClose={() => {
                setShowClientAddOverlay(false);
                setSelectedClient(null);
                setIsEditing(false);
              }}
              userGroups={userGroups}
              handleDeleteClient={handleDeleteClient}
              refetch={refetch}
            />
          </div>
        </div>
      )}

      {/* ADDED: Duplicates Modal */}
      {showDuplicatesModal && (
        <DuplicatesModal
          duplicateGroups={duplicateGroups}
          onClose={() => {
            setShowDuplicatesModal(false);
            refetch(); // Refresh the clients list
          }}
          onMerge={handleMergeDuplicates}
        />
      )}
    </div>
  );
};

export default ClientsTable;
