import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';
import {
  FaBars, FaHome, FaCalendarAlt, FaUserFriends, FaClipboardList,
  FaRegCalendarCheck, FaTasks, FaCar, FaCompass, FaCalendarTimes,
  FaUserMinus, FaUserLock, FaWarehouse, FaTicketAlt,
  FaBullhorn,
  FaPhone
} from 'react-icons/fa';
import { HiDocumentReport } from "react-icons/hi";

import { Amplify } from 'aws-amplify';
import { getCurrentUser, fetchAuthSession, signOut } from 'aws-amplify/auth';

import logo from '../../assets/images/hc_login_logo.png'; // Import the logo image
import awsExports from '../../aws-exports';

Amplify.configure(awsExports);

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [userGroups, setUserGroups] = useState([]);
  const [userDetails, setUserDetails] = useState(null);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const fetchUserDetails = async () => {
    try {
      const { signInDetails } = await getCurrentUser();
      const session = await fetchAuthSession();
      const groups = session.tokens.idToken.payload['cognito:groups'] || [];
      setUserGroups(groups);

      const [firstName, lastNameWithDomain] = signInDetails.loginId.split('.');
      const lastName = lastNameWithDomain.split('@')[0]; // Remove domain part
      setUserDetails({ firstName, lastName, userId: signInDetails.loginId }); // Set user details in state
    } catch (error) {
      console.log('User not authenticated', error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'signOut') {
        signOut();
        window.location.reload(); // Reload to reflect the logout
      }
    };
  
    window.addEventListener('storage', handleStorageChange);
  
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);
  

  const handleLogout = async () => {
    try {
      await signOut(); // Sign out the user
      localStorage.setItem('signOut', Date.now()); // Broadcast the log-out event to other tabs
      window.location.reload(); // Refresh the page to reflect the log-out
    } catch (error) {
      console.error('Error during sign out:', error);
    }
  };

  return (
    <div>
      <div className={`overlay ${isOpen ? 'active' : ''}`} onClick={toggleSidebar}></div>
      <div className="sidebar-icon" onClick={toggleSidebar}>
        <FaBars size={30} />
      </div>
      <nav className={`sidebar ${isOpen ? 'open' : ''}`}>
        <div className="sidebar-header">
          <img src={logo} alt="Logo" className="sidebar-logo" />
          <button className="close-btn" onClick={toggleSidebar}>X</button>
        </div>
        <ul className="sidebar-menu">
          <li><Link to="/dashboard" onClick={toggleSidebar}><FaHome /> Dashboard</Link></li>
          <li><Link to="/appointments" onClick={toggleSidebar}><FaRegCalendarCheck /> Appointments</Link></li>
          <li><Link to="/calendar" onClick={toggleSidebar}><FaCalendarAlt /> Calendar</Link></li>
          <li><Link to="/work-schedules" onClick={toggleSidebar}><FaCalendarTimes /> Work Schedules</Link></li>
          <li><Link to="/issue-ticket" onClick={toggleSidebar}><FaTicketAlt /> Issue Ticket</Link></li>
          <li><Link to="/clients" onClick={toggleSidebar}><FaUserFriends /> Clients</Link></li>
          <li><Link to="/call-list" onClick={toggleSidebar}><FaPhone /> Call List</Link></li>
          {/* <li><Link to="/events" onClick={toggleSidebar}><FaTasks /> Events</Link></li> */}
          <li><Link to="/mobile-outreach" onClick={toggleSidebar}><FaCar /> Mobile Outreach</Link></li>
          {/* <li><Link to="/activities" onClick={toggleSidebar}><FaCompass /> Activities</Link></li> */}
          <li><Link to="/reports" onClick={toggleSidebar}><FaClipboardList /> Reports</Link></li>

          {/* Conditionally render these links based on user groups */}
          {userGroups.includes('Admin') && (
            <>
              <li><Link to="/weekly-reports" onClick={toggleSidebar}><HiDocumentReport /> Weekly Reports</Link></li>
              <li><Link to="/inventory-home" onClick={toggleSidebar}><FaWarehouse /> Inventory</Link></li>
              <li><Link to="/promo-reports" onClick={toggleSidebar}><FaBullhorn /> Promotional Report</Link></li>
            </>
          )}
          {userGroups.includes('Manager') && (
            <>
              <li><Link to="/weekly-reports" onClick={toggleSidebar}><HiDocumentReport /> Weekly Reports</Link></li>
            </>
          )}

          <li><Link to="#" onClick={handleLogout}><FaUserLock /> Log Out</Link></li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;