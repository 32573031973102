import React, { useState, useEffect } from "react";
import './OverlayEdit.css';

const OverlayEdit = ({ isOpen, onClose, item, onSave }) => {
    // Estado local para el item editable
    const [editableItem, setEditableItem] = useState(item || {});

    // Sincroniza el estado interno cuando el item cambia
    useEffect(() => {
        if (item) {
            setEditableItem(item);
        }
    }, [item]);

    // Maneja los cambios en los inputs
    const handleChange = (field, value) => {
        setEditableItem((prev) => {
            // Verificar si existe `tagId` o `tagid` en el objeto
            if (field === "tagId" || field === "tagid") {
                const keyToUpdate = prev.hasOwnProperty("tagId") ? "tagId" : "tagid";
                return {
                    ...prev,
                    [keyToUpdate]: value,
                };
            }

            // Para otros campos, actualizar normalmente
            return {
                ...prev,
                [field]: value,
            };
        });
    };


    if (!isOpen) return null; // Solo renderiza si `isOpen` es true

    const handleSave = (e) => {
        e.preventDefault();
        console.log("Datos finales a guardar:", editableItem);
        onSave(editableItem); // Llama a la función para guardar
        onClose(); // Cierra el overlay
    };


    const friendlyNames = {
        ExtensionCord: "Extension Cord",
        BackupBaterry: "Backup Battery",
        DockingStation:"Docking Station",
        Speaker: "Speaker",
        USB: "USB Drive",
        TabletRemarkable: "Tablet",
        ExternalDisk: "External Disk",
        Bag: "Bag",
        clickCounter: "Click Counter",
        Supplies: "Supplies",
        GeneralInventory: "General Equipment",
        Headset: "Headset",
        ubs:"USB",
        PowerBank: "Power Bank",
        Monitor: "Monitor",
        Keyboard:"Keyboard",
        Mouse:"Mouse",
        Laptop:"Laptop",
        Phone:"Phone",
        Scanner:"Scanner",
        Printer:"Printer",
      };
 

    return (
        <div
            className="edit-overlayEdit"
            onClick={(e) => {
                // Cierra el overlay si se hace clic fuera del contenido
                if (e.target.classList.contains("overlayEdit-content")) {
                    onClose();
                }
            }}
        >
            <div className="overlayEdit-content">
            <h2>Edit {friendlyNames[editableItem?.__typename] || "Item"}</h2>

                <form
                    onSubmit={handleSave}
                >
                    {((editableItem?.tagid !== undefined && editableItem.tagid !== "") ||
                        (editableItem?.tagId !== undefined && editableItem.tagId !== "")) && (
                            <>
                                <label>Tag ID:
                                    <input
                                        type="text"
                                        value={editableItem.tagid || editableItem?.tagId || null}
                                        onChange={(e) => handleChange("tagId", e.target.value)}
                                    />
                                </label>
                            </>
                        )}
                    {editableItem?.serial_Number !== undefined && editableItem.serial_Number !== " " && (
                        <>
                            <label>
                                Serial Number:
                                <input
                                    type="text"
                                    value={editableItem?.serial_Number || ""}
                                    onChange={(e) => handleChange("serial_Number", e.target.value)}
                                />
                            </label> </>
                    )}
                    {editableItem?.password !== undefined && editableItem.password !== " " && (
                        <>
                            <label>Passcode:</label>
                            <input
                                type="text"
                                name="password"
                                value={editableItem?.password || ""}
                                onChange={(e) => handleChange("password", e.target.value)}
                            />
                        </>
                    )}
                    {editableItem?.status !== undefined && editableItem.status !== " " && (
                        <>
                            <label>Status:</label>
                            <select
                                name="status"
                                value={editableItem?.status || ""}
                                onChange={(e) => handleChange("status", e.target.value)}
                            >
                                <option value="" disabled hidden>
                                    Select Status
                                </option>
                                <option value="use">Use</option>
                                <option value="broken">Broken</option>
                                <option value="transfer">Transfer</option>
                                <option value="lost">Lost</option>
                                <option value="inv">Inventario</option>
                            </select>
                        </>
                    )}

                    {editableItem?.charger_cable !== undefined && editableItem.charger_cable !== "N/A" && (
                        <>
                            <label>Charger Cable:</label>
                            <select
                                name="charger_cable"
                                value={editableItem.charger_cable}
                                onChange={(e) => handleChange("charger_cable", e.target.value)}
                            >
                                <option value="" disabled hidden>
                                    Select
                                </option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        </>
                    )}
                    {editableItem?.Connector_cable !== undefined && editableItem.Connector_cable !== "N/A" && (
                        <>
                            <label>Connector Cable:</label>
                            <select
                                name="Connector_cable"
                                value={editableItem.Connector_cable || ""}
                                onChange={(e) => handleChange("Connector_cable", e.target.value)}
                            >
                                <option value="" disabled hidden>
                                    Select
                                </option>
                                <option value="HDMI">HDMI</option>
                                <option value="C-C">C-C</option>
                                <option value="USB-C">USB-C</option>
                                <option value="USB-A to USB-B">USB-A to USB-B</option>
                                <option value="Other">Other</option>
                                <option value="no">Cable not provided</option>
                            </select>
                        </>
                    )}

                    <label htmlFor="assignment_date">Assignment Date:</label>
                    <input
                        type="date"
                        id="assignment_date"
                        name="assignment_date"
                        value={editableItem?.assignment_date || ""}
                        onChange={(e) => handleChange("assignment_date", e.target.value)}
                    />
                    <div className="button-group">
                        <button type="submit">Save</button>
                        <button type="button" onClick={onClose}>
                            Close
                        </button>
                    </div>
                </form>
            </div>
        </div >
    );
};

export default OverlayEdit;
