/* eslint-disable */
// this is an auto generated file. This will be overwritten
import { gql } from '@apollo/client';

export const listEvents = gql`
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        EventID
        title
        start
        end
        attendees
        location
        description
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;


export const GET_CLIENTFIX2 = gql`
  query GetClientFix2($id: ID!) {
    getClientFix2(id: $id) {
      id
      name
      last_name
      address
      city
      county
      zip_code
      prefered_lang
      email
      insurance_picked
      plan
      payment_per_month
      phone
      navigator
      callStatus
    }
  }
`;

export const listClientFix2s = gql`
  query listClientFix2s(
    $filter: ModelClientFix2FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientFix2s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        last_name
        email
        address
        city
        county
        zip_code
        prefered_lang
        insurance_picked
        last_contacted_date
        navigator
        payment_per_month
        phone
        plan
        callStatus
      }
      nextToken
      __typename
    }
  }
`;

export const clientsByNavigator = gql`
  query clientsByNavigator(
    $navigator: String!
    $limit: Int
    $nextToken: String
  ) {
    clientsByNavigator(navigator: $navigator, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        last_name
        email
        address
        city
        county
        zip_code
        prefered_lang
        insurance_picked
        last_contacted_date
        navigator
        payment_per_month
        phone
        plan
        callStatus
      }
      nextToken
      __typename
    }
  }
`;

export const clientsByName = gql`
  query clientsByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelClientFix2FilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientsByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        last_name
        email
        address
        city
        county
        zip_code
        prefered_lang
        insurance_picked
        last_contacted_date
        navigator
        payment_per_month
        phone
        plan
        callStatus
        createdAt
        updatedAt
      }
      nextToken
      __typename
    }
  }
`;


export const clientsByNavigatorNotCalled = gql`
  query ClientsByNavigatorNotCalled($navigator: String!, $status: String!) {
    clientsByNavigator(navigator: $navigator, callStatus: $status) {
      items {
        id
        name
        last_name
        phone
        navigator
        callStatus
      }
    }
  }
`;


export const listAppointments = gql`
  query ListAppointments(
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppointments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        customer
        clientEmail
        location
        date
        recurrence
        service
        navigator
        time
        duration
        notes
        ModifiedBy
        status
        reminder24Sent
        reminder30Sent
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listReports = gql`
  query ListReports($limit: Int, $nextToken: String) {
    listReports(limit: $limit, nextToken: $nextToken) {
      items {
      id
      Timestamp
      Username
      Date
      Navigator
      outreachEvent
      year_contract
      flyers
      Number_FollowUp_Attempt_Contact_NoResponse
      MACtouches
      name
      lastName
      phone
      address
      city
      county
      email
      prefered_lang
      otherLanguage
      commitCard
      commitcardNo
      signature
      bestTimeToCall
      CommitCardNotes
      no_show
      ConsumerAuthOnFile
      employerCoverage
      cost_to_employee
      familyCovered
      amountFamCoverage
      NumPeopleHousehold
      NumPeopleApplying
      NumAdults
      NumChildren
      EmployeeTool
      IncomeType
      IncomeAmount
      PayFrequency
      coverageAppliedFor
      OtherCoverage
      New_Re_Enrollment
      clientEnroll
      QHP_Amount
      plans
      ClientCompleteMMC
      AppealQuestion
      RescheduleQuestion
      reschedule_reason
      otherRescheduleChange
      one2oneInquiry
      HealthInsuranceLiteracy
      Locating_Providers
      Billing_Payment_Questions
      EvaluateHealtcareOptionsUsingTools
      AccessingPreventativeHealthServices
      HealtcareGovAcc
      createdAccounts
      troubleshootedAccounts
      MarketplaceEligibility
      MarketPlanCompare
      qhp_enrollment_hcgov
      elctronic_qhp_enrollment_marketplaceCallCenter
      written_qhp_enrollment_hcgov
      medicaid_chip_app_ref
      complex_case_help_center
      data_matchingIssues
      data_matchingIssues_other
      SEP_eligibility
      SEP_eligibility_other
      Employer_sponsored_coverage_issues
      Employer_sponsored_coverage_issues_other
      APTC_CSR_assistance
      APTC_CSR_assistance_other
      Complex_Cases_Other
      Answering_questions_about
      Answering_questions_about_other
      Marketplace_tax_forms
      Filing_Marketplace
      Submitting_Marketplace
      Assisted_with_other_consumer_information
      pertinent_info_tracking
      hear_from_us
      event_hear_abt_us
      facebook_hear_abt_us
      insta_hear_abt_us
      SAC_hear_abt_us
      twitter_hear_abt_us
      tv_hear_abt_us
      hospital_hear_abt_us
      library_opt
      workforce_hear_abt_us
      tablepresentation_hear_abt_us
      referral_hear_abt_us
      newspaper_hear_abt_us
      radio_hear_abt_us
      flyer_hear_abt_us
      county2
      other_county
      zip_code
      finalNotes
      plan_ClientName_0
      plan_ClientLastName_0
      Insurer_0
      PlanMetal_0
      PriceofPlan_0
      APCT_Subsidy_0
      DentalConfirm0
      Dentalplan_ClientName_0
      Dentalplan_ClientLastName_0
      Dentalplan_Name_0
      Dentalplan_Cost_0
      plan_ClientName_1
      plan_ClientLastName_1
      Insurer_1
      PlanMetal_1
      PriceofPlan_1
      APCT_Subsidy_1
      DentalConfirm1
      Dentalplan_ClientName_1
      Dentalplan_ClientLastName_1
      Dentalplan_Name_1
      Dentalplan_Cost_1
      plan_ClientName_2
      plan_ClientLastName_2
      Insurer_2
      PlanMetal_2
      PriceofPlan_2
      APCT_Subsidy_2
      DentalConfirm2
      Dentalplan_ClientName_2
      Dentalplan_ClientLastName_2
      Dentalplan_Name_2
      Dentalplan_Cost_2
      plan_ClientName_3
      plan_ClientLastName_3
      Insurer_3
      PlanMetal_3
      PriceofPlan_3
      APCT_Subsidy_3
      DentalConfirm3
      Dentalplan_ClientName_3
      Dentalplan_ClientLastName_3
      Dentalplan_Name_3
      Dentalplan_Cost_3
      plan_ClientName_4
      plan_ClientLastName_4
      Insurer_4
      PlanMetal_4
      PriceofPlan_4
      APCT_Subsidy_4
      DentalConfirm4
      Dentalplan_ClientName_4
      Dentalplan_ClientLastName_4
      Dentalplan_Name_4
      Dentalplan_Cost_4
      plan_ClientName_5
      plan_ClientLastName_5
      Insurer_5
      PlanMetal_5
      PriceofPlan_5
      APCT_Subsidy_5
      DentalConfirm5
      Dentalplan_ClientName_5
      Dentalplan_ClientLastName_5
      Dentalplan_Name_5
      Dentalplan_Cost_5
      plan_ClientName_6
      plan_ClientLastName_6
      Insurer_6
      PlanMetal_6
      PriceofPlan_6
      APCT_Subsidy_6
      DentalConfirm6
      Dentalplan_ClientName_6
      Dentalplan_ClientLastName_6
      Dentalplan_Name_6
      Dentalplan_Cost_6
      plan_ClientName_7
      plan_ClientLastName_7
      Insurer_7
      PlanMetal_7
      PriceofPlan_7
      APCT_Subsidy_7
      DentalConfirm7
      Dentalplan_ClientName_7
      Dentalplan_ClientLastName_7
      Dentalplan_Name_7
      Dentalplan_Cost_7
      plan_ClientName_8
      plan_ClientLastName_8
      Insurer_8
      PlanMetal_8
      PriceofPlan_8
      APCT_Subsidy_8
      DentalConfirm8
      Dentalplan_ClientName_8
      Dentalplan_ClientLastName_8
      Dentalplan_Name_8
      Dentalplan_Cost_8
      plan_ClientName_9
      plan_ClientLastName_9
      Insurer_9
      PlanMetal_9
      PriceofPlan_9
      APCT_Subsidy_9
      DentalConfirm9
      Dentalplan_ClientName_9
      Dentalplan_ClientLastName_9
      Dentalplan_Name_9
      Dentalplan_Cost_9
      plan_ClientName_10
      plan_ClientLastName_10
      Insurer_10
      PlanMetal_10
      PriceofPlan_10
      APCT_Subsidy_10
      DentalConfirm10
      Dentalplan_ClientName_10
      Dentalplan_ClientLastName_10
      Dentalplan_Name_10
      Dentalplan_Cost_10
      plan_ClientName_11
      plan_ClientLastName_11
      Insurer_11
      PlanMetal_11
      PriceofPlan_11
      APCT_Subsidy_11
      DentalConfirm11
      Dentalplan_ClientName_11
      Dentalplan_ClientLastName_11
      Dentalplan_Name_11
      Dentalplan_Cost_11
      plan_ClientName_12
      plan_ClientLastName_12
      Insurer_12
      PlanMetal_12
      PriceofPlan_12
      APCT_Subsidy_12
      DentalConfirm12
      Dentalplan_ClientName_12
      Dentalplan_ClientLastName_12
      Dentalplan_Name_12
      Dentalplan_Cost_12
      plan_ClientName_13
      plan_ClientLastName_13
      Insurer_13
      PlanMetal_13
      PriceofPlan_13
      APCT_Subsidy_13
      DentalConfirm13
      Dentalplan_ClientName_13
      Dentalplan_ClientLastName_13
      Dentalplan_Name_13
      Dentalplan_Cost_13
      plan_ClientName_14
      plan_ClientLastName_14
      Insurer_14
      PlanMetal_14
      PriceofPlan_14
      APCT_Subsidy_14
      DentalConfirm14
      Dentalplan_ClientName_14
      Dentalplan_ClientLastName_14
      Dentalplan_Name_14
      Dentalplan_Cost_14
      }
      nextToken
    }
  }
`;

export const DashboardListReports = gql`
  query ListReports($limit: Int, $nextToken: String) {
    listReports(limit: $limit, nextToken: $nextToken) {
      items {
        id
        Date
        Navigator
        clientEnroll
        one2oneInquiry
        HealthInsuranceLiteracy
        Locating_Providers
        Billing_Payment_Questions
        EvaluateHealtcareOptionsUsingTools
        AccessingPreventativeHealthServices
        MarketplaceEligibility
        MarketPlanCompare
        qhp_enrollment_hcgov
        elctronic_qhp_enrollment_marketplaceCallCenter
        written_qhp_enrollment_hcgov
        medicaid_chip_app_ref
        complex_case_help_center
        data_matchingIssues
        SEP_eligibility
        Employer_sponsored_coverage_issues
        APTC_CSR_assistance
        Complex_Cases_Other
        Marketplace_tax_forms
        Filing_Marketplace
        Submitting_Marketplace
        Username
        NumAdults
        NumChildren
        county
        outreachEvent
      }
      nextToken
    }
  }
`;


export const GET_REPORTS_BY_DATE = gql`
  query GetReportsByDate($startDate: String!, $endDate: String!, $nextToken: String) {
  listReports(
    filter: {
      Date: {
        between: [$startDate, $endDate]
      }
    }
    limit: 1000
    nextToken: $nextToken
  ) {
      items {
        Navigator
        Date
        one2oneInquiry
        HealthInsuranceLiteracy
        Locating_Providers
        Billing_Payment_Questions
        EvaluateHealtcareOptionsUsingTools
        AccessingPreventativeHealthServices
        HealtcareGovAcc
        MarketplaceEligibility
        MarketPlanCompare
        qhp_enrollment_hcgov
        elctronic_qhp_enrollment_marketplaceCallCenter
        written_qhp_enrollment_hcgov
        medicaid_chip_app_ref
        complex_case_help_center
        data_matchingIssues
        SEP_eligibility
        Employer_sponsored_coverage_issues
        APTC_CSR_assistance
        Complex_Cases_Other
        Answering_questions_about
        Marketplace_tax_forms
        Filing_Marketplace
        Submitting_Marketplace
        Assisted_with_other_consumer_information
      }
        nextToken
    }
  }
`;
export const GET_REPORTS_BY_SPECIFIC_DATE = gql`
  query GetReportsBySpecificDate($date: String!, $nextToken: String) {
    listReports(
      filter: {
        Date: {
          eq: $date
        }
      }
      limit: 1000
      nextToken: $nextToken
    ) {
      items  {
        Navigator
        Date
        one2oneInquiry
        HealthInsuranceLiteracy
        Locating_Providers
        Billing_Payment_Questions
        EvaluateHealtcareOptionsUsingTools
        AccessingPreventativeHealthServices
        HealtcareGovAcc
        MarketplaceEligibility
        MarketPlanCompare
        qhp_enrollment_hcgov
        elctronic_qhp_enrollment_marketplaceCallCenter
        written_qhp_enrollment_hcgov
        medicaid_chip_app_ref
        complex_case_help_center
        data_matchingIssues
        SEP_eligibility
        Employer_sponsored_coverage_issues
        APTC_CSR_assistance
        Complex_Cases_Other
        Answering_questions_about
        Marketplace_tax_forms
        Filing_Marketplace
        Submitting_Marketplace
        Assisted_with_other_consumer_information
      }
      nextToken
    }
  }
`;

export const listMice = gql`
  query listMice(
    $filter: ModelMouseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMice(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
          id
          tagId
          model
          brand
          serial_Number
          wireless
          usb
          combo
          price
          store
          purchase_date
          assignment_date
          warranty_date
          returnable_date
          available
         status
         assigned_to
         location
         team
      }
      nextToken
      __typename
    }
  }
`;

export const GET_MOUSE_BY_DATE_RANGE = gql`
query GetMiceByDateRange($filter: ModelMouseFilterInput, $nextToken: String) {
  listMice(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
      id
      tagId
      model
      brand
      serial_Number
      wireless
      usb
      combo
      price
      store
      purchase_date
      assignment_date
      warranty_date
      returnable_date
      available
      status
      assigned_to
      location
      team
    }
   nextToken
  
  }
}

`;


export const listUsers = gql`
  query listUsers (
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
       id
       ThreeCX_id
       ThreeCX_password
       first_name
       last_name
       thc_email_id
       thc_email_password
       gmail_email_id
       gmail_email_password
       apple_id
       apple_password
       phone_number
       navigator_id
       createdAt
       updatedAt
       team
       location
       notes
      }
      nextToken
      __typename
    }
  }
`;

export const listUsersByTeamOne = gql`
  query listUsersByTeamOne(
    $limit: Int
    $nextToken: String
  ) {
    listUsers(
      filter: { team: { eq: "1" } } # Filter to get users with team == 1
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ThreeCX_id
        first_name
        last_name
        thc_email_id
        gmail_email_id
        apple_id
        phone_number
        navigator_id
        createdAt
        updatedAt
        team
        location
      }
      nextToken
      __typename
    }
  }
`;


export const listKeyboards = gql`
  query listKeyboards (
    $filter: ModelKeyboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKeyboards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
      tagid
      model 
      brand
      serial_Number
      wireless
      usb
      combo
      price
      store
      purchase_date
      assignment_date
      warranty_date
      returnable_date
      available
      status
      assigned_to
      location
      team
      }
      nextToken
      __typename
    }
  }
`;


export const GET_KEYBOARDS_BY_DATE_RANGE = gql`
 query GetKeyboardByDateRange($filter: ModelKeyboardFilterInput, $nextToken: String) {
  listKeyboards(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
         id
        tagid
        model 
        brand
        serial_Number
        wireless
        usb
        combo
        price
        store
        purchase_date
        assignment_date
        warranty_date
        returnable_date
        available
        status
        assigned_to
        location
        team
    }
    nextToken
   }
  }
`;

export const listMonitors = gql`
  query listMonitors (
    $filter: ModelMonitorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMonitors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
       id
       tagid
       model
       brand
       inches
       serial_Number
       price
       store
       purchase_date
       charger_cable
       monitor_type
       Connector_cable
       assignment_date
       warranty_date
       returnable_date
       available
       status
       assigned_to
       location
       team
      }
      nextToken
      __typename
    }
  }
`;


export const GET_MONITORS_BY_DATE_RANGE = gql`
 query GetKeyboardByDateRange($filter: ModelMonitorFilterInput, $nextToken: String) {
 listMonitors(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
       id
       tagid
       model
       brand
       inches
       serial_Number
       price
       store
       purchase_date
       charger_cable
       monitor_type
       Connector_cable
       assignment_date
       warranty_date
       returnable_date
       available
       status
       assigned_to
       location
       team
    }
    nextToken
   }
  }
`;
export const listScanners = gql`
  query listScanners (
    $filter: ModelScannerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScanners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
  id
  tagid
  model
  model_number
  brand
  serial_Number
  price
  store
  purchase_date
  charger_cable
  scanner_type
  Connector_cable
  wireless
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
      }
      nextToken
      __typename
    }
  }
`;
export const GET_SCANNER_BY_DATE_RANGE = gql`
query GetScannerByDateRange($filter: ModelScannerFilterInput, $nextToken: String) {
  listScanners(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
    id
  tagid
  model
  model_number
  brand
  serial_Number
  price
  store
  purchase_date
  charger_cable
  scanner_type
  Connector_cable
  wireless
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
    }
   nextToken
  
  }
}

`;

export const listPrinters = gql`
  query listPrinters (
    $filter: ModelPrinterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrinters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
       tagid
       model
      brand
      serial_Number
      price
      store
      purchase_date
      charger_cable
      Connector_cable
      wireless
      black_ink
     color_ink
     black_refill_dates
     color_refill_dates
      assignment_date
      warranty_date
       returnable_date
      available
      status
      assigned_to
      location
      team
      
      }
      nextToken
      __typename
    }
  }
`;


export const GET_PRINTER_BY_DATE_RANGE = gql`
query GetPrinterByDateRange($filter: ModelPrinterFilterInput, $nextToken: String) {
  listPrinters(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
            id
       tagid
       model
      brand
      serial_Number
      price
      store
      purchase_date
      charger_cable
      Connector_cable
      wireless
      black_ink
      color_ink
      black_refill_dates
      color_refill_dates
      assignment_date
      warranty_date
       returnable_date
      available
      status
      assigned_to
      location
      team

    }
   nextToken
  
  }
}

`;
export const listExtensionCords = gql`
  query listExtensionCords (
    $filter: ModelExtensionCordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExtensionCords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
     id
    tagid
    model
    brand
    serial_Number
    price
    store
    purchase_date
    assignment_date
    warranty_date
    returnable_date
    available
    status
    assigned_to
    location
    team 
      }
      nextToken
      __typename
    }
  }
`;
export const GET_EXTENSION_BY_DATE_RANGE = gql`
query GetExtensionByDateRange($filter: ModelExtensionCordFilterInput, $nextToken: String) {
  listExtensionCords(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
    id
    tagid
    model
    brand
    serial_Number
    price
    store
    purchase_date
    assignment_date
    warranty_date
    returnable_date
    available
    status
    assigned_to
    location
    team
    }
   nextToken
  
  }
}

`;
export const listPhones = gql`
  query listPhones (
    $filter: ModelPhoneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhones(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
   id
   tagid
   model
   brand
   serial_Number
   price
   store
   purchase_date
  cel_number
  password
  charger_cable
  EID
  imei
  ICCID
  pin
  apple_id
  apple_password
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  Description
  location
  team
      }
      nextToken
      __typename
    }
  }
`;
export const GET_PHONE_BY_DATE_RANGE = gql`
query GetPhoneByDateRange($filter: ModelPhoneFilterInput, $nextToken: String) {
  listPhones(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
    id
   tagid
   model
   brand
   serial_Number
   price
   store
   purchase_date
  cel_number
  password
  charger_cable
  EID
  imei
  ICCID
  pin
  apple_id
  apple_password
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  Description
  location
  team
  
    }
   nextToken
  
  }
}

`;
export const listBackupBatteries = gql`
  query listBackupBatteries (
    $filter: ModelBackupBatteryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBackupBatteries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team

      }
      nextToken
      __typename
    }
  }
`;
export const GET_BACKUP_BY_DATE_RANGE = gql`
query GetBackupByDateRange($filter: ModelBackupBatteryFilterInput, $nextToken: String) {
  listBackupBatteries(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
      id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
    }
   nextToken
  
  }
}

`;

export const listPowerBanks = gql`
  query listPowerBanks (
    $filter: ModelPowerBankFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPowerBanks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team

      }
      nextToken
      __typename
    }
  }
`;

export const GET_POWERBANK_BY_DATE_RANGE = gql`
query GetPowerBankByDateRange($filter: ModelPowerBankFilterInput, $nextToken: String) {
  listPowerBanks(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
    id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
    }
   nextToken
  
  }
}

`;
export const listGeneralInventories = gql`
  query listGeneralInventories (
    $filter: ModelGeneralInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGeneralInventories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
  id
  Item_Description
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  notes
  location
  team

      }
      nextToken
      __typename
    }
  }
`;
export const GET_GeneralInventory__BY_DATE_RANGE = gql`
query GetGeneralInventorByDateRange($filter: ModelGeneralInventoryFilterInput, $nextToken: String) {
  listGeneralInventories(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
  Item_Description
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  notes
  location
  team
    }
   nextToken
  
  }
}

`;
export const listUSBS = gql`
  query listUSBS (
    $filter: ModelUSBFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUSBS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  storage
  descripcion
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
     
      }
      nextToken
      __typename
    }
  }
`;

export const GET_USB_BY_DATE_RANGE = gql`
query GetUSBByDateRange($filter: ModelUSBFilterInput, $nextToken: String) {
  listUSBS(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  storage
  descripcion
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
    }
   nextToken
  
  }
}

`;
export const listDockingStations = gql`
  query listDockingStations (
    $filter: ModelDockingStationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDockingStations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  mac_compatible
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
   team
      }
      nextToken
      __typename
    }
  }
`;

export const GET_DOCKINGSTATION_BY_DATE_RANGE = gql`
query GetDockingStationByDateRange($filter: ModelDockingStationFilterInput, $nextToken: String) {
  listDockingStations(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  mac_compatible
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
   team
    }
   nextToken
  
  }
}

`;

export const listSpeakers = gql`
  query listSpeakers (
    $filter: ModelSpeakerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpeakers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
   team
 
      }
      nextToken
      __typename
    }
  }
`;

export const GET_SPEAKERS_BY_DATE_RANGE = gql`
query GetSpeakerByDateRange($filter: ModelSpeakerFilterInput, $nextToken: String) {
  listSpeakers(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
   team
    }
   nextToken
  
  }
}

`;

export const listLaptops = gql`
  query listLaptops (
    $filter: ModelLaptopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLaptops(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
      id
  tagid
  model
  brand
  serial_Number
  price
  store
  charger_cable
  password
  norton_antivirus
  os
  ram
  storage
  device_id
  product_id
  bitloker
  microsoft_account
  microsoft_acount_password
  apple_id
  apple_password
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  Description
  location
  team
  admin_password

      }
      nextToken
      __typename
    }
  }
`;

export const GET_LAPTOP_BY_DATE_RANGE = gql`
query GetLaptopByDateRange($filter: ModelLaptopFilterInput, $nextToken: String) {
  listLaptops(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
     id
  tagid
  model
  brand
  serial_Number
  price
  store
  charger_cable
  password
  norton_antivirus
  os
  ram
  storage
  device_id
  product_id
  bitloker
  microsoft_account
  microsoft_acount_password
  apple_id
  apple_password
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  Description
  location
  team
  admin_password
    }
   nextToken
  
  }
}

`;

export const listTabletRemarkables = gql`
  query listTabletRemarkables (
    $filter: ModelTabletRemarkableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTabletRemarkables(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  charger_cable
  password
  os
  ram
  storage
  apple_id 
  apple_password
  phone_associate
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  
      }
      nextToken
      __typename
    }
  }
`;

export const GET_TABLET_BY_DATE_RANGE = gql`
query GetTabletRemarkablesByDateRange($filter: ModelTabletRemarkableFilterInput, $nextToken: String) {
  listTabletRemarkables(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
   id
  tagid
  model
  brand
  serial_Number
  price
  store
  charger_cable
  password
  os
  ram
  storage
  apple_id 
  apple_password
  phone_associate
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
    }
   nextToken
  
  }
}

`;
export const listHeadsets = gql`
  query listHeadsets (
    $filter: ModelHeadsetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHeadsets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
  id
  tagid
  brand
  serial_Number
  charger_cable
  microphone
  aux_cable
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team

      }
      nextToken
      __typename
    }
  }
`;

export const GET_HEADSET_BY_DATE_RANGE = gql`
query GetHeadsetByDateRange($filter: ModelHeadsetFilterInput, $nextToken: String) {
  listHeadsets(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
  id
  tagid
  brand
  serial_Number
  charger_cable
  microphone
  aux_cable
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
    }
   nextToken
  
  }
}

`;
export const listProjectors = gql`
  query listProjectors (
    $filter: ModelProjectorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
  id
  tagId
  brand
  serial_Number
  charger_cable
  output_cable
  price
  store
  purchase_date
  warranty_date
  returnable_date
  available
  status
  location
  
      }
      nextToken
      __typename
    }
  }
`;
export const GET_PROJECTOR_BY_DATE_RANGE = gql`
query GetProjectorByDateRange($filter: ModelProjectorFilterInput, $nextToken: String) {
  listProjectors(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
  id
  tagId
  brand
  serial_Number
  charger_cable
  output_cable
  price
  store
  purchase_date
  warranty_date
  returnable_date
  available
  status
  location
    }
   nextToken
  
  }
}

`;
export const listExternalDisks = gql`
  query listExternalDisks (
    $filter: ModelExternalDiskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExternalDisks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
  tagid
  model
  brand
  serial_Number
  storage
  descripcion
  charger_cable
  output_cable
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
      }
      nextToken
      __typename
    }
  }
`;
export const GET_EXTERNALDISK_BY_DATE_RANGE = gql`
query GetExternalDiskByDateRange($filter: ModelExternalDiskFilterInput, $nextToken: String) {
  listExternalDisks(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
        id
  tagid
  model
  brand
  serial_Number
  storage
  descripcion
  charger_cable
  output_cable
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
    }
   nextToken
  
  }
}

`;
export const listBags = gql`
  query listBags (
    $filter: ModelBagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
  id
  brand
  type
  price
  store
  purchase_date
  assignment_date
  returnable_date
  available
  status
  assigned_to
  location
  team
      }
      nextToken
      __typename
    }
  }
`;
export const GET_BAG_BY_DATE_RANGE = gql`
query GeTBagByDateRange($filter: ModelBagFilterInput, $nextToken: String) {
  listBags(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
  id
  brand
  type
  price
  store
  purchase_date
  assignment_date
  returnable_date
  available
  status
  assigned_to
  location
  team
    }
   nextToken
  
  }
}

`;
export const listClickCounters = gql`
  query listClickCounters (
    $filter: ModelClickCounterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClickCounters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
  id
  price
  store
  purchase_date
  available
  assigned_to
      }
      nextToken
      __typename
    }
  }
`;

export const GET_CLICKCOUNTER_BY_DATE_RANGE = gql`
query GetClickCounterByDateRange($filter: ModelClickCounterFilterInput, $nextToken: String) {
  listClickCounters(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
  id
  price
  store
  purchase_date
  available
  assigned_to
    }
   nextToken
  
  }
}

`;
export const listSupplies = gql`
  query listSupplies (
    $filter: ModelSuppliesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSupplies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
  id
  name
  tagId
  price
  store
  purchase_date
  assignment_date
  available
  assigned_to
  location
  team
      }
      nextToken
      __typename
    }
  }
`;
export const GET_SUPPLIES_BY_DATE_RANGE = gql`
query GetSuppliesByDateRange($filter: ModelSuppliesFilterInput, $nextToken: String) {
  listSupplies(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
  id
  name
  tagId
  price
  store
  purchase_date
  assignment_date
  available
  assigned_to
  location
  team
    }
   nextToken
  
  }
}

`;

export const LIST_COUNTIES = gql`
  query ListCounties {
    listCounties {
      items {
        id
        name
        cities {
          items {
            id
            name
            locations {
              items {
                id
                name
                address
                navigators {
                  items {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;


export const GET_ALL_LOCATIONS = gql`
  query GetAllLocations {
    listLocations {
      items {
        id
        name
        address
        cityID
        city {
          name
        }
        outreachDate
        pointsOfContact {
          name
          phone
          email
        }
        operationHours {
          day
          startTime
          endTime
        }
      }
    }
  }
`;


export const GET_CITY_BY_NAME = gql`
  query GetCityByName($name: String!) {
    listCities(filter: { name: { eq: $name } }) {
      items {
        id
        name
      }
    }
  }
`;

export const listDeletedUserHistories = gql`
  query listDeletedUserHistories (
    $filter: ModelDeletedUserHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeletedUserHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
         id
       ThreeCX_id
       ThreeCX_password
       first_name
       last_name
       thc_email_id
       thc_email_password
       gmail_email_id
       gmail_email_password
       apple_id
       apple_password
       phone_number
       navigator_id
       team
       cancellation_date
       notes
      }
      nextToken
      __typename
    }
  }
`;
export const listDeleteEquipmentHistories = gql`
  query listDeleteEquipmentHistories(
    $filter: ModelDeleteEquipmentHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeleteEquipmentHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
  id
  Item
  tagId
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  available
  previous_employees
  location
  notes
      }
      nextToken
      __typename
    }
  }
`;

export const GET_DeleteEquipmentHistories__BY_DATE_RANGE = gql`
query GetDeleteEquipmentHistoriesByDateRange($filter: ModelDeleteEquipmentHistoryFilterInput, $nextToken: String) {
  listDeleteEquipmentHistories(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
   id
  Item
  tagId
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  available
  previous_employees
  location
  notes
    }
   nextToken
  
  }
}

`;
export const GET_PROMOTIONAL_REPORTS_BY_DATE = gql`
query GetPromotionalReportsByDate($startDate: String!, $endDate: String!, $nextToken: String) {
  listPromotionalReports(
    filter: {
      activityDateTime: {
        between: [$startDate, $endDate]
      }
    }
    limit: 1000
    nextToken: $nextToken
  ) {
    items {
      id
      activityName
      activityDateTime
      numberOfConsumersTargeted
      mediaType
      activityDescription
      UploadedFileName
      createdAt
    }
    nextToken
  }
}
`
export const GET_ALL_UNIQUE_FILENAMES = gql`
  query GetAllUniqueFileNames {
  listPromotionalReports(limit: 1000) {
    items {
        UploadedFileName
      }
      nextToken
    }
  }
`;

export const CHECK_FILE_EXISTENCE_BY_NAME = gql`
  query CheckFileExistenceByName($UploadedFileName: String!) {
    listPromotionalReports(filter: { UploadedFileName: { eq: $UploadedFileName } }) {
      items {
        id
        UploadedFileName
      }
    }
  }
`;

export const getCallList = gql`
  query GetCallList($id: ID!) {
    getCallList(id: $id) {
      id
      clientID
      navigator
      callDate
      answered
      appointmentMade
      needsFollowUp
      notes
      contactRound
      createdAt
      updatedAt
    }
  }
`;

// Admin query: fetch all call list items
export const listAllCallLists = gql`
  query ListAllCallLists($limit: Int, $nextToken: String) {
    listCallLists(limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientID
        client {
          name
          last_name
          phone
          email
          prefered_lang
          county
          zip_code
          navigator
        }
        navigator
        callDate
        answered
        appointmentMade
        needsFollowUp
        notes
        contactRound
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

// Navigator query: fetch call list items for a specific navigator
export const listCallListsByNavigator = gql`
  query callsByNavigator(
  $navigator: String!
  $limit: Int
  $nextToken: String
) {
  callsByNavigator(navigator: $navigator, limit: $limit, nextToken: $nextToken) {
    items {
      id
      clientID
      client {
        id
        name
        last_name
        phone
        email
        prefered_lang
        county
        zip_code
        navigator
      }
      navigator
      callDate
      answered
      appointmentMade
      needsFollowUp
      notes
      contactRound
      followUpScheduledDate
      lastInteractionID
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;


export const getWorkSchedulesByUser = gql`
  query workSchedulesByUser($userId: ID!,$startDate: String!, $endDate: String!) {
    workSchedulesByUser(userId: $userId, filter: { date: { between: [$startDate, $endDate] } }) {
      items {
        id
        userId
        date
        startTime
        endTime
        breakStart
        breakEnd
        location
        createdAt
        updatedAt
      }
    }
  }
`;

export const getWorkSchedule = gql`
  query GetWorkSchedule($id: ID!) {
    getWorkSchedule(id: $id) {
      id
      userId
      user {
        id
        first_name
        last_name
      }
      date
      startTime
      endTime
      breakStart
      breakEnd
      location
      createdAt
      updatedAt
    }
  }
`;

export const listScheduleTemplates = gql`
  query ListScheduleTemplates {
    listScheduleTemplates {
      items {
        id
        name
        days {
          day
          shift
        }
        totalHours
        createdAt
        updatedAt
      }
    }
  }
`;

export const listOptOutClients = gql`
  query ListOptOutClients($filter: ModelOptOutClientFilterInput, $limit: Int, $nextToken: String) {
    listOptOutClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        last_name
        address
        city
        county
        zip_code
        prefered_lang
        email
        insurance_picked
        plan
        payment_per_month
        phone
        optOutReason
        optOutDate
        navigator
        callStatus
      }
      nextToken
    }
  }
`;

export const listInteractionsForClient = gql`
  query ListInteractionsForClient($clientID: ID!) {
    callsByClient(clientID: $clientID) {
      items {
        id
        callDate
        navigator
        interactions {
          items {
            id
            callDate
            answered
            appointmentMade
            notes
            contactMethod
            followUpScheduledDate
            createdAt
          }
        }
      }
    }
  }
`;

export const listOverContactedClients = gql`
  query ListOverContactedClients {
    listClientFix2(filter: { lastContactedDate: { lt: "2024-01-01" } }) {
      items {
        id
        firstName
        lastName
        lastContactedDate
      }
    }
  }
`;

export const countClientInteractions = gql`
  query CountClientInteractions($callListID: ID!) {
    interactionsByCallList(callListID: $callListID) {
      items {
        id
      }
    }
  }
`;

export const listFollowUpCalls = gql`
  query ListFollowUpCalls {
    listCallLists(filter: { needsFollowUp: { eq: true } }) {
      items {
        id
        clientID
        callDate
        followUpScheduledDate
        navigator
        notes
        createdAt
      }
    }
  }
`;

export const GET_INTERACTIONS_BY_CALL_LIST = gql`
  query GetInteractionsByCallList($callListID: ID!) {
    interactionsByCallList(callListID: $callListID) {
      items {
        id
        callDate
        time
        answered
        appointmentMade
        notes
        contactMethod
        createdBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const listTickets = gql`
  query listTickets (
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTickets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
 id
  type
  name
  location
  time
  date
  dateTimeSupervisor
  dateTimeAdmin
  supervisor_name
  admin_name
  type_issue
  program_name
  equipment_name
  equipment_tag
  status
  completed_date
  request_item
  quantity
  tried_troubleshooting
  priority
  supervisor_approval
  troubleshooting_problem1
  troubleshooting_problem2
  admin_troubleshooting_problem
  troubleshooting_steps_taken1
  troubleshooting_steps_taken2
  admin_troubleshooting_steps_taken
  troubleshooting_resolution1
  troubleshooting_resolution2
  admin_troubleshooting_resolution
  date_needed
  createdAt
  purchase_link
 
      }
      nextToken
      __typename
    }
  }
`;
export const ticketsByEmployee = gql`
  query ticketsByEmployee(
    $name: String!
    $limit: Int
    $nextToken: String
  ) {
    ticketsByEmployee(name: $name, limit: $limit, nextToken: $nextToken) {
      items {
  id
  type
  name
  location
  time
  date
  dateTimeSupervisor
  dateTimeAdmin
  supervisor_name
  admin_name
  type_issue
  program_name
  equipment_name
  equipment_tag
  status
  completed_date
  request_item
  quantity
  tried_troubleshooting
  priority
  supervisor_approval
  troubleshooting_problem1
  troubleshooting_problem2
  admin_troubleshooting_problem
  troubleshooting_steps_taken1
  troubleshooting_steps_taken2
  admin_troubleshooting_steps_taken
  troubleshooting_resolution1
  troubleshooting_resolution2
  admin_troubleshooting_resolution
  date_needed
  purchase_link
      }
      nextToken
      __typename
    }
  }
`;
