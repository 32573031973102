import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { DashboardListReports } from '../../../graphql/queries';
import { Bar } from 'react-chartjs-2';
import './Rank_Dashboard.css';

// OPTIONAL: If you plan on using any new Chart.js features (like for line charts),
// you can register them here. If you're sticking to Bar charts only,
// you don't strictly need this. But it's safe to include:
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// Helper: Force the selected date to "Friday" start
function getFriday(d) {
  // Clone date, force it to local noon to avoid timezone offset
  const date = new Date(
    d.getFullYear(),
    d.getMonth(),
    d.getDate(),
    12, 0, 0, 0
  );
  const day = date.getDay(); // Sunday=0, Monday=1, ... Saturday=6
  // shift = how many days back to get to Friday
  const shift = ((day - 5) + 7) % 7;
  date.setDate(date.getDate() - shift);
  return date;
}

// Helper: Friday + 6 days => Thursday
function getThursday(d) {
  const friday = getFriday(d);
  return new Date(friday.getTime() + 6 * 24 * 60 * 60 * 1000);
}

const RankDashboard = () => {
  const [navigatorData, setNavigatorData] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedNavigators, setSelectedNavigators] = useState([]);

  // "All Time" vs. "By Week"
  const [showByWeek, setShowByWeek] = useState(false);
  const [weekDate, setWeekDate] = useState(new Date());

  // For the single ranking table: which metric to rank by
  const [rankingType, setRankingType] = useState('qhp');

  // Apollo lazy query
  const [fetchReports] = useLazyQuery(DashboardListReports);

  // Default date range
  const defaultStartDate = new Date('2024-08-27');
  const defaultEndDate = new Date('2025-08-29');

  // Weekly date range if showByWeek = true
  const weekStart = getFriday(weekDate);
  const weekEnd = getThursday(weekDate);

  // We'll store all fetched reports here, then filter them in processNavigatorData
  const [allReports, setAllReports] = useState([]);

  useEffect(() => {
    loadNavigatorProductivity();
    // eslint-disable-next-line
  }, []);

  // Re-filter data whenever user toggles "By Week" or changes weekDate
  useEffect(() => {
    if (!allReports || allReports.length === 0) return;
    processNavigatorData(allReports);
    // eslint-disable-next-line
  }, [showByWeek, weekDate]);

  const loadNavigatorProductivity = async () => {
    let combinedReports = [];
    let nextToken = null;
    let isLoading = true;

    setLoading(true);

    try {
      while (isLoading) {
        const { data } = await fetchReports({
          variables: {
            limit: 50,
            nextToken: nextToken,
          },
        });

        if (data && data.listReports && data.listReports.items) {
          combinedReports = [...combinedReports, ...data.listReports.items];
          nextToken = data.listReports.nextToken;
        } else {
          nextToken = null;
        }
        isLoading = !!nextToken;
      }

      // Store everything; we'll filter in processNavigatorData
      setAllReports(combinedReports);
      processNavigatorData(combinedReports);
    } catch (error) {
      console.error('Error fetching reports:', error);
    } finally {
      setLoading(false);
    }
  };

  // Decide which date range to use, then build navigatorData
  const processNavigatorData = (reportsArray) => {
    const navigatorProductivity = {};

    const startDate = showByWeek ? weekStart : defaultStartDate;
    const endDate = showByWeek ? weekEnd : defaultEndDate;

    for (let report of reportsArray) {
      const reportDate = new Date(report.Date);
      if (reportDate >= startDate && reportDate <= endDate) {
        const navigator = report.Navigator;
        if (!navigatorProductivity[navigator]) {
          navigatorProductivity[navigator] = { QHP: 0, Medicaid: 0 };
        }

        // Sum QHP enrollments
        const qhpFields = [
          'qhp_enrollment_hcgov',
          'elctronic_qhp_enrollment_marketplaceCallCenter',
          'written_qhp_enrollment_hcgov',
        ];
        navigatorProductivity[navigator].QHP += qhpFields.reduce(
          (sum, field) => sum + (parseFloat(report[field]) || 0),
          0
        );

        // Sum Medicaid enrollments
        navigatorProductivity[navigator].Medicaid +=
          parseFloat(report.medicaid_chip_app_ref) || 0;
      }
    }

    setNavigatorData(navigatorProductivity);
    setSelectedNavigators(Object.keys(navigatorProductivity));
  };

  // Sort the data in 3 ways (QHP, Medicaid, Sum)
  const sortedNavigatorsByQHP = Object.entries(navigatorData).sort(
    ([, a], [, b]) => b.QHP - a.QHP
  );
  const sortedNavigatorsByMedicaid = Object.entries(navigatorData).sort(
    ([, a], [, b]) => b.Medicaid - a.Medicaid
  );
  const sortedNavigatorsBySum = Object.entries(navigatorData).sort(
    ([, a], [, b]) => b.QHP + b.Medicaid - (a.QHP + a.Medicaid)
  );

  // Decide which sorted array to use based on rankingType
  function getSortedNavigators(rankingType) {
    if (rankingType === 'qhp') return sortedNavigatorsByQHP;
    if (rankingType === 'medicaid') return sortedNavigatorsByMedicaid;
    return sortedNavigatorsBySum; // 'sum'
  }
  const currentSortedNavigators = getSortedNavigators(rankingType);

  // Decide which label and value to show in the table's 3rd column
  function getColumnLabel(rankingType) {
    if (rankingType === 'qhp') return 'QHP';
    if (rankingType === 'medicaid') return 'Medicaid';
    return 'Total';
  }
  function getValueByType(data, rankingType) {
    if (rankingType === 'qhp') return data.QHP;
    if (rankingType === 'medicaid') return data.Medicaid;
    return data.QHP + data.Medicaid;
  }
  const columnLabel = getColumnLabel(rankingType);

  // Filter the data used in the QHP and Medicaid bar charts
  const filteredDataByQHP = sortedNavigatorsByQHP.filter(([navigator]) =>
    selectedNavigators.includes(navigator)
  );
  const filteredDataByMedicaid = sortedNavigatorsByMedicaid.filter(
    ([navigator]) => selectedNavigators.includes(navigator)
  );

  // Prepare chart data
  const qhpChartData = {
    labels: filteredDataByQHP.map(([navigator]) => navigator),
    datasets: [
      {
        label: 'QHP Enrollments',
        data: filteredDataByQHP.map(([, data]) => data.QHP),
        backgroundColor: 'rgba(142, 212, 212, 0.6)',
      },
    ],
  };

  const medicaidChartData = {
    labels: filteredDataByMedicaid.map(([navigator]) => navigator),
    datasets: [
      {
        label: 'Medicaid Enrollments',
        data: filteredDataByMedicaid.map(([, data]) => data.Medicaid),
        backgroundColor: 'rgba(189, 158, 250, 0.6)',
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const toggleNavigator = (navigator) => {
    setSelectedNavigators((prevSelected) =>
      prevSelected.includes(navigator)
        ? prevSelected.filter((nav) => nav !== navigator)
        : [...prevSelected, navigator]
    );
  };

  // Handle toggling between "All Time" and "By Week"
  const handleShowByWeekChange = (e) => {
    setShowByWeek(e.target.value === 'byWeek');
  };

  return (
    <div className="rank-dashboard">
      <header className="rank-dashboard-header">
        <h1>Navigator Productivity Dashboard</h1>
      </header>

      {loading ? (
        <p>Loading data...</p>
      ) : (
        <>
          {/* Toggle between "All Time" and "By Week" */}
          <div style={{ marginBottom: '1rem' }}>
            <label>
              <input
                type="radio"
                name="timeRange"
                value="allTime"
                checked={!showByWeek}
                onChange={handleShowByWeekChange}
              />
              All Time
            </label>
            &nbsp;&nbsp;
            <label>
              <input
                type="radio"
                name="timeRange"
                value="byWeek"
                checked={showByWeek}
                onChange={handleShowByWeekChange}
              />
              By Week
            </label>

            {showByWeek && (
              <div style={{ marginTop: '0.5rem' }}>
                <label htmlFor="weekDate">Select a date in the desired week: </label>
                <input
                  type="date"
                  id="weekDate"
                  value={weekDate.toISOString().split('T')[0]}
                  onChange={(e) => {
                    if (!e.target.value) return;
                    const [yyyy, mm, dd] = e.target.value.split('-'); // e.g. "2024-12-20"
                    // Force local time to 12:00 (noon) to avoid timezone offsets
                    setWeekDate(
                      new Date(
                        Number(yyyy),
                        Number(mm) - 1,
                        Number(dd),
                        12,
                        0,
                        0,
                        0
                      )
                    );
                  }}
                />
                <p>
                  <strong>Week Range: </strong>
                  {weekStart.toDateString()} – {weekEnd.toDateString()}
                </p>
              </div>
            )}
          </div>

          {/* Navigator selection checkboxes */}
          <div className="navigator-selection">
            {Object.entries(navigatorData)
              .sort(([aName], [bName]) => aName.localeCompare(bName))
              .map(([navigator]) => (
                <label key={navigator}>
                  <input
                    type="checkbox"
                    checked={selectedNavigators.includes(navigator)}
                    onChange={() => toggleNavigator(navigator)}
                  />
                  {navigator}
                </label>
              ))}
          </div>

          {/* QHP Bar Chart */}
          <div className="chart-container">
            <h2>QHP Enrollments</h2>
            <Bar data={qhpChartData} options={options} />
          </div>

          {/* Medicaid Bar Chart */}
          <div className="chart-container">
            <h2>Medicaid Enrollments</h2>
            <Bar data={medicaidChartData} options={options} />
          </div>

          {/* Ranking Type Selector */}
          <div style={{ margin: '1rem 0' }}>
            <label>
              <input
                type="radio"
                name="rankingType"
                value="qhp"
                checked={rankingType === 'qhp'}
                onChange={(e) => setRankingType(e.target.value)}
              />
              QHP
            </label>
            &nbsp;&nbsp;
            <label>
              <input
                type="radio"
                name="rankingType"
                value="medicaid"
                checked={rankingType === 'medicaid'}
                onChange={(e) => setRankingType(e.target.value)}
              />
              Medicaid
            </label>
            &nbsp;&nbsp;
            <label>
              <input
                type="radio"
                name="rankingType"
                value="sum"
                checked={rankingType === 'sum'}
                onChange={(e) => setRankingType(e.target.value)}
              />
              Sum
            </label>
          </div>

          {/* Single Ranking Table */}
          <div className="rank-dashboard-tables-container">
            <h2 className="rank-dashboard-table-title">
              Ranking by {columnLabel}
            </h2>

            <table className="rank-dashboard-table rank-dashboard-single-table">
              <thead className="rank-dashboard-table-head">
                <tr>
                  <th className="rank-dashboard-table-header">Rank</th>
                  <th className="rank-dashboard-table-header">Navigator</th>
                  <th className="rank-dashboard-table-header">{columnLabel}</th>
                </tr>
              </thead>
              <tbody className="rank-dashboard-table-body">
                {currentSortedNavigators.map(([navigator, data], index) => {
                  const value = getValueByType(data, rankingType);
                  return (
                    <tr
                      className="rank-dashboard-table-row"
                      key={navigator}
                    >
                      <td className="rank-dashboard-table-cell">{index + 1}</td>
                      <td className="rank-dashboard-table-cell">{navigator}</td>
                      <td className="rank-dashboard-table-cell">{value}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default RankDashboard;
