import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import LoadingSpinner from '../../../components/layout/LoadingSpinner';
import useFetchUserByEmail from '../../../components/UserInfoLoader/LoadUserInfo';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import { GET_INTERACTIONS_BY_CALL_LIST } from '../../../graphql/queries';
import {
  CREATE_INTERACTION,
  UPDATE_CALL_LIST,
  DELETE_INTERACTION,
  UPDATE_CLIENTFIX2, // <-- Using this for Opt Out
  DELETE_CALL_LIST
} from '../../../graphql/mutations';
import './TouchPointModal.css';
import { capitalize } from '../../../components/common/capitalize';

const TouchPointModal = ({ onClose, selectedCall }) => {
  const [isAddInteractionModalOpen, setIsAddInteractionModalOpen] = useState(false);
  const [userGroups, setUserGroups] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const { userInfo: createdByUser, loading: userLoading, error: userError } = useFetchUserByEmail(userEmail);
  // Utility function that returns today's date as YYYY-MM-DD
  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  };

  const [interactionDetails, setInteractionDetails] = useState({
    callDate: getTodayDate(), // <-- Set the default to today's date
    time: '',
    answered: false,
    appointmentMade: false,
    notes: '',
    contactMethod: '',
    followUpScheduledDate: '',
  });

  // --- New state for the second checkbox ---
  const [addToOptOut, setAddToOptOut] = useState(false);
  const [completedAlreadyInsured, setCompletedAlreadyInsured] = useState(false);
  // -----------------------------------------

  const callListID = selectedCall?.id;

  const { data, loading, error, refetch } = useQuery(GET_INTERACTIONS_BY_CALL_LIST, {
    variables: { callListID },
    skip: !callListID,
  });

  const [updateCallList] = useMutation(UPDATE_CALL_LIST, {
    onCompleted: () => {
      refetch(); // Refresh interaction list
    }
  });
  const [deleteInteraction] = useMutation(DELETE_INTERACTION);
  const [createInteraction, { loading: createLoading, error: createError }] = useMutation(CREATE_INTERACTION, {
    onCompleted: () => {
      refetch(); // Refresh interaction list
      setIsAddInteractionModalOpen(false); // Close the modal
    },
  });
  const [updateClientFix2] = useMutation(UPDATE_CLIENTFIX2);
  const [deleteCallList] = useMutation(DELETE_CALL_LIST);

  const fetchUserDetails = async () => {
    try {
      const { signInDetails } = await getCurrentUser();
      const session = await fetchAuthSession();
      const groups = session.tokens.idToken.payload['cognito:groups'] || [];
      setUserGroups(groups);
      setUserEmail(signInDetails.loginId);
      setUserDetails({
        firstName: capitalize(createdByUser?.first_name),
        lastName: capitalize(createdByUser?.last_name),
      });
    } catch (error) {
      console.log('User not authenticated', error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const validateNotes = (notes, message) => {
    // Guard clause for empty notes
    if (!notes || !notes.trim()) {
      alert(message);
      return false;
    }
    return true;
  };

  const handleOptOut = async ({
    interactionDetails,
    selectedCall,
    callListID,
    deleteCallList,
    updateClientFix2,
    refetch,
    setIsAddInteractionModalOpen,
  }) => {
    // Ensure notes
    if (!validateNotes(interactionDetails.notes, 'Please provide notes for opting out.')) return;

    // Update client callStatus to "Opt Out"
    const client = selectedCall.id;
    await updateClientFix2({
      variables: {
        input: {
          id: client,
          callStatus: 'Opt Out',
          last_contacted_date: interactionDetails.callDate,
        },
      },
    });

    // Remove from the call list
    await deleteCallList({
      variables: {
        input: {
          id: callListID,
        },
      },
    });

    // Refetch data and close modal
    refetch();
    setIsAddInteractionModalOpen(false);
  };

  const handleCompletedAlreadyInsured = async ({
    interactionDetails,
    callListID,
    selectedCall,
    updateCallList,
    updateClientFix2,
    refetch,
    setIsAddInteractionModalOpen,
  }) => {
    // Ensure notes
    if (!validateNotes(interactionDetails.notes, 'Please provide notes for completed/already insured.')) return;

    // Update callList contactRound to 3
    await updateCallList({
      variables: {
        input: {
          id: callListID,
          contactRound: 3,
          callDate: interactionDetails.callDate,
          answered: interactionDetails.answered,
          appointmentMade: interactionDetails.appointmentMade,
          notes: interactionDetails.notes,
        },
      },
    });

    // Update client with callStatus "Completed"
    await updateClientFix2({
      variables: {
        input: {
          id: selectedCall.id,
          callStatus: 'Completed',
          last_contacted_date: interactionDetails.callDate,
        },
      },
    });

    // Refetch data and close modal
    refetch();
    setIsAddInteractionModalOpen(false);
  };

  const handleDefaultInteraction = async ({
    interactionDetails,
    callListID,
    selectedCall,
    createInteraction,
    updateCallList,
    updateClientFix2,
    refetch,
    setIsAddInteractionModalOpen,
  }) => {
    // 1) Prepare followUpDate
    const followUpDate = interactionDetails.followUpScheduledDate.trim()
      ? interactionDetails.followUpScheduledDate
      : null;
  
    // 2) Create the new interaction
    const interactionInput = { ...interactionDetails };
    if (!interactionInput.followUpScheduledDate?.trim()) {
      interactionInput.followUpScheduledDate = null;
    }
    delete interactionInput.addToOptOut;
  
    await createInteraction({
      variables: {
        input: {
          ...interactionInput,
          callListID,
        },
      },
    });
  
    // 3) Refetch to get the UPDATED interactions count
    const { data: updatedData } = await refetch(); 
    // Grab the fresh list of interactions
    const updatedInteractions = updatedData?.interactionsByCallList?.items || [];
    const newCount = updatedInteractions.length; 
    // You can also do +1 if you want, but usually newCount is enough
  
    // 4) Update Call List with the new contactRound
    await updateCallList({
      variables: {
        input: {
          id: callListID,
          contactRound: newCount, 
          followUpScheduledDate: followUpDate,
          callDate: interactionDetails.callDate,
          answered: interactionDetails.answered,
          appointmentMade: interactionDetails.appointmentMade,
          notes: interactionDetails.notes,
        },
      },
    });
  
    // 5) Update the client’s last_contacted_date (unchanged)
    await updateClientFix2({
      variables: {
        input: {
          id: selectedCall.id,
          last_contacted_date: interactionDetails.callDate,
        },
      },
    });
  
    // 6) Done: Refetch data if needed & close modal
    setIsAddInteractionModalOpen(false);
  };
  

  const handleInteractionSubmit = async () => {
    // 1. Check for callListID
    if (!callListID) {
      console.error('callListID is missing.');
      return;
    }

    // 2. Assign createdBy
    try {
      if (userDetails?.firstName && userDetails?.lastName) {
        interactionDetails.createdBy = `${userDetails.firstName} ${userDetails.lastName}`;
      } else if (createdByUser?.first_name && createdByUser?.last_name) {
        interactionDetails.createdBy = `${createdByUser.first_name} ${createdByUser.last_name}`;
      } else {
        console.warn('Both userDetails and createdByUser are missing or incomplete.');
        interactionDetails.createdBy = 'Unknown User'; // Fallback
      }
    } catch (error) {
      console.error('Error setting createdBy in interactionDetails:', error);
      return; // Abort if there's an error
    }

    // 3. Main logic: separate scenarios
    try {
      if (addToOptOut) {
        // --- Opt Out ---
        await handleOptOut({
          interactionDetails,
          selectedCall,
          callListID,
          deleteCallList,
          updateClientFix2,
          refetch,
          setIsAddInteractionModalOpen,
        });
        return;
      }

      if (completedAlreadyInsured) {
        // --- Completed / Already Insured ---
        await handleCompletedAlreadyInsured({
          interactionDetails,
          callListID,
          selectedCall,
          updateCallList,
          updateClientFix2,
          refetch,
          setIsAddInteractionModalOpen,
        });
        return;
      }

      // --- Default Interaction ---
      await handleDefaultInteraction({
        interactionDetails,
        callListID,
        selectedCall,
        createInteraction,
        updateCallList,
        updateClientFix2,
        refetch,
        setIsAddInteractionModalOpen,
      });
    } catch (err) {
      console.error('Error processing interaction:', err);
    }
  };

  const handleDeleteInteraction = async (interactionID) => {
    if (!callListID) {
      console.error('callListID is missing.');
      return;
    }
  
    try {
      // 1) Delete the chosen interaction
      await deleteInteraction({ variables: { id: interactionID } });
  
      // 2) Refetch the updated interactions
      const { data: updatedData } = await refetch();
      const updatedInteractions = updatedData?.interactionsByCallList?.items || [];
      const newCount = updatedInteractions.length;
  
      // 3) Update call list with the new count
      await updateCallList({
        variables: {
          input: {
            id: callListID,
            contactRound: newCount,
            notes: interactionDetails.notes,
          },
        },
      });
    } catch (err) {
      console.error('Error deleting interaction or updating call list:', err);
    }
  };
  

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div className="error-message">Error loading interactions: {error.message}</div>;
  }

  const interactions = data?.interactionsByCallList?.items || [];
  return (
    <div
      className="touchpoint-modal-overlay"
      onClick={onClose}
    >
      <div
        className="touchpoint-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <button className="close-btn" onClick={onClose}>
          Close
        </button>
        <h2>
          Information for{' '}
          {capitalize(selectedCall.client?.name) + ' ' + capitalize(selectedCall.client?.last_name)}
        </h2>
        <div className="client-details">
          <p>
            <strong>Name:</strong>{' '}
            {capitalize(selectedCall.client?.name) || 'N/A'}{' '}
            {capitalize(selectedCall.client?.last_name) || 'N/A'}
          </p>
          <p>
            <strong>Navigator:</strong>{' '}
            {selectedCall.client.navigator || 'N/A'}
          </p>
          <p>
            <strong>Call Date:</strong> {selectedCall.callDate || 'N/A'}
          </p>
          <p>
            <strong>Follow-Up Date:</strong>{' '}
            {selectedCall.followUpScheduledDate || 'N/A'}
          </p>
          <p>
            <strong>Contact Round:</strong>{' '}
            {selectedCall.contactRound || 'N/A'}
          </p>
        </div>
        <div className="interactions-list">
          <h3>Previous Interactions</h3>
          {interactions.length > 0 ? (
            interactions.map((interaction) => (
              <div key={interaction.id} className="interaction-item">
                <p>
                  <strong>Call Date:</strong>{' '}
                  {interaction.callDate || 'N/A'}
                </p>
                <p>
                  <strong>Time:</strong> {interaction.time || 'N/A'}
                </p>
                <p>
                  <strong>Answered:</strong>{' '}
                  {interaction.answered ? 'Yes' : 'No'}
                </p>
                <p>
                  <strong>Appointment Made:</strong>{' '}
                  {interaction.appointmentMade ? 'Yes' : 'No'}
                </p>
                <p>
                  <strong>Contact Method:</strong>{' '}
                  {interaction.contactMethod || 'N/A'}
                </p>
                <p>
                  <strong>Created By:</strong>{' '}
                  {interaction.createdBy}
                </p>
                <p>
                  <strong>Notes:</strong> {interaction.notes || 'No notes available'}
                </p>
                <button
                  className="delete-interaction-button"
                  onClick={() => handleDeleteInteraction(interaction.id)}
                >
                  Delete
                </button>
              </div>
            ))
          ) : (
            <p>No interactions found.</p>
          )}
        </div>

        {/* Add Interaction Button */}
        <div className="action-buttons">
          <button
            className="add-interaction-button"
            onClick={() => setIsAddInteractionModalOpen(true)}
          >
            Add Interaction
          </button>
        </div>

        {/* Add Interaction Modal */}
        {isAddInteractionModalOpen && (
          <div
            className="add-interaction-modal-overlay"
            onClick={() => setIsAddInteractionModalOpen(false)}
          >
            <div
              className="add-interaction-modal-content"
              onClick={(e) => e.stopPropagation()}
            >
              <h3>Add Interaction</h3>
              {/* First checkbox: Opt-Out */}
              <label>
                Add to Opt-Out List:
                <input
                  type="checkbox"
                  checked={addToOptOut}
                  onChange={(e) => {
                    setAddToOptOut(e.target.checked);
                    if (e.target.checked) {
                      setCompletedAlreadyInsured(false);
                    }
                  }}
                  disabled={completedAlreadyInsured}
                />
              </label>

              {/* Second checkbox: Completed/Already Insured */}
              <label>
                Completed/Already Insured:
                <input
                  type="checkbox"
                  checked={completedAlreadyInsured}
                  onChange={(e) => {
                    setCompletedAlreadyInsured(e.target.checked);
                    if (e.target.checked) {
                      setAddToOptOut(false);
                    }
                  }}
                  disabled={addToOptOut}
                />
              </label>

              {/* 
                If either opt-out or completed is checked,
                disable all other fields except Notes.
                Notes is required in both cases.
              */}
              <label>
                Call Date:
                <input
                  type="date"
                  value={interactionDetails.callDate}
                  onChange={(e) =>
                    setInteractionDetails({
                      ...interactionDetails,
                      callDate: e.target.value,
                    })
                  }
                  disabled={addToOptOut || completedAlreadyInsured}
                />
              </label>
              <label>
                Time:
                <input
                  type="time"
                  value={interactionDetails.time}
                  onChange={(e) =>
                    setInteractionDetails({
                      ...interactionDetails,
                      time: e.target.value,
                    })
                  }
                  disabled={addToOptOut || completedAlreadyInsured}
                />
              </label>
              <label>
                Answered:
                <input
                  type="checkbox"
                  checked={interactionDetails.answered}
                  onChange={(e) =>
                    setInteractionDetails({
                      ...interactionDetails,
                      answered: e.target.checked,
                    })
                  }
                  disabled={addToOptOut || completedAlreadyInsured}
                />
              </label>
              <label>
                Appointment Made:
                <input
                  type="checkbox"
                  checked={interactionDetails.appointmentMade}
                  onChange={(e) =>
                    setInteractionDetails({
                      ...interactionDetails,
                      appointmentMade: e.target.checked,
                    })
                  }
                  disabled={addToOptOut || completedAlreadyInsured}
                />
              </label>
              <label>
                Notes: <span style={{ color: 'red' }}>*</span>
                <textarea
                  required
                  value={interactionDetails.notes}
                  onChange={(e) =>
                    setInteractionDetails({
                      ...interactionDetails,
                      notes: e.target.value,
                    })
                  }
                />
              </label>
              <label>
                Contact Method:
                <input
                  type="text"
                  value={interactionDetails.contactMethod}
                  onChange={(e) =>
                    setInteractionDetails({
                      ...interactionDetails,
                      contactMethod: e.target.value,
                    })
                  }
                  disabled={addToOptOut || completedAlreadyInsured}
                />
              </label>
              <label>
                Follow-Up Scheduled Date:
                <input
                  type="date"
                  value={interactionDetails.followUpScheduledDate}
                  onChange={(e) =>
                    setInteractionDetails({
                      ...interactionDetails,
                      followUpScheduledDate: e.target.value,
                    })
                  }
                  disabled={addToOptOut || completedAlreadyInsured}
                />
              </label>

              <button onClick={handleInteractionSubmit} disabled={createLoading}>
                {createLoading ? 'Adding...' : 'Add Interaction'}
              </button>
              {createError && (
                <p className="error-message">Error: {createError.message}</p>
              )}
              <button onClick={() => setIsAddInteractionModalOpen(false)}>
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TouchPointModal;
