import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Inventory_header.css';
import { FaHome } from 'react-icons/fa';

const Inventory_header = () => {
    const navigate = useNavigate();
    const location = useLocation(); // Obtiene la ruta actual
    const [selectedEquipment, setSelectedEquipment] = useState();


    useEffect(() => {
        // Usamos una expresión regular para extraer el nombre del equipo
        const match = location.pathname.match(/equipment-([a-zA-Z0-9\-]+)/);

        if (match) {
            setSelectedEquipment(match[1]); // El nombre del equipo es el primer grupo de la expresión regular
        } else {
            setSelectedEquipment(''); // Si no es una página de equipo, vaciar la selección
        }
    }, [location.pathname]); // Se ejecuta cada vez que cambia la ruta

    const handleClick = (equipment) => {
        setSelectedEquipment(equipment);
        navigate(`../equipment-${equipment}`);
    };

    const equipmentList = [
        'mouse',
        'keyboard',
        'laptop',
        'phone',
        'monitor',
        'printer',
        'scanner',
        'extension-cord',
        'power-bank',
        'backup-battery',
        'docking-station',
        'speaker',
        'usb',
        'tablet-remarkable',
        'headset',
        'projector',
        'external-disk',
        'other-equipment',
        'bag',
        'click-counter',
        'office-supplies',

    ];

    const sortedEquipmentList = [...equipmentList].sort((a, b) => a.localeCompare(b)); 

    return (
        <div className="menu-header">
            {/* Botón para ir a la página principal */}
            <button
                onClick={() => navigate('/inventory-home')}
                className="home-button"
            >
                <FaHome className="icon-large" />
            </button>
            
            {sortedEquipmentList.map((equipment) => (
                equipment === selectedEquipment ? (
                    // Reemplaza el botón del equipo actual con "Employee"
                    <button
                        key="employee"
                        onClick={() => navigate('../inventory-employees')}
                        className="employee-button"
                    >
                        Employee
                    </button>
                ) : (
                    // Botones para otros equipos
                    <button
                        key={equipment}
                        onClick={() => handleClick(equipment)}
                        className={selectedEquipment === equipment ? 'selected' : ''}
                    >
                        {equipment.charAt(0).toUpperCase() + equipment.slice(1).replace('-', ' ')}
                    </button>
                )
            ))}
        </div>
    );
};

export default Inventory_header;

