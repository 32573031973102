import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { listDeleteEquipmentHistories, listMonitors, listUsers } from '../../../graphql/queries';
import { createMonitor, deleteMonitor, updateMonitor, createDeleteEquipmentHistory } from '../../../graphql/mutations';
import './Monitor.css';
import XLSX from 'xlsx-js-style';
import { FaFileExcel } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import Inventory_header from '../../../components/layout/Inventory_header';
import useFilteredData from '../../../components/layout/useFilteredDate';
import SearchBar from '../../../components/layout/SearchBar';
import { capitalize } from '../../../components/common/capitalize';
import Previous_employees from '../Previous_Employees/Previous_Employees';

const Monitor = () => {

    const [monitor, setMonitor] = useState([]);
    const [nextToken, setNextToken] = useState(null);
    const [showMonitorAddOverlay, setShowMonitorAddOverlay] = useState(false);
    const [selectedMonitor, setSelectedMonitor] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [newMonitor, setNewMonitor] = useState({
        id: '',
        tagid: '',
        model: '',
        brand: '',
        inches: '',
        serial_Number: '',
        price: '',
        store: '',
        purchase_date: '',
        charger_cable: true,
        monitor_type: '',
        Connector_cable: 'HDMI',
        assignment_date: '',
        warranty_date: '',
        returnable_date: '',
        available: true,
        status: 'inv',
        assigned_to: '',
        location: '',
        team: '',

    });

    const [sortConfig, setSortConfig] = useState(null);
    const [duplicateError, setDuplicateError] = useState('');
    const [priceError, setPriceError] = useState('');
    const [statusError, setStatusError] = useState('');
    const { loading1, error: userError, data: userData } = useQuery(listUsers);
    const [searchTerm, setSearchTerm] = useState('');


    const handleSaveAsExcel = async () => {
        try {

            const monitorToExport = filteredMonitors.length > 0 ? filteredMonitors : monitor;

            if (monitorToExport.length > 0) {
                // Sort the monitors by Timestamp in ascending order (earliest first)
                const sortedmonitor = [...monitorToExport].sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp));

                // Prepare the data for Excel export with custom column titles
                const worksheetData = sortedmonitor.map((monitor, index) => ({
                    '#': index + 1, // Agregar el número de fila
                    'TAG:': monitor.tagid,
                    'Brand': monitor.brand,
                    'Inches:': monitor.inches,
                    'Type:': monitor.monitor_type,
                    'Available:': monitor.available ? 'Yes' : 'No',
                    'Serial Number:': monitor.serial_Number,
                    'Status:': monitor.status,
                    'Assigned to:': monitor.assigned_to,
                    'Team:': monitor.team,
                    'Location:': monitor.location,
                    // Add other fields as needed

                }));


                // Convert JSON to sheet starting from row 2
                const worksheet = XLSX.utils.json_to_sheet([]);
                let endCol = 0;

                XLSX.utils.sheet_add_aoa(worksheet, [['Monitors']], { origin: 'A1' });

                if (worksheetData.length > 0) {
                    // Agrega los datos a partir de la fila A2
                    XLSX.utils.sheet_add_json(worksheet, worksheetData, { origin: 'A2', skipHeader: false });

                    // Define el rango de referencia (!ref)
                    const endRow = worksheetData.length + 1; // +1 para el título en A1
                    const endCol = Object.keys(worksheetData[0]).length - 1;
                    worksheet['!ref'] = XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: endRow, c: endCol } });
                } else {
                    console.error("No hay datos para exportar.");
                    return;
                }

                // Merge title cells across the columns
                worksheet['!merges'] = worksheet['!merges'] || [];
                worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 10 } }); // A1 to H1 for the added column

                // Style the title row
                worksheet['A1'].s = {
                    font: {
                        bold: true,
                        sz: 20, // Font size
                        color: { rgb: '1F4E79' } // Dark blue text color
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center'
                    }
                };

                // Apply styling for the header row (now starting at row 2)
                const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
                for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                    const cellAddress = XLSX.utils.encode_cell({ r: 1, c: col }); // Header row is now row 1
                    if (!worksheet[cellAddress]) continue;
                    worksheet[cellAddress].s = {
                        fill: {
                            fgColor: { rgb: '1F4E79' } // Dark blue background
                        },
                        font: {
                            bold: true,
                            color: { rgb: 'FFFFFF' } // White text
                        },
                        alignment: {
                            horizontal: 'center'
                        },
                        border: {
                            top: { style: 'thin', color: { rgb: '000000' } },
                            left: { style: 'thin', color: { rgb: '000000' } },
                            bottom: { style: 'thin', color: { rgb: '000000' } },
                            right: { style: 'thin', color: { rgb: '000000' } },
                        }
                    };
                }

                // Apply alternating row colors and borders starting from row 3
                for (let row = 2; row <= headerRange.e.r; row++) {
                    const isEvenRow = row % 2 === 0;
                    for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
                        if (!worksheet[cellAddress]) continue;

                        worksheet[cellAddress].s = {
                            fill: {
                                fgColor: { rgb: isEvenRow ? 'D3D3D3' : 'FFFFFF' } // Light gray for even rows, white for odd rows
                            },
                            border: {
                                top: { style: 'thin', color: { rgb: '000000' } },
                                left: { style: 'thin', color: { rgb: '000000' } },
                                bottom: { style: 'thin', color: { rgb: '000000' } },
                                right: { style: 'thin', color: { rgb: '000000' } },
                            },
                            alignment: {
                                horizontal: 'center'
                            }
                        };
                    }
                }
                // Adjust columns to fit content
                worksheet['!cols'] = [
                    { wch: 10 }, // Adjust width for each column
                    { wch: 10 },
                    { wch: 10 },
                    { wch: 10 },
                    { wch: 20 },
                    { wch: 10 },
                    { wch: 30 },
                    { wch: 15 },
                    { wch: 30 },
                    { wch: 5 },
                    { wch: 30 }
                ];
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'monitors');

                // Write file
                XLSX.writeFile(workbook, 'monitorsData.xlsx');
            } else {
                console.error('No data to export');
            }
        } catch (error) {
            console.error('Error exporting data to Excel:', error);
        }
    };

    const handleEditMonitor = (monitor) => {
        setSelectedMonitor(monitor);
        setIsEditing(true);
        setShowMonitorAddOverlay(true);
    };


    const { loading, data, fetchMore } = useQuery(listMonitors, {
        variables: { limit: 100 },
    });

    const [addMonitor] = useMutation(createMonitor, {
        refetchQueries: [{ query: listMonitors, variables: { limit: 100 } }]
    });

    const [removeMonitor] = useMutation(deleteMonitor, {
        refetchQueries: [{ query: listMonitors, variables: { limit: 100 } }]
    });
    const [updMonitor] = useMutation(updateMonitor, {
        refetchQueries: [{ query: listMonitors, variables: { limit: 100 } }]
    });

    const [addDeleteEquipmentHistory] = useMutation(createDeleteEquipmentHistory, {
        refetchQueries: [{ query: listDeleteEquipmentHistories, variables: { limit: 100 } }]
    });

    useEffect(() => {
        const fetchAllMonitor = async () => {
            let allMonitors = [];
            let currentNextToken = null;

            do {
                const { data } = await fetchMore({
                    variables: { limit: 100, nextToken: currentNextToken },
                });

                allMonitors = [...allMonitors, ...data.listMonitors.items];
                currentNextToken = data.listMonitors.nextToken;
            } while (currentNextToken);

            setMonitor(allMonitors);
            setNextToken(currentNextToken);
        };

        if (data) {
            fetchAllMonitor();
        }
    }, [data, fetchMore]);


    const clearNewMonitor = () => {
        setNewMonitor({
            id: '',
            tagid: '',
            model: '',
            brand: '',
            inches: '',
            serial_Number: '',
            price: '',
            store: '',
            purchase_date: '',
            charger_cable: true,
            monitor_type: '',
            Connector_cable: 'HDMI',
            assignment_date: '',
            warranty_date: '',
            returnable_date: '',
            available: true,
            status: 'inv',
            assigned_to: '',
            location: '',
            team: '',
        });
    };
    const loadMore = () => {
        if (!nextToken) return; // Check if nextToken is null

        fetchMore({
            variables: { limit: 100, nextToken },
            updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;
                setNextToken(fetchMoreResult.listMonitors.nextToken);
                return {
                    listMonitors: {
                        ...fetchMoreResult.listMonitors,
                        items: [...prevResult.listMonitors.items, ...fetchMoreResult.listMonitors.items]
                    }
                };
            }
        });
    };

    //Filtro de Monitors empleando el componente useFilteredData
    const filteredMonitors = useFilteredData(monitor || [], searchTerm, sortConfig);

    const handleInputChange = (e) => {
        const { name, type, checked, value } = e.target;
        let formattedValue = value;

        // Convert to lowercase 
        if (name === 'serial_Number') {
            formattedValue = value.toUpperCase();
        }

        if (name === 'charger_cable') {
            // Convertir el valor de la selección a un booleano
            formattedValue = value === 'true' || value === true ? true : false;
        }

        if (type === 'checkbox') {
            formattedValue = checked;
        }
        if (isEditing) {
            setSelectedMonitor({
                ...selectedMonitor,
                [name]: formattedValue
            });
        } else {
            setNewMonitor({
                ...newMonitor,
                id: uuidv4(),
                [name]: formattedValue
            });
        }

    };


    const handleAddMonitor = () => {

        // Formatear la fecha en el formato YYYY-MM-DD si existe, o establecerla en null si está vacía
        const formattedPurchase = newMonitor.purchase_date
            ? new Date(newMonitor.purchase_date).toISOString().split('T')[0]
            : null;
        const formattedAssignment = newMonitor.assignment_date
            ? new Date(newMonitor.assignment_date).toISOString().split('T')[0]
            : null;

        const formattedWarranty = newMonitor.warranty_date
            ? new Date(newMonitor.warranty_date).toISOString().split('T')[0]
            : null;

        const formattedReturnable = newMonitor.returnable_date
            ? new Date(newMonitor.returnable_date).toISOString().split('T')[0]
            : null;

        // Validate that the price contains only digits and is a valid number
        let priceValue = null;
        if (newMonitor.price !== "" && newMonitor.price !== null && newMonitor.price != undefined) {
            priceValue = parseFloat(newMonitor.price);

            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');

                return;
            }
        }
        setPriceError('');

        // Check for duplicates based on `tagid` and `serial_Number`
        const isDuplicate = monitor.some(monitor =>
            monitor.tagid === newMonitor.tagid &&
            monitor.serial_Number === newMonitor.serial_Number
        );

        if (isDuplicate) {
            setDuplicateError('A monitor with the same TAG and Serial Number already exists.');
            console.log('Duplicate validation failed');
            return;
        } else {
            setDuplicateError('');
        }

        // Check if newMonitor and its properties are defined
        if (!newMonitor || typeof newMonitor.status === 'undefined' || typeof newMonitor.available === 'undefined') {
            console.error('Monitor data is incomplete or invalid.');
            setStatusError('Monitor data is incomplete or invalid.');
            return;
        }

        // Check availability first
        if (newMonitor.available === true) {

            // If available, status must be Inventario
            if (newMonitor.status !== 'inv') {
                setStatusError('If the Monitor is available, its status must be "Inventario".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // If available and status is Inventario, assigned_to must be empty or "N/A"
            if (newMonitor.assigned_to !== '' && newMonitor.assigned_to !== 'n/a') {
                setStatusError('If the Monitor is available and its status is "Inventario", the "Assigned to" field must be empty or "N/A".');
                console.log('Assigned_to validation failed');
                return; // Exit function if validation fails
            }

        } else if (newMonitor.available !== "") {
            // If not available, status must be one of the allowed statuses
            const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
            if (!allowedStatuses.includes(newMonitor.status)) {
                setStatusError('If the Monitor is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // Clear error if validation passes
            setStatusError('');
        }


        // If all validations pass, proceed with adding the monitor to the database
        addMonitor({
            variables: { input: { ...newMonitor, price: priceValue, purchase_date: formattedPurchase, warranty_date: formattedWarranty, returnable_date: formattedReturnable, assignment_date: formattedAssignment } }
        })
            .then(() => {
                clearNewMonitor();
                alert("The Monitor was an added success")
                setShowMonitorAddOverlay(false);
            })
            .catch(error => {
                console.error("Error adding monitor:", error);
            });
    };


    const removeUnnecessaryFields = (data, fieldsToRemove) => {
        fieldsToRemove.forEach((field) => {
            delete data[field];
        });
        return data;
    };
    const handleDeleteMonitor = async () => {
        try {
            // Remover campos innecesarios
            const MonitorData = removeUnnecessaryFields({ ...selectedMonitor }, [
                'inches',
                'charger_cable',
                'monitor_type',
                'Connector_cable',
                'warranty_date',
                'returnable_date',
                'status',
                'team',
            ]);

            // Lista de campos permitidos según el esquema GraphQL
            const allowedFields = [
                'id',
                'Item',
                'tagId',
                'tagid', // Agregado
                'serial_Number',
                'price',
                'brand',
                'model',
                'store',
                'purchase_date',
                'assignment_date',
                'available',
                'previous_employees',
                'assigned_to', // Agregado
                'location',
                'notes',
            ];

            // Filtrar los campos permitidos
            const cleanedNewMonitors = Object.fromEntries(
                Object.entries(MonitorData).filter(([key]) => allowedFields.includes(key))
            );


            console.log("Antes de normalizar:", cleanedNewMonitors);

            // Normalizar claves alternativas
            cleanedNewMonitors.tagId = cleanedNewMonitors.tagId || cleanedNewMonitors.tagid;
            delete cleanedNewMonitors.tagid;

            cleanedNewMonitors.previous_employees =
                cleanedNewMonitors.previous_employees || cleanedNewMonitors.assigned_to;
            delete cleanedNewMonitors.assigned_to;

            console.log("Despues de normalizar:", cleanedNewMonitors);

            // Añadir valores clave predeterminados
            if (!cleanedNewMonitors.Item) {
                cleanedNewMonitors.Item = "Monitor"; // Establecer el valor predeterminado para `Item`
            }

            // Eliminar valores vacíos
            Object.keys(cleanedNewMonitors).forEach((key) => {
                if (cleanedNewMonitors[key] === "" || cleanedNewMonitors[key] === null) {
                    delete cleanedNewMonitors[key];
                }
            });

            // Agregar empleado a la tabla PreviousEmployee
            await addDeleteEquipmentHistory({
                variables: { input: cleanedNewMonitors },
            });

            // Eliminar el monitor
            await removeMonitor({
                variables: { input: { id: cleanedNewMonitors.id } },
            });

            // Resetear estados
            setShowMonitorAddOverlay(false);
            setIsEditing(false);
            setSelectedMonitor(null);

        } catch (error) {
            console.error("Error deleting monitor", error);
        }
    };

    const handleUpdateMonitor = () => {
        const formattedData_purchase = selectedMonitor.purchase_date ? new Date(selectedMonitor.purchase_date).toISOString().split('T')[0] : null;
        const formattedData_warranty = selectedMonitor.warranty_date ? new Date(selectedMonitor.warranty_date).toISOString().split('T')[0] : null;
        const formattedData_returnable = selectedMonitor.returnable_date ? new Date(selectedMonitor.returnable_date).toISOString().split('T')[0] : null;
        const formattedData_assignment = selectedMonitor.assignment_date ? new Date(selectedMonitor.assignment_date).toISOString().split('T')[0] : null;

        // Validate that the price contains only digits and is a valid number

        let priceValue = null;
        if (selectedMonitor.price !== "" && selectedMonitor.price !== null && selectedMonitor.price != undefined) {
            priceValue = parseFloat(selectedMonitor.price);

            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');
                return;
            }
        }
        setPriceError('');
        // Check availability first
        if (selectedMonitor.available === 'true') {
            // Si está disponible, el estado debe ser 'inv'
            if (selectedMonitor.status !== 'inv') {
                setStatusError('If the Monitor is available, its status must be "Inventario".');
                console.log('Status validation failed aqui');
                return; // Salir de la función si la validación falla
            }
            // Si está disponible y el estado es 'Inventario', assigned_to debe estar vacío o ser "N/A"
            if (selectedMonitor.assigned_to !== '' && selectedMonitor.assigned_to.toLowerCase() !== 'n/a') {
                setStatusError('If the Monitor is available and its status is "Inventario", the "Assigned to" field must be empty or "N/A".');
                console.log('Assigned_to validation failed');
                return; // Salir de la función si la validación falla
            }
        } else if (selectedMonitor.available === 'false' || selectedMonitor.available === "") {
            // Si no está disponible, el estado debe ser uno de los permitidos
            const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
            if (!allowedStatuses.includes(selectedMonitor.status)) {
                setStatusError('If the Monitor is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
                console.log('Status validation failed');
                return; // Salir de la función si la validación falla
            }
        }

        // Limpiar el error si la validación pasa
        setStatusError('');


        updMonitor({
            variables: {
                input: {
                    id: selectedMonitor.id,
                    tagid: selectedMonitor.tagid,
                    model: selectedMonitor.model,
                    brand: selectedMonitor.brand,
                    inches: selectedMonitor.inches,
                    serial_Number: selectedMonitor.serial_Number,
                    price: priceValue,
                    store: selectedMonitor.store,
                    purchase_date: formattedData_purchase,
                    charger_cable: selectedMonitor.charger_cable,
                    monitor_type: selectedMonitor.monitor_type,
                    Connector_cable: selectedMonitor.Connector_cable,
                    assignment_date: formattedData_assignment,
                    warranty_date: formattedData_warranty,
                    returnable_date: formattedData_returnable,
                    available: selectedMonitor.available,
                    status: selectedMonitor.status,
                    assigned_to: selectedMonitor.assigned_to,
                    location: selectedMonitor.location,
                    team: selectedMonitor.team
                }
            }
        }).then(() => {
            setIsEditing(true);
            setShowMonitorAddOverlay(false);

        }).catch(error => {
            console.error("Error updating monitor:", error);
        });
    };

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    //listUsers 
    const users = React.useMemo(() => {
        if (userData && userData.listUsers) {
            return userData.listUsers.items || [];
        }
        return [];
    }, [userData]);
    if (userError) return <p>Error loading users: {userError.message}</p>;
    if (loading1) return <p>Error loading users: {userError.message}</p>;

    const formatDate = (dateString) => {
        const options = { month: 'numeric', day: 'numeric', year: 'numeric' };
        const date = new Date(dateString);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

        return localDate.toLocaleDateString(undefined, options);
    };

    const formatPrice = (price) => {
        if (price === null || price === undefined || price === '') {
            return '';
        }
        const parsedPrice = parseFloat(price);
        return isNaN(parsedPrice) ? '' : parsedPrice.toFixed(2);
    };

    const monitorTypeMap = {
        desk: 'Desk Monitor',
        portable: 'Portable Monitor',
        portable_screen: 'Portable Screen', // Agregar nueva opción
        mobile_pixels_trio_max: 'Mobile Pixels-Trio Max', // Agregar nueva opción
        // Puedes agregar más opciones aquí según sea necesario
    };

    return (
        <div className='monitors-page'>
            <header className="inventory-header">
                <h1>Monitor</h1>
            </header>
            <Inventory_header />
            <div className="inventory-search">
                <button onClick={handleSaveAsExcel}> <FaFileExcel /> Export </button>
            </div>

            <div className="searchBar"><button className="add-monitor-btn" onClick={() => {
                clearNewMonitor();
                setSelectedMonitor(null);
                setIsEditing(false);
                setShowMonitorAddOverlay(true);
            }}>+ Add New Monitor</button>
                <SearchBar searchTerm={searchTerm} onSearch={setSearchTerm} />
            </div>
            <div className="monitor-table-container">
                <table className="monitor-table" >
                    <thead>
                        <tr>
                            <th>#</th> { }
                            <th onClick={() => requestSort('tagid')}>
                                TAG

                            </th>
                            <th onClick={() => requestSort('brand')}>
                                Brand
                                {sortConfig?.key === 'brand' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('model')}>
                                Model
                                {sortConfig?.key === 'model' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('inches')}>
                                Inches
                                {sortConfig?.key === 'inches' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('serial_Number')}>
                                Serial Number
                                {sortConfig?.key === 'serial_Number' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('price')}>
                                Price
                                {sortConfig?.key === 'price' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('store')}>
                                Store
                                {sortConfig?.key === 'store' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('purchase_date')}>
                                Purchase Date
                                {sortConfig?.key === 'purchase_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('available')}>
                                Available
                                {sortConfig?.key === 'available' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('status')}>
                                Status
                                {sortConfig?.key === 'status' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('assigned_to')}>
                                Assigned To
                                {sortConfig?.key === 'assigned_to' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('assignment_date')}>
                                Assignment Date
                                {sortConfig?.key === 'assignment_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('charger_cable')}>
                                Charger Cable
                                {sortConfig?.key === 'charger_cable' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('monitor_type')}>
                                Monitor Type
                                {sortConfig?.key === 'monitor_type' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('Connector_cable')}>
                                Connector Cable
                                {sortConfig?.key === 'Connector_cable' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('warranty_date')}>
                                Warranty Date
                                {sortConfig?.key === 'warranty_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('returnable_date')}>
                                Returnable Date
                                {sortConfig?.key === 'returnable_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>


                            <th onClick={() => requestSort('location')}>
                                Location
                                {sortConfig?.key === 'location' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('team')}>
                                Team
                                {sortConfig?.key === 'team' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {filteredMonitors.map((monitor, index) => (
                            <tr key={monitor.id} onClick={() => handleEditMonitor(monitor)}>
                                <td>{index + 1}</td> {/* Número de fila */}
                                <td className="monitor-tagid">{capitalize(monitor.tagid)}</td>
                                <td className="monitor-brand">{capitalize(monitor.brand.toLowerCase())}</td>
                                <td className="monitor-model">{capitalize(monitor.model.toLowerCase())}</td>

                                <td className="monitor-inches">{monitor.inches}</td>
                                <td className="monitor-serial_Number">{monitor.serial_Number?.toUpperCase() || 'N/A'}</td>
                                <td className="monitor-price">${formatPrice(monitor.price)}</td>
                                <td className="monitor-store">{monitor.store}</td>
                                <td className="monitor-purchase_date">{monitor.purchase_date ? formatDate(monitor.purchase_date) : 'No Date'}</td>
                                <td className="monitor-available">{monitor.available ? 'Yes' : 'No'} </td>
                                <td className="monitor-status">
                                    {monitor.status === 'broken' ? 'Broken' :
                                        monitor.status === 'use' ? 'Use' :
                                            monitor.status === 'transfer' ? 'Transfer' :
                                                monitor.status === 'lost' ? 'Lost' :
                                                    monitor.status === 'inv' ? 'Inventario' :
                                                        'Unknown'} {/* Muestra 'Unknown' si el estado no coincide con ninguno de los anteriores */}
                                </td>
                                <td className="monitor-assigned_to">{monitor.assigned_to}</td>
                                <td className="monitor-assignment_date">{monitor.assignment_date ? formatDate(monitor.assignment_date) : 'No Date'}</td>
                                <td className="monitor-charger_cable">{monitor.charger_cable ? 'Yes' : 'No'}</td>
                                <td className="monitor-monitor_type">
                                    {monitor && monitor.monitor_type ? ( // Verificar que monitor y monitor.monitor_type existan
                                        monitorTypeMap[monitor.monitor_type] || 'Unknown' // Muestra "Unknown" si no coincide
                                    ) : 'Unknown'}
                                </td>
                                <td className="monitor-connector_cable">
                                    {monitor.Connector_cable === 'HDMI' ? 'HDMI Cable' : monitor.Connector_cable === 'C-C' ? 'C-C'
                                        : monitor.Connector_cable === 'USB-C'? 'USB-C Cable'
                                        : monitor.Connector_cable === 'no' ? 'Cable not provided'
                                            : 'Other'}</td>


                                <td className="monitor-warranty_date">{monitor.warranty_date ? formatDate(monitor.warranty_date) : 'No Date'}</td>
                                <td className="monitor-returnable_date">{monitor.returnable_date ? formatDate(monitor.returnable_date) : 'No Date'}</td>


                                <td className="monitor-location">{monitor.location}</td>
                                <td className="monitor-team">{monitor.team}</td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {nextToken && !loading && (
                <button onClick={loadMore}>Load More</button>
            )}

            {loading && <p>Loading...</p>}

            {showMonitorAddOverlay && (

                <div className="monitor_add_overlay" onClick={(e) => {
                    // Close overlay only if clicked outside the modal content
                    if (e.target.classList.contains("monitor_add_overlay")) {
                        setShowMonitorAddOverlay(false);
                        setIsEditing(false);
                        setSelectedMonitor(null);
                    }
                }}>
                    <div className="overlay-content-inventory ">
                        <div className="header-row">
                            <h2 className="header-title">{isEditing ? 'Edit Monitor' : 'Add New Monitor'}</h2>
                            <button onClick={() => {
                                setShowMonitorAddOverlay(false);
                                clearNewMonitor();
                                setSelectedMonitor(null);
                                setIsEditing(false);
                            }}>X</button>
                        </div>

                        <label>TAG:</label><input name="tagid" placeholder="TAG" value={isEditing ? selectedMonitor.tagid : newMonitor.tagid} onChange={handleInputChange} required />
                        <label>Model:</label><input name="model" placeholder="Model" value={isEditing ? selectedMonitor.model : newMonitor.model} onChange={handleInputChange} required />

                        <label>Brand:</label><input name="brand" placeholder="Brand" value={isEditing ? selectedMonitor.brand : newMonitor.brand} onChange={handleInputChange} />
                        <label>Inches:</label><input name="inches" placeholder="Inches" value={isEditing ? selectedMonitor.inches : newMonitor.inches} onChange={handleInputChange} />
                        <label>Serial Number:</label><input name="serial_Number" placeholder="Serial Number" value={isEditing ? selectedMonitor.serial_Number : newMonitor.serial_Number} onChange={handleInputChange} required />


                        <label>Price:</label><input name="price" placeholder="price" value={isEditing ? selectedMonitor.price : newMonitor.price} onChange={handleInputChange} />
                        <label>Store:</label><input name="store" placeholder="store" value={isEditing ? selectedMonitor.store : newMonitor.store} onChange={handleInputChange} />

                        <label htmlFor="purchase_date">Purchase Date:</label>
                        <input
                            type="Date"
                            id="purchase_date"
                            name="purchase_date"
                            value={isEditing ? selectedMonitor.purchase_date : newMonitor.purchase_date}
                            onChange={handleInputChange}

                        />
                        <label>Charger Cable:</label>

                        <select name="charger_cable" placeholder="charger_cable" value={isEditing ? selectedMonitor.charger_cable : newMonitor.charger_cable} onChange={handleInputChange} >
                            <option value="" disabled hidden className="placeholder-option">Select</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                        </select>

                        <label>Monitor Type:</label>
                        <select
                            name="monitor_type"
                            value={isEditing ? (selectedMonitor.monitor_type || "") : (newMonitor.monitor_type || "")}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="" disabled>Select Monitor Type</option>
                            <option value="desk">Desk Monitor</option>
                            <option value="portable">Portable Monitor</option>
                            <option value="portable_screen">Portable Screen</option>
                            <option value="mobile_pixels_trio_max">Mobile Pixels-Trio Max</option>
                        </select>

                        <label>Connector Cable:</label>

                        <select name="Connector_cable" placeholder="Connector_cable" value={isEditing ? selectedMonitor.Connector_cable : newMonitor.Connector_cable} onChange={handleInputChange} >
                            <option value="" disabled hidden className="placeholder-option">Select</option>
                            <option value="HDMI">HDMI</option>
                            <option value="C-C">C-C</option>
                            <option value="USB-C">USB-C</option>
                            <option value="no">Cable not provided</option>
                            <option value="HDMI">Other</option>
                        </select>
                        <label htmlFor="assignment_date">Assignment Date:</label>
                        <input
                            type="date"
                            id="assignment_date"
                            name="assignment_date"
                            value={isEditing ? selectedMonitor.assignment_date : newMonitor.assignment_date}
                            onChange={handleInputChange}

                        />
                        <label htmlFor="warranty_date">Warranty Date:</label>
                        <input
                            type="date"
                            id="warranty_date"
                            name="warranty_date"
                            value={isEditing ? selectedMonitor.warranty_date : newMonitor.warranty_date}
                            onChange={handleInputChange}

                        />
                        <label htmlFor="returnable_date">Returnable Date:</label>
                        <input
                            type="date"
                            id="returnable_date"
                            name="returnable_date"
                            value={isEditing ? selectedMonitor.returnable_date : newMonitor.returnable_date}
                            onChange={handleInputChange}
                        />

                        <label>Available:</label>

                        <select name="available" placeholder="available" value={isEditing ? selectedMonitor.available : newMonitor.available} onChange={handleInputChange} >
                            <option value="" disabled hidden className="placeholder-option">Select Availability</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>

                        </select>
                        <label>Status:</label>
                        <select name="status" placeholder="status" value={isEditing ? selectedMonitor.status : newMonitor.status} onChange={handleInputChange} >
                            <option value="" disabled hidden>Select Status</option>
                            <option value="use">Use</option>
                            <option value="broken">Broken</option>
                            <option value="transfer">Transfer</option>
                            <option value="lost">Lost</option>
                            <option value="inv">Inventario</option>

                        </select>
                        <label>Assigned To:</label>
                        <select
                            name="assigned_to"
                            value={isEditing ? selectedMonitor.assigned_to : newMonitor.assigned_to}
                            onChange={handleInputChange}
                        >
                            <option value="">Select an Employee</option>

                            {users.length > 0 ? (
                                [...users].sort((a, b) => {
                                    const nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
                                    const nameB = `${b.first_name} ${b.last_name}`.toLowerCase();
                                    return nameA.localeCompare(nameB);
                                }).map(user => (
                                    <option key={user.id} value={`${capitalize(user.first_name)} ${capitalize(user.last_name)}`}>
                                        {capitalize(user.first_name)} {capitalize(user.last_name)}
                                    </option>
                                ))
                            ) : (

                                <option value="">No users available</option>
                            )}
                            <option value="n/a">N/A</option> {/* Opción N/A */}
                        </select>
                        <label>Location:</label><input name="location" placeholder="location" value={isEditing ? selectedMonitor.location : newMonitor.location} onChange={handleInputChange} />
                        <label>Team:</label><input name="team" placeholder="team" value={isEditing ? selectedMonitor.team : newMonitor.team} onChange={handleInputChange} />

                        {duplicateError && <p className="error">{duplicateError}</p>} {/* Display duplicate error message */}
                        {priceError && <p className="error">{priceError}</p>} {/* Display price error message */}
                        {statusError && <p className="error">{statusError}</p>} {/* Display duplicate error message */}

                        <div className="button-group">
                            <button className="overlay-add-monitor" onClick={isEditing ? handleUpdateMonitor : handleAddMonitor} >
                                {isEditing ? 'Update Monitor' : 'Add Monitor'}
                            </button>
                            {isEditing && (
                                <button
                                    className="overlay-delet-monitor"
                                    onClick={() => {
                                        if (window.confirm('Are you sure you want to delete this monitor?')) {
                                            handleDeleteMonitor();
                                        }
                                    }}
                                >
                                    Delete monitor
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}







        </div>
    );
};

export default Monitor;
