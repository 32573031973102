import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import './Issue_Ticket_List.css';
import XLSX from 'xlsx-js-style';
import { FaFileExcel } from 'react-icons/fa';
import useFilteredData from '../../components/layout/useFilteredDate';
import { UPDATE_TICKET, DELETE_TICKET } from '../../graphql/mutations';
import { listTickets, ticketsByEmployee, listUsers } from '../../graphql/queries';
import SearchBar from '../../components/layout/SearchBar';
import { capitalize } from '../../components/common/capitalize';
import logo from '../../assets/images/BCH Logo Final.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import { useSearchParams } from "react-router-dom";

const Issue_Ticket_List = () => {

  const [issue_Ticket_List, setIssue_Ticket_List] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [overlayType, setOverlayType] = useState(null);
  const [showIssue_Ticket_ListAddOverlay, setShowIssue_Ticket_ListAddOverlay] = useState(false);
  const [showIssue_Ticket_Print, setShowIssue_Ticket_Print] = useState(false);
  const [selectedIssue_Ticket_List, setSelectedIssue_Ticket_List] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [sortConfig, setSortConfig] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [userGroupsState, setUserGroups] = useState([]);
  const { loading1, error: userError, data: userData } = useQuery(listUsers);
  const [session, setSession] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const isAdminOrManager = ['Admin', 'Manager'];
  const [notifyAdmin, setNotifyAdmin] = useState(false);
  const [dateCompletedError, setDateCompletedError] = useState('');


  const [newIssue_Ticket_List, setNewIssue_Ticket_List] = useState({
    id: '',
    type: '',
    name: '',
    location: '',
    time: '',
    date: '',
    dateTimeSupervisor: '',
    dateTimeAdmin: '',
    supervisor_name: '',
    admin_name: 'Juan Gonzalez',
    type_issue: '',
    program_name: '',
    equipment_name: '',
    equipment_tag: '',
    status: '',
    completed_date: '',
    request_item: '',
    quantity: '',
    tried_troubleshooting: true,
    priority: '',
    supervisor_approval: '',
    troubleshooting_problem1: '',
    troubleshooting_problem2: '',
    admin_troubleshooting_problem: '',
    troubleshooting_steps_taken1: '',
    troubleshooting_steps_taken2: '',
    admin_troubleshooting_steps_taken: '',
    troubleshooting_resolution1: '',
    troubleshooting_resolution2: '',
    admin_troubleshooting_resolution: '',
    date_needed: '',
    purchase_link: '',

  });

  const handlePrint = () => {
    const overlay = document.querySelector('.overlay_tickets');
    if (!overlay) {
      alert("No se encontró el elemento 'overlay_tickets' para imprimir.");
      return;
    }

    // Crear el iframe oculto para impresión
    const printIframe = document.createElement('iframe');
    printIframe.style.position = 'absolute';
    printIframe.style.width = '0';
    printIframe.style.height = '0';
    printIframe.style.border = 'none';
    document.body.appendChild(printIframe);

    // Espera a que el iframe cargue antes de imprimir
    printIframe.onload = () => {
      const iframeWindow = printIframe.contentWindow || printIframe;
      iframeWindow.focus();
      iframeWindow.print();

      // Elimina el iframe después de imprimir
      setTimeout(() => {
        printIframe.parentNode.removeChild(printIframe);
      }, 1000);
    };

    // Escribir el contenido del overlay en el iframe
    const iframeDoc = printIframe.contentWindow.document;
    iframeDoc.open();
    console.log(overlay.outerHTML)
    iframeDoc.write(`
     <html>
        <head>
          <title>Print Overlay</title>
          <style>
            ${document.querySelector('style') ? document.querySelector('style').innerHTML : ''}
            ${[...document.styleSheets]
        .map((sheet) => {
          try {
            return [...sheet.cssRules].map((rule) => rule.cssText).join('');
          } catch (e) {
            return '';
          }
        })
        .join('')}
            @media print {
             body {
                margin: 0;
                padding: 0;
                font-size: 12px;
                height: auto;
              }
              table {
                width: 100%;
                margin: 0 auto;
                table-layout: auto;
                border-collapse: collapse;
              }
              table th,
              table td {
                border: 1px solid #ddd;
                padding: 5px;
                text-align: center;
                word-wrap: break-word;
               } 
               table td:nth-child(8) {
                width:100px;
                max-width: 100px;
                min-width: 100px;
                word-wrap: break-word;
                text-overflow: ellipsis;
               }
                table td:nth-child(10) {
                width:100px;
                max-width: 100px;
                min-width: 100px;
               }
            }
          </style>
        </head>
        <body>
          ${overlay.outerHTML}
        </body>
      </html>
    `);
    iframeDoc.close();
  };


  const handleTicketAction = (issue_Ticket_List, action) => {
    console.log("Acción recibida:", action);
    console.log("Lista de tickets seleccionada:", issue_Ticket_List);

    if (action === 'edit') {
      console.log("Entrando en modo edición...");
      setOverlayType('edit'); // Configura el tipo de overlay como 'edit'
      setIsEditing(true); // Habilita el modo de edición
      setSelectedIssue_Ticket_List(issue_Ticket_List);
      setShowIssue_Ticket_ListAddOverlay(true);
    } else if (action === 'print') {
      console.log("Entrando en modo impresión...");
      setOverlayType('print');
      setIsEditing(true);
      setShowIssue_Ticket_ListAddOverlay(false);
      setSelectedIssue_Ticket_List(issue_Ticket_List);
      setShowIssue_Ticket_Print(true);
      console.log("overlayType:", overlayType);
      console.log("showIssue_Ticket_Print:", showIssue_Ticket_Print);
    }
  };

  const closePrintOverlay = () => {
    setShowIssue_Ticket_Print(false);
  };

  const user = React.useMemo(() => {
    return userData?.listUsers?.items || [];
  }, [userData]);

  // Función para buscar un email por nombre completo
  const findUserByFullName = (fullName, userList) => {
    // Separar el fullName en firstName y lastName
    const [firstName, ...lastNameParts] = fullName.split(" ");
    const lastName = lastNameParts.join(" "); // Unir posibles partes del apellido

    // Buscar el usuario en la lista
    const user = userList.find(
      (user) =>
        user.first_name.toLowerCase() === firstName.toLowerCase() &&
        user.last_name.toLowerCase() === lastName.toLowerCase()
    );

    return user ? user.thc_email_id : null; // Retorna solo el email o null
  };


  const handleSaveAsExcel = async () => {
    try {

      const backupToExport = filteredIssue_Ticket_Lists.length > 0 ? filteredIssue_Ticket_Lists : issue_Ticket_List;


      if (backupToExport.length > 0) {
        // Sort the issue_Ticket_Lists by Timestamp in ascending order (earliest first)
        const sortedissue_Ticket_List = [...backupToExport].sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp));

        // Prepare the data for Excel export with custom column titles
        const worksheetData = sortedissue_Ticket_List.map((issue_Ticket_List, index) => ({
          '#': index + 1, // Agregar el número de fila
          'type:': issue_Ticket_List.type,
          'Type Issue': issue_Ticket_List.type_issue,
          'Name:': issue_Ticket_List.name,
          'Location:': issue_Ticket_List.location,
          'Supervisor Name:': issue_Ticket_List.supervisor_name,
          'Status:': issue_Ticket_List.status,
          'Program Name:': issue_Ticket_List.program_name,
          'Equipment Name:': issue_Ticket_List.equipment_name,
          'Equipment TAG:': issue_Ticket_List.equipment_tag,
          'Request Item:': issue_Ticket_List.request_item,
          'Quantity:': issue_Ticket_List.quantity,
          'Tried Troubleshooting:': issue_Ticket_List.tried_troubleshooting ? 'Yes' : 'No',
          'Priority:': issue_Ticket_List.priority,
          'Supervisor Approval:': issue_Ticket_List.supervisor_approval ? 'Yes' : 'No',
          'Problem:': issue_Ticket_List.troubleshooting_problem1,
          'Steps Taken:': issue_Ticket_List.troubleshooting_steps_taken1,
          'Resolution:': issue_Ticket_List.troubleshooting_resolution1,


          // Add other fields as needed

        }));


        // Convert JSON to sheet starting from row 2
        const worksheet = XLSX.utils.json_to_sheet([]);
        let endCol = 0;

        XLSX.utils.sheet_add_aoa(worksheet, [['Tickets']], { origin: 'A1' });

        if (worksheetData.length > 0) {
          // Agrega los datos a partir de la fila A2
          XLSX.utils.sheet_add_json(worksheet, worksheetData, { origin: 'A2', skipHeader: false });

          // Define el rango de referencia (!ref)
          const endRow = worksheetData.length + 1; // +1 para el título en A1
          const endCol = Object.keys(worksheetData[0]).length - 1;
          worksheet['!ref'] = XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: endRow, c: endCol } });
        } else {
          console.error("No hay datos para exportar.");
          return;
        }

        // Merge title cells across the columns
        worksheet['!merges'] = worksheet['!merges'] || [];
        worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 17 } }); // A1 to H1 for the added column

        // Style the title row
        worksheet['A1'].s = {
          font: {
            bold: true,
            sz: 20, // Font size
            color: { rgb: '1F4E79' } // Dark blue text color
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center'
          }
        };

        // Apply styling for the header row (now starting at row 2)
        const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
        for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
          const cellAddress = XLSX.utils.encode_cell({ r: 1, c: col }); // Header row is now row 1
          if (!worksheet[cellAddress]) continue;
          worksheet[cellAddress].s = {
            fill: {
              fgColor: { rgb: '1F4E79' } // Dark blue background
            },
            font: {
              bold: true,
              color: { rgb: 'FFFFFF' } // White text
            },
            alignment: {
              horizontal: 'center'
            },
            border: {
              top: { style: 'thin', color: { rgb: '000000' } },
              left: { style: 'thin', color: { rgb: '000000' } },
              bottom: { style: 'thin', color: { rgb: '000000' } },
              right: { style: 'thin', color: { rgb: '000000' } },
            }
          };
        }

        // Apply alternating row colors and borders starting from row 3
        for (let row = 2; row <= headerRange.e.r; row++) {
          const isEvenRow = row % 2 === 0;
          for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
            if (!worksheet[cellAddress]) continue;

            worksheet[cellAddress].s = {
              fill: {
                fgColor: { rgb: isEvenRow ? 'D3D3D3' : 'FFFFFF' } // Light gray for even rows, white for odd rows
              },
              border: {
                top: { style: 'thin', color: { rgb: '000000' } },
                left: { style: 'thin', color: { rgb: '000000' } },
                bottom: { style: 'thin', color: { rgb: '000000' } },
                right: { style: 'thin', color: { rgb: '000000' } },
              },
              alignment: {
                horizontal: 'center'
              }
            };
          }
        }
        // Adjust columns to fit content
        worksheet['!cols'] = [
          { wch: 5 }, // Adjust width for each column
          { wch: 10 },
          { wch: 25 },
          { wch: 10 },
          { wch: 15 },
          { wch: 10 },
          { wch: 25 },
          { wch: 15 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
        ];
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'issue_Ticket_Lists');

        // Write file
        XLSX.writeFile(workbook, 'Ticket_ListsData.xlsx');
      } else {
        console.error('No data to export');
      }
    } catch (error) {
      console.error('Error exporting data to Excel:', error);
    }
  };
  const filteredIssue_Ticket_Lists = useFilteredData(issue_Ticket_List || [], searchTerm, sortConfig);

  const [removeTicket] = useMutation(DELETE_TICKET, {
    refetchQueries: [{ query: listTickets, variables: { limit: 100 } }]
  });
  const [updateTicket] = useMutation(UPDATE_TICKET, {
    refetchQueries: [{ query: listTickets, variables: { limit: 100 } }]
  });


  const userHasAccess = Array.isArray(userGroupsState) && userGroupsState.some(group => isAdminOrManager.includes(group));

  // Obtener sesión y detalles del usuario
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const sessionData = await fetchAuthSession();
        setSession(sessionData);

        const { signInDetails } = await getCurrentUser();
        const groups = sessionData?.tokens?.idToken?.payload['cognito:groups'] || [];
        setUserGroups(groups);

        const loginId = signInDetails?.loginId;
        if (!loginId) throw new Error('Invalid loginId format');

        const [firstName, lastNameWithDomain] = loginId.split('.');
        const lastName = lastNameWithDomain?.split('@')[0];

        const fullName = `${(firstName)} ${(lastName)}`;

        setUserDetails({
          firstName: capitalize(firstName),
          lastName: capitalize(lastName),
          fullName,
        });
      } catch (error) {
        console.error('User not authenticated', error);
      }
    };

    fetchUserData();
  }, []);

  // Detectar ticketId en la URL
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const ticketId = queryParams.get("ticketId");

    console.log("ticketId detected:", ticketId); // Para depurar

    if (ticketId) {
      const ticket = filteredIssue_Ticket_Lists.find(
        (t) => t.id === ticketId
      );
      if (ticket) {
        setSelectedIssue_Ticket_List(ticket);
        console.log("Opening overlay for ticket:", ticket);
        setOverlayType('edit');
        setShowIssue_Ticket_ListAddOverlay(true);  // Muestra el overlay
      }
    }
  }, [location.search, filteredIssue_Ticket_Lists, setSelectedIssue_Ticket_List, setShowIssue_Ticket_ListAddOverlay]);

  const employeeName = newIssue_Ticket_List?.name?.trim() || userDetails?.fullName || session?.tokens?.idToken?.payload['name'] || null;
  const skipQuery = !employeeName && !userHasAccess;

  // Llamada a useQuery 
  const { data, loading, error, fetchMore } = useQuery(userHasAccess ? listTickets : ticketsByEmployee, {
    variables: {
      limit: userHasAccess ? 1000 : 100,
      ...(userHasAccess ? {} : employeeName ? { name: employeeName } : { name: "default_fallback" })
    },
    skip: skipQuery
  });

  // Manejo de tickets para usuarios regulares
  useEffect(() => {
    const fetchAllIssue_Ticket_Lists = async () => {
      let AllIssue_Ticket_Lists = [];
      let currentNextToken = null;

      try {
        do {
          console.log("Fetching tickets for:", employeeName);

          const { data } = await fetchMore({
            variables: {
              limit: 100,
              name: employeeName,
              nextToken: currentNextToken
            },
          });

          console.log("Fetch More Response:", data);

          if (data?.ticketsByEmployee?.items) {
            console.log("Tickets List:", data.ticketsByEmployee.items);

            AllIssue_Ticket_Lists = [
              ...AllIssue_Ticket_Lists,
              ...data.ticketsByEmployee.items,
            ];
          }

          currentNextToken = data?.ticketsByEmployee?.nextToken;
        } while (currentNextToken);

        setIssue_Ticket_List(AllIssue_Ticket_Lists);
        setNextToken(currentNextToken);
      } catch (error) {
        console.error("Error fetching tickets:", error);
      }
    };

    if (data?.ticketsByEmployee) {
      fetchAllIssue_Ticket_Lists();
    }
  }, [data, fetchMore, employeeName]);

  //Manejo de Tickets para usuarios Admin y Manager
  useEffect(() => {
    if (userHasAccess) {
      const fetchAllTickets = async () => {
        let allTickets = [];
        let currentNextToken = null;

        console.log("Cargando todos los tickets para Admin...");

        try {
          do {
            const { data } = await fetchMore({
              variables: { limit: 1000, nextToken: currentNextToken }
            });

            if (data?.listTickets?.items) {
              console.log("Tickets obtenidos:", data.listTickets.items);

              allTickets = [...allTickets, ...data.listTickets.items];
            }

            currentNextToken = data?.listTickets?.nextToken;
            console.log("nextToken recibido:", currentNextToken);

          } while (currentNextToken);

          console.log("Todos los tickets cargados:", allTickets);
          setIssue_Ticket_List(allTickets);
        } catch (error) {
          console.error("Error al obtener tickets para Admin:", error);
        }
      };

      fetchAllTickets();
    }
  }, [userHasAccess, fetchMore]);

  const handleCheckboxChange = (e) => {
    setNotifyAdmin(e.target.checked);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setSelectedIssue_Ticket_List((prev) => ({
      ...prev,
      [name]: value,

    }));
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };


  // Lambda que enviará un correo al supervisor usando Amazon SES 
  const invokeLambdaFunction = async (emailData) => {
    const url = "https://buykqjzjjh.execute-api.us-east-2.amazonaws.com/compassdev/sendemail";

    try {
      // Obtener token de autenticación (si es necesario)
      const session = await fetchAuthSession();
      if (!session || !session.tokens || !session.tokens.idToken) {
        throw new Error("User is not authenticated or token is missing.");
      }
      const idToken = session.tokens.idToken.toString();

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailData),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to send email. Error: ${errorText}`);
      }

      const data = await response.json();
      console.log("Lambda function response:", data);
      alert("Notification email sent successfully.");
    } catch (error) {
      console.error("Error invoking Lambda:", error.message);
      alert("Failed to send notification email.");
    }
  };

  //Roles
  const isManager = userGroupsState?.some(group => group === 'Manager');
  const isAdmin = userGroupsState?.some(group => group === 'Admin');

  // Eliminar ticket
  const handleDeleteTicket = async (ticketId) => {
    // Confirmación antes de eliminar el ticket
    const isConfirmed = window.confirm("Are you sure you want to delete this ticket?");

    if (!isConfirmed) return; // Si el usuario cancela, no hace nada.

    if (!ticketId) {
      console.error("Ticket ID is missing. Cannot delete.");
      return;
    }
    try {
      await removeTicket({
        variables: { input: { id: ticketId } },
      });

      // Actualiza la lista de tickets después de eliminar
      setIssue_Ticket_List((prevTickets) => prevTickets.filter(ticket => ticket.id !== ticketId));
      // Resetear estados
      setShowIssue_Ticket_ListAddOverlay(false);
      setIsEditing(false);
      setSelectedIssue_Ticket_List(null);

    } catch (error) {
      console.error("Error deleting Ticket", error);
    }
  };

  const handleUpdateTicket = async () => {
    const formattedData_date = selectedIssue_Ticket_List.date
      ? new Date(selectedIssue_Ticket_List.date).toISOString().split('T')[0]
      : null;
    const formattedData_date_needed = selectedIssue_Ticket_List.date_needed
      ? new Date(selectedIssue_Ticket_List.date_needed).toISOString().split('T')[0]
      : null;
    const formattedData_completed_date = selectedIssue_Ticket_List.completed_date
      ? new Date(selectedIssue_Ticket_List.completed_date).toISOString().split('T')[0]
      : null;
    // Crear timestamp en formato AWSDateTime
    const timestampNow = new Date().toISOString();
    console.log("AWS DateTime:", timestampNow);

    try {
      // Validar si el estado es "completed" y requiere fecha de completado
      if (selectedIssue_Ticket_List.status === "completed" && !formattedData_completed_date) {
        setDateCompletedError("Completed date is required for tickets with status 'completed'.");
        alert("Please provide a valid completed date.");
        return; // Detener ejecución si falta la fecha
      }
      const { data } = await updateTicket({
        variables: {
          input: {
            id: selectedIssue_Ticket_List.id,
            type: selectedIssue_Ticket_List.type,
            name: selectedIssue_Ticket_List.name,
            location: selectedIssue_Ticket_List.location,
            time: selectedIssue_Ticket_List.time,
            date: formattedData_date,
            ...(isAdmin ? { dateTimeAdmin: timestampNow } : {}),
            ...(isManager ? { dateTimeSupervisor: timestampNow } : {}),
            supervisor_name: selectedIssue_Ticket_List.supervisor_name,
            admin_name: selectedIssue_Ticket_List.admin_name,
            type_issue: selectedIssue_Ticket_List.type_issue,
            program_name: selectedIssue_Ticket_List.program_name,
            equipment_name: selectedIssue_Ticket_List.equipment_name,
            equipment_tag: selectedIssue_Ticket_List.equipment_tag,
            status: selectedIssue_Ticket_List.status,
            completed_date: formattedData_completed_date,
            request_item: selectedIssue_Ticket_List.request_item,
            custom_request_item: selectedIssue_Ticket_List.custom_request_item,
            quantity: selectedIssue_Ticket_List.quantity,
            tried_troubleshooting: selectedIssue_Ticket_List.tried_troubleshooting,
            priority: selectedIssue_Ticket_List.priority,
            supervisor_approval: selectedIssue_Ticket_List.supervisor_approval,
            troubleshooting_problem1: selectedIssue_Ticket_List.troubleshooting_problem1,
            troubleshooting_problem2: selectedIssue_Ticket_List.troubleshooting_problem2,
            admin_troubleshooting_problem: selectedIssue_Ticket_List.admin_troubleshooting_problem,
            troubleshooting_steps_taken1: selectedIssue_Ticket_List.troubleshooting_steps_taken1,
            troubleshooting_steps_taken2: selectedIssue_Ticket_List.troubleshooting_steps_taken2,
            admin_troubleshooting_steps_taken: selectedIssue_Ticket_List.admin_troubleshooting_steps_taken,
            troubleshooting_resolution1: selectedIssue_Ticket_List.troubleshooting_resolution1,
            troubleshooting_resolution2: selectedIssue_Ticket_List.troubleshooting_resolution2,
            admin_troubleshooting_resolution: selectedIssue_Ticket_List.admin_troubleshooting_resolution,
            date_needed: formattedData_date_needed,
            purchase_link: selectedIssue_Ticket_List.purchase_link,
          }
        }
      });

      // Actualizar el estado local inmediatamente después de la actualización
      setIssue_Ticket_List((prevList) =>
        prevList.map((ticket) =>
          ticket.id === data.updateTicket.id ? data.updateTicket : ticket
        )
      );

      // Mostrar mensaje de éxito según el estado del ticket
      if (selectedIssue_Ticket_List.status === "completed") {
        alert("Ticket closed successfully!");
      } else {
        alert("Ticket updated successfully!");
      }

      setDateCompletedError(""); // Limpia errores previos
      closeOverlay(); // Cerrar overlay solo al éxito

      const base64Image = sessionStorage.getItem("temporaryImage");
      if (base64Image) {
        const imgElement = document.createElement("img");
        imgElement.src = `data:image/png;base64,${base64Image}`; // Cambiar el formato según sea necesario
        //document.body.appendChild(imgElement);
      }
      

      const ticketId = selectedIssue_Ticket_List.id;
      const displayTicketId = ticketId.toString(36).toUpperCase().slice(-6);
      let emailAdmin = "juan.gonzalez@healthcollaborative.net";

      if (selectedIssue_Ticket_List.type_issue === "software") {
        if (selectedIssue_Ticket_List.program_name === "Compass") {
          emailAdmin = "eulises.franco@healthcollaborative.net";
        } else if (selectedIssue_Ticket_List.program_name === "Heartland") {
          emailAdmin = "paulina.ponciano@healthcollaborative.net";
        }
      }

      // Si el checkbox está marcado, envía la notificación
      if (notifyAdmin) {

        const emailContent = {
          to: emailAdmin, // email del Admin
          subject: `New Ticket Created by ${selectedIssue_Ticket_List.supervisor_name}`,
          body: `
        <html>
          <body style="font-family: Arial, sans-serif; margin: 0; padding: 0;">
            <table width="100%" cellspacing="0" cellpadding="0" border="0">
              <tr>
                <td align="center">
                  <table width="600" cellspacing="0" cellpadding="0" border="0" bgcolor="#ffffff" style="border: 1px solid #ddd;">
                    <tr>
                      <td align="center" bgcolor="#1a2a6c" style="padding: 20px;">
                        <h1 style="color: white; margin: 0; font-size: 24px;">New Ticket Notification</h1>
                      </td>
                    </tr>
      
                    <tr>
                      <td style="padding: 30px;">
                        <p style="margin: 0 0 10px; font-size: 16px;">A ticket requires your attention. Please review the details below:</p>
                        
                        <table width="100%" border="1" cellpadding="10" cellspacing="0" style="border-collapse: collapse;">
                          <tr>
                            <th align="left" bgcolor="#1a2a6c" style="color: white;">Ticket ID</th>
                            <td>${displayTicketId}</td>
                          </tr>
                          <tr>
                            <th align="left" bgcolor="#1a2a6c" style="color: white;">Employee Name:</th>
                            <td>${selectedIssue_Ticket_List.name}</td>
                          </tr>
                          <tr>
                            <th align="left" bgcolor="#1a2a6c" style="color: white;">Location:</th>
                            <td>${selectedIssue_Ticket_List.location}</td>
                          </tr>
                          <tr>
                            <tr>
                            <th align="left" bgcolor="#1a2a6c" style="color: white;">Date:</th>
                            <td>${selectedIssue_Ticket_List.date}</td>
                          </tr>
                          <tr>
                            <th align="left" bgcolor="#1a2a6c" style="color: white;">Priority:</th>
                            <td>
                              ${selectedIssue_Ticket_List.priority === 1 ? 'Low Priority' :
              selectedIssue_Ticket_List.priority === 2 ? 'Medium Priority' :
                selectedIssue_Ticket_List.priority === 3 ? 'High Priority' :
                  'Unknown Priority'
            }
                            </td>
                          </tr>
                          <tr>
                            <th align="left" bgcolor="#1a2a6c" style="color: white;">Report Type:</th>
                            <td>${selectedIssue_Ticket_List.type}</td>
                          </tr>
                          ${selectedIssue_Ticket_List.type_issue ? `
                          <tr>
                            <th align="left" bgcolor="#1a2a6c" style="color: white;">Issue Type:</th>
                            <td>${capitalize(selectedIssue_Ticket_List.type_issue)}</td>
                          </tr>
                           ` : ''}
                            ${selectedIssue_Ticket_List.equipment_name ? `
                              <tr>
                                <th align="left" bgcolor="#1a2a6c" style="color: white;">Equipment:</th>
                                <td>${capitalize(selectedIssue_Ticket_List.equipment_name)}</td>
                              </tr>
                              ` : ''}
                              
                            ${selectedIssue_Ticket_List.program_name ? `
                              <tr>
                                <th align="left" bgcolor="#1a2a6c" style="color: white;">Software/WebSite:</th>
                                <td>${capitalize(selectedIssue_Ticket_List.program_name)}</td>
                              </tr>
                              ` : ''}
                                 ${selectedIssue_Ticket_List.request_item ? `
                              <tr>
                                <th align="left" bgcolor="#1a2a6c" style="color: white;">Request a Item:</th>
                                <td>${selectedIssue_Ticket_List.request_item}</td>
                              </tr>
                              ` : ''}
                                 ${selectedIssue_Ticket_List.status ? `
                              <tr>
                                <th align="left" bgcolor="#1a2a6c" style="color: white;">Status:</th>
                                <td>${capitalize(selectedIssue_Ticket_List.status)}</td>
                              </tr>
                              ` : ''}
                          
                        </table>
      
                        <p style="margin-top: 30px; font-size: 14px;">
                          Click the link below to update the ticket:
                        </p>
      
                        <table cellspacing="0" cellpadding="0" border="0" width="100%">
                          <tr>
                            <td align="center">
                              <a href="https://compasshealthcollaborative.org/issue-ticket-list?ticketId=${ticketId}"
                                 style="display: inline-block; padding: 12px 25px; font-size: 16px; color: white; background-color: #1a2a6c; text-decoration: none; border: 1px solid #007bff;">
                                Open Ticket #${displayTicketId}
                              </a>
                            </td>
                          </tr>
                        </table>
                          ${base64Image ? `
                             <p style="margin-top: 20px; font-size: 16px;">Attached Image:</p>
                <img src="data:image/png;base64,${base64Image}" alt="Error Photo" style="max-width: 100%; height: auto;"/>
           ` : ''}
                        <p style="margin-top: 40px; font-size: 12px; color: #666; text-align: center;">
                          This is an automated email. Please do not reply. 
                          &copy; 2025 Compass Health Collaborative. All Rights Reserved.
                        </p>
                  
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </body>
        </html>
        `,

        }
        console.log('Sending email to:', emailContent.to);
        await invokeLambdaFunction(emailContent);
        console.log("Notification sent to admin.");


      }

      // Notificación al empleado si el ticket está completo
      if (selectedIssue_Ticket_List.status === "completed" && selectedIssue_Ticket_List.completed_date) {
        const employeeEmail = findUserByFullName(selectedIssue_Ticket_List.name, user); // Suponiendo que tienes el correo del empleado en la lista
        if (employeeEmail) {
          console.log(`Employee Email: ${employeeEmail}`);
        } else {
          console.log("Employee not found.");
        }
        const emailContent = {
          to: employeeEmail,
          subject: `Ticket #${displayTicketId} Completed`,
          body: `
        <html>
    <body style="font-family: Arial, sans-serif; background-color: #f9f9f9; margin: 0; padding: 0;">
      <table width="100%" cellpadding="0" cellspacing="0" style="background-color: #f9f9f9; padding: 20px 0;">
        <tr>
          <td align="center">
            <table width="600" cellpadding="0" cellspacing="0" style="background-color: #ffffff; border-radius: 8px; box-shadow: 0 2px 4px rgba(0,0,0,0.1);">
              <tr>
                <td align="center" style="background-color:rgb(37, 59, 146); color: #ffffff; padding: 20px; border-top-left-radius: 8px; border-top-right-radius: 8px;">
                  <h1 style="margin: 0; font-size: 24px;">Ticket Completed</h1>
                </td>
              </tr>
              <tr>
                <td style="padding: 20px; color: #333333; line-height: 1.6;">
                  <p style="font-size: 16px; margin: 0 0 20px;">Dear <strong>${capitalize(selectedIssue_Ticket_List.name)}</strong>,</p>
                  <p style="font-size: 16px; margin: 0 0 20px;">We’re pleased to inform you that the ticket you worked on has been successfully completed on <strong>${formattedData_completed_date}</strong>.</p>
                  <p style="font-size: 16px; margin: 0 0 20px;">Your dedication and effort have been greatly appreciated. Thank you for contributing to the success of the team!</p>
                  <p style="font-size: 16px; margin: 0 0 20px;">If you have any further questions or need additional support, please don’t hesitate to reach out.</p>
                </td>
              </tr>
              <tr>
                <td align="center" style="padding: 20px;">
                  <a href="https://compasshealthcollaborative.org/issue-ticket-list?ticketId=${ticketId}" 
                     style="display: inline-block; padding: 12px 25px; font-size: 16px; color: #ffffff; background-color:rgb(37, 59, 146); text-decoration: none; border-radius: 4px;">
                    View Ticket
                  </a>
                </td>
              </tr>
              <tr>
                <td style="padding: 20px; font-size: 14px; color: #777777; text-align: center;">
                  <p style="margin: 0;">This is an automated email. Please do not reply.</p>
                  <p style="margin: 0;">&copy; 2025 Compass Health Collaborative. All Rights Reserved.</p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </body>
  </html>
  
        `
        };

        console.log("Sending completion notification to employee:", employeeEmail);
        await invokeLambdaFunction(emailContent);
        console.log("Notification sent to employee.");
      }

    } catch (error) {
      console.error("Error updating Ticket:", error);
      alert("Failed to update the ticket. Please try again.");
    }

    setNotifyAdmin('');
  };

  // Cerrar overlay
  const closeOverlay = () => {
    setOverlayType('edit')
    setShowIssue_Ticket_ListAddOverlay(false);
    setSelectedIssue_Ticket_List(null);
    setIsEditing(false);
  };

  const formatDate = (dateString) => {
    const options = { month: 'numeric', day: 'numeric', year: 'numeric' };
    const date = new Date(dateString);
    const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

    return localDate.toLocaleDateString(undefined, options);
  };

  const formattedTimestamp = (dateString) => {
    return new Date(dateString).toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true  // Cambiar a true para formato de 12 horas
    });
  };


  return (
    <div className="issue_Ticket_List-page" >
      <header className="inventory-header">
        <h1>Tickets Update</h1>
      </header>

      <div className="inventory-search">
        <button onClick={() => navigate('/issue-ticket')} >Issue Ticket</button>
        <button onClick={handleSaveAsExcel}> <FaFileExcel /> Export </button>
      </div>

      <div className="searchBar">

        <SearchBar searchTerm={searchTerm} onSearch={setSearchTerm} />
      </div>

      <div className="issue_Ticket_List-table-container">
        <table className="issue_Ticket_List-table">
          <thead>
            <tr>
              <th>#</th>
              <th>View</th>
              <th onClick={() => requestSort('type')}>
                Ticket Type
                {sortConfig?.key === 'type' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('type_issue')}>
                Issue Type
                {sortConfig?.key === 'type_issue' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('name')}>
                Employee Name
                {sortConfig?.key === 'name' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('location')}>
                Location
                {sortConfig?.key === 'location' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('time')}>
                Time
                {sortConfig?.key === 'time' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('date')}>
                Date Submitted
                {sortConfig?.key === 'date' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('equipment_name')}>
                Equipment Name
                {sortConfig?.key === 'equipment_name' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('equipment_tag')}>
                TAG
                {sortConfig?.key === 'equipment_tag' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('request_item')}>
                Request an item
                {sortConfig?.key === 'request_item' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('quantity')}>
                Quantity
                {sortConfig?.key === 'quantity' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('date_needed')}>
                Date Needed
                {sortConfig?.key === 'date_needed' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('program_name')}>
                Program Name
                {sortConfig?.key === 'program_name' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('priority')}>
                Priority
                {sortConfig?.key === 'priority' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('tried_troubleshooting')}>
                Tried Troubleshooting
                {sortConfig?.key === 'tried_troubleshooting' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('troubleshooting_problem1')}>
                Problem
                {sortConfig?.key === 'troubleshooting_problem1' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('troubleshooting_steps_taken1')}>
                Steps Taken
                {sortConfig?.key === 'troubleshooting_steps_taken1' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('troubleshooting_resolution1')}>
                Resolution
                {sortConfig?.key === 'troubleshooting_resolution1' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('createdAt')}>
                Creation Date 
                {sortConfig?.key === 'createdAt' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>

              <th onClick={() => requestSort('purchase_link')}>
                Suggested Purchase Link
                {sortConfig?.key === 'purchase_link' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('status')}>
                Status
                {sortConfig?.key === 'status' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('supervisor_name')}>
                Supervisor Name
                {sortConfig?.key === 'supervisor_name' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('supervisor_approval')}>
                Supervisor Approval
                {sortConfig?.key === 'supervisor_approval' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>

              {(isAdmin || isManager) && (
                <>
                  <th onClick={() => requestSort('troubleshooting_problem2')}>
                    Problem by Sup.
                    {sortConfig?.key === 'troubleshooting_problem2' && (
                      <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                    )}
                  </th>
                  <th onClick={() => requestSort('troubleshooting_steps_taken2')}>
                    Steps Taken by Sup.
                    {sortConfig?.key === 'troubleshooting_steps_taken2' && (
                      <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                    )}
                  </th>
                  <th onClick={() => requestSort('troubleshooting_resolution2')}>
                    Resolution by Sup.
                    {sortConfig?.key === 'troubleshooting_resolution2' && (
                      <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                    )}
                  </th>
                  <th onClick={() => requestSort('dateTimeSupervisor')}>
                    Supervisor Review Date
                    {sortConfig?.key === 'dateTimeSupervisor' && (
                      <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                    )}
                  </th>  </>
              )}
              {isAdmin && (
                <>
                  <th onClick={() => requestSort('admin_name')}>
                    Admin Name
                    {sortConfig?.key === 'admin_name' && (
                      <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                    )}
                  </th>
                  <th onClick={() => requestSort('admin_troubleshooting_problem')}>
                    Problem by Admin.
                    {sortConfig?.key === 'admin_troubleshooting_problem' && (
                      <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                    )}
                  </th>
                  <th onClick={() => requestSort('admin_troubleshooting_steps_taken')}>
                    Steps Taken by Admin.
                    {sortConfig?.key === 'admin_troubleshooting_steps_taken' && (
                      <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                    )}
                  </th>
                  <th onClick={() => requestSort('admin_troubleshooting_resolution')}>
                    Resolution by Admin.
                    {sortConfig?.key === 'admin_troubleshooting_resolution' && (
                      <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                    )}
                  </th>
                  <th onClick={() => requestSort('dateTimeAdmin')}>
                    Admin Review Date
                    {sortConfig?.key === 'dateTimeAdmin' && (
                      <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                    )}
                  </th>  </>
              )}
              <th onClick={() => requestSort('completed_date')}>
                Completed Date
                {sortConfig?.key === 'completed_date' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>

            </tr>
          </thead>
          <tbody>
            {filteredIssue_Ticket_Lists.map((issue_Ticket_List, index) => (
              <tr
                key={issue_Ticket_List.id}
                onClick={() => handleTicketAction(issue_Ticket_List, 'edit')}
              >
                <td>{index + 1}</td>
                <td>
                  <button className="print-ticket-btn" onClick={(e) => { e.stopPropagation(); handleTicketAction(issue_Ticket_List, 'print') }}>Print </button></td>
                <td className="type">{issue_Ticket_List.type}</td>
                <td className="type_issue">{issue_Ticket_List.type_issue || "-"}</td>
                <td className="name">{issue_Ticket_List.name}</td>
                <td className="location">{issue_Ticket_List.location}</td>
                <td className="time">{issue_Ticket_List.time}</td>
                <td className="date">{issue_Ticket_List.date ? formatDate(issue_Ticket_List.date) : ""}</td>
                <td className="equipment">{issue_Ticket_List.equipment_name}</td>
                <td className="tag">{issue_Ticket_List.equipment_tag}</td>
                <td className="request_item">{capitalize(issue_Ticket_List.request_item)}
                </td>
                <td className="quantity">{issue_Ticket_List.quantity}</td>
                <td className="date_needed">{issue_Ticket_List.date_needed ? formatDate(issue_Ticket_List.date_needed) : ""}</td>
                <td className="program_name">{issue_Ticket_List.program_name}</td>
                <td className="priority">{issue_Ticket_List.priority}</td>
                <td className="tried_troubleshooting">{issue_Ticket_List.tried_troubleshooting ? 'Yes' : 'No'}</td>
                <td className="problem">{issue_Ticket_List.troubleshooting_problem1}</td>
                <td className="steps_taken">{issue_Ticket_List.troubleshooting_steps_taken1}</td>
                <td className="resolution">{issue_Ticket_List.troubleshooting_resolution1}</td>
                <td className="createdAt">{issue_Ticket_List.createdAt ? new Date(issue_Ticket_List.createdAt).toLocaleString() : ''}</td>
                <td className="purchase_link">{issue_Ticket_List.purchase_link}</td>
                <td className="status">
                  {issue_Ticket_List.status === 'new' ? 'New' :
                    issue_Ticket_List.status === 'in_progress' ? 'In Progress' :
                      issue_Ticket_List.status === 'rejected' ? 'Rejected' :
                        issue_Ticket_List.status === 'completed' ? 'Completed' :
                          issue_Ticket_List.status === 'approved' ? 'Approved' :
                            ''} {/* Muestra 'Unknown' si el estado no coincide con ninguno de los anteriores */}
                </td>
                <td className="supervisor_name">{capitalize(issue_Ticket_List.supervisor_name)}</td>
                <td className="supervisor_approval">{issue_Ticket_List.supervisor_approval ? 'Yes' : 'No'}</td>

                {(isAdmin || isManager) && (
                  <>
                    <td className="problem2">{issue_Ticket_List.troubleshooting_problem2}</td>
                    <td className="steps_taken2">{issue_Ticket_List.troubleshooting_steps_taken2}</td>
                    <td className="resolution2">{issue_Ticket_List.troubleshooting_resolution2}</td>
                    <td className="datetimeSupervisor">{issue_Ticket_List.dateTimeSupervisor ? formattedTimestamp(issue_Ticket_List.dateTimeSupervisor) : ""}</td>
                  </>
                )}
                {isAdmin && (
                  <>
                    <td className="admin_name">{issue_Ticket_List.admin_name}</td>
                    <td className="problem3">{issue_Ticket_List.admin_troubleshooting_problem}</td>
                    <td className="steps_taken3">{issue_Ticket_List.admin_troubleshooting_steps_taken}</td>
                    <td className="resolution3">{issue_Ticket_List.admin_troubleshooting_resolution}</td>
                    <td className="dateAdmin">{issue_Ticket_List.dateTimeAdmin ? formattedTimestamp(issue_Ticket_List.dateTimeAdmin) : ""}</td>
                  </>
                )}
                <td className="completed_date">{issue_Ticket_List.completed_date ? formatDate(issue_Ticket_List.completed_date) : ""}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {
        overlayType === 'edit' && showIssue_Ticket_ListAddOverlay && (

          <div className="issue_Ticket_List_add_overlay" onClick={(e) => {
            if (e.target.classList.contains("issue_Ticket_List_add_overlay")) {
              closeOverlay();
            }
          }}>
            <div className="overlay-content-inventory">
              <div className="header-row">
                <h2 className="header-title">{'Update Issue Ticket'}</h2>
                <button onClick={closeOverlay}>X</button>
              </div>
              <label>Ticket Type:</label><input name="type" placeholder="Ticket Type" value={selectedIssue_Ticket_List.type} onChange={handleInputChange} required />
              <label>Type Issue:</label> <input name="type_issue" placeholder="Type Issue" value={selectedIssue_Ticket_List.type_issue} onChange={handleInputChange} required />
              <label>Name Employee:</label><input name="name" placeholder="Name Employee" value={selectedIssue_Ticket_List.name} onChange={handleInputChange} required />
              <label>Location:</label><input name="location" placeholder="Location" value={selectedIssue_Ticket_List.location} onChange={handleInputChange} required />
              <label>Time:</label><input name="time" placeholder="time" value={selectedIssue_Ticket_List.time} onChange={handleInputChange} />
              <label>Date:</label><input type="date" name="date" placeholder="date" value={selectedIssue_Ticket_List.date} onChange={handleInputChange} />
              <label>Supervisor Name:</label><input name="supervisor_name" placeholder="Supervisor Name" value={selectedIssue_Ticket_List.supervisor_name} onChange={handleInputChange} />
              <label>Equipment:</label><input name="equipment_name" placeholder="Equipment Name" value={selectedIssue_Ticket_List.equipment_name} onChange={handleInputChange} />
              <label>TAG:</label><input name="equipment_tag" placeholder="Equipment TAG" value={selectedIssue_Ticket_List.equipment_tag} onChange={handleInputChange} />
              <label>Program:</label><input name="program_name" placeholder="Program" value={selectedIssue_Ticket_List.program_name} onChange={handleInputChange} />
              <label>Request an Item:</label><input name="request_item" placeholder="Request an item" value={selectedIssue_Ticket_List.request_item} onChange={handleInputChange} />
              <label>Quantity:</label><input name="quantity" placeholder="Quantity" value={selectedIssue_Ticket_List.quantity} onChange={handleInputChange} />
              <label htmlFor="date-needed">Date Needed:</label>
              <input
                type="date"
                id="date-needed"
                name="date_needed"
                value={selectedIssue_Ticket_List?.date_needed || ""}
                onChange={handleInputChange}
              />
              <label>Priority:</label><input name="priority" placeholder="priority" value={selectedIssue_Ticket_List.priority} onChange={handleInputChange} />
              <label>Tried Troubleshooting:</label>
              <select
                name="tried_troubleshooting"
                value={selectedIssue_Ticket_List?.tried_troubleshooting || ""}
                onChange={handleInputChange}
                required
              >
                <option value="" disabled hidden className="placeholder-option">
                  Select
                </option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
              <label>Problem:</label><input name="troubleshooting_problem1" placeholder="Problem" value={selectedIssue_Ticket_List.troubleshooting_problem1} onChange={handleInputChange} />
              <label>Steps Taken:</label><input name="troubleshooting_steps_taken1" placeholder="Steps Taken" value={selectedIssue_Ticket_List.troubleshooting_steps_taken1} onChange={handleInputChange} />
              <label>Resolution:</label><input name="troubleshooting_resolution1" placeholder="Resolution" value={selectedIssue_Ticket_List.troubleshooting_resolution1} onChange={handleInputChange} />

              <label htmlFor="purchase-link">Suggested Purchase Link:</label>
              <input
                type="url"
                id="purchase-link"
                name="purchase_link"
                placeholder="Enter a purchase link (optional)"
                value={selectedIssue_Ticket_List?.purchase_link || ""}
                onChange={handleInputChange}
              />
              {selectedIssue_Ticket_List?.purchase_link && (
                <p>
                  Suggested Link:{" "}
                  <a
                    href={selectedIssue_Ticket_List.purchase_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {selectedIssue_Ticket_List.purchase_link}
                  </a>
                </p>
              )}

              {isManager && (
                <div>
                  <h3 className='warning'>Only filled out by authorized person</h3>
                  <label>Supervisor Approval:</label>
                  <select
                    name="supervisor_approval"
                    value={selectedIssue_Ticket_List?.supervisor_approval || ""}
                    onChange={handleInputChange}
                  >
                    <option value="" disabled hidden className="placeholder-option">
                      Select
                    </option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>

                  <label>Problem:</label>
                  <input
                    name="troubleshooting_problem2"
                    placeholder="Supervisor Problem"
                    value={selectedIssue_Ticket_List.troubleshooting_problem2}
                    onChange={handleInputChange}
                  />

                  <label>Steps Taken:</label>
                  <input
                    name="troubleshooting_steps_taken2"
                    placeholder="Supervisor Steps Taken"
                    value={selectedIssue_Ticket_List.troubleshooting_steps_taken2}
                    onChange={handleInputChange}
                  />

                  <label>Resolution:</label>
                  <input
                    name="troubleshooting_resolution2"
                    placeholder="Supervisor Resolution"
                    value={selectedIssue_Ticket_List.troubleshooting_resolution2}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="status">Status:</label>
                  <select
                    name="status"
                    id="status"
                    value={selectedIssue_Ticket_List.status || ""}
                    onChange={handleInputChange}
                    aria-label="Select ticket status"
                  >
                    <option value="" disabled hidden className="placeholder-option">Select Status</option>
                    <option value="new">New</option>
                    <option value="in_progress">In Progress</option>
                    <option value="approved">Approved</option>
                    <option value="rejected">Rejected</option>
                    <option value="completed">Completed</option>
                  </select>

                  {selectedIssue_Ticket_List.status === "approved" && (
                    <p style={{ color: "green" }}>Ticket approved. Awaiting completion.</p>
                  )}
                  {selectedIssue_Ticket_List.status === "rejected" && (
                    <p style={{ color: "red" }}>Ticket rejected. No further action required.</p>
                  )}

                  {selectedIssue_Ticket_List.status === "completed" && (
                    <div>
                      <label>Completed Date:</label>
                      <input
                        type="date"
                        name="completed_date"
                        placeholder="completed_date"
                        value={selectedIssue_Ticket_List.completed_date || ""}
                        onChange={handleInputChange}
                      />
                    </div>
                  )}
                  {/* Checkbox para enviar notificación */}
                  <div className="checkbox_notify">
                    <input
                      type="checkbox"
                      id="notifyAdmin"
                      name="notifyAdmin"
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="notifyAdmin" style={{ marginLeft: '8px' }}>
                      Send notification to Admin
                    </label>
                  </div>

                </div>
              )}

              {isAdmin && (
                <div>
                  <h3 className='warning'>Only filled out by authorized person</h3>
                  <label htmlFor="admin_name">Admin Name:</label>
                  <select
                    id="admin_name"
                    name="admin_name"
                    value={selectedIssue_Ticket_List.admin_name}
                    onChange={(e) => handleInputChange(e)}
                  >
                    <option value="" disabled hidden>Select a Name</option>
                    <option value="Anel Trevino">Anel Trevino</option>
                    <option value="Juan Gonzalez">Juan Gonzalez</option>
                    <option value="Paulina Porciano">Paulina Porciano</option>
                    <option value="Eulises Franco">Eulises Franco</option>
                    <option value="Ileana Avila">Ileana Avila</option>
                  </select>


                  <label>Problem:</label>
                  <input
                    name="admin_troubleshooting_problem"
                    placeholder="Admin Problem"
                    value={selectedIssue_Ticket_List.admin_troubleshooting_problem}
                    onChange={handleInputChange}
                  />

                  <label>Steps Taken:</label>
                  <input
                    name="admin_troubleshooting_steps_taken"
                    placeholder="Admin Steps Taken"
                    value={selectedIssue_Ticket_List.admin_troubleshooting_steps_taken}
                    onChange={handleInputChange}
                  />

                  <label>Resolution:</label>
                  <input
                    name="admin_troubleshooting_resolution"
                    placeholder="Admin Resolution"
                    value={selectedIssue_Ticket_List.admin_troubleshooting_resolution}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="status">Status:</label>
                  <select
                    name="status"
                    id="status"
                    value={selectedIssue_Ticket_List.status || ""}
                    onChange={handleInputChange}
                    aria-label="Select ticket status"
                  >
                    <option value="" disabled hidden className="placeholder-option">Select Status</option>
                    <option value="new">New</option>
                    <option value="in_progress">In Progress</option>
                    <option value="approved">Approved</option>
                    <option value="rejected">Rejected</option>
                    <option value="completed">Completed</option>
                  </select>
                  <label>Completed Date:</label><input type="date" name="completed_date" placeholder="completed_date" value={selectedIssue_Ticket_List.completed_date} onChange={handleInputChange} />
                </div>
              )}
              {dateCompletedError && <p className="error">{dateCompletedError}</p>} {/* Display dateError error message */}
              <div className="button-group">
                <button className="overlay-add-issue_Ticket_List" onClick={handleUpdateTicket}>
                  Update Issue Ticket
                </button>
                {isEditing && (
                  <button onClick={() => handleDeleteTicket(selectedIssue_Ticket_List.id)} className="overlay-delet-issue_Ticket_List">
                    Delete Issue Ticket
                  </button>
                )}
              </div>
            </div>
          </div>
        )
      }

      {
        overlayType === 'print' && showIssue_Ticket_Print && (
          <div className="overlay-print" onClick={(e) => {
            if (e.target.classList.contains("overlay-print")) {
              setShowIssue_Ticket_Print(false);
              setSelectedIssue_Ticket_List(null);
              setIsEditing(false);
            }
          }}>
            <div className="overlay_tickets">
              <h2>Ticket Details</h2>
              <div>
                <img src={logo} alt="Logo" className="logo" />
              </div>
              <table className="ticket-details">
                <tbody>
                  <tr><td><strong>Ticket ID:</strong></td><td>{(selectedIssue_Ticket_List.id).toString(36).toUpperCase().slice(-6) || '-'}</td></tr>
                  <tr><td><strong>Ticket Creation Date:</strong></td><td>{formattedTimestamp(selectedIssue_Ticket_List.createdAt)}</td></tr>
                  <tr><td><strong>Employee Name:</strong></td><td>{capitalize(selectedIssue_Ticket_List.name) || '-'}</td></tr>
                  <tr><td><strong>Location:</strong></td><td>{selectedIssue_Ticket_List?.location || '-'}</td></tr>
                  <tr><td><strong>Type:</strong></td><td>{capitalize(selectedIssue_Ticket_List.type) || '-'}</td></tr>
                  {selectedIssue_Ticket_List?.type_issue && (
                    <tr>
                      <td><strong>Type Issue:</strong></td>
                      <td>{capitalize(selectedIssue_Ticket_List.type_issue)}</td>
                    </tr>
                  )}
                   
                  {selectedIssue_Ticket_List?.equipment_name && (
                    <tr>
                      <td><strong>Equipment:</strong></td>
                      <td>{selectedIssue_Ticket_List.equipment_name}</td>
                    </tr>
                  )}

                  {selectedIssue_Ticket_List?.equipment_tag && (
                    <tr>
                      <td><strong>Tag:</strong></td>
                      <td>{selectedIssue_Ticket_List.equipment_tag}</td>
                    </tr>
                  )}

                  {selectedIssue_Ticket_List?.program_name && (
                    <tr>
                      <td><strong>Software/Website:</strong></td>
                      <td>{selectedIssue_Ticket_List.program_name}</td>
                    </tr>
                  )}
                  {selectedIssue_Ticket_List?.troubleshooting_problem1 && (
                    <tr>
                      <td><strong>Problem:</strong></td>
                      <td>{capitalize(selectedIssue_Ticket_List.troubleshooting_problem1)}</td>
                    </tr>
                  )}
                  {selectedIssue_Ticket_List?.troubleshooting_steps_taken1 && (
                    <tr>
                      <td><strong>Steps Taken:</strong></td>
                      <td>{capitalize(selectedIssue_Ticket_List.troubleshooting_steps_taken1)}</td>
                    </tr>
                  )}
                  {selectedIssue_Ticket_List?.troubleshooting_resolution1 && (
                    <tr>
                      <td><strong>Resolution:</strong></td>
                      <td>{capitalize(selectedIssue_Ticket_List.troubleshooting_resolution1)}</td>
                    </tr>
                  )}

                  <tr><td><strong>Priority:</strong></td><td>{selectedIssue_Ticket_List.priority === 1 ? 'Low Priority' :
                    selectedIssue_Ticket_List.priority === 2 ? 'Medium Priority' :
                      selectedIssue_Ticket_List.priority === 3 ? 'High Priority' :
                        'Unknown Priority'}</td></tr>
                  {selectedIssue_Ticket_List?.request_item && (
                    <tr>
                      <td><strong>Request Item:</strong></td>
                      <td>{selectedIssue_Ticket_List.request_item}</td>
                    </tr>
                  )}
                  <tr><td><strong>Quantity:</strong></td><td>{selectedIssue_Ticket_List?.quantity || '-'}</td></tr>
                  {selectedIssue_Ticket_List?.date_needed && (
                    <tr>
                      <td><strong>Date Needed:</strong></td>
                      <td>{formatDate(selectedIssue_Ticket_List.date_needed)}</td>
                    </tr>
                  )}
                  <tr><td><strong>Supervisor Name:</strong></td><td>{selectedIssue_Ticket_List?.supervisor_name || '-'}</td></tr>
                  <tr><td><strong>Supervisor Approval:</strong></td><td>{selectedIssue_Ticket_List?.supervisor_approval ? 'Yes' : 'No'}</td></tr>
                  <tr><td><strong>Status:</strong></td><td>{capitalize(selectedIssue_Ticket_List.status) || '-'}</td></tr>
                  {selectedIssue_Ticket_List?.completed_date && (
                    <tr>
                      <td><strong>Completed Date:</strong></td>
                      <td>{formatDate(selectedIssue_Ticket_List.completed_date)}</td>
                    </tr>
                  )}
                </tbody>
              </table>

              <div className="overlay-buttons">
                <button onClick={handlePrint}>Print</button>
                <button onClick={closePrintOverlay}>Close</button>
              </div>
            </div>
          </div>


        )
      }

    </div >


  );
























};

export default Issue_Ticket_List;
