/* eslint-disable */
// this is an auto generated file. This will be overwritten
import { gql } from '@apollo/client';


export const createEvent = gql`
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      EventID
      title
      start
      end
      attendees
      location
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const updateEvent = gql`
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      EventID
      title
      start
      end
      attendees
      location
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const deleteEvent = gql`
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      EventID
      title
      start
      end
      attendees
      location
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const CREATE_CLIENTFIX2 = gql`
  mutation CreateClientFix2($input: CreateClientFix2Input!) {
    createClientFix2(input: $input) {
      id
      name
      last_name
      email
      address
      city
      county
      zip_code
      prefered_lang
      insurance_picked
      last_contacted_date
      navigator
      payment_per_month
      phone
      plan
      callStatus 
    }
  }
`;

export const UPDATE_CLIENTFIX2 = gql`
  mutation updateClientFix2($input: UpdateClientFix2Input!) {
    updateClientFix2(input: $input) {
      id
      name
      last_name
      email
      address
      city
      county
      zip_code
      prefered_lang
      insurance_picked
      last_contacted_date
      navigator
      payment_per_month
      phone
      plan
      callStatus 
    }
  }
`;


export const DELETE_CLIENTFIX2 = gql`
  mutation DeleteClientFix2($input: DeleteClientFix2Input!) {
    deleteClientFix2(input: $input) {
      id
    }
  }
`;


export const UPDATE_CALL_STATUS = gql`
  mutation UpdateCallStatus($id: ID!, $callStatus: String!) {
    updateClientFix2(input: { id: $id, callStatus: $callStatus }) {
      id
      callStatus
    }
  }
`;

export const CREATE_OPT_OUT_CLIENT = gql`
  mutation CreateOptOutClient($input: CreateOptOutClientInput!) {
    createOptOutClient(input: $input) {
      id
      name
      last_name
      address
      city
      county
      zip_code
      prefered_lang
      email
      insurance_picked
      plan
      payment_per_month
      phone
      navigator
      optOutDate
      navigator
      callStatus
    }
  }
`;

export const DELETE_OPT_OUT_CLIENT = gql`
mutation DeleteOptOutClient($input: DeleteOptOutClientInput!) {
  deleteOptOutClient(input: $input) {
    id
  }
}
`;


export const createAppointment = gql`
  mutation CreateAppointment($input: CreateAppointmentInput!, $condition: ModelAppointmentConditionInput) {
    createAppointment(input: $input, condition: $condition) {
      id
      title
      customer
      clientEmail
      location
      date
      recurrence
      service
      navigator
      time
      duration
      notes
      ModifiedBy
      status
      reminder24Sent
      reminder30Sent
    }
  }
`;

export const updateAppointment = gql`
  mutation UpdateAppointment($input: UpdateAppointmentInput!, $condition: ModelAppointmentConditionInput) {
    updateAppointment(input: $input, condition: $condition) {
      id
      title
      customer
      clientEmail
      location
      date
      recurrence
      service
      navigator
      time
      duration
      notes
      ModifiedBy
      status
      reminder24Sent
      reminder30Sent
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const deleteAppointment = gql`
  mutation DeleteAppointment($input: DeleteAppointmentInput!, $condition: ModelAppointmentConditionInput) {
    deleteAppointment(input: $input, condition: $condition) {
      id
      title
      customer
      clientEmail
      location
      date
      recurrence
      service
      navigator
      time
      duration
      notes
      ModifiedBy
      status
      reminder24Sent
      reminder30Sent
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const CREATE_REPORT = gql`
  mutation CreateReport($input: CreateReportInput!) {
    createReport(input: $input) {
      id
      Timestamp
      Username
      Date
      Navigator
      outreachEvent
      year_contract
      flyers
      Number_FollowUp_Attempt_Contact_NoResponse
      MACtouches
      name
      lastName
      phone
      address
      city
      county
      email
      prefered_lang
      otherLanguage
      commitCard
      commitcardNo
      signature
      bestTimeToCall
      CommitCardNotes
      no_show
      ConsumerAuthOnFile
      employerCoverage
      cost_to_employee
      familyCovered
      amountFamCoverage
      NumPeopleHousehold
      NumPeopleApplying
      NumAdults
      NumChildren
      EmployeeTool
      IncomeType
      IncomeAmount
      PayFrequency
      coverageAppliedFor
      OtherCoverage
      New_Re_Enrollment
      clientEnroll
      QHP_Amount
      plans
      ClientCompleteMMC
      AppealQuestion
      RescheduleQuestion
      reschedule_reason
      otherRescheduleChange
      one2oneInquiry
      HealthInsuranceLiteracy
      Locating_Providers
      Billing_Payment_Questions
      EvaluateHealtcareOptionsUsingTools
      AccessingPreventativeHealthServices
      HealtcareGovAcc
      createdAccounts
      troubleshootedAccounts
      MarketplaceEligibility
      MarketPlanCompare
      qhp_enrollment_hcgov
      elctronic_qhp_enrollment_marketplaceCallCenter
      written_qhp_enrollment_hcgov
      medicaid_chip_app_ref
      complex_case_help_center
      data_matchingIssues
      data_matchingIssues_other
      SEP_eligibility
      SEP_eligibility_other
      Employer_sponsored_coverage_issues
      Employer_sponsored_coverage_issues_other
      APTC_CSR_assistance
      APTC_CSR_assistance_other
      Complex_Cases_Other
      Answering_questions_about
      Answering_questions_about_other
      Marketplace_tax_forms
      Filing_Marketplace
      Submitting_Marketplace
      Assisted_with_other_consumer_information
      pertinent_info_tracking
      hear_from_us
      event_hear_abt_us
      facebook_hear_abt_us
      insta_hear_abt_us
      SAC_hear_abt_us
      twitter_hear_abt_us
      tv_hear_abt_us
      hospital_hear_abt_us
      library_opt
      workforce_hear_abt_us
      tablepresentation_hear_abt_us
      referral_hear_abt_us
      newspaper_hear_abt_us
      radio_hear_abt_us
      flyer_hear_abt_us
      county2
      other_county
      zip_code
      finalNotes
      plan_ClientName_0
      plan_ClientLastName_0
      Insurer_0
      PlanMetal_0
      PriceofPlan_0
      APCT_Subsidy_0
      DentalConfirm0
      Dentalplan_ClientName_0
      Dentalplan_ClientLastName_0
      Dentalplan_Name_0
      Dentalplan_Cost_0
      plan_ClientName_1
      plan_ClientLastName_1
      Insurer_1
      PlanMetal_1
      PriceofPlan_1
      APCT_Subsidy_1
      DentalConfirm1
      Dentalplan_ClientName_1
      Dentalplan_ClientLastName_1
      Dentalplan_Name_1
      Dentalplan_Cost_1
      plan_ClientName_2
      plan_ClientLastName_2
      Insurer_2
      PlanMetal_2
      PriceofPlan_2
      APCT_Subsidy_2
      DentalConfirm2
      Dentalplan_ClientName_2
      Dentalplan_ClientLastName_2
      Dentalplan_Name_2
      Dentalplan_Cost_2
      plan_ClientName_3
      plan_ClientLastName_3
      Insurer_3
      PlanMetal_3
      PriceofPlan_3
      APCT_Subsidy_3
      DentalConfirm3
      Dentalplan_ClientName_3
      Dentalplan_ClientLastName_3
      Dentalplan_Name_3
      Dentalplan_Cost_3
      plan_ClientName_4
      plan_ClientLastName_4
      Insurer_4
      PlanMetal_4
      PriceofPlan_4
      APCT_Subsidy_4
      DentalConfirm4
      Dentalplan_ClientName_4
      Dentalplan_ClientLastName_4
      Dentalplan_Name_4
      Dentalplan_Cost_4
      plan_ClientName_5
      plan_ClientLastName_5
      Insurer_5
      PlanMetal_5
      PriceofPlan_5
      APCT_Subsidy_5
      DentalConfirm5
      Dentalplan_ClientName_5
      Dentalplan_ClientLastName_5
      Dentalplan_Name_5
      Dentalplan_Cost_5
      plan_ClientName_6
      plan_ClientLastName_6
      Insurer_6
      PlanMetal_6
      PriceofPlan_6
      APCT_Subsidy_6
      DentalConfirm6
      Dentalplan_ClientName_6
      Dentalplan_ClientLastName_6
      Dentalplan_Name_6
      Dentalplan_Cost_6
      plan_ClientName_7
      plan_ClientLastName_7
      Insurer_7
      PlanMetal_7
      PriceofPlan_7
      APCT_Subsidy_7
      DentalConfirm7
      Dentalplan_ClientName_7
      Dentalplan_ClientLastName_7
      Dentalplan_Name_7
      Dentalplan_Cost_7
      plan_ClientName_8
      plan_ClientLastName_8
      Insurer_8
      PlanMetal_8
      PriceofPlan_8
      APCT_Subsidy_8
      DentalConfirm8
      Dentalplan_ClientName_8
      Dentalplan_ClientLastName_8
      Dentalplan_Name_8
      Dentalplan_Cost_8
      plan_ClientName_9
      plan_ClientLastName_9
      Insurer_9
      PlanMetal_9
      PriceofPlan_9
      APCT_Subsidy_9
      DentalConfirm9
      Dentalplan_ClientName_9
      Dentalplan_ClientLastName_9
      Dentalplan_Name_9
      Dentalplan_Cost_9
      plan_ClientName_10
      plan_ClientLastName_10
      Insurer_10
      PlanMetal_10
      PriceofPlan_10
      APCT_Subsidy_10
      DentalConfirm10
      Dentalplan_ClientName_10
      Dentalplan_ClientLastName_10
      Dentalplan_Name_10
      Dentalplan_Cost_10
      plan_ClientName_11
      plan_ClientLastName_11
      Insurer_11
      PlanMetal_11
      PriceofPlan_11
      APCT_Subsidy_11
      DentalConfirm11
      Dentalplan_ClientName_11
      Dentalplan_ClientLastName_11
      Dentalplan_Name_11
      Dentalplan_Cost_11
      plan_ClientName_12
      plan_ClientLastName_12
      Insurer_12
      PlanMetal_12
      PriceofPlan_12
      APCT_Subsidy_12
      DentalConfirm12
      Dentalplan_ClientName_12
      Dentalplan_ClientLastName_12
      Dentalplan_Name_12
      Dentalplan_Cost_12
      plan_ClientName_13
      plan_ClientLastName_13
      Insurer_13
      PlanMetal_13
      PriceofPlan_13
      APCT_Subsidy_13
      DentalConfirm13
      Dentalplan_ClientName_13
      Dentalplan_ClientLastName_13
      Dentalplan_Name_13
      Dentalplan_Cost_13
      plan_ClientName_14
      plan_ClientLastName_14
      Insurer_14
      PlanMetal_14
      PriceofPlan_14
      APCT_Subsidy_14
      DentalConfirm14
      Dentalplan_ClientName_14
      Dentalplan_ClientLastName_14
      Dentalplan_Name_14
      Dentalplan_Cost_14
    }
  }
`;

export const UPDATE_REPORT = gql`
  mutation UpdateReport($input: UpdateReportInput!) {
    updateReport(input: $input) {
      id
      Timestamp
      Username
      Date
      Navigator
      outreachEvent
      year_contract
      flyers
      Number_FollowUp_Attempt_Contact_NoResponse
      MACtouches
      name
      lastName
      phone
      address
      city
      county
      email
      prefered_lang
      otherLanguage
      commitCard
      commitcardNo
      signature
      bestTimeToCall
      CommitCardNotes
      no_show
      ConsumerAuthOnFile
      employerCoverage
      cost_to_employee
      familyCovered
      amountFamCoverage
      NumPeopleHousehold
      NumPeopleApplying
      NumAdults
      NumChildren
      EmployeeTool
      IncomeType
      IncomeAmount
      PayFrequency
      coverageAppliedFor
      OtherCoverage
      New_Re_Enrollment
      clientEnroll
      QHP_Amount
      plans
      ClientCompleteMMC
      AppealQuestion
      RescheduleQuestion
      reschedule_reason
      otherRescheduleChange
      one2oneInquiry
      HealthInsuranceLiteracy
      Locating_Providers
      Billing_Payment_Questions
      EvaluateHealtcareOptionsUsingTools
      AccessingPreventativeHealthServices
      HealtcareGovAcc
      createdAccounts
      troubleshootedAccounts
      MarketplaceEligibility
      MarketPlanCompare
      qhp_enrollment_hcgov
      elctronic_qhp_enrollment_marketplaceCallCenter
      written_qhp_enrollment_hcgov
      medicaid_chip_app_ref
      complex_case_help_center
      data_matchingIssues
      data_matchingIssues_other
      SEP_eligibility
      SEP_eligibility_other
      Employer_sponsored_coverage_issues
      Employer_sponsored_coverage_issues_other
      APTC_CSR_assistance
      APTC_CSR_assistance_other
      Complex_Cases_Other
      Answering_questions_about
      Answering_questions_about_other
      Marketplace_tax_forms
      Filing_Marketplace
      Submitting_Marketplace
      Assisted_with_other_consumer_information
      pertinent_info_tracking
      hear_from_us
      event_hear_abt_us
      facebook_hear_abt_us
      insta_hear_abt_us
      SAC_hear_abt_us
      twitter_hear_abt_us
      tv_hear_abt_us
      hospital_hear_abt_us
      library_opt
      workforce_hear_abt_us
      tablepresentation_hear_abt_us
      referral_hear_abt_us
      newspaper_hear_abt_us
      radio_hear_abt_us
      flyer_hear_abt_us
      county2
      other_county
      zip_code
      finalNotes
      plan_ClientName_0
      plan_ClientLastName_0
      Insurer_0
      PlanMetal_0
      PriceofPlan_0
      APCT_Subsidy_0
      DentalConfirm0
      Dentalplan_ClientName_0
      Dentalplan_ClientLastName_0
      Dentalplan_Name_0
      Dentalplan_Cost_0
      plan_ClientName_1
      plan_ClientLastName_1
      Insurer_1
      PlanMetal_1
      PriceofPlan_1
      APCT_Subsidy_1
      DentalConfirm1
      Dentalplan_ClientName_1
      Dentalplan_ClientLastName_1
      Dentalplan_Name_1
      Dentalplan_Cost_1
      plan_ClientName_2
      plan_ClientLastName_2
      Insurer_2
      PlanMetal_2
      PriceofPlan_2
      APCT_Subsidy_2
      DentalConfirm2
      Dentalplan_ClientName_2
      Dentalplan_ClientLastName_2
      Dentalplan_Name_2
      Dentalplan_Cost_2
      plan_ClientName_3
      plan_ClientLastName_3
      Insurer_3
      PlanMetal_3
      PriceofPlan_3
      APCT_Subsidy_3
      DentalConfirm3
      Dentalplan_ClientName_3
      Dentalplan_ClientLastName_3
      Dentalplan_Name_3
      Dentalplan_Cost_3
      plan_ClientName_4
      plan_ClientLastName_4
      Insurer_4
      PlanMetal_4
      PriceofPlan_4
      APCT_Subsidy_4
      DentalConfirm4
      Dentalplan_ClientName_4
      Dentalplan_ClientLastName_4
      Dentalplan_Name_4
      Dentalplan_Cost_4
      plan_ClientName_5
      plan_ClientLastName_5
      Insurer_5
      PlanMetal_5
      PriceofPlan_5
      APCT_Subsidy_5
      DentalConfirm5
      Dentalplan_ClientName_5
      Dentalplan_ClientLastName_5
      Dentalplan_Name_5
      Dentalplan_Cost_5
      plan_ClientName_6
      plan_ClientLastName_6
      Insurer_6
      PlanMetal_6
      PriceofPlan_6
      APCT_Subsidy_6
      DentalConfirm6
      Dentalplan_ClientName_6
      Dentalplan_ClientLastName_6
      Dentalplan_Name_6
      Dentalplan_Cost_6
      plan_ClientName_7
      plan_ClientLastName_7
      Insurer_7
      PlanMetal_7
      PriceofPlan_7
      APCT_Subsidy_7
      DentalConfirm7
      Dentalplan_ClientName_7
      Dentalplan_ClientLastName_7
      Dentalplan_Name_7
      Dentalplan_Cost_7
      plan_ClientName_8
      plan_ClientLastName_8
      Insurer_8
      PlanMetal_8
      PriceofPlan_8
      APCT_Subsidy_8
      DentalConfirm8
      Dentalplan_ClientName_8
      Dentalplan_ClientLastName_8
      Dentalplan_Name_8
      Dentalplan_Cost_8
      plan_ClientName_9
      plan_ClientLastName_9
      Insurer_9
      PlanMetal_9
      PriceofPlan_9
      APCT_Subsidy_9
      DentalConfirm9
      Dentalplan_ClientName_9
      Dentalplan_ClientLastName_9
      Dentalplan_Name_9
      Dentalplan_Cost_9
      plan_ClientName_10
      plan_ClientLastName_10
      Insurer_10
      PlanMetal_10
      PriceofPlan_10
      APCT_Subsidy_10
      DentalConfirm10
      Dentalplan_ClientName_10
      Dentalplan_ClientLastName_10
      Dentalplan_Name_10
      Dentalplan_Cost_10
      plan_ClientName_11
      plan_ClientLastName_11
      Insurer_11
      PlanMetal_11
      PriceofPlan_11
      APCT_Subsidy_11
      DentalConfirm11
      Dentalplan_ClientName_11
      Dentalplan_ClientLastName_11
      Dentalplan_Name_11
      Dentalplan_Cost_11
      plan_ClientName_12
      plan_ClientLastName_12
      Insurer_12
      PlanMetal_12
      PriceofPlan_12
      APCT_Subsidy_12
      DentalConfirm12
      Dentalplan_ClientName_12
      Dentalplan_ClientLastName_12
      Dentalplan_Name_12
      Dentalplan_Cost_12
      plan_ClientName_13
      plan_ClientLastName_13
      Insurer_13
      PlanMetal_13
      PriceofPlan_13
      APCT_Subsidy_13
      DentalConfirm13
      Dentalplan_ClientName_13
      Dentalplan_ClientLastName_13
      Dentalplan_Name_13
      Dentalplan_Cost_13
      plan_ClientName_14
      plan_ClientLastName_14
      Insurer_14
      PlanMetal_14
      PriceofPlan_14
      APCT_Subsidy_14
      DentalConfirm14
      Dentalplan_ClientName_14
      Dentalplan_ClientLastName_14
      Dentalplan_Name_14
      Dentalplan_Cost_14
    }
  }
`;

export const DELETE_REPORT = gql`
  mutation DeleteReport($input: DeleteReportInput!) {
    deleteReport(input: $input) {
      id
    }
  }
`;


export const createMouse = gql`
  mutation CreateMouse(
    $input: CreateMouseInput!
    $condition: ModelMouseConditionInput
  ) {
    createMouse(input: $input, condition: $condition) {
      id
      tagId
      model
      brand
      serial_Number
      wireless
      usb
      combo
      price
      store
      purchase_date
      assignment_date
      warranty_date
      returnable_date
      available
      status
      assigned_to
      location
      team
    }
  }
`;

export const updateMouse = gql`
  mutation UpdateMouse(
    $input: UpdateMouseInput!
    $condition: ModelMouseConditionInput
  ) {
    updateMouse(input: $input, condition: $condition) {
    id
    tagId
    model
    brand
    serial_Number
    wireless
    usb
    combo
    price
    store
    purchase_date
    assignment_date
    warranty_date
    returnable_date
    available
    status
    assigned_to
    location
    team
    }
  }
`;
export const deleteMouse = gql`
  mutation DeleteMouse($input: DeleteMouseInput!, $condition: ModelMouseConditionInput) {
    deleteMouse(input: $input, condition: $condition) 
    {
    id
    tagId
    model
    brand
    serial_Number
    wireless
    usb
    combo
    price
    store
    purchase_date
    assignment_date
    warranty_date
    returnable_date
    available
    status
    assigned_to
    location
    team
    }
  }
`;
export const createUser = gql`
  mutation CreateUser ($input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser (input: $input, condition: $condition) {
       id
       ThreeCX_id
       ThreeCX_password
       first_name
       last_name
       thc_email_id
       thc_email_password
       gmail_email_id
       gmail_email_password
       apple_id
       apple_password
       phone_number
       navigator_id
       team
       location
       notes
    }
  }
`;

export const updateUser = gql`
  mutation UpdateUser($input: UpdateUserInput! $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
       id
       ThreeCX_id
       ThreeCX_password
       first_name
       last_name
       thc_email_id
       thc_email_password
       gmail_email_id
       gmail_email_password
       apple_id
       apple_password
       phone_number
       navigator_id
       team
       location
       notes
       
    }
  }
`;
export const deleteUser = gql`
  mutation DeleteUser($input: DeleteUserInput!, $condition: ModelUserConditionInput) {
    deleteUser(input: $input, condition: $condition) 
    {
       id
       ThreeCX_id
       ThreeCX_password
       first_name
       last_name
       thc_email_id
       thc_email_password
       gmail_email_id
       gmail_email_password
       apple_id
       apple_password
       phone_number
       navigator_id
       team
       location
       notes
    }
  }
`;

export const createKeyboard = gql`
  mutation CreateKeyboard($input: CreateKeyboardInput!, $condition: ModelKeyboardConditionInput) {
    createKeyboard(input: $input, condition: $condition) {
      id
      tagid
      model 
      brand
      serial_Number
      wireless
      usb
      combo
      price
      store
      purchase_date
      assignment_date
      warranty_date
      returnable_date
      available
      status
      assigned_to
      location
      team
    }
  }
`;

export const updateKeyboard = gql`
  mutation UpdateKeyboard($input: UpdateKeyboardInput!, $condition: ModelKeyboardConditionInput) {
    updateKeyboard(input: $input, condition: $condition) {
      id
      tagid
      model 
      brand
      serial_Number
      wireless
      usb
      combo
      price
      store
      purchase_date
      assignment_date
      warranty_date
      returnable_date
      available
      status
      assigned_to
      location
      team
    }
  }
`;

export const deleteKeyboard = gql`
  mutation DeleteKeyboard($input: DeleteKeyboardInput!, $condition: ModelKeyboardConditionInput) {
    deleteKeyboard(input: $input, condition: $condition) {
      id
      tagid
      model 
      brand
      serial_Number
      wireless
      usb
      combo
      price
      store
      purchase_date
      assignment_date
      warranty_date
      returnable_date
      available
      status
      assigned_to
      location
      team
    }
  }
`;

export const createMonitor = gql`
  mutation CreateMonitor($input: CreateMonitorInput!, $condition: ModelMonitorConditionInput) {
    createMonitor(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  inches
  serial_Number
  price
  store
  purchase_date
  charger_cable
  monitor_type
  Connector_cable
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  
    }
  }
`;
export const updateMonitor = gql`
  mutation UpdateMonitor($input: UpdateMonitorInput!, $condition: ModelMonitorConditionInput) {
    updateMonitor(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  inches
  serial_Number
  price
  store
  purchase_date
  charger_cable
  monitor_type
  Connector_cable
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
    }
  }
`;

export const deleteMonitor = gql`
  mutation DeleteMonitor($input: DeleteMonitorInput!, $condition: ModelMonitorConditionInput) {
    deleteMonitor(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  inches
  serial_Number
  price
  store
  purchase_date
  charger_cable
  monitor_type
  Connector_cable
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
    }
  }
`;

export const createScanner = gql`
  mutation CreateScanner($input: CreateScannerInput!, $condition: ModelScannerConditionInput) {
    createScanner(input: $input, condition: $condition) {
id
tagid
model
model_number
brand
serial_Number
price
store
purchase_date
charger_cable
scanner_type
Connector_cable
wireless
assignment_date
warranty_date
returnable_date
available
status
assigned_to
location
team
  
    }
  }
`;
export const updateScanner = gql`
  mutation UpdateScanner($input: UpdateScannerInput!, $condition: ModelScannerConditionInput) {
    updateScanner(input: $input, condition: $condition) {
  id
  tagid
  model
  model_number
  brand
  serial_Number
  price
  store
  purchase_date
  charger_cable
  scanner_type
  Connector_cable
  wireless
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
    }
  }
`;
export const deleteScanner = gql`
  mutation DeleteScanner($input: DeleteScannerInput!, $condition: ModelScannerConditionInput) {
    deleteScanner(input: $input, condition: $condition) {
  id
  tagid
  model
  model_number
  brand
  serial_Number
  price
  store
  purchase_date
  charger_cable
  scanner_type
  Connector_cable
  wireless
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
    }
  }
`;

export const createPrinter = gql`
  mutation CreatePrinter($input: CreatePrinterInput!, $condition: ModelPrinterConditionInput) {
    createPrinter(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  charger_cable
  Connector_cable
  wireless
  black_ink
  color_ink
  black_refill_dates
  color_refill_dates
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
    }
  }
`;
export const updatePrinter = gql`
  mutation UpdatePrinter($input: UpdatePrinterInput!, $condition: ModelPrinterConditionInput) {
    updatePrinter(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  charger_cable
  Connector_cable
  wireless
  black_ink
  color_ink
  black_refill_dates
  color_refill_dates
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
    }
  }
`;

export const deletePrinter = gql`
  mutation DeletePrinter($input: DeletePrinterInput!, $condition: ModelPrinterConditionInput) {
    deletePrinter(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  charger_cable
  Connector_cable
  wireless
  black_ink
  color_ink
  black_refill_dates
  color_refill_dates
  assignment_date
  warranty_date
 returnable_date
  available
  status
  assigned_to
  location
  team
    }
  }
`;
export const createExtensionCord = gql`
  mutation CreateExtensionCord($input: CreateExtensionCordInput!, $condition: ModelExtensionCordConditionInput) {
    createExtensionCord(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
    }
  }
`;
export const updateExtensionCord = gql`
  mutation UpdateExtensionCord($input: UpdateExtensionCordInput!, $condition: ModelExtensionCordConditionInput) {
    updateExtensionCord(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
    }
  }
`;
export const deleteExtensionCord = gql`
  mutation DeleteExtensionCord($input: DeleteExtensionCordInput!, $condition: ModelExtensionCordConditionInput) {
    deleteExtensionCord(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
    }
  }
`;

export const createPhone = gql`
  mutation CreatePhone($input: CreatePhoneInput!, $condition: ModelPhoneConditionInput) {
    createPhone(input: $input, condition: $condition) {
   id
   tagid
   model
   brand
   serial_Number
   price
   store
   purchase_date
  cel_number
  password
  charger_cable
  EID
  imei
  ICCID
  pin
  apple_id
  apple_password
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  Description
  location
  team
    }
  }
`;
export const updatePhone = gql`
  mutation UpdatePhone($input: UpdatePhoneInput!, $condition: ModelPhoneConditionInput) {
    updatePhone(input: $input, condition: $condition) {
      id
   tagid
   model
   brand
   serial_Number
   price
   store
   purchase_date
  cel_number
  password
  charger_cable
  EID
  imei
  ICCID
  pin
  apple_id
  apple_password
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  Description
  location
  team
    }
  }
`;
export const deletePhone = gql`
  mutation DeletePhone($input: DeletePhoneInput!, $condition: ModelPhoneConditionInput) {
    deletePhone(input: $input, condition: $condition) {
   id
   tagid
   model
   brand
   serial_Number
   price
   store
   purchase_date
  cel_number
  password
  charger_cable
  EID
  imei
  ICCID
  pin
  apple_id
  apple_password
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  Description
  location
  team
    }
  }
`;

export const createBackupBattery = gql`
  mutation CreateBackupBattery($input: CreateBackupBatteryInput!, $condition: ModelBackupBatteryConditionInput) {
    createBackupBattery(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team

    }
  }
`;
export const updateBackupBattery = gql`
  mutation UpdateBackupBattery($input: UpdateBackupBatteryInput!, $condition: ModelBackupBatteryConditionInput) {
    updateBackupBattery(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team

    }
  }
`;
export const deleteBackupBattery = gql`
  mutation DeleteBackupBattery($input: DeleteBackupBatteryInput!, $condition: ModelBackupBatteryConditionInput) {
    deleteBackupBattery(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  
    }
  }
`;

export const createPowerBank = gql`
  mutation CreatePowerBank($input: CreatePowerBankInput!, $condition: ModelPowerBankConditionInput) {
    createPowerBank(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team

    }
  }
`;
export const updatePowerBank = gql`
  mutation UpdatePowerBank($input: UpdatePowerBankInput!, $condition: ModelPowerBankConditionInput) {
    updatePowerBank(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team

    }
  }
`;

export const deletePowerBank = gql`
  mutation DeletePowerBank($input: DeletePowerBankInput!, $condition: ModelPowerBankConditionInput) {
    deletePowerBank(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  
    }
  }
`;
export const createUSB = gql`
  mutation CreateUSB($input: CreateUSBInput!, $condition: ModelUSBConditionInput) {
    createUSB(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  storage
  descripcion
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team

    }
  }
`;
export const updateUSB = gql`
  mutation UpdateUSB($input: UpdateUSBInput!, $condition: ModelUSBConditionInput) {
    updateUSB(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  storage
  descripcion
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team

    }
  }
`;
export const deleteUSB = gql`
  mutation DeleteUSB($input: DeleteUSBInput!, $condition: ModelUSBConditionInput) {
    deleteUSB(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  storage
  descripcion
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  
    }
  }
`;

export const createDockingStation = gql`
  mutation CreateDockingStation($input: CreateDockingStationInput!, $condition: ModelDockingStationConditionInput) {
    createDockingStation(input: $input, condition: $condition) {
   id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  mac_compatible
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
   team

    }
  }
`;

export const updateDockingStation = gql`
  mutation UpdateDockingStation($input: UpdateDockingStationInput!, $condition: ModelDockingStationConditionInput) {
    updateDockingStation(input: $input, condition: $condition) {
   id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  mac_compatible
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
   team

    }
  }
`;

export const deleteDockingStation = gql`
  mutation DeleteDockingStation($input: DeleteDockingStationInput!, $condition: ModelDockingStationConditionInput) {
    deleteDockingStation(input: $input, condition: $condition) {
   id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  mac_compatible
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
   team
  
    }
  }
`;

export const createSpeaker = gql`
  mutation CreateSpeaker($input: CreateSpeakerInput!, $condition: ModelSpeakerConditionInput) {
    createSpeaker(input: $input, condition: $condition) {
    id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team

    }
  }
`;

export const updateSpeaker = gql`
  mutation UpdateSpeaker ($input: UpdateSpeakerInput!, $condition: ModelSpeakerConditionInput) {
    updateSpeaker(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team

    }
  }
`;

export const deleteSpeaker = gql`
  mutation DeleteSpeaker($input: DeleteSpeakerInput!, $condition: ModelSpeakerConditionInput) {
    deleteSpeaker(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  
  
    }
  }
`;


export const createGeneralInventory = gql`
  mutation CreateGeneralInventory($input: CreateGeneralInventoryInput!, $condition: ModelGeneralInventoryConditionInput) {
    createGeneralInventory(input: $input, condition: $condition) {
  id
  Item_Description
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  notes
  location
  team

    }
  }
`;
export const updateGeneralInventory = gql`
  mutation UpdateGeneralInventory ($input: UpdateGeneralInventoryInput!, $condition: ModelGeneralInventoryConditionInput) {
    updateGeneralInventory(input: $input, condition: $condition) {
  id
  Item_Description
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  notes
  location
  team

    }
  }
`;

export const deleteGeneralInventory = gql`
  mutation DeleteGeneralInventory($input: DeleteGeneralInventoryInput!, $condition: ModelGeneralInventoryConditionInput) {
    deleteGeneralInventory(input: $input, condition: $condition) {
  id
  Item_Description
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  notes
  location
  team
  
  
    }
  }
`;

export const createLaptop = gql`
  mutation CreateLaptop($input: CreateLaptopInput!, $condition: ModelLaptopConditionInput) {
    createLaptop(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  charger_cable
  password
  norton_antivirus
  os
  ram
  storage
  device_id
  product_id
  bitloker
  microsoft_account
  microsoft_acount_password
  apple_id
  apple_password
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  Description
  location
  team
  admin_password
    }
  }
`;
export const updateLaptop = gql`
  mutation UpdateLaptop ($input: UpdateLaptopInput!, $condition: ModelLaptopConditionInput) {
    updateLaptop(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  charger_cable
  password
  norton_antivirus
  os
  ram
  storage
  device_id
  product_id
  bitloker
  microsoft_account
  microsoft_acount_password
  apple_id
  apple_password
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  Description
  location
  team
  admin_password
    }
  }
`;
export const deleteLaptop = gql`
  mutation DeleteLaptop($input: DeleteLaptopInput!, $condition: ModelLaptopConditionInput) {
    deleteLaptop(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  charger_cable
  password
  norton_antivirus
  os
  ram
  storage
  device_id
  product_id
  bitloker
  microsoft_account
  microsoft_acount_password
  apple_id
  apple_password
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  Description
  location
  team
  admin_password
    }
  }
`;

export const createTabletRemarkable = gql`
  mutation CreateTabletRemarkable($input: CreateTabletRemarkableInput!, $condition: ModelTabletRemarkableConditionInput) {
    createTabletRemarkable(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  charger_cable
  password
  os
  ram
  storage
  apple_id 
  apple_password
  phone_associate
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
    }
  }
`;

export const updateTabletRemarkable = gql`
  mutation UpdateTabletRemarkable ($input: UpdateTabletRemarkableInput!, $condition: ModelTabletRemarkableConditionInput) {
    updateTabletRemarkable(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  charger_cable
  password
  os
  ram
  storage
  apple_id 
  apple_password
  phone_associate
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
    }
  }
`;
export const deleteTabletRemarkable = gql`
  mutation DeleteTabletRemarkable($input: DeleteTabletRemarkableInput!, $condition: ModelTabletRemarkableConditionInput) {
    deleteTabletRemarkable(input: $input, condition: $condition) {
 id
  tagid
  model
  brand
  serial_Number
  price
  store
  charger_cable
  password
  os
  ram
  storage
  apple_id 
  apple_password
  phone_associate
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
    }
  }
`;

export const createHeadset = gql`
  mutation CreateHeadset($input: CreateHeadsetInput!, $condition: ModelHeadsetConditionInput) {
    createHeadset(input: $input, condition: $condition) {
  id
  tagid
  brand
  serial_Number
  charger_cable
  microphone
  aux_cable
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
    }
  }
`;
export const updateHeadset = gql`
  mutation UpdateHeadset  ($input: UpdateHeadsetInput!, $condition: ModelHeadsetConditionInput) {
    updateHeadset(input: $input, condition: $condition) {
  id
  tagid
  brand
  serial_Number
  charger_cable
  microphone
  aux_cable
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
    }
  }
`;

export const deleteHeadset = gql`
  mutation DeleteHeadset($input: DeleteHeadsetInput!, $condition: ModelHeadsetConditionInput) {
    deleteHeadset(input: $input, condition: $condition) {
  id
  tagid
  brand
  serial_Number
  charger_cable
  microphone
  aux_cable
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  location
  team
    }
  }
`;

export const createProjector = gql`
  mutation CreateProjector($input: CreateProjectorInput!, $condition: ModelProjectorConditionInput) {
    createProjector(input: $input, condition: $condition) {
  id
  tagId
  brand
  serial_Number
  charger_cable
  output_cable
  price
  store
  purchase_date
  warranty_date
  returnable_date
  available
  status
  location
  
    }
  }
`;
export const updateProjector = gql`
  mutation UpdateProjector($input: UpdateProjectorInput!, $condition: ModelProjectorConditionInput) {
    updateProjector(input: $input, condition: $condition) {
   id
  tagId
  brand
  serial_Number
  charger_cable
  output_cable
  price
  store
  purchase_date
  warranty_date
  returnable_date
  available
  status
  location
  
    }
  }
`;

export const deleteProjector = gql`
  mutation DeleteProjector($input: DeleteProjectorInput!, $condition: ModelProjectorConditionInput) {
    deleteProjector(input: $input, condition: $condition) {
  id
  tagId
  brand
  serial_Number
  charger_cable
  output_cable
  price
  store
  purchase_date
  warranty_date
  returnable_date
  available
  status
  location
  
    }
  }
`;
export const createExternalDisk = gql`
  mutation CreateExternalDisk($input: CreateExternalDiskInput!, $condition: ModelExternalDiskConditionInput) {
    createExternalDisk(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  storage
  descripcion
  charger_cable
  output_cable
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  
    }
  }
`;
export const updateExternalDisk = gql`
  mutation UpdateExternalDisk($input: UpdateExternalDiskInput!, $condition: ModelExternalDiskConditionInput) {
    updateExternalDisk(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  storage
  descripcion
  charger_cable
  output_cable
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
 
    }
  }
`;
export const deleteExternalDisk = gql`
  mutation DeleteExternalDisk($input: DeleteExternalDiskInput!, $condition: ModelExternalDiskConditionInput) {
    deleteExternalDisk(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  storage
  descripcion
  charger_cable
  output_cable
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team

    }
  }
`;
export const createBag = gql`
  mutation CreateBag($input: CreateBagInput!, $condition: ModelBagConditionInput) {
    createBag(input: $input, condition: $condition) {
  id
  brand
  type
  price
  store
  purchase_date
  assignment_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  
    }
  }
`;
export const updateBag = gql`
  mutation UpdateBag($input: UpdateBagInput!, $condition: ModelBagConditionInput) {
    updateBag(input: $input, condition: $condition) {
  id
  brand
  type
  price
  store
  purchase_date
  assignment_date
  returnable_date
  available
  status
  assigned_to
  location
  team
 
    }
  }
`;
export const deleteBag = gql`
  mutation DeleteBag($input: DeleteBagInput!, $condition: ModelBagConditionInput) {
    deleteBag(input: $input, condition: $condition) {
  id
  brand
  type
  price
  store
  purchase_date
  assignment_date
  returnable_date
  available
  status
  assigned_to
  location
  team

    }
  }
`;
export const createClickCounter = gql`
  mutation CreateClickCounter($input: CreateClickCounterInput!, $condition: ModelClickCounterConditionInput) {
    createClickCounter(input: $input, condition: $condition) {
  id
  price
  store
  purchase_date
  available
  assigned_to
  
    }
  }
`;
export const updateClickCounter = gql`
  mutation UpdateClickCounter($input: UpdateClickCounterInput!, $condition: ModelClickCounterConditionInput) {
    updateClickCounter(input: $input, condition: $condition) {
   id
  price
  store
  purchase_date
  available
  assigned_to
    }
  }
`;

export const deleteClickCounter = gql`
  mutation DeleteClickCounter($input: DeleteClickCounterInput!, $condition: ModelClickCounterConditionInput) {
    deleteClickCounter(input: $input, condition: $condition) {
   id
  price
  store
  purchase_date
  available
  assigned_to
    }
  }
`;
export const createSupplies = gql`
  mutation CreateSupplies($input: CreateSuppliesInput!, $condition: ModelSuppliesConditionInput) {
    createSupplies(input: $input, condition: $condition) {
  id
  name
  tagId
  price
  store
  purchase_date
  assignment_date
  available
  assigned_to
  location
  team
  
    }
  }
`;
export const updateSupplies = gql`
  mutation UpdateSupplies($input: UpdateSuppliesInput!, $condition: ModelSuppliesConditionInput) {
    updateSupplies(input: $input, condition: $condition) {
  id
  name
  tagId
  price
  store
  purchase_date
  assignment_date
  available
  assigned_to
  location
  team
    }
  }
`;

export const deleteSupplies = gql`
  mutation DeleteSupplies($input: DeleteSuppliesInput!, $condition: ModelSuppliesConditionInput) {
    deleteSupplies(input: $input, condition: $condition) {
   id
  price
  store
  purchase_date
  assignment_date
  available
  assigned_to
  location
  team
    }
  }
`;

export const CREATE_COUNTY = gql`
  mutation CreateCounty($input: CreateCountyInput!) {
    createCounty(input: $input) {
      id
      name
    }
  }
`;

export const UPDATE_COUNTY = gql`
  mutation UpdateCounty($input: UpdateCountyInput!) {
    updateCounty(input: $input) {
      id
      name
    }
  }
`;

export const DELETE_COUNTY = gql`
  mutation DeleteCounty($id: ID!) {
    deleteCounty(input: { id: $id }) {
      id
    }
  }
`;
export const CREATE_CITY = gql`
  mutation CreateCity($input: CreateCityInput!) {
    createCity(input: $input) {
      id
      name
      countyID
    }
  }
`;
export const UPDATE_CITY = gql`
  mutation UpdateCity($input: UpdateCityInput!) {
    updateCity(input: $input) {
      id
      name
    }
  }
`;
export const DELETE_CITY = gql`
  mutation DeleteCity($id: ID!) {
    deleteCity(input: { id: $id }) {
      id
    }
  }
`;
export const CREATE_LOCATION = gql`
  mutation CreateLocation($input: CreateLocationInput!) {
    createLocation(input: $input) {
      id
      name
      address
      cityID
      city {
        id
        name
      }
      navigators {
        items {
          id
          name
        }
      }
      operationHours {
        day
        startTime
        endTime
      }
      pointsOfContact {
        name
        phone
        email
      }
      outreachDate
    }
  }
`;

export const UPDATE_LOCATION = gql`
  mutation UpdateLocation($input: UpdateLocationInput!) {
    updateLocation(input: $input) {
      id
      name
      address
      cityID
      city {
        id
        name
      }
      navigators {
        items {
          id
          name
        }
      }
      operationHours {
        day
        startTime
        endTime
      }
      pointsOfContact {
        name
        phone
        email
      }
      outreachDate
    }
  }
`;

export const DELETE_LOCATION = gql`
  mutation DeleteLocation($id: ID!) {
    deleteLocation(input: { id: $id }) {
      id
    }
  }
`;

export const CREATE_NAVIGATOR = gql`
  mutation CreateNavigator($input: CreateNavigatorInput!) {
    createNavigator(input: $input) {
      id
      name
      locationID
    }
  }
`;

export const UPDATE_NAVIGATOR = gql`
  mutation UpdateNavigator($input: UpdateNavigatorInput!) {
    updateNavigator(input: $input) {
      id
      name
      locationID
    }
  }
`;

export const createDeletedUserHistory = gql`
  mutation CreateDeletedUserHistory ($input: CreateDeletedUserHistoryInput!
    $condition: ModelDeletedUserHistoryConditionInput
  ) {
    createDeletedUserHistory (input: $input, condition: $condition) {
        id
       ThreeCX_id
       ThreeCX_password
       first_name
       last_name
       thc_email_id
       thc_email_password
       gmail_email_id
       gmail_email_password
       apple_id
       apple_password
       phone_number
       navigator_id
       team
       cancellation_date
       notes
    }
  }
`;

export const deleteDeletedUserHistory = gql`
  mutation DeleteDeletedUserHistory($input: DeleteDeletedUserHistoryInput!, $condition: ModelDeletedUserHistoryConditionInput) {
    deleteDeletedUserHistory(input: $input, condition: $condition) {
       id
       ThreeCX_id
       ThreeCX_password
       first_name
       last_name
       thc_email_id
       thc_email_password
       gmail_email_id
       gmail_email_password
       apple_id
       apple_password
       phone_number
       navigator_id
       team
       cancellation_date
       notes
    }
  }
`;
export const updateDeletedUserHistory = gql`
  mutation UpdateDeletedUserHistory($input: UpdateDeletedUserHistoryInput!, $condition: ModelDeletedUserHistoryConditionInput) {
    updateDeletedUserHistory(input: $input, condition: $condition) {
   id
       ThreeCX_id
       ThreeCX_password
       first_name
       last_name
       thc_email_id
       thc_email_password
       gmail_email_id
       gmail_email_password
       apple_id
       apple_password
       phone_number
       navigator_id
       team
       cancellation_date
       notes
    }
  }
`;

export const createDeleteEquipmentHistory = gql`
  mutation CreateDeleteEquipmentHistory ($input: CreateDeleteEquipmentHistoryInput!
    $condition: ModelDeleteEquipmentHistoryConditionInput
  ) {
    createDeleteEquipmentHistory (input: $input, condition: $condition) {
  id
  Item
  tagId
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  available
  previous_employees
  location
  notes

    }
  }
`;
export const deleteDeleteEquipmentHistory = gql`
  mutation DeleteDeleteEquipmentHistory($input: DeleteDeleteEquipmentHistoryInput!, $condition: ModelDeleteEquipmentHistoryConditionInput) {
    deleteDeleteEquipmentHistory(input: $input, condition: $condition) {
  id
  Item
  tagId
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  available
  previous_employees
  location
  notes
    }
  }
`;

export const updateDeleteEquipmentHistory = gql`
  mutation UpdateDeleteEquipmentHistory($input: UpdateDeleteEquipmentHistoryInput!, $condition: ModelDeleteEquipmentHistoryConditionInput) {
    updateDeleteEquipmentHistory(input: $input, condition: $condition) {
  id
  Item
  tagId
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  available
  previous_employees
  location
  notes
    }
  }
`;

export const CREATE_PROMOTIONAL_REPORT = gql`
  mutation CreatePromotionalReport(
    $input: CreatePromotionalReportInput!
  ) {
    createPromotionalReport(input: $input) {
      id
      activityName
      activityDateTime
      numberOfConsumersTargeted
      mediaType
      activityDescription
      UploadedFileName
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_CALL_LIST = gql`
  mutation CreateCallList($input: CreateCallListInput!) {
    createCallList(input: $input) {
      id
      clientID
      navigator
      callDate
      answered
      appointmentMade
      needsFollowUp
      notes
      contactRound
      followUpScheduledDate
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_CALL_LIST = gql`
  mutation UpdateCallList($input: UpdateCallListInput!) {
    updateCallList(input: $input) {
      id
      clientID
      navigator
      callDate
      answered
      appointmentMade
      needsFollowUp
      notes
      contactRound
      updatedAt
    }
  }
`;

export const DELETE_CALL_LIST = gql`
  mutation DeleteCallList($input: DeleteCallListInput!) {
    deleteCallList(input: $input) {
      id
    }
  }
`;

export const createWorkSchedule = gql`
  mutation CreateWorkSchedule($input: CreateWorkScheduleInput!) {
    createWorkSchedule(input: $input) {
      id
      userId
      date
      startTime
      endTime
      breakStart
      breakEnd
      location
    }
  }
`;

export const deleteWorkSchedule = gql`
  mutation DeleteWorkSchedule($input: DeleteWorkScheduleInput!) {
    deleteWorkSchedule(input: $input) {
      id
    }
  }
`;

export const updateWorkSchedule = gql`
  mutation UpdateWorkSchedule($input: UpdateWorkScheduleInput!) {
    updateWorkSchedule(input: $input) {
      id
      userId
      date
      startTime
      endTime
      breakStart
      breakEnd
      location
    }
  }
`;

export const createScheduleTemplate = gql`
  mutation CreateScheduleTemplate($input: CreateScheduleTemplateInput!) {
    createScheduleTemplate(input: $input) {
      id
      name
      days {
        day
        shift
      }
      totalHours
      createdAt
      updatedAt
    }
  }
`;

export const updateScheduleTemplate = gql`
  mutation UpdateScheduleTemplate($input: UpdateScheduleTemplateInput!) {
    updateScheduleTemplate(input: $input) {
      id
      name
      days {
        day
        shift
      }
      totalHours
      updatedAt
    }
  }
`;

export const deleteScheduleTemplate = gql`
  mutation DeleteScheduleTemplate($input: DeleteScheduleTemplateInput!) {
    deleteScheduleTemplate(input: $input) {
      id
    }
  }
`;


export const CREATE_INTERACTION = gql`
  mutation CreateInteraction($input: CreateInteractionInput!) {
    createInteraction(input: $input) {
      id
      callDate
      time
      answered
      appointmentMade
      notes
      contactMethod
      followUpScheduledDate
      createdBy
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_INTERACTION = gql`
  mutation DeleteInteraction($id: ID!) {
    deleteInteraction(input: { id: $id }) {
      id
    }
  }
`;

export const CREATE_TICKET = gql`
  mutation CreateTicket($input: CreateTicketInput!) {
    createTicket(input: $input) {
  id
  type
  name
  location
  time
  date
  dateTimeSupervisor
  dateTimeAdmin
  supervisor_name
  admin_name
  type_issue
  program_name
  equipment_name
  equipment_tag
  status
  completed_date
  request_item
  quantity
  tried_troubleshooting
  priority
  supervisor_approval
  troubleshooting_problem1
  troubleshooting_problem2
  admin_troubleshooting_problem
  troubleshooting_steps_taken1
  troubleshooting_steps_taken2
  admin_troubleshooting_steps_taken
  troubleshooting_resolution1
  troubleshooting_resolution2
  admin_troubleshooting_resolution
  date_needed
  purchase_link


    }
  }
`;

export const UPDATE_TICKET = gql`
  mutation UpdateTicket($input: UpdateTicketInput!) {
    updateTicket(input: $input) {
  id
  type
  name
  location
  time
  date
  dateTimeSupervisor
  dateTimeAdmin
  supervisor_name
  admin_name
  type_issue
  program_name
  equipment_name
  equipment_tag
  status
  completed_date
  request_item
  quantity
  tried_troubleshooting
  priority
  supervisor_approval
  troubleshooting_problem1
  troubleshooting_problem2
  admin_troubleshooting_problem
  troubleshooting_steps_taken1
  troubleshooting_steps_taken2
  admin_troubleshooting_steps_taken
  troubleshooting_resolution1
  troubleshooting_resolution2
  admin_troubleshooting_resolution
  date_needed
  purchase_link

    }
  }
`;
export const DELETE_TICKET = gql`
  mutation DeleteTicket($input: DeleteTicketInput!) {
    deleteTicket(input: $input) {
      id
    }
  }
`;