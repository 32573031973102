
import React, { useState, useMemo, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { listDeletedUserHistories, listGeneralInventories, listUsers, listPowerBanks, listLaptops, listPrinters, listClickCounters, listTabletRemarkables, listSpeakers, listExternalDisks, listScanners, listDockingStations, listPhones, listHeadsets, listMice, listKeyboards, listMonitors, listExtensionCords, listBackupBatteries, listBags, listSupplies, listUSBS, listDeleteEquipmentHistories } from '../../../graphql/queries';
import { createUser, deleteUser, updateUser, createDeletedUserHistory, updateLaptop, updatePhone, updatePrinter, updateScanner, updateMouse, updateKeyboard, updateMonitor, updateExtensionCord, updatePowerBank, updateBackupBattery, updateUSB, updateDockingStation, updateSpeaker, updateTabletRemarkable, updateHeadset, updateExternalDisk, updateGeneralInventory, updateBag, updateClickCounter, updateSupplies } from '../../../graphql/mutations';
import './Employees.css';
import './OverlayInventory.css';
import XLSX from 'xlsx-js-style';
import { FaFileExcel } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import Inventory_header from '../../../components/layout/Inventory_header';
import logo2 from '../../../assets/images/BCH Logo Final.png';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import useFilteredData from '../../../components/layout/useFilteredDate';
import SearchBar from '../../../components/layout/SearchBar';
import { capitalize } from '../../../components/common/capitalize';
import OverlayEdit from "../../../components/layout/OverlayEdit";



const Employees = () => {

  const [employees, setEmployees] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [selectedEmployees, setSelectedEmployees] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [overlayType, setOverlayType] = useState(null);
  const [showEmployeesAddOverlay, setShowEmployeesAddOverlay] = useState(false);
  const [showEmployeesShowInventoryOverlay, setShowEmployeesShowInventoryOverlay] = useState(false);
  const [showPasswords, setShowPasswords] = useState(false);


  const [newEmployees, setNewEmployees] = useState({
    id: '',
    ThreeCX_id: '',
    ThreeCX_password: '',
    first_name: '',
    last_name: '',
    thc_email_id: '',
    thc_email_password: '',
    gmail_email_id: '',
    gmail_email_password: '',
    apple_id: '',
    apple_password: '',
    phone_number: '',
    navigator_id: '',
    team: '',
    location: '',
    notes: '',
  });

  const [sortConfig, setSortConfig] = useState(null);
  const [duplicateError, setDuplicateError] = useState('');
  const [threeCX_idError, setThreeCX_idError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [thcEmailIdError, setThcEmailIdError] = useState('');
  const [gmailEmailIdError, setGmailEmailIdError] = useState('');
  const [appleIdError, setAppleIdError] = useState('');
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedEquipment, setSelectedEquipment] = useState([]);
  const [availableItems, setAvailableItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null); // Equipo seleccionado desde la vista Employee


  const [message, setMessage] = useState(""); // Para manejar el mensaje
  const [messageType, setMessageType] = useState("error"); // Para el tipo de mensaje (error, success, etc.)


  const [showSelect, setShowSelect] = useState(false); // Controla si se muestra el <select>
  const [selectedOption, setSelectedOption] = useState(''); // Controla la opción seleccionada

  const [availableOthersItems, setAvailableOthersItems] = useState([]); // Items disponibles para la opción seleccionada para Other
  const [selectedOtherItem, setSelectedOtherItem] = useState(null); // Item seleccionado del listado para Other

  // New Overlay de Edicion desde la view Inventory.
  const [isLaptopOverlayOpen, setIsLaptopOverlayOpen] = useState(false);
  const [selectedLaptop, setSelectedLaptop] = useState(null);
  const [isPrinterOverlayOpen, setIsPrinterOverlayOpen] = useState(false);
  const [selectedPrinter, setSelectedPrinter] = useState(null);
  const [isScannerOverlayOpen, setIsScannerOverlayOpen] = useState(false);
  const [selectedScanner, setSelectedScanner] = useState(null);
  const [isPhoneOverlayOpen, setIsPhoneOverlayOpen] = useState(false);
  const [selectedPhone, setSelectedPhone] = useState(null);
  const [isHeadsetOverlayOpen, setIsHeadseteOverlayOpen] = useState(false);
  const [selectedHeadset, setSelectedHeadset] = useState(null);
  const [isMouseOverlayOpen, setIsMouseOverlayOpen] = useState(false);
  const [selectedMouse, setSelectedMouse] = useState(null);
  const [isKeyboardOverlayOpen, setIsKeyboardOverlayOpen] = useState(false);
  const [selectedKeyboard, setSelectedKeyboard] = useState(null);
  const [isMonitorOverlayOpen, setIsMonitorOverlayOpen] = useState(false);
  const [selectedMonitor, setSelectedMonitor] = useState(null);
  const [isExtensionCordOverlayOpen, setIsExtensionCordOverlayOpen] = useState(false);
  const [selectedExtensionCord, setSelectedExtensionCord] = useState(null);
  const [isBackupBaterryOverlayOpen, setIsBackupBaterryOverlayOpen] = useState(false);
  const [selectedBackupBaterry, setSelectedBackupBaterry] = useState(null);
  const [isBagOverlayOpen, setIsBagOverlayOpen] = useState(false);
  const [selectedBag, setSelectedBag] = useState(null);
  const [isSuppliesOverlayOpen, setIsSuppliesOverlayOpen] = useState(false);
  const [selectedSupplies, setSelectedSupplies] = useState(null);
  const [isUSBOverlayOpen, setIsUSBOverlayOpen] = useState(false);
  const [selectedUSB, setSelectedUSB] = useState(null);
  const [isDockingStationOverlayOpen, setIsDockingStationOverlayOpen] = useState(false);
  const [selectedDockingStation, setSelectedDockingStation] = useState(null);
  const [isSpeakerOverlayOpen, setIsSpeakerOverlayOpen] = useState(false);
  const [selectedSpeaker, setSelectedSpeaker] = useState(null);
  const [isPowerBankOverlayOpen, setIsPowerBankOverlayOpen] = useState(false);
  const [selectedPowerBank, setSelectedPowerBank] = useState(null);
  const [isTabletOverlayOpen, setIsTabletOverlayOpen] = useState(false);
  const [selectedTablet, setSelectedTablet] = useState(null);
  const [isExternoDiskOverlayOpen, setIsExternoDiskOverlayOpen] = useState(false);
  const [selectedExternoDisk, setSelectedExternoDisk] = useState(null)
  const [isGeneralInventorylayOpen, setIsGeneralInventoryOverlayOpen] = useState(false);
  const [selectedGeneralInventory, setSelectedGeneralInventory] = useState(null)

  // Variables para realizar el filtro para ver el inventario de cada employee
  const { data: dataLaptops } = useQuery(listLaptops);
  const { data: dataPrinters } = useQuery(listPrinters);
  const { data: dataScanners } = useQuery(listScanners);
  const { data: dataPhones } = useQuery(listPhones);
  const { data: dataHeadset } = useQuery(listHeadsets);
  const { data: dataMice } = useQuery(listMice);
  const { data: dataKeyboard } = useQuery(listKeyboards);
  const { data: dataMonitors } = useQuery(listMonitors);
  const { data: dataExtensionCords } = useQuery(listExtensionCords);
  const { data: dataBackupBatteries } = useQuery(listBackupBatteries);
  const { data: dataBags } = useQuery(listBags);
  const { data: dataSupplies } = useQuery(listSupplies);
  const { data: dataUSBs } = useQuery(listUSBS);
  const { data: dataDockingStations } = useQuery(listDockingStations);
  const { data: dataSpeakers } = useQuery(listSpeakers);
  const { data: dataTablets } = useQuery(listTabletRemarkables);
  const { data: dataExternoDisks } = useQuery(listExternalDisks);
  const { data: dataClickCounter } = useQuery(listClickCounters);
  const { data: dataPowerBank } = useQuery(listPowerBanks);
  const { data: dataGeneralInventory } = useQuery(listGeneralInventories);
  const { data: dataEquipmentRemoved } = useQuery(listDeleteEquipmentHistories);

  // Variables para actualizar el estado y available de los equipos cuando elimino el employee
  const [updatelaptop] = useMutation(updateLaptop, {
    refetchQueries: [{ query: listLaptops, variables: { limit: 100 } }]
  });
  const [updatephone] = useMutation(updatePhone, {
    refetchQueries: [{ query: listPhones, variables: { limit: 100 } }]
  });
  const [updatescanner] = useMutation(updateScanner, {
    refetchQueries: [{ query: listScanners, variables: { limit: 100 } }]
  });
  const [updateprinter] = useMutation(updatePrinter, {
    refetchQueries: [{ query: listPrinters, variables: { limit: 100 } }]
  });
  const [updatemouse] = useMutation(updateMouse, {
    refetchQueries: [{ query: listMice, variables: { limit: 100 } }]
  });
  const [updatekeyboard] = useMutation(updateKeyboard, {
    refetchQueries: [{ query: listKeyboards, variables: { limit: 100 } }]
  });
  const [updatemonitor] = useMutation(updateMonitor, {
    refetchQueries: [{ query: listMonitors, variables: { limit: 100 } }]
  });
  const [updateextensionCord] = useMutation(updateExtensionCord, {
    refetchQueries: [{ query: listExtensionCords, variables: { limit: 100 } }]
  });
  const [updatepowerBank] = useMutation(updatePowerBank, {
    refetchQueries: [{ query: listPowerBanks, variables: { limit: 100 } }]
  });
  const [updatebackupBattery] = useMutation(updateBackupBattery, {
    refetchQueries: [{ query: listBackupBatteries, variables: { limit: 100 } }]
  });
  const [updateuSB] = useMutation(updateUSB, {
    refetchQueries: [{ query: listUSBS, variables: { limit: 100 } }]
  });
  const [updatedockingStation] = useMutation(updateDockingStation, {
    refetchQueries: [{ query: listDockingStations, variables: { limit: 100 } }]
  });
  const [updatespeaker] = useMutation(updateSpeaker, {
    refetchQueries: [{ query: listSpeakers, variables: { limit: 100 } }]
  });
  const [updatetabletRemarkable] = useMutation(updateTabletRemarkable, {
    refetchQueries: [{ query: listTabletRemarkables, variables: { limit: 100 } }]
  });
  const [updateheadset] = useMutation(updateHeadset, {
    refetchQueries: [{ query: listHeadsets, variables: { limit: 100 } }]
  });
  const [updateexternalDisk] = useMutation(updateExternalDisk, {
    refetchQueries: [{ query: listExternalDisks, variables: { limit: 100 } }]
  });
  const [updategeneralInventory] = useMutation(updateGeneralInventory, {
    refetchQueries: [{ query: listGeneralInventories, variables: { limit: 100 } }]
  });
  const [updatebag] = useMutation(updateBag, {
    refetchQueries: [{ query: listBags, variables: { limit: 100 } }]
  });
  const [updateclickCounter] = useMutation(updateClickCounter, {
    refetchQueries: [{ query: listClickCounters, variables: { limit: 100 } }]
  });
  const [updatesupplies] = useMutation(updateSupplies, {
    refetchQueries: [{ query: listSupplies, variables: { limit: 100 } }]
  });


  const syncCheckboxState = () => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach(checkbox => {
      if (checkbox.checked) {
        checkbox.setAttribute('checked', 'checked');
      } else {
        checkbox.removeAttribute('checked');
      }
    });
  };

  const handlePrintOverlay = () => {
    const overlay = document.querySelector('.custom-overlay');
    if (!overlay) {
      console.error("No se encontró el elemento 'custom-overlay' en el DOM.");
      return;
    }

    syncCheckboxState(); // Sincronizar estado de los checkbox antes de imprimir

    const fields = ['notes', 'employeeName', 'employeeDate', 'supervisorName', 'supervisorDate'];
    const fieldValues = fields.reduce((acc, field) => {
      acc[field] = document.getElementById(field).value || '';
      return acc;
    }, {});

    // Asignamos los valores a los elementos correspondientes en overlay
    document.getElementById('printNotes').textContent = fieldValues.notes;
    document.getElementById('printEmployeeName').textContent = fieldValues.employeeName;
    document.getElementById('printEmployeeDate').textContent = fieldValues.employeeDate;
    document.getElementById('printSupervisorName').textContent = fieldValues.supervisorName;
    document.getElementById('printSupervisorDate').textContent = fieldValues.supervisorDate;


    // Mostrar los spans y ocultar los inputs temporalmente
    const spansToShow = [
      'printNotes',
      'printEmployeeName',
      'printEmployeeDate',
      'printSupervisorName',
      'printSupervisorDate',

    ];
    const inputsToHide = fields.map(field => document.getElementById(field));

    spansToShow.forEach(id => {
      document.getElementById(id).style.display = 'inline';
    });
    inputsToHide.forEach(input => {
      if (input) input.style.display = 'none';
    });

    // Crear el iframe oculto
    const printIframe = document.createElement('iframe');
    printIframe.style.position = 'absolute';
    printIframe.style.width = '0';
    printIframe.style.height = '0';
    printIframe.style.border = 'none';
    document.body.appendChild(printIframe);

    printIframe.onload = () => {
      printIframe.contentWindow.focus();
      printIframe.contentWindow.print();

      // Restaurar estilos después de imprimir
      spansToShow.forEach(id => {
        document.getElementById(id).style.display = 'none';
      });
      inputsToHide.forEach(input => {
        if (input) input.style.display = 'inline';
      });

      setTimeout(() => {
        printIframe.parentNode.removeChild(printIframe);
      }, 1000);
    };

    // Escribir el contenido en el iframe
    const iframeDoc = printIframe.contentWindow.document;
    iframeDoc.open();
    iframeDoc.write(`
      <html>
        <head>
          <title>Print Overlay</title>
          <style>
            ${document.querySelector('style') ? document.querySelector('style').innerHTML : ''}
            ${[...document.styleSheets]
        .map((sheet) => {
          try {
            return [...sheet.cssRules].map((rule) => rule.cssText).join('');
          } catch (e) {
            return '';
          }
        })
        .join('')}
            @media print {
              body {
                margin: 0;
                padding: 0;
                font-size: 12px;
                height: auto;
              }
                input[type="checkbox"] {
                            display: inline-block; 
                            transform: scale(1.2); 
                            margin: 5px;
                        }
              table {
                width: 100%;
                margin: 0 auto;
                table-layout: auto;
                border-collapse: collapse;
              }
              table th,
              table td {
                border: 1px solid #ddd;
                padding: 5px;
                text-align: center;
                word-wrap: break-word;
               } 
               table td:nth-child(8) {
                width:100px;
                max-width: 100px;
                min-width: 100px;
                word-wrap: break-word;
                text-overflow: ellipsis;
               }
                table td:nth-child(10) {
                width:100px;
                max-width: 100px;
                min-width: 100px;
               }
            }
          </style>
        </head>
        <body>
          ${overlay.outerHTML}
        </body>
      </html>
    `);
    iframeDoc.close();

    // Actualiza la base de datos con las notas guardadas en el campo location

    if (!selectedEmployees?.id) {
      console.error("El ID del empleado es obligatorio para actualizar la ubicación.");
      return;
    }
    const input = {
      id: selectedEmployees.id,          // ID del empleado
      notes: fieldValues.notes || '' // Notas
    };

    updEmployees({ variables: { input } });
    console.log('User updated successfully:');

  };

  const handleSaveAsExcel = async () => {
    try {
      const employeesToExport = filteredEmployees.length > 0 ? filteredEmployees : employees;
      if (employeesToExport.length > 0) {
        // Ordena los empleados por la marca de tiempo
        const sortedEmployees = [...employeesToExport].sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp));

        // Prepara los datos para la exportación a Excel con títulos personalizados de las columnas y columnas vacías
        const worksheetData = sortedEmployees.map((employees, index) => ({
          '#': index + 1, // Agregar el número de fila
          'First Name': employees.first_name,
          'Last Name': employees.last_name,
          '3CX': employees.ThreeCX_id,
          '3CX Password': employees.ThreeCX_password,
          'THC Email': employees.thc_email_id,
          'Gmail': employees.gmail_email_id,
          'Apple': employees.apple_id,
          'Phone Number': employees.phone_number,
          'Navigator ID': employees.navigator_id,
          'Team': employees.team,
          'Location': employees.location,
        }));

        // Convert JSON to sheet starting from row 2
        const worksheet = XLSX.utils.json_to_sheet([]);
        let endCol = 0;

        XLSX.utils.sheet_add_aoa(worksheet, [['Employees']], { origin: 'A1' });

        if (worksheetData.length > 0) {
          // Agrega los datos a partir de la fila A2
          XLSX.utils.sheet_add_json(worksheet, worksheetData, { origin: 'A2', skipHeader: false });

          // Define el rango de referencia (!ref)
          const endRow = worksheetData.length + 1; // +1 para el título en A1
          const endCol = Object.keys(worksheetData[0]).length - 1;
          worksheet['!ref'] = XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: endRow, c: endCol } });
        } else {
          console.error("No hay datos para exportar.");
          return;
        }

        // Merge title cells across the columns
        worksheet['!merges'] = worksheet['!merges'] || [];
        worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 11 } }); // A1 to H1 for the added column

        // Style the title row
        worksheet['A1'].s = {
          font: {
            bold: true,
            sz: 20, // Font size
            color: { rgb: '1F4E79' } // Dark blue text color
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center'
          }
        };

        // Apply styling for the header row (now starting at row 2)
        const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
        for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
          const cellAddress = XLSX.utils.encode_cell({ r: 1, c: col }); // Header row is now row 1
          if (!worksheet[cellAddress]) continue;
          worksheet[cellAddress].s = {
            fill: {
              fgColor: { rgb: '1F4E79' } // Dark blue background
            },
            font: {
              bold: true,
              color: { rgb: 'FFFFFF' } // White text
            },
            alignment: {
              horizontal: 'center'
            },
            border: {
              top: { style: 'thin', color: { rgb: '000000' } },
              left: { style: 'thin', color: { rgb: '000000' } },
              bottom: { style: 'thin', color: { rgb: '000000' } },
              right: { style: 'thin', color: { rgb: '000000' } },
            }
          };
        }

        // Apply alternating row colors and borders starting from row 3
        for (let row = 2; row <= headerRange.e.r; row++) {
          const isEvenRow = row % 2 === 0;
          for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
            if (!worksheet[cellAddress]) continue;

            worksheet[cellAddress].s = {
              fill: {
                fgColor: { rgb: isEvenRow ? 'D3D3D3' : 'FFFFFF' } // Light gray for even rows, white for odd rows
              },
              border: {
                top: { style: 'thin', color: { rgb: '000000' } },
                left: { style: 'thin', color: { rgb: '000000' } },
                bottom: { style: 'thin', color: { rgb: '000000' } },
                right: { style: 'thin', color: { rgb: '000000' } },
              },
              alignment: {
                horizontal: 'center'
              }
            };
          }
        }


        // Ajusta el ancho de las columnas (opcional)
        worksheet['!cols'] = [
          { wch: 5 },
          { wch: 15 }, // Ancho de la columna 'First Name'
          { wch: 15 }, // Ancho de la columna 'Last Name'
          { wch: 5 }, // Ancho de la columna '3CX'
          { wch: 15 }, // Ancho de la columna '3CX'
          { wch: 45 }, // Ancho de la columna 'THC Email'
          { wch: 45 }, // Ancho de la columna 'Gmail'
          { wch: 45 }, // Ancho de la columna 'Apple'
          { wch: 15 }, // Ancho de la columna 'Phone Number'
          { wch: 15 },  // Ancho de la columna 'NAV'
          { wch: 5 }, // Ancho de la columna 'Team'
          { wch: 10 }, // Ancho de la columna 'location'
        ];

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Employees');

        // Exporta el archivo
        XLSX.writeFile(workbook, 'EmployeesData.xlsx');
      } else {
        console.error('No data to export');
      }
    } catch (error) {
      console.error('Error exporting data to Excel:', error);
    }
  };

  const { loading, data, fetchMore } = useQuery(listUsers, {
    variables: { limit: 100 },
  });


  const [addEmployees] = useMutation(createUser, {
    refetchQueries: [{ query: listUsers, variables: { limit: 100 } }]
  });

  const [removeEmployees] = useMutation(deleteUser, {
    refetchQueries: [{ query: listUsers, variables: { limit: 100 } }]
  });
  const [updEmployees] = useMutation(updateUser, {
    refetchQueries: [{ query: listUsers, variables: { limit: 100 } }]
  });

  // Add los usuarios eliminados de la tabla a otra lista de usuarios eliminados
  const [addEmployeesPreviousEmployee] = useMutation(createDeletedUserHistory, {
    refetchQueries: [{ query: listDeletedUserHistories, variables: { limit: 100 } }]
  });

  useEffect(() => {
    const fetchAllEmployees = async () => {
      let AllEmployees = [];
      let currentNextToken = null;


      do {
        const { data } = await fetchMore({
          variables: { limit: 100, nextToken: currentNextToken },
        });

        AllEmployees = [...AllEmployees, ...data.listUsers.items];
        currentNextToken = data.listUsers.nextToken;
      } while (currentNextToken);


      // Ordenar los empleados por 'first_name' alfabéticamente
      AllEmployees.sort((a, b) => a.first_name.localeCompare(b.first_name));
      setEmployees(AllEmployees);
      setNextToken(currentNextToken);
    };

    if (data) {
      fetchAllEmployees();
    }
  }, [data, fetchMore]);
  // Muestra un indicador de carga mientras loading sea true


  const clearNewEmployess = () => {
    setNewEmployees({
      id: '',
      ThreeCX_id: '',
      ThreeCX_password: '',
      first_name: '',
      last_name: '',
      thc_email_id: '',
      thc_email_password: '',
      gmail_email_id: '',
      gmail_email_password: '',
      apple_id: '',
      apple_password: '',
      phone_number: '',
      navigator_id: '',
      team: '',
      location: '',
      notes: '',
    });
  };

  const loadMore = () => {
    fetchMore({
      variables: { limit: 100, nextToken },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prevResult;
        setNextToken(fetchMoreResult.listUsers.nextToken);
        return {
          listUsers: {
            ...fetchMoreResult.listUsers,
            items: [...prevResult.listUsers.items, ...fetchMoreResult.listUsers.items]
          }
        };
      }
    });
  };

  const filteredEmployees = useFilteredData(employees || [], searchTerm, sortConfig);

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Asegurar dos dígitos para el mes
    const day = String(d.getDate()).padStart(2, '0'); // Asegurar dos dígitos para el día
    return `${year}-${month}-${day}`;
  };


  const handleInputChange = (e) => {

    const { name, value } = e.target;
    let formattedValue = value;

    if (['first_name', 'last_name',].includes(name)) {
      formattedValue = value.toLowerCase();
    }

    if (name === 'phone_number') {
      formattedValue = value.replace(/[^\d]/g, ''); // Remove all non-digit characters
      if (formattedValue.length > 10) {
        setPhoneError('Phone number cannot be longer than 10 digits.');
      } else if (formattedValue.length < 10) {
        setPhoneError('');
      } else {
        setPhoneError('');
      }
    }
    if (isEditing) {
      setSelectedEmployees({
        ...selectedEmployees,
        [name]: formattedValue
      });
    } else {
      setNewEmployees({
        ...newEmployees,
        id: uuidv4(),
        [name]: formattedValue
      });
    }

  };

  // Función para alternar la visibilidad de las contraseñas
  const togglePasswords = () => {
    setShowPasswords(prevState => !prevState);
  };



  const handleAddEmployees = () => {

    // Check for duplicates based on `ThreeCX` and `first name`

    const isDuplicate = employees.some(employee =>
      String(employee.ThreeCX_id) === String(newEmployees.ThreeCX_id) &&
      employee.first_name.trim().toLowerCase() === newEmployees.first_name.trim().toLowerCase()
    );
    if (isDuplicate) {

      setDuplicateError('An employee with the same ThreeCX ID and first name already exists.');
      console.log('Duplicate validation failed');
      return;
    } else {
      setDuplicateError('');
    }


    // Check that phone_number is exactly 10 digits and contains only numbers
    const phoneNumber = newEmployees.phone_number ? parseInt(newEmployees.phone_number.trim(), 10) : null;

    if (phoneNumber !== null && !/^\d{10}$/.test(String(phoneNumber))) {
      console.log(`Número de teléfono inválido: ${phoneNumber}`);
      setPhoneError('Phone number must be exactly 10 digits and contain only numbers.');
      return;
    } else {
      setPhoneError('');
    }

    // Check ThreeCX ID must not be empty and must be an integer
    if (!newEmployees.ThreeCX_id) {
      setThreeCX_idError('The ThreeCX ID cannot be empty.');
      console.log('ThreeCX ID validation failed: Empty value');
      return;
    }

    const isThreeCXIdInteger = Number.isInteger(Number(newEmployees.ThreeCX_id));

    if (!isThreeCXIdInteger) {
      setThreeCX_idError('The ThreeCX ID must be an integer.');
      console.log('ThreeCX ID validation failed: Not an integer');
      return;
    } else {
      setThreeCX_idError('');
    }

    // Check email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const isValidEmail = (email) => emailPattern.test(email);

    const validateEmails = (thc_email_id, gmail_email_id, apple_id) => {
      let isValid = true;

      // Validar THC Email ID solo si no es nulo o vacío
      if (thc_email_id && thc_email_id.trim() !== '') {
        if (!isValidEmail(thc_email_id)) {
          setThcEmailIdError('THC Email ID is not a valid email address.');
          console.log('THC Email ID validation failed');
          isValid = false;
        } else {
          setThcEmailIdError('');
        }
      } else {
        setThcEmailIdError('');  // No mostrar error si está vacío o es nulo
      }

      // Validar Gmail Email ID solo si no es nulo o vacío
      if (gmail_email_id && gmail_email_id.trim() !== '') {
        if (!isValidEmail(gmail_email_id)) {
          setGmailEmailIdError('Gmail Email ID is not a valid email address.');
          console.log('Gmail Email ID validation failed');
          isValid = false;
        } else {
          setGmailEmailIdError('');
        }
      } else {
        setGmailEmailIdError('');  // No mostrar error si está vacío o es nulo
      }

      // Validar Apple ID solo si no es nulo o vacío
      if (apple_id && apple_id.trim() !== '') {
        if (!isValidEmail(apple_id)) {
          setAppleIdError('Apple ID is not a valid email address.');
          console.log('Apple ID validation failed');
          isValid = false;
        } else {
          setAppleIdError('');
        }
      } else {
        setAppleIdError('');  // No mostrar error si está vacío o es nulo
      }

      return isValid;
    };


    // Obtener los datos y validar
    const cleanedGmailEmailId = newEmployees.gmail_email_id?.trim() || null
    const cleanedThcEmailId = newEmployees.thc_email_id?.trim() || null
    const cleanedAppleId = newEmployees.apple_id?.trim() || null

    if (!validateEmails(cleanedThcEmailId, cleanedGmailEmailId, cleanedAppleId)) {
      console.log('One or more email addresses are invalid.');
      return;
    }


    // If all validations pass, proceed with adding the employees to the database
    addEmployees({
      variables: { input: { ...newEmployees, phone_number: phoneNumber, gmail_email_id: cleanedGmailEmailId, thc_email_id: cleanedThcEmailId, apple_id: cleanedAppleId, } }
    })
      .then(() => {
        clearNewEmployess();
        alert("The Employee was an added success")
        setShowEmployeesAddOverlay(false);
      })

      .catch(error => {
        console.error("Error adding employees:", error);
      });
  };

  // Para Eliminar el usuario de esta lista y enviarla a previous employee y actualizar sus estados
  const updateEquipmentStatus = async (type, items) => {
    try {
      const updatePromises = items.map((item) => {
        switch (type) {
          case 'clickCounters':
            return updateclickCounter({
              variables: {
                input: {
                  id: item.id,
                  available: "true",
                  assigned_to: "N/A"
                },
              },
            });
          case 'bags':
            return updatebag({
              variables: {
                input: {
                  id: item.id,
                  available: "true",
                  status: "inv",
                  assigned_to: "N/A"
                },
              },
            });
          case 'generalInventories':
            return updategeneralInventory({
              variables: {
                input: {
                  id: item.id,
                  available: "true",
                  status: "inv",
                  assigned_to: "N/A"
                },
              },
            });
          case 'externalDisks':
            return updateexternalDisk({
              variables: {
                input: {
                  id: item.id,
                  available: "true",
                  status: "inv",
                  assigned_to: "N/A"
                },
              },
            });
          case 'headsets':
            return updateheadset({
              variables: {
                input: {
                  id: item.id,
                  available: "true",
                  status: "inv",
                  assigned_to: "N/A"
                },
              },
            });
          case 'tabletRemarkables':
            return updatetabletRemarkable({
              variables: {
                input: {
                  id: item.id,
                  available: "true",
                  status: "inv",
                  assigned_to: "N/A"
                },
              },
            });
          case 'speakers':
            return updatespeaker({
              variables: {
                input: {
                  id: item.id,
                  available: "true",
                  status: "inv",
                  assigned_to: "N/A"
                },
              },
            });
          case 'dockingStations':
            return updatedockingStation({
              variables: {
                input: {
                  id: item.id,
                  available: "true",
                  status: "inv",
                  assigned_to: "N/A"
                },
              },
            });
          case 'usbs':
            return updateuSB({
              variables: {
                input: {
                  id: item.id,
                  available: "true",
                  status: "inv",
                  assigned_to: "N/A"
                },
              },
            });
          case 'backupBatteries':
            return updatebackupBattery({
              variables: {
                input: {
                  id: item.id,
                  available: "true",
                  status: "inv",
                  assigned_to: "N/A"
                },
              },
            });
          case 'powerBanks':
            return updatepowerBank({
              variables: {
                input: {
                  id: item.id,
                  available: "true",
                  status: "inv",
                  assigned_to: "N/A"
                },
              },
            });
          case 'extensionCords':
            return updateextensionCord({
              variables: {
                input: {
                  id: item.id,
                  available: "true",
                  status: "inv",
                  assigned_to: "N/A"
                },
              },
            });
          case 'monitorsDesk':
            return updatemonitor({
              variables: {
                input: {
                  id: item.id,
                  available: "true",
                  status: "inv",
                  assigned_to: "N/A"
                },
              },
            });
          case 'monitorsPortable':
            return updatemonitor({
              variables: {
                input: {
                  id: item.id,
                  available: "true",
                  status: "inv",
                  assigned_to: "N/A"
                },
              },
            });
          case 'keyboards':
            return updatekeyboard({
              variables: {
                input: {
                  id: item.id,
                  available: "true",
                  status: "inv",
                  assigned_to: "N/A"
                },
              },
            });
          case 'mice':
            return updatemouse({
              variables: {
                input: {
                  id: item.id,
                  available: "true",
                  status: "inv",
                  assigned_to: "N/A"
                },
              },
            });
          case 'laptops':
            return updatelaptop({
              variables: {
                input: {
                  id: item.id,
                  available: "true",
                  status: "inv",
                  assigned_to: "N/A"
                },
              },
            });
          case 'phones':
            return updatephone({
              variables: {
                input: {
                  id: item.id,
                  available: "true",
                  status: "inv",
                  assigned_to: "N/A"
                },
              },
            });
          case 'scanners':
            return updatescanner({
              variables: {
                input: {
                  id: item.id,
                  available: "true",
                  status: "inv",
                  assigned_to: "N/A"
                },
              },
            });
          case 'printers':
            return updateprinter({
              variables: {
                input: {
                  id: item.id,
                  available: "true",
                  status: "inv",
                  assigned_to: "N/A"
                },
              },
            });
          default:
            throw new Error(`Unknown equipment type: ${type}`);
        }
      });

      await Promise.all(updatePromises);
      console.log(`Estado de los ${type} actualizado correctamente.`);
    } catch (error) {
      console.error(`Error al actualizar los ${type}:`, error);
    }
  };


  const removeUnnecessaryFields = (data, fieldsToRemove) => {
    fieldsToRemove.forEach((field) => {
      delete data[field];
    });
    return data;
  };

  const handleDeleteEmployees = async () => {
    try {
      const employeeData = removeUnnecessaryFields({ ...selectedEmployees }, [
        'location',
        '__typename',
        'updatedAt',
        'createdAt',
      ]);

      // Lista de campos permitidos según el esquema GraphQL
      const allowedFields = [
        'id',
        'ThreeCX_id',
        'ThreeCX_password',
        'first_name',
        'last_name',
        'thc_email_id',
        'thc_email_password',
        'gmail_email_id',
        'gmail_email_password',
        'apple_id',
        'apple_password',
        'phone_number',
        'navigator_id',
        'team',
        'cancellation_date',
        'notes',
      ];

      // Filtrar los campos permitidos
      const cleanedNewEmployees = Object.fromEntries(
        Object.entries(employeeData).filter(([key]) => allowedFields.includes(key))
      );

      // Asegurarse de que `cancellation_date` tenga el formato correcto
      cleanedNewEmployees.cancellation_date = formatDate(new Date());
      if (!/^\d{4}-\d{2}-\d{2}$/.test(cleanedNewEmployees.cancellation_date)) {
        console.error("Formato inválido para cancellation_date:", cleanedNewEmployees.cancellation_date);
        delete cleanedNewEmployees.cancellation_date;
      }

      console.log("Fecha formateada:", formatDate(new Date()));

      // Eliminar valores vacíos
      Object.keys(cleanedNewEmployees).forEach((key) => {
        if (cleanedNewEmployees[key] === "" || cleanedNewEmployees[key] === null) {
          delete cleanedNewEmployees[key];
        }
      });

      console.log("Campos permitidos:", allowedFields);
      console.log("Datos originales:", employeeData);
      console.log("Datos limpios que se enviarán:", cleanedNewEmployees);

      // Agregar empleado a la tabla PreviousEmployee
      await addEmployeesPreviousEmployee({
        variables: { input: cleanedNewEmployees },
      });
      console.log("Empleado agregado a la tabla PreviousEmployee");

      // Actualizar equipos filtrados
      if (filteredLaptops.length > 0) {
        await updateEquipmentStatus('laptops', filteredLaptops);
      }
      if (filteredPhones.length > 0) {
        await updateEquipmentStatus('phones', filteredPhones);
      }
      if (filteredScanners.length > 0) {
        await updateEquipmentStatus('scanners', filteredScanners);
      }
      if (filteredPrinters.length > 0) {
        await updateEquipmentStatus('printers', filteredPrinters);
      }
      if (filteredMouse.length > 0) {
        await updateEquipmentStatus('mice', filteredMouse);
      }
      if (filteredKeyboard.length > 0) {
        await updateEquipmentStatus('keyboards', filteredKeyboard);
      }
      if (filteredMonitorDesk.length > 0) {
        await updateEquipmentStatus('monitorsDesk', filteredMonitorDesk);
      }
      if (filteredMonitorPortable.length > 0) {
        await updateEquipmentStatus('monitorsPortable', filteredMonitorPortable);
      }
      if (filteredExtensionCords.length > 0) {
        await updateEquipmentStatus('extensionCords', filteredExtensionCords);
      }
      if (filteredPowerBank.length > 0) {
        await updateEquipmentStatus('powerBanks', filteredPowerBank);
      }
      if (filteredBackupBaterries.length > 0) {
        await updateEquipmentStatus('backupBatteries', filteredBackupBaterries);
      }
      if (filteredUSB.length > 0) {
        await updateEquipmentStatus('usbs', filteredUSB);
      }
      if (filteredDockingsStation.length > 0) {
        await updateEquipmentStatus('dockingStations', filteredDockingsStation);
      }
      if (filteredSpeakers.length > 0) {
        await updateEquipmentStatus('speakers', filteredSpeakers);
      }
      if (filteredtablets.length > 0) {
        await updateEquipmentStatus('tabletRemarkables', filteredtablets);
      }
      if (filteredHeadset.length > 0) {
        await updateEquipmentStatus('headsets', filteredHeadset);
      }
      if (filteredExternalDisk.length > 0) {
        await updateEquipmentStatus('externalDisks', filteredExternalDisk);
      }
      if (filteredgeneralInventorys.length > 0) {
        await updateEquipmentStatus('generalInventories', filteredgeneralInventorys);
      }
      if (filteredBag.length > 0) {
        await updateEquipmentStatus('bags', filteredBag);
      }
      if (filteredClickCounter.length > 0) {
        await updateEquipmentStatus('clickCounters', filteredClickCounter);
      }

      // Eliminar empleado de la tabla actual
      await removeEmployees({
        variables: { input: { id: employeeData.id } },
      });

      console.log("Empleado eliminado de la tabla actual");
      setShowEmployeesAddOverlay(false);
      setIsEditing(false);
    } catch (error) {
      console.error("Error en el proceso:", error);
    }
  };


  const handleUpdateEmployees = async () => {

    // Check that phone_number is exactly 10 digits and contains only numbers
    let phoneNumber = selectedEmployees.phone_number ? String(selectedEmployees.phone_number).trim() : null;

    if (phoneNumber === null || phoneNumber === '') {
      // Si está vacío, envía null a GraphQL
      phoneNumber = null;
      setPhoneError('');
    } else if (!/^\d{10}$/.test(phoneNumber)) {
      // Si el número no tiene exactamente 10 dígitos, muestra un error
      console.log(phoneNumber);
      setPhoneError('Phone number must be exactly 10 digits and contain only numbers.');
      return;
    } else {
      // Si es válido, limpia el error
      setPhoneError('');
    }

    // Check ThreeCX ID must not be empty and must be an integer
    if (!selectedEmployees.ThreeCX_id) {
      setThreeCX_idError('The ThreeCX ID cannot be empty.');
      console.log('ThreeCX ID validation failed: Empty value');
      return;
    }

    const isThreeCXIdInteger = Number.isInteger(Number(selectedEmployees.ThreeCX_id));

    if (!isThreeCXIdInteger) {
      setThreeCX_idError('The ThreeCX ID must be an integer.');
      console.log('ThreeCX ID validation failed: Not an integer');
      return;
    } else {
      setThreeCX_idError('');
    }

    // Check email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const isValidEmail = (email) => emailPattern.test(email);

    const validateEmails = (thc_email_id, gmail_email_id, apple_id) => {
      let isValid = true;

      if (thc_email_id === 'N/A' || thc_email_id === null || thc_email_id === '') {
        // Si el valor es 'N/A', null o una cadena vacía, no hay error
        setThcEmailIdError('');
      } else if (!isValidEmail(thc_email_id)) {
        // Si no es un email válido, muestra el error
        setThcEmailIdError('THC Email ID is not a valid email address.');
        console.log('THC Email ID validation failed');
        isValid = false;
      } else {
        // Si es un email válido, limpia el error
        setThcEmailIdError('');
      }

      if (gmail_email_id === 'N/A' || gmail_email_id === null || gmail_email_id === '') {
        // Si el valor es 'N/A', null o una cadena vacía, no hay error
        setGmailEmailIdError('');
      } else if (!isValidEmail(gmail_email_id)) {
        // Si no es un email válido, muestra el error
        setGmailEmailIdError('Gmail Email ID is not a valid email address.');
        console.log('THC Gmail ID validation failed');
        isValid = false;
      } else {
        // Si es un email válido, limpia el error
        setGmailEmailIdError('');
      }

      if (apple_id && !isValidEmail(apple_id)) {
        setAppleIdError('Apple ID is not a valid email address.');
        console.log('Apple ID validation failed');
        isValid = false;
      } else {
        setAppleIdError('');
      }

      return isValid;
    };

    // Obtener los datos y validar
    const { thc_email_id, gmail_email_id, apple_id } = selectedEmployees;
    const areEmailsValid = validateEmails(thc_email_id, gmail_email_id, apple_id);

    if (areEmailsValid) {
      // Continúa con el proceso de envío
      console.log('All email addresses are valid.');
    } else {
      // Maneja el error
      console.log('One or more email addresses are invalid.');
    }


    updEmployees({
      variables: {
        input: {
          id: selectedEmployees.id || '',
          ThreeCX_id: selectedEmployees.ThreeCX_id || '',
          ThreeCX_password: selectedEmployees.ThreeCX_password || '',
          first_name: selectedEmployees.first_name || '',
          last_name: selectedEmployees.last_name || '',
          thc_email_id: selectedEmployees.thc_email_id || null,
          thc_email_password: selectedEmployees.thc_email_password || '',
          gmail_email_id: selectedEmployees.gmail_email_id || null,
          gmail_email_password: selectedEmployees.gmail_email_password || '',
          apple_id: selectedEmployees.apple_id || null,
          apple_password: selectedEmployees.apple_password || '',
          phone_number: phoneNumber || '',
          navigator_id: selectedEmployees.navigator_id || "",
          team: selectedEmployees.team || '',
          location: selectedEmployees.location || '',
          notes: selectedEmployees.notes || '',
        }
      }
    }
    ).then(() => {
      setIsEditing(true);
      setShowEmployeesAddOverlay(false);
      setThreeCX_idError('');
      setDuplicateError('');
      setThcEmailIdError('');
      setGmailEmailIdError('');
      setAppleIdError('');
      setPhoneError('');

    }).catch(error => {
      console.error("Error updating employees:", error);
    });
  };

  const handleEmployeesAction = (employees, action = 'edit') => {
    setSelectedEmployees(employees);

    if (action === 'edit') {
      setOverlayType('edit'); // Configura el tipo de overlay como 'edit'
      setIsEditing(true); // Habilita el modo de edición
      setShowEmployeesAddOverlay(true); // Muestra el overlay para agregar/editar empleados
    } else if (action === 'inventory') {
      setOverlayType('inventory'); // Configura el tipo de overlay como 'view' o cualquier otro
      setIsEditing(true); // Desactiva el modo de edición
      setShowEmployeesAddOverlay(false); // Oculta el overlay de agregar empleados
      setShowEmployeesShowInventoryOverlay(true); // Muestra el overlay del inventario (si aplica)
    }
  };


  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };


  const laptops = dataLaptops?.listLaptops || [];
  const printers = dataPrinters?.listPrinters || [];
  const scanners = dataScanners?.listScanners || [];
  const phones = dataPhones?.listPhones || [];
  const headsets = dataHeadset?.listHeadsets || [];
  const mice = dataMice?.listMice || [];
  const keyboards = dataKeyboard?.listKeyboards || [];
  const monitors = dataMonitors?.listMonitors || [];
  const extensionCords = dataExtensionCords?.listExtensionCords || [];
  const BackupBaterrys = dataBackupBatteries?.listBackupBatteries || [];
  const bags = dataBags?.listBags || [];
  const supplies = dataSupplies?.listSupplies || [];
  const usbs = dataUSBs?.listUSBS || [];
  const DockingStations = dataDockingStations?.listDockingStations || [];
  const speakers = dataSpeakers?.listSpeakers || [];
  const tablets = dataTablets?.listTabletRemarkables || [];
  const externoDisks = dataExternoDisks?.listExternalDisks || [];
  const clickCounter = dataClickCounter?.listClickCounters || [];
  const PowerBanks = dataPowerBank?.listPowerBanks || [];
  const generalInventory = dataGeneralInventory?.listGeneralInventories || [];
  const EquipmentRemoved = dataEquipmentRemoved?.listDeleteEquipmentHistories || [];

  // laptop 
  const filteredLaptops = React.useMemo(() => {
    if (!Array.isArray(laptops?.items)) return [];

    return laptops.items.filter(laptop => {
      // console.log('Printer Assigned To:', laptop.assigned_to); // Imprime el valor de assigned_to para cada impresora

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return laptop.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [laptops?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);

  //printers  
  const filteredPrinters = React.useMemo(() => {
    if (!Array.isArray(printers?.items)) return [];

    return printers.items.filter(printer => {
      // console.log('Printer Assigned To:', printer.assigned_to); // Imprime el valor de assigned_to para cada impresora

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return printer.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [printers?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);

  //scanners 
  const filteredScanners = React.useMemo(() => {
    if (!Array.isArray(scanners?.items)) return [];

    return scanners.items.filter(scanner => {
      // console.log('Printer Assigned To:', scanner.assigned_to); // Imprime el valor de assigned_to para cada impresora

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return scanner.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [scanners?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);

  //phone 
  const filteredPhones = React.useMemo(() => {
    if (!Array.isArray(phones?.items)) return [];


    return phones.items.filter(phone => {

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return phone.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [phones?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);

  //Headset 
  const filteredHeadset = React.useMemo(() => {
    if (!Array.isArray(headsets?.items)) return [];


    return headsets.items.filter(headset => {

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return headset.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [headsets?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);

  //Mouse
  const filteredMouse = React.useMemo(() => {
    if (!Array.isArray(mice?.items)) return [];


    return mice.items.filter(mouse => {

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return mouse.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [mice?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);

  //Keyboard
  const filteredKeyboard = React.useMemo(() => {
    if (!Array.isArray(keyboards?.items)) return [];


    return keyboards.items.filter(keyboard => {

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return keyboard.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [keyboards?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);

  //Monitor Desk
  const filteredMonitorDesk = React.useMemo(() => {
    if (!Array.isArray(monitors?.items)) return [];


    return monitors.items.filter(monitor => {
      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return monitor.assigned_to === `${employeeFirstName} ${employeeLastName}` && monitor.monitor_type === "desk";

    });
  }, [monitors?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);

  //Monitor Portable
  const filteredMonitorPortable = React.useMemo(() => {
    if (!Array.isArray(monitors?.items)) return [];


    return monitors.items.filter(monitor => {
      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return monitor.assigned_to === `${employeeFirstName} ${employeeLastName}` &&
        ["portable", "portable_screen", "mobile_pixels_trio_max"].includes(monitor.monitor_type);


    });
  }, [monitors?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);
  //extensionCords
  const filteredExtensionCords = React.useMemo(() => {
    if (!Array.isArray(extensionCords?.items)) return [];


    return extensionCords.items.filter(extensionCord => {

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return extensionCord.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [extensionCords?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);

  //backupBaterries
  const filteredBackupBaterries = React.useMemo(() => {
    if (!Array.isArray(BackupBaterrys?.items)) return [];


    return BackupBaterrys.items.filter(backupBaterry => {

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return BackupBaterrys.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [BackupBaterrys?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);

  //bag
  const filteredBag = React.useMemo(() => {
    if (!Array.isArray(bags?.items)) return [];


    return bags.items.filter(bag => {

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return bag.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [bags?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);

  //Supplies
  const filteredSupplies = React.useMemo(() => {
    if (!Array.isArray(supplies?.items)) return [];


    return supplies.items.filter(supplie => {

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return supplie.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [supplies?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);

  //Docking Station
  const filteredDockingsStation = React.useMemo(() => {
    if (!Array.isArray(DockingStations?.items)) return [];


    return DockingStations.items.filter(docking => {

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return docking.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [DockingStations?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);


  //USB
  const filteredUSB = React.useMemo(() => {
    if (!Array.isArray(usbs?.items)) return [];


    return usbs.items.filter(usb => {

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return usb.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [usbs?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);


  //Speakers
  const filteredSpeakers = React.useMemo(() => {
    if (!Array.isArray(speakers?.items)) return [];


    return speakers.items.filter(speaker => {

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return speaker.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [speakers?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);

  //Tablets
  const filteredtablets = React.useMemo(() => {
    if (!Array.isArray(tablets?.items)) return [];


    return tablets.items.filter(tablet => {

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return tablet.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [tablets?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);


  //Externasl Disk
  const filteredExternalDisk = React.useMemo(() => {
    if (!Array.isArray(externoDisks?.items)) return [];


    return externoDisks.items.filter(externoDisk => {

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return externoDisk.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [externoDisks?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);

  //Power Bank
  const filteredPowerBank = React.useMemo(() => {
    if (!Array.isArray(PowerBanks?.items)) return [];


    return PowerBanks.items.filter(PowerBanks => {

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return PowerBanks.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [PowerBanks?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);

  //Click Counter
  const filteredClickCounter = React.useMemo(() => {
    if (!Array.isArray(clickCounter?.items)) return [];


    return clickCounter.items.filter(clickCounter => {

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return clickCounter.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [clickCounter?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);

  //other equipment
  const filteredgeneralInventorys = React.useMemo(() => {
    if (!Array.isArray(generalInventory?.items)) return [];


    return generalInventory.items.filter(generalInventory => {

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return generalInventory.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [generalInventory?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);


  // EquipmentRemoved 
  const filteredDeleteEquipmenteHistory = React.useMemo(() => {
    if (!Array.isArray(EquipmentRemoved?.items)) return [];

    return EquipmentRemoved.items.filter(EquipmentRemoved => {
      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return EquipmentRemoved.previous_employees === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [EquipmentRemoved?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);


  // Datos  de equipos disponibles
  // Crear el inventario desde las props o variables globales
  const inventory = useMemo(
    () => ({
      laptops: laptops?.items || [],
      phones: phones?.items || [],
      printers: printers?.items || [],
      scanners: scanners?.items || [],
      headsets: headsets?.items || [],
      monitors: monitors?.items || [],
      PowerBanks: PowerBanks?.items || [],
      mice: mice?.items || [],
      keyboards: keyboards?.items || [],
      DockingStations: DockingStations?.items || [],
    }),
    [laptops, phones, printers, scanners, headsets, monitors, PowerBanks, mice, keyboards, DockingStations]
  );

  // Otros equipos para asignar 
  const other = useMemo(
    () => ({
      extensionCords: extensionCords?.items || [],
      BackupBaterrys: BackupBaterrys?.items || [],
      speakers: speakers?.items || [],
      usbs: usbs?.items || [],
      tablets: tablets?.items || [],
      externoDisks: externoDisks?.items || [],
      generalInventory: generalInventory?.items || [],
      bags: bags?.items || [],
      clickCounter: clickCounter?.items || [],
      supplies: supplies?.items || [],
    }),
    [extensionCords, BackupBaterrys, speakers, usbs, tablets, externoDisks, generalInventory, bags, clickCounter, supplies]
  );
  const friendlyNames = {
    extensionCords: "Extension Cords",
    BackupBaterrys: "Backup Batteries",
    speakers: "Speakers",
    usbs: "USB Drives",
    tablets: "Tablets",
    externoDisks: "External Disks",
    generalInventory: "Media && Other Equipment",
    bags: "Bags",
    clickCounter: "Click Counters",
    supplies: "Supplies",
  };


  // Filtrar elementos disponibles según la selección solo para Navigator Program
  const filteredItems = useMemo(() => {
    console.log("Filtering Available Items:", availableItems);

    if (!Array.isArray(availableItems)) return [];
    return availableItems.filter((item) => {
      // Asegúrate de que el elemento está disponible
      if (item.available !== true) return false;

      // Normaliza el tagid para evitar problemas de mayúsculas/minúsculas o espacios
      const normalizedTagId = item.tagid?.toString().toLowerCase().trim() || item.tagId?.toString().toLowerCase().trim()

      // Filtra los elementos que tengan TAG que no comiencen con 5 ni contengan la palabra thc
      return !(normalizedTagId?.startsWith('5')) && !(normalizedTagId?.includes('thc'));


    });
  }, [availableItems]);

  // Filtrar elementos disponibles según la selección solo para THC
  const filteredItemsTHC = useMemo(() => {
    console.log("Filtering Available Items:", availableItems);

    if (!Array.isArray(availableItems)) return [];

    return availableItems.filter((item) => {
      // Asegúrate de que el elemento está disponible
      if (item.available !== true) return false;

      // Normaliza el tagid para evitar problemas de mayúsculas/minúsculas o espacios
      const normalizedTagId = item.tagid?.toString().toLowerCase().trim() || item.tagId?.toString().toLowerCase().trim();

      // Filtra los elementos que tengan TAG que comiencen con 5 y tengan valor THC
      return (typeof normalizedTagId === 'string' && normalizedTagId.startsWith('5')) || normalizedTagId === "thc";

    });
  }, [availableItems]);

  // Filtrar otros elementos disponibles 
  const filteredItemsOther = useMemo(() => {
    if (!Array.isArray(availableOthersItems)) return [];

    const filtered = availableOthersItems.filter((item) => {
      if (item.available !== true) {
        console.log("Item no pasó el filtro (no disponible):", item);
        return false;
      }

      // Normaliza el tagid (o tagId) para evitar problemas de mayúsculas/minúsculas o espacios
      const normalizedTagId = item.tagid?.toString().toLowerCase().trim() || item.tagId?.toString().toLowerCase().trim();

      // Permitir elementos con tagId válido o vacío
      if (!normalizedTagId) {
        console.log("Item con tagId vacío (incluido en el filtro):", item);
        return true; // Incluye los elementos con `tagId` vacío
      }

      // Si tiene un tagId válido, también pasa el filtro
      console.log("Item con tagId válido (incluido en el filtro):", item);
      return true;
    });

    console.log("Filtered Items:", filtered); // Verifica los elementos que pasaron el filtro
    return filtered;
  }, [availableOthersItems]);

  // Manejar el cambio de los checkboxes
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;

    console.log("Selected Value:", value); // Verifica el valor seleccionado
    console.log("Inventory Key Exists:", inventory[value] ? "Yes" : "No"); // ¿La clave existe?

    setSelectedEquipment((prev) => {
      if (!Array.isArray(prev)) return checked ? [value] : []; // Maneja casos inesperados

      const updatedSelection = checked
        ? [...prev, value]
        : prev.filter((item) => item !== value);

      // Si no hay elementos seleccionados, limpia el estado de los elementos disponibles
      if (updatedSelection.length === 0) {
        setAvailableItems([]);
        console.log("No items selected, clearing available items.");
      }

      return updatedSelection;
    });

    // Intentar asignar items desde inventory si el checkbox está seleccionado
    if (checked) {
      const selectedItems = inventory[value] || []; // Obtén los items correspondientes
      console.log("Selected Items from Inventory:", selectedItems); // Verifica el contenido de los items seleccionados
      setAvailableItems(selectedItems); // Actualiza el estado con los items seleccionados
    }
  };



  // Asignar elementos seleccionados
  const handleAssign = async (item) => {

    console.log("Item Passed to Mutation:", item);
    if (!item || !item.__typename) {
      setMessage("The selected item is invalid or does not have a valid type.");
      setMessageType("error"); // Define el tipo de mensaje (opcional)
      setTimeout(() => setMessage(""), 2000);
      return;
    }

    if (!isEditing) {
      if (!newEmployees.first_name || !newEmployees.last_name) {
        setMessage("Employee information is incomplete. Please provide both first and last name.");
        setMessageType("error"); // Define el tipo de mensaje (opcional)
        setTimeout(() => setMessage(""), 2000);
        return;
      }
    } else {


      if (!selectedEmployees.first_name || !selectedEmployees.last_name) {
        setMessage("Please update both first and last name for the employee.");
        setMessageType("error"); // Define el tipo de mensaje (opcional)
        setTimeout(() => setMessage(""), 2000);
        return;
      }
    }
    const assignment_dateNew = formatDate(new Date());
    console.log("Formatted Assignment Date:", assignment_dateNew);

    try {
      // Crear el objeto actualizado
      const updatedItem = {

        input: {
          id: item.id,
          available: false,
          status: "use",
          assignment_date: assignment_dateNew,
          assigned_to: isEditing
            ? `${capitalize(selectedEmployees.first_name)} ${capitalize(selectedEmployees.last_name)}`
            : `${capitalize(newEmployees.first_name)} ${capitalize(newEmployees.last_name)}`,
        },
      };
      console.log("Typename:", item.__typename);
      console.log("Typename (trimmed):", item.__typename.trim());
      console.log("Exact Typename Match:", item.__typename === "BackupBattery");

      // Ensure typename is lowercase and trimmed
      const typename = item.__typename.toLowerCase();

      // Seleccionar la mutación correspondiente según el tipo del ítem
      switch (typename) {
        case "laptop":
          await updatelaptop({ variables: updatedItem });
          break;
        case "phone":
          await updatephone({ variables: updatedItem });
          break;
        case "scanner":
          await updatescanner({ variables: updatedItem });
          break;
        case "printer":
          await updateprinter({ variables: updatedItem });
          break;
        case "headset":
          await updateheadset({ variables: updatedItem });
          break;
        case "monitor":
          await updatemonitor({ variables: updatedItem });
          break;
        case "mouse":
          await updatemouse({ variables: updatedItem });
          break;
        case "keyboard":
          await updatekeyboard({ variables: updatedItem });
          break;
        case "powerbank":
          await updatepowerBank({ variables: updatedItem });
          break;
        case "dockingstation":
          await updatedockingStation({ variables: updatedItem });
          break;
        case "extensioncord":
          await updateextensionCord({ variables: updatedItem });
          break;
        case "backupbattery":
          await updatebackupBattery({ variables: updatedItem });
          break;
        case "speaker":
          await updatespeaker({ variables: updatedItem });
          break;
        case "usb":
          await updateuSB({ variables: updatedItem });
          break;
        case "tabletremarkable":
          await updatetabletRemarkable({ variables: updatedItem });
          break;
        case "externaldisk":
          await updateexternalDisk({ variables: updatedItem });
          break;
        case "generalinventory":
          await updategeneralInventory({ variables: updatedItem });
          break;
        case "bag":
          await updatebag({ variables: updatedItem });
          break;
        case "clickcounter":
          await updateclickCounter({
            variables: {
              input: {
                id: item.id,
                available: false,
                assigned_to: isEditing
                  ? `${capitalize(selectedEmployees.first_name)} ${capitalize(selectedEmployees.last_name)}`
                  : `${capitalize(newEmployees.first_name)} ${capitalize(newEmployees.last_name)}`,
              },
            },
          });
          break;

        default:
          alert(`Unknown item type: ${item.__typename}`);
          return;
      }


      // Actualiza la lista de elementos disponibles excluyendo el seleccionado
      setAvailableItems((prevItems) => prevItems.filter((i) => i.id !== selectedItem.id));

      // Limpia el ítem seleccionado
      setSelectedItem(null);

      setMessage(`Item "${item.tagid}" successfully assigned to ${updatedItem.input.assigned_to}.`);
      setMessageType("success");
      setTimeout(() => setMessage(""), 3000);
    } catch (error) {
      console.error("Error assigning item:", error);
      alert("Failed to assign the item. Please try again.");
    }
  };
  //Other Available Equipment
  const handleButtonClick = () => {
    setShowSelect(!showSelect); // Alterna la visibilidad del <select>
  };

  const handleSelectChange = (e) => {
    const option = e.target.value;
    setSelectedOption(option);

    // Verifica si la clave existe en `other`
    if (other[option]) {
      console.log("Other Key Exists: Yes");

      // Actualiza los items disponibles
      const selectedOtherItem = other[option]; // Obtén los items de la clave seleccionada
      console.log("Selected Items from Inventory:", selectedOtherItem);

      setAvailableOthersItems(selectedOtherItem); // Actualiza el estado de los items disponibles
      setSelectedOtherItem(option); // Actualiza el estado de la opción seleccionada
    } else {
      console.log("Other Key Exists: No");

      // Si no existe, limpia los estados
      setAvailableOthersItems([]);
      setSelectedOption('');
    }
  };
  const handleSelectLaptop = (laptop) => {
    setSelectedLaptop(laptop);
    setIsLaptopOverlayOpen(true);
  };
  const handleSelectPrinter = (printer) => {
    setSelectedPrinter(printer);
    setIsPrinterOverlayOpen(true);
  };
  const handleSelectScanner = (scanner) => {
    setSelectedScanner(scanner);
    setIsScannerOverlayOpen(true);
  };
  const handleSelectPhone = (phone) => {
    setSelectedPhone(phone);
    setIsPhoneOverlayOpen(true);
  };
  const handleSelectHeadset = (headset) => {
    setSelectedHeadset(headset);
    setIsHeadseteOverlayOpen(true);
  };
  const handleSelectMouse = (mouse) => {
    setSelectedMouse(mouse);
    setIsMouseOverlayOpen(true);
  };
  const handleSelectKeyboard = (keyboard) => {
    setSelectedKeyboard(keyboard);
    setIsKeyboardOverlayOpen(true);
  };
  const handleSelectMonitor = (monitor) => {
    setSelectedMonitor(monitor);
    setIsMonitorOverlayOpen(true);
  };
  const handleSelectExtensionCord = (extensionCord) => {
    setSelectedExtensionCord(extensionCord);
    setIsExtensionCordOverlayOpen(true);
  };
  const handleSelectBackupBaterry = (backupBaterry) => {
    setSelectedBackupBaterry(backupBaterry);
    setIsBackupBaterryOverlayOpen(true);
  };
  const handleSelectBag = (bag) => {
    setSelectedBag(bag);
    setIsBagOverlayOpen(true);
  };
  const handleSelectSupplies = (supplies) => {
    setSelectedSupplies(supplies);
    setIsSuppliesOverlayOpen(true);
  };
  const handleSelectUSB = (usb) => {
    setSelectedUSB(usb);
    setIsUSBOverlayOpen(true);
  };
  const handleSelectDockingStation = (dockingstation) => {
    setSelectedDockingStation(dockingstation);
    setIsDockingStationOverlayOpen(true);
  };
  const handleSelectSpeaker = (speaker) => {
    setSelectedSpeaker(speaker);
    setIsSpeakerOverlayOpen(true);
  };

  const handleSelectPowerBank = (powerBank) => {
    setSelectedPowerBank(powerBank);
    setIsPowerBankOverlayOpen(true);
  };
  const handleSelectTablet = (tablet) => {
    setSelectedTablet(tablet);
    setIsTabletOverlayOpen(true);
  };
  const handleSelectExternoDisks = (externoDisk) => {
    setSelectedExternoDisk(externoDisk);
    setIsExternoDiskOverlayOpen(true);
  };
  const handleSelectGeneralInventory = (generalInventory) => {
    setSelectedGeneralInventory(generalInventory);
    setIsGeneralInventoryOverlayOpen(true);
  };
  const updateFunctions = {
    bags: updatebag,
    supplies: updatesupplies,
    generalInventories: updategeneralInventory,
    externalDisks: updateexternalDisk,
    headsets: updateheadset,
    tabletRemarkables: updatetabletRemarkable,
    speakers: updatespeaker,
    dockingStations: updatedockingStation,
    usbs: updateuSB,
    backupBatteries: updatebackupBattery,
    powerBanks: updatepowerBank,
    extensionCords: updateextensionCord,
    monitorsDesk: updatemonitor,
    monitorsPortable: updatemonitor,
    keyboards: updatekeyboard,
    mice: updatemouse,
    laptops: updatelaptop,
    phones: updatephone,
    scanners: updatescanner,
    printers: updateprinter,
    // No fue necesario hacerle tambien un overlay a ClickCounter
  };


  const handleSaveItem = async (type, updatedItem) => {
    console.log("Item recibido para guardar:", updatedItem.__typename);

    if (!updatedItem || typeof updatedItem !== "object") {
      console.error("updatedItem no es válido:", updatedItem);
      return;
    }
    // Crear el objeto filtrado con los campos requeridos
    const filteredItemToUpdate = {
      id: updatedItem.id,
      // Usar tagId solo si el tipo es "Mice" o "Supplies", de lo contrario usar tagid pero excluir bag and Click Counter
      ...(type === "mice" || type === "supplies"
        ? { tagId: updatedItem.tagId || null }
        : !["bags", "clickCounters"].includes(type) && { tagid: updatedItem.tagid || null }),

      assignment_date: updatedItem.assignment_date
        ? new Date(updatedItem.assignment_date).toISOString().split("T")[0]
        : null,

      // Agregar Status solo si es diferente de Supplies
      ...(!["supplies"].includes(type) && {
        status: updatedItem.status || null,
      }),
      // Agregar Serial Number solo si es diferente de bag, clickcounter..
      ...(!["bags", "clickCounters", "supplies"].includes(type) && {
        serial_Number: updatedItem.serial_Number || null,
      }),
      // Agregar charger_cable solo si laptops, phones...
      ...(["laptops", "phones", "tabletRemarkables", "printers", "scanners", "headsets", "monitorsDesk", "monitorsPortable", "externalDisks"].includes(type) && {
        charger_cable: updatedItem.charger_cable || null,
      }),

      // Agregar password solo si laptops, phones...
      ...(["laptops", "phones", "tabletRemarkables"].includes(type) && {
        password: updatedItem.password || null,
      }),

      // Agregar Connector_cable solo si es printers, scanners
      ...(["printers", "scanners", "monitorsDesk", "monitorsPortable"].includes(type) && {
        Connector_cable: updatedItem.Connector_cable || null,
      }),

      // Agregar pin solo si phones
      ...(["phones"].includes(type) && {
        pin: updatedItem.password || null,
      }),
      // Agregar aux_cable solo si headset
      ...(["headsets"].includes(type) && {
        aux_cable: updatedItem.Connector_cable || null,
      }),

    };

    console.log("Item filtrado para actualizar:", filteredItemToUpdate);

    if (!updateFunctions[type]) {
      console.error(`Tipo de equipo desconocido: ${type}`);
      alert(`Error: Tipo de equipo desconocido (${type}).`);
      return;
    }

    try {
      // Llama a la función de actualización específica
      const result = await updateFunctions[type]({
        variables: { input: filteredItemToUpdate },
      });
      console.log(`Resultado de la llamada a ${type}:`, result);


    } catch (error) {
      console.error(`Error al actualizar el ${type}:`, error);
      alert(`Error al actualizar el ${type}. Por favor, inténtalo de nuevo.`);
    } finally {
      // Limpia los objetos seleccionados después de actualizar
      setSelectedPrinter({});
      setSelectedLaptop({});
      setSelectedScanner({});
      setSelectedPhone({});
      setSelectedHeadset({});
      setSelectedMouse({});
      setSelectedKeyboard({});
      setSelectedMonitor({});
      setSelectedExtensionCord({});
      setSelectedBackupBaterry({});
      setSelectedBag({});
      setSelectedSupplies({});
      setSelectedUSB({});
      setSelectedSpeaker({});
      setSelectedDockingStation({});
      setSelectedPowerBank({});
      setSelectedTablet({});
      setSelectedExternoDisk({});
      setSelectedGeneralInventory({});
      // Limpia el filtro después de la actualización
      filteredItemToUpdate.id = null;
      filteredItemToUpdate.tagid = null;
      filteredItemToUpdate.serial_Number = null;
      filteredItemToUpdate.status = null;
      filteredItemToUpdate.charger_cable = null;
      filteredItemToUpdate.assignment_date = null;
      delete filteredItemToUpdate.password;
      delete filteredItemToUpdate.pin;
      delete filteredItemToUpdate.Connector_cable;

      console.log("Filtro limpiado:", filteredItemToUpdate);
    }
  };

  return (
    <div className="employees-page">
      <div className="employees-header">
        <h1>Employees</h1>
      </div>
      <Inventory_header />
      <div className="employees-search">
        <button onClick={handleSaveAsExcel}>
          <FaFileExcel /> Export
        </button>
        {/* Botón para mostrar u ocultar las contraseñas */}
        <button onClick={togglePasswords}>
          {showPasswords ? 'Hide Passwords' : 'Show Passwords'}
        </button>
      </div>
      <div className="searchBar">
        <button className="add-employees-btn" onClick={() => {
          setSelectedEmployees(null);
          setShowEmployeesAddOverlay(true);
          setShowEmployeesShowInventoryOverlay(false);
          setOverlayType('edit');
          clearNewEmployess();
          setIsEditing(false);
          setShowSelect(false);
          setAvailableOthersItems([]);
        }}>+ Add New Employee</button>
        <SearchBar searchTerm={searchTerm} onSearch={setSearchTerm} />
      </div>

      <div className="employees-table-container">

        <table className="employees-table">
          <thead>
            <tr>
              <th>#</th> { }

              <th onClick={() => requestSort('ThreeCX_id')}>
                3CX ID
                {sortConfig?.key === 'ThreeCX_id' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('ThreeCX_password')}>
                3CX Password
                {sortConfig?.key === 'ThreeCX_password' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('first_name')}>
                First_name
                {sortConfig?.key === 'first_name' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('last_name')}>
                Last_name
                {sortConfig?.key === 'last_name' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('thc_email_id')}>
                Thc email ID
                {sortConfig?.key === 'thc_email_id' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('thc_email_password')}>
                Thc email Password
                {sortConfig?.key === 'thc_email_password' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('gmail_email_id')}>
                Gmail email Id
                {sortConfig?.key === 'gmail_email_id' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('gmail_email_password')}>
                Gmail email Password
                {sortConfig?.key === 'gmail_email_password' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('apple_id')}>
                Apple ID
                {sortConfig?.key === 'apple_id' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('apple_password')}>
                Apple Password
                {sortConfig?.key === 'apple_password' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('phone_number')}>
                Phone Number
                {sortConfig?.key === 'phone_number' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('navigator_id')}>
                Navigator ID
                {sortConfig?.key === 'navigator_id' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('team')}>
                Team
                {sortConfig?.key === 'team' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>

              <th onClick={() => requestSort('location')}>

                Location
                {sortConfig?.key === 'location' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('notes')}>

                Notes
                {sortConfig?.key === 'notes' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('createdAt')}>
                Create Date
                {sortConfig?.key === 'createdAt' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('updatedAt')}>
                Update Date
                {sortConfig?.key === 'updatedAt' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th >View</th>

            </tr>
          </thead>
          <tbody>
            {filteredEmployees.map((employees, index) => (
              <tr key={employees.id} onClick={() => handleEmployeesAction(employees, 'edit')}>
                <td>{index + 1}</td> {/* Número de fila */}
                <td className="employees-ThreeCX_id">{employees.ThreeCX_id}</td>
                <td className="employees-ThreeCX-password">{showPasswords ? String(employees.ThreeCX_password) : '******'}</td>
                <td className="employees-first_name">{capitalize(employees.first_name.toLowerCase())}</td>
                <td className="employees-last_name">{capitalize(employees.last_name.toLowerCase())}</td>
                <td className="employees-thc_email_id">{employees.thc_email_id ? String(employees.thc_email_id).toLowerCase() : 'N/A'}</td>
                {/* Mostrar contraseña o asteriscos según showPasswords */}
                <td className="employees-thc_email_password">
                  {showPasswords ? String(employees.thc_email_password) : '******'}
                </td>
                <td className="employees-gmail_email_id">{employees.gmail_email_id ? String(employees.gmail_email_id).toLowerCase() : 'N/A'}</td>
                {/* Mostrar la contraseña de Gmail o puntos según showPasswords */}
                <td className="employees-gmail_email_password">
                  {showPasswords ? String(employees.gmail_email_password) : '******'}
                </td>
                <td className="employees-apple_id">{employees.apple_id ? String(employees.apple_id).toLowerCase() : 'N/A'}</td>
                <td className="employees-apple_password">{showPasswords ? String(employees.apple_password) : '******'}</td>
                <td className="employees-phone_number">{employees.phone_number}</td>
                <td className="employees-navigator_id">{String(employees.navigator_id)}</td>
                <td className="employees-team">{employees.team}</td>
                <td className="employees-location">{employees.location}</td>
                <td className="employees-notes" id="employees-notes">{employees.notes}</td>
                <td className="employees-createdAt">{employees.createdAt ? new Date(employees.createdAt).toLocaleString() : 'N/A'} </td>
                <td className="employees-updatedAt">{employees.updatedAt ? new Date(employees.updatedAt).toLocaleString() : 'N/A'}</td>

                <td className='employees-table'>
                  <button className="delete-employees-btn" onClick={(e) => { e.stopPropagation(); handleEmployeesAction(employees, 'inventory') }}> Inventory</button>
                </td>
              </tr>

            ))}
          </tbody>
        </table>
      </div>


      {
        nextToken && !loading && (
          <button onClick={loadMore}>Load More</button>
        )
      }

      {loading && <p>Loading...</p>}

      {
        overlayType === 'edit' && showEmployeesAddOverlay && (

          <div className="employees_add_overlay" onClick={(e) => {
            // Close overlay only if clicked outside the modal content
            if (e.target.classList.contains("employees_add_overlay")) {
              setShowEmployeesAddOverlay(false);
              clearNewEmployess();
              setSelectedEmployees(null);
              setIsEditing(false);
              setSelectedItem(null);
              setSelectedEquipment([]);
              setAvailableItems([]);
              setShowSelect(false);
              setAvailableOthersItems([]);
            }
          }}>
            <div className="overlay-content-employees">
              <div className="header-row">
                <h2 className="header-title">{isEditing ? 'Edit Employee' : 'Add New Employee'}</h2>
                <button onClick={() => {
                  setShowEmployeesAddOverlay(false);
                  clearNewEmployess();
                  setSelectedEmployees(null);
                  setIsEditing(false);
                  setSelectedItem(null);
                  setSelectedEquipment([]);
                  setAvailableItems([]);
                  setShowSelect(false);
                  setAvailableOthersItems([]);

                }}>X</button>
              </div>
              <label>3CX ID</label>
              <input name="ThreeCX_id" placeholder="3CX ID" value={isEditing ? selectedEmployees.ThreeCX_id : newEmployees.ThreeCX_id} onChange={handleInputChange} required />

              <label>3CX Password</label>
              <input
                type={showPasswords ? "text" : "password"} // Alternar entre "text" y "password"
                name="ThreeCX_password"
                placeholder="3CX Password"
                value={isEditing ? selectedEmployees.ThreeCX_password : newEmployees.ThreeCX_password}
                onChange={handleInputChange}
                required
              />
              <label>First name </label>
              <input name="first_name" placeholder="First name" value={isEditing ? selectedEmployees.first_name : newEmployees.first_name} onChange={handleInputChange} required />
              <label>Last name</label>
              <input name="last_name" placeholder="Last Name" value={isEditing ? selectedEmployees.last_name : newEmployees.last_name} onChange={handleInputChange} required />
              <label>THC Email ID</label>
              <input name="thc_email_id" placeholder="THC Email ID" value={isEditing ? selectedEmployees.thc_email_id : newEmployees.thc_email_id} onChange={handleInputChange} required />
              <label>THC Email Password</label>
              <input type={showPasswords ? "text" : "password"} name="thc_email_password" placeholder="THC Email Password" value={isEditing ? selectedEmployees.thc_email_password : newEmployees.thc_email_password} onChange={handleInputChange} required />
              <label>Gmail Email ID</label><input name="gmail_email_id" placeholder="Gmail Email ID" value={isEditing ? selectedEmployees.gmail_email_id : newEmployees.gmail_email_id} onChange={handleInputChange} required />
              <label>Gmail Email Password</label>
              <input type={showPasswords ? "text" : "password"} name="gmail_email_password" placeholder="Gmail Email Password" value={isEditing ? selectedEmployees.gmail_email_password : newEmployees.gmail_email_password} onChange={handleInputChange} required />
              <label>Apple ID</label><input name="apple_id" placeholder="Apple ID" value={isEditing ? selectedEmployees.apple_id : newEmployees.apple_id} onChange={handleInputChange} required />
              <label>Apple Password</label><input type={showPasswords ? "text" : "password"} name="apple_password" placeholder="Apple Password" value={isEditing ? selectedEmployees.apple_password : newEmployees.apple_password} onChange={handleInputChange} required />
              <label>Phone Number</label><input name="phone_number" placeholder="Phone number" value={isEditing ? selectedEmployees.phone_number : newEmployees.phone_number} onChange={handleInputChange} required />
              <label>Navigator ID</label><input name="navigator_id" placeholder="navigator ID" value={isEditing ? selectedEmployees.navigator_id : newEmployees.navigator_id} onChange={handleInputChange} />
              <label>Location</label><input name="location" placeholder="Notes" value={isEditing ? selectedEmployees.location : newEmployees.location} onChange={handleInputChange} />
              <label>Notes</label><input name="notes" placeholder="notes" value={isEditing ? selectedEmployees.notes : newEmployees.notes} onChange={handleInputChange} />
              <label>Team</label><input name="team" placeholder="team" value={isEditing ? selectedEmployees.team : newEmployees.team} onChange={handleInputChange} />


              {duplicateError && <p className="error">{duplicateError}</p>} {/* Display duplicate error message */}
              {phoneError && <p className="error">{phoneError}</p>} {/* Display price error message */}
              {threeCX_idError && <p className="error">{threeCX_idError}</p>} {/* Display  error message */}
              {thcEmailIdError && <p className="error">{thcEmailIdError}</p>} {/* Display  error message */}
              {gmailEmailIdError && <p className="error">{gmailEmailIdError}</p>} {/* Display  error message */}
              {appleIdError && <p className="error">{appleIdError}</p>} {/* Display  error message */}
              {message && <p>{message}</p>} {/* Display  error message */}


              <div className="button-group">
                <button className="overlay-add-employees" onClick={isEditing ? handleUpdateEmployees : handleAddEmployees}>
                  {isEditing ? 'Update Employee' : 'Add Employee'}
                </button>
                {isEditing && (
                  <button
                    className="overlay-delete-employees"
                    onClick={() => {
                      if (window.confirm('Are you sure you want to delete this employees?')) {
                        handleDeleteEmployees();
                      }
                    }}
                  >
                    Delete Employee
                  </button>

                )}
                <button className="overlay-add-employees" onClick={togglePasswords}>
                  {showPasswords ? 'Hide Passwords' : 'Show Passwords'}
                </button>
              </div>
              {/* Section para asignar equipos */}

              {message && (
                <div className={`message ${messageType}`}>
                  {message}
                </div>
              )}
              <section className="assign-equipment">
                <form>
                  <fieldset>
                    <legend>Select Equipment</legend>
                    {Object.keys(inventory).map((item) => (
                      <div className="equipment-option" key={item}>
                        <label>
                          <input
                            type="checkbox"
                            name="equipment"
                            value={item}
                            onChange={handleCheckboxChange}
                          />
                          {item.charAt(0).toUpperCase() + item.slice(1)}
                        </label>
                      </div>
                    ))}
                  </fieldset>
                </form>
                {/* Contenedor para los equipos disponibles CMS */}
                {Array.isArray(availableItems) && availableItems.length > 0 && (
                  <fieldset>
                    <legend>{capitalize(selectedEquipment[selectedEquipment.length - 1])} Available for CMS</legend>
                    <div className="scrollable-list">
                      {filteredItems.length > 0 ? (
                        filteredItems.map((item) => (
                          <div
                            key={item.id}
                            className={`available-item ${selectedItem?.id === item.id ? "selected" : ""}`}
                            onClick={() => setSelectedItem(item)}
                          >
                            {item.tagid || item.tagId} {item.brand}
                          </div>
                        ))
                      ) : (
                        <div className="no-items">No Available Items</div>
                      )}
                    </div>

                    <button className="assign-button" onClick={() => {
                      console.log("Selected Item:", selectedItem); // Verifica el valor en la consola
                      handleAssign(selectedItem);
                    }}>Assign Selected Item</button>
                  </fieldset>

                )}
                {/* Contenedor para los equipos disponibles THC */}
                {Array.isArray(availableItems) && availableItems.length > 0 && (
                  <fieldset>
                    <legend>{capitalize(selectedEquipment[selectedEquipment.length - 1])} Available for THC</legend>
                    <div className="scrollable-list">
                      {filteredItemsTHC.length > 0 ? (
                        filteredItemsTHC.map((item) => (
                          <div
                            key={item.id}
                            className={`available-item ${selectedItem?.id === item.id ? "selected" : ""}`}
                            onClick={() => setSelectedItem(item)}
                          >
                            {item.tagid || item.tagId} {item.brand}
                          </div>
                        ))
                      ) : (
                        <div className="no-items">No Available Items</div>
                      )}
                    </div>

                    <button className="assign-button" onClick={() => {
                      console.log("Selected Item:", selectedItem); // Verifica el valor en la consola
                      handleAssign(selectedItem);
                    }}>Assign Selected Item</button>
                  </fieldset>

                )}
              </section>
              {/* Contenedor para otros equipos disponibles Speakers,Office Supplies... */}
              <section>
                <button
                  type="button"
                  onClick={handleButtonClick}
                  className="available-button"
                >
                  Other Available Equipment
                </button>
                {showSelect && (
                  <div className="select-container">
                    <label htmlFor="available-equipment"></label>
                    <select
                      id="available-equipment"
                      onChange={handleSelectChange}
                      value={selectedOption}
                    >
                      <option value="">Select an option:</option>
                      {Object.keys(other).map((item) => (
                        <option key={item} value={item}>
                          {friendlyNames[item] || item.charAt(0).toUpperCase() + item.slice(1)}
                        </option>
                      ))}
                    </select>
                    {message && (
                      <div className={`message ${messageType}`}>
                        {message}
                      </div>
                    )}
                    {Array.isArray(availableOthersItems) && availableOthersItems.length > 0 ? (
                      <fieldset>
                        <legend>{friendlyNames[selectedOption]} Availables:</legend>
                        <div className="scrollable-list">
                          {filteredItemsOther.length > 0 ? (
                            filteredItemsOther.map((item) => (
                              <div
                                key={item.id}
                                className={`available-item ${selectedOtherItem?.id === item.id ? 'selected' : ''}`}
                                onClick={() => setSelectedOtherItem(item)}
                              >
                                {item.tagId || item.tagid || item.store || 'No Tag'}{' '}
                                {item.name || item.brand || 'Unknown'}
                              </div>
                            ))
                          ) : (
                            <div className="no-items">No Available Items</div>
                          )}
                        </div>
                        <button
                          className="assign-button"
                          onClick={() => handleAssign(selectedOtherItem)}
                          disabled={!selectedOtherItem} // Deshabilitar si no hay un item seleccionado
                        >
                          Assign Selected Item
                        </button>
                      </fieldset>
                    ) : null}



                  </div>
                )}
              </section>

            </div>
          </div>
        )
      }

      {
        overlayType === 'inventory' && showEmployeesShowInventoryOverlay && (

          <div className="inventory-overlay" onClick={(e) => {
            // Verificar si el clic ocurrió en el fondo del overlay
            if (e.target.classList.contains("inventory-overlay")) {
              console.log("Closing overlay");
              setShowEmployeesShowInventoryOverlay(false);
              setSelectedEmployees(null);
              setIsEditing(false);
            }
          }}>
            <div className="custom-overlay">

              <div className="logo-container">

                <img src={logo2} alt="Logo" className="logo" />


              </div>
              <button className="close-button" onClick={() => {
                setShowEmployeesShowInventoryOverlay(false);
                setSelectedEmployees(null);
                setIsEditing(false);
              }}>X</button>

              <table className="log-table">
                <thead>
                  <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Cell Number</th>
                    <th>NAV ID</th>
                  </tr>
                </thead>
                <tbody>

                  <td>{isEditing ? selectedEmployees.first_name.toUpperCase() : newEmployees.first_name}</td>
                  <td>{isEditing ? selectedEmployees.last_name.toUpperCase() : newEmployees.last_name}</td>
                  <td>{isEditing ? selectedEmployees.phone_number : newEmployees.phone_number}</td>
                  <td>{isEditing ? selectedEmployees.navigator_id : newEmployees.navigator_id}</td>

                </tbody>
              </table>

              {/* Sección de Acuerdo y Confirmación */}
              <div class="justified-text">
                <p>
                  I acknowledge that while I am working for Bexar County Community Health Collaborative Navigator Program (BCCHCNP), I will take proper care of all company equipment that I am entrusted with. I further understand that upon termination, I will return all BCCHCNP property and that the property will be returned in proper working condition. I understand I may be held financially responsible for lost or damaged property. This agreement includes, but is not limited to intellectual property, laptops, cell phones, printers, and other equipment.
                </p>
                <p>
                  I acknowledge that I have received the following items with the following passcodes, passwords, and other safety and privacy policies:
                </p>
              </div>

              {/* Tabla de Información del Empleado */}
              <table >
                <thead>
                  <tr>
                    <th colSpan="6">Employee Information</th>
                  </tr>
                  <tr>
                    <th>Item</th>
                    <th>Email</th>
                    <th>Password</th>
                    <th>Date</th>
                    <th>Initials</th>
                    <th>Returned</th>
                  </tr>
                </thead>
                <tbody>
                  {["Apple ID", "Gmail Email", "THC Email", "3CX"].map((item, index) => (
                    <tr key={index}>
                      <td>{item}</td>
                      <td>
                        {item === "Apple ID" ? (
                          isEditing ? selectedEmployees.apple_id : newEmployees.apple_id
                        ) : item === "Gmail Email" ? (
                          isEditing ? selectedEmployees.gmail_email_id : newEmployees.gmail_email_id
                        ) : item === "THC Email" ? (
                          isEditing ? selectedEmployees.thc_email_id : newEmployees.thc_email_id
                        ) : (
                          isEditing ? selectedEmployees.ThreeCX_id : newEmployees.ThreeCX_id
                        )}

                      </td>
                      <td>
                        {item === "Apple ID" ? (
                          isEditing ? selectedEmployees.apple_password : newEmployees.apple_password
                        ) : item === "Gmail Email" ? (
                          isEditing ? selectedEmployees.gmail_email_password : newEmployees.gmail_email_password
                        ) : item === "THC Email" ? (
                          isEditing ? selectedEmployees.thc_email_password : newEmployees.thc_email_password
                        ) : (
                          isEditing ? selectedEmployees.ThreeCX_password : newEmployees.ThreeCX_password
                        )}
                      </td>
                      <td>
                        {item === "Apple ID" ? (//AQUI
                          isEditing ? moment(selectedEmployees.createdAt).format("MM-DD-YYYY") : moment(newEmployees.createdAt).format("MM-DD-YYYY")
                        ) : item === "Gmail Email" ? (
                          isEditing ? moment(selectedEmployees.createdAt).format("MM-DD-YYYY") : moment(newEmployees.createdAt).format("MM-DD-YYYY")
                        ) : item === "THC Email" ? (
                          isEditing ? moment(selectedEmployees.createdAt).format("MM-DD-YYYY") : moment(newEmployees.createdAt).format("MM-DD-YYYY")
                        ) : (
                          isEditing ? moment(selectedEmployees.createdAt).format("MM-DD-YYYY") : moment(newEmployees.createdAt).format("MM-DD-YYYY")
                        )}
                      </td>
                      <td> </td>
                      <td><input type="checkbox" /></td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* Tabla de otros equipos */}
              <table>
                <thead>
                  <tr >
                    <th colSpan="10">Item Information  </th>
                  </tr>
                  <tr>
                    <th>Item</th>
                    <th>TAG #</th>
                    <th>Serial #</th>
                    <th>Passcode</th>
                    <th>Status</th>
                    <th>Charger Cable</th>
                    <th>Connector Cable</th>
                    <th>Assignment Date</th>
                    <th>Initials</th>
                    <th>Returned</th>
                  </tr>
                </thead>
                <tbody>

                  {filteredLaptops.map((laptop, index) => (
                    <tr key={`laptop-${index}`} onClick={() => handleSelectLaptop(laptop)}>
                      <td>Laptop</td>
                      <td className="tag-column">{laptop.tagid}</td>
                      <td>{laptop.serial_Number?.toUpperCase() || 'N/A'}</td>
                      <td>{laptop.password}</td>
                      <td>{laptop.status}</td>
                      <td>{laptop.charger_cable ? 'Yes' : 'No'}</td>
                      <td>{'-'}</td>
                      <td>{moment(laptop.assignment_date).isValid() ? moment(laptop.assignment_date).format("MM-DD-YYYY") : 'No Date'}</td>


                      {/* Evitar que el overlay se abra al hacer clic en Initials */}
                      <td onClick={(event) => event.stopPropagation()}>
                        {''}
                      </td>

                      <td onClick={(event) => event.stopPropagation()}>
                        <div class="checkbox-returned">
                          <label>
                            <input
                              type="checkbox"
                              name="condition"
                              value="Bad"
                              defaultChecked={laptop.status?.toLowerCase() === "broken"}
                            />

                            Bad
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Good" />

                            Good
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Fair" />

                            Fair
                          </label>
                          <label>

                          </label>
                        </div>
                      </td>
                    </tr>
                  ))}
                  <OverlayEdit
                    isOpen={isLaptopOverlayOpen}
                    onClose={() => setIsLaptopOverlayOpen(false)}
                    item={selectedLaptop} // Pasa el objeto inicial al overlay
                    onSave={(updatedItem) => {
                      console.log("Datos enviados desde OverlayEdit:", updatedItem);
                      handleSaveItem('laptops', updatedItem);
                    }}
                  />
                  {filteredPrinters.map((printer, index) => (
                    <tr key={`printer-${index}`} onClick={() => handleSelectPrinter(printer)}>
                      <td>Printer</td>
                      <td>{printer.tagid}</td>
                      <td>{printer.serial_Number?.toUpperCase() || 'N/A'}</td>
                      <td>{'-'}</td>
                      <td>{printer.status}</td>
                      <td>{printer.charger_cable ? 'Yes' : 'No'}</td>
                      <td>{printer.Connector_cable}</td>
                      <td>{moment(printer.assignment_date).isValid() ? moment(printer.assignment_date).format("MM-DD-YYYY") : 'No Date'}</td>
                      <td onClick={(event) => event.stopPropagation()}>{''}</td>
                      <td onClick={(event) => event.stopPropagation()}>
                        <div class="checkbox-returned">
                          <label>
                            <input
                              type="checkbox"
                              name="condition"
                              value="Bad"
                              defaultChecked={printer.status?.toLowerCase() === "broken"}
                            />
                            Bad
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Good" />
                            Good
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Fair" />
                            Fair
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))}
                  <OverlayEdit
                    isOpen={isPrinterOverlayOpen}
                    onClose={() => setIsPrinterOverlayOpen(false)}
                    item={selectedPrinter} // Pasa el objeto inicial al overlay
                    onSave={(updatedItem) => handleSaveItem('printers', updatedItem)} // Recibe el objeto actualizado
                  />
                  {filteredScanners.map((scanner, index) => (
                    <tr key={`scanner-${index}`} onClick={() => handleSelectScanner(scanner)}>
                      <td>Scanner</td>
                      <td>{scanner.tagid}</td>
                      <td>{scanner.serial_Number?.toUpperCase() || 'N/A'}</td>
                      <td>{'-'}</td>
                      <td>{scanner.status}</td>
                      <td>{scanner.charger_cable ? 'Yes' : 'No'}</td>
                      <td>{scanner.Connector_cable}</td>
                      <td>{moment(scanner.assignment_date).isValid() ? moment(scanner.assignment_date).format("MM-DD-YYYY") : 'No Date'}</td>
                      <td onClick={(event) => event.stopPropagation()}>{''}</td>
                      <td onClick={(event) => event.stopPropagation()}>
                        <div class="checkbox-returned">
                          <label>
                          <input
                              type="checkbox"
                              name="condition"
                              value="Bad"
                              defaultChecked={scanner.status?.toLowerCase() === "broken"}
                            />
                            Bad
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Good" />
                            Good
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Fair" />
                            Fair
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))}
                  <OverlayEdit
                    isOpen={isScannerOverlayOpen}
                    onClose={() => setIsScannerOverlayOpen(false)}
                    item={selectedScanner} // Pasa el objeto inicial al overlay
                    onSave={(updatedItem) => handleSaveItem('scanners', updatedItem)} // Recibe el objeto actualizado
                  />
                  {filteredPhones.map((phone, index) => (
                    <tr key={`phone-${index}`} onClick={() => handleSelectPhone(phone)}>
                      <td>Phone</td>
                      <td>{phone.tagid}</td>
                      <td>{phone.serial_Number?.toUpperCase() || 'N/A'}</td>
                      <td>{phone.pin}</td>
                      <td>{phone.status}</td>
                      <td>{phone.charger_cable ? 'Yes' : 'No'}</td>
                      <td>{'-'}</td>
                      <td>{moment(phone.assignment_date).isValid() ? moment(phone.assignment_date).format("MM-DD-YYYY") : 'No Date'}</td>
                      <td onClick={(event) => event.stopPropagation()}>{''}</td>
                      <td onClick={(event) => event.stopPropagation()}>
                        <div class="checkbox-returned">
                          <label>
                          <input
                              type="checkbox"
                              name="condition"
                              value="Bad"
                              defaultChecked={phone.status?.toLowerCase() === "broken"}
                            />
                            Bad
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Good" />
                            Good
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Fair" />
                            Fair
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))}
                  <OverlayEdit
                    isOpen={isPhoneOverlayOpen}
                    onClose={() => setIsPhoneOverlayOpen(false)}
                    item={selectedPhone} // Pasa el objeto inicial al overlay
                    onSave={(updatedItem) => handleSaveItem('phones', updatedItem)} // Recibe el objeto actualizado
                  />
                  {filteredHeadset.map((headset, index) => (
                    <tr key={`headset-${index}`} onClick={() => handleSelectHeadset(headset)} >
                      <td>Headset</td>
                      <td>{headset.tagid}</td>
                      <td>{headset.serial_Number?.toUpperCase() || 'N/A'}</td>
                      <td>{'-'}</td>
                      <td>{headset.status}</td>
                      <td>{headset.charger_cable ? 'Yes' : 'No'}</td>
                      <td>{headset.aux_cable ? 'Auxiliary Input' : '-'}</td>
                      <td>{moment(headset.assignment_date).isValid() ? moment(headset.assignment_date).format("MM-DD-YYYY") : 'No Date'}</td>
                      <td onClick={(event) => event.stopPropagation()}>{''}</td>
                      <td onClick={(event) => event.stopPropagation()}>
                        <div class="checkbox-returned">
                          <label>
                          <input
                              type="checkbox"
                              name="condition"
                              value="Bad"
                              defaultChecked={headset.status?.toLowerCase() === "broken"}
                            />
                            Bad
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Good" />
                            Good
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Fair" />
                            Fair
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))}
                  <OverlayEdit
                    isOpen={isHeadsetOverlayOpen}
                    onClose={() => setIsHeadseteOverlayOpen(false)}
                    item={selectedHeadset} // Pasa el objeto inicial al overlay
                    onSave={(updatedItem) => handleSaveItem('headsets', updatedItem)} // Recibe el objeto actualizado
                  />
                  {filteredMouse.map((mouse, index) => (
                    <tr key={`mouse-${index}`} onClick={() => handleSelectMouse(mouse)}>
                      <td>Mouse</td>
                      <td>{mouse.tagId}</td>
                      <td>{mouse.serial_Number?.toUpperCase() || 'N/A'}</td>
                      <td>{'-'}</td>
                      <td>{mouse.status}</td>
                      <td>{'-'}</td>
                      <td>{'-'}</td>
                      <td>{moment(mouse.assignment_date).isValid() ? moment(mouse.assignment_date).format("MM-DD-YYYY") : 'No Date'}</td>
                      <td onClick={(event) => event.stopPropagation()}>{''}</td>
                      <td onClick={(event) => event.stopPropagation()}>
                        <div class="checkbox-returned">
                          <label>
                          <input
                              type="checkbox"
                              name="condition"
                              value="Bad"
                              defaultChecked={mouse.status?.toLowerCase() === "broken"}
                            />
                            Bad
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Good" />
                            Good
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Fair" />
                            Fair
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))}
                  <OverlayEdit
                    isOpen={isMouseOverlayOpen}
                    onClose={() => setIsMouseOverlayOpen(false)}
                    item={selectedMouse} // Pasa el objeto inicial al overlay
                    onSave={(updatedItem) => handleSaveItem('mice', updatedItem)} // Recibe el objeto actualizado
                  />
                  {filteredKeyboard.map((keyboard, index) => (
                    <tr key={`keyboard-${index}`} onClick={() => handleSelectKeyboard(keyboard)}>
                      <td>Keyboard</td>
                      <td>{keyboard.tagid}</td>
                      <td>{keyboard.serial_Number?.toUpperCase() || 'N/A'}</td>
                      <td>{'-'}</td>
                      <td>{keyboard.status}</td>
                      <td>{'-'}</td>
                      <td>{'-'}</td>
                      <td>{moment(keyboard.assignment_date).isValid() ? moment(keyboard.assignment_date).format("MM-DD-YYYY") : 'No Date'}</td>
                      <td onClick={(event) => event.stopPropagation()}>{''}</td>
                      <td onClick={(event) => event.stopPropagation()}>
                        <div class="checkbox-returned">
                          <label>
                          <input
                              type="checkbox"
                              name="condition"
                              value="Bad"
                              defaultChecked={keyboard.status?.toLowerCase() === "broken"}
                            />
                            Bad
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Good" />
                            Good
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Fair" />
                            Fair
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))}
                  <OverlayEdit
                    isOpen={isKeyboardOverlayOpen}
                    onClose={() => setIsKeyboardOverlayOpen(false)}
                    item={selectedKeyboard} // Pasa el objeto inicial al overlay
                    onSave={(updatedItem) => handleSaveItem('keyboards', updatedItem)} // Recibe el objeto actualizado
                  />
                  {filteredMonitorDesk.map((monitor, index) => (
                    <tr key={`monitor-${index}`} onClick={() => handleSelectMonitor(monitor)}>
                      <td> Desk Monitor </td>
                      <td>{monitor.tagid}</td>
                      <td>{monitor.serial_Number?.toUpperCase() || 'N/A'}</td>
                      <td>{'-'}</td>
                      <td>{monitor.status}</td>
                      <td>{monitor.charger_cable ? 'Yes' : 'No'}</td>
                      <td>{monitor.Connector_cable}</td>
                      <td>{moment(monitor.assignment_date).isValid() ? moment(monitor.assignment_date).format("MM-DD-YYYY") : 'No Date'}</td>
                      <td onClick={(event) => event.stopPropagation()}>{''}</td>
                      <td onClick={(event) => event.stopPropagation()}>
                        <div class="checkbox-returned">
                          <label>
                          <input
                              type="checkbox"
                              name="condition"
                              value="Bad"
                              defaultChecked={monitor.status?.toLowerCase() === "broken"}
                            />
                            Bad
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Good" />
                            Good
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Fair" />
                            Fair
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))}
                  <OverlayEdit
                    isOpen={isMonitorOverlayOpen}
                    onClose={() => setIsMonitorOverlayOpen(false)}
                    item={selectedMonitor} // Pasa el objeto inicial al overlay
                    onSave={(updatedItem) => handleSaveItem('monitorsDesk', updatedItem)} // Recibe el objeto actualizado
                  />
                  {filteredMonitorPortable.map((monitor, index) => (
                    <tr key={`monitor-${index}`} onClick={() => handleSelectMonitor(monitor)}>
                      <td> Portable Monitor</td>
                      <td>{monitor.tagid}</td>
                      <td>{monitor.serial_Number?.toUpperCase() || 'N/A'}</td>
                      <td>{'-'}</td>
                      <td>{monitor.status}</td>
                      <td>{monitor.charger_cable ? 'Yes' : 'No'}</td>
                      <td>{monitor.Connector_cable}</td>
                      <td>{moment(monitor.assignment_date).isValid() ? moment(monitor.assignment_date).format("MM-DD-YYYY") : 'No Date'}</td>
                      <td onClick={(event) => event.stopPropagation()}>{''}</td>
                      <td onClick={(event) => event.stopPropagation()}>
                        <div class="checkbox-returned">
                          <label>
                          <input
                              type="checkbox"
                              name="condition"
                              value="Bad"
                              defaultChecked={monitor.status?.toLowerCase() === "broken"}
                            />
                            Bad
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Good" />
                            Good
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Fair" />
                            Fair
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))}
                  <OverlayEdit
                    isOpen={isMonitorOverlayOpen}
                    onClose={() => setIsMonitorOverlayOpen(false)}
                    item={selectedMonitor} // Pasa el objeto inicial al overlay
                    onSave={(updatedItem) => handleSaveItem('monitorsPortable', updatedItem)} // Recibe el objeto actualizado
                  />
                  {filteredExtensionCords.map((extensionCord, index) => (
                    <tr key={`extensionCord-${index}`} onClick={() => handleSelectExtensionCord(extensionCord)}>
                      <td>Extension Cord</td>
                      <td>{extensionCord.tagid}</td>
                      <td>{extensionCord.serial_Number?.toUpperCase() || 'N/A'}</td>
                      <td>{'-'}</td>
                      <td>{extensionCord.status}</td>
                      <td>{'-'}</td>
                      <td>{'-'}</td>
                      <td>{moment(extensionCord.assignment_date).isValid() ? moment(extensionCord.assignment_date).format("MM-DD-YYYY") : 'No Date'}</td>
                      <td onClick={(event) => event.stopPropagation()}>{''}</td>
                      <td onClick={(event) => event.stopPropagation()}>
                        <div class="checkbox-returned">
                          <label>
                          <input
                              type="checkbox"
                              name="condition"
                              value="Bad"
                              defaultChecked={extensionCord.status?.toLowerCase() === "broken"}
                            />
                            Bad
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Good" />
                            Good
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Fair" />
                            Fair
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))}
                  <OverlayEdit
                    isOpen={isExtensionCordOverlayOpen}
                    onClose={() => setIsExtensionCordOverlayOpen(false)}
                    item={selectedExtensionCord} // Pasa el objeto inicial al overlay
                    onSave={(updatedItem) => handleSaveItem('extensionCords', updatedItem)} // Recibe el objeto actualizado
                  />
                  {filteredBackupBaterries.map((backupBaterry, index) => (
                    <tr key={`-${index}`} onClick={() => handleSelectBackupBaterry(backupBaterry)}>
                      <td>Backup Baterry</td>
                      <td>{backupBaterry.tagid}</td>
                      <td>{backupBaterry.serial_Number?.toUpperCase() || 'N/A'}</td>
                      <td>{'-'}</td>
                      <td>{backupBaterry.status}</td>
                      <td>{'-'}</td>
                      <td>{'-'}</td>
                      <td>{moment(backupBaterry.assignment_date).isValid() ? moment(backupBaterry.assignment_date).format("MM-DD-YYYY") : 'No Date'}</td>
                      <td onClick={(event) => event.stopPropagation()}>{''}</td>
                      <td onClick={(event) => event.stopPropagation()}>
                        <div class="checkbox-returned">
                          <label>
                          <input
                              type="checkbox"
                              name="condition"
                              value="Bad"
                              defaultChecked={backupBaterry.status?.toLowerCase() === "broken"}
                            />
                            Bad
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Good" />
                            Good
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Fair" />
                            Fair
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))}
                  <OverlayEdit
                    isOpen={isBackupBaterryOverlayOpen}
                    onClose={() => setIsBackupBaterryOverlayOpen(false)}
                    item={selectedBackupBaterry} // Pasa el objeto inicial al overlay
                    onSave={(updatedItem) => handleSaveItem('backupBatteries', updatedItem)} // Recibe el objeto actualizado
                  />

                  {filteredUSB.map((usb, index) => (
                    <tr key={`-${index}`} onClick={() => handleSelectUSB(usb)}>
                      <td>USB</td>
                      <td>{usb.tagid}</td>
                      <td>{usb.serial_Number?.toUpperCase() || 'N/A'}</td>
                      <td>{'-'}</td>
                      <td>{usb.status}</td>
                      <td>{'-'}</td>
                      <td>{'-'}</td>
                      <td>{moment(usb.assignment_date).isValid() ? moment(usb.assignment_date).format("MM-DD-YYYY") : 'No Date'}</td>
                      <td onClick={(event) => event.stopPropagation()}>{''}</td>
                      <td onClick={(event) => event.stopPropagation()}>
                        <div class="checkbox-returned">
                          <label>
                          <input
                              type="checkbox"
                              name="condition"
                              value="Bad"
                              defaultChecked={usb.status?.toLowerCase() === "broken"}
                            />
                            Bad
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Good" />
                            Good
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Fair" />
                            Fair
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))}
                  <OverlayEdit
                    isOpen={isUSBOverlayOpen}
                    onClose={() => setIsUSBOverlayOpen(false)}
                    item={selectedUSB} // Pasa el objeto inicial al overlay
                    onSave={(updatedItem) => handleSaveItem('usbs', updatedItem)} // Recibe el objeto actualizado
                  />
                  {filteredDockingsStation.map((docking, index) => (
                    <tr key={`-${index}`} onClick={() => handleSelectDockingStation(docking)}>
                      <td>Docking Station</td>
                      <td>{docking.tagid}</td>
                      <td>{docking.serial_Number?.toUpperCase() || 'N/A'}</td>
                      <td>{'-'}</td>
                      <td>{docking.status}</td>
                      <td>{'-'}</td>
                      <td>{'-'}</td>
                      <td>{moment(docking.assignment_date).isValid() ? moment(docking.assignment_date).format("MM-DD-YYYY") : 'No Date'}</td>
                      <td onClick={(event) => event.stopPropagation()}>{''}</td>
                      <td onClick={(event) => event.stopPropagation()}>
                        <div class="checkbox-returned">
                          <label>
                          <input
                              type="checkbox"
                              name="condition"
                              value="Bad"
                              defaultChecked={docking.status?.toLowerCase() === "broken"}
                            />
                            Bad
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Good" />
                            Good
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Fair" />
                            Fair
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))}
                  <OverlayEdit
                    isOpen={isDockingStationOverlayOpen}
                    onClose={() => setIsDockingStationOverlayOpen(false)}
                    item={selectedDockingStation} // Pasa el objeto inicial al overlay
                    onSave={(updatedItem) => handleSaveItem('dockingStations', updatedItem)} // Recibe el objeto actualizado
                  />
                  {filteredSpeakers.map((speaker, index) => (
                    <tr key={`-${index}`} onClick={() => handleSelectSpeaker(speaker)}>
                      <td>Speaker </td>
                      <td>{speaker.tagid}</td>
                      <td>{speaker.serial_Number?.toUpperCase() || 'N/A'}</td>
                      <td>{'-'}</td>
                      <td>{speaker.status}</td>
                      <td>{'-'}</td>
                      <td>{'-'}</td>
                      <td>{moment(speaker.assignment_date).isValid() ? moment(speaker.assignment_date).format("MM-DD-YYYY") : 'No Date'}</td>
                      <td onClick={(event) => event.stopPropagation()}>{''}</td>
                      <td onClick={(event) => event.stopPropagation()}>
                        <div class="checkbox-returned">
                          <label>
                          <input
                              type="checkbox"
                              name="condition"
                              value="Bad"
                              defaultChecked={speaker.status?.toLowerCase() === "broken"}
                            />
                            Bad
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Good" />
                            Good
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Fair" />
                            Fair
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))}
                  <OverlayEdit
                    isOpen={isSpeakerOverlayOpen}
                    onClose={() => setIsSpeakerOverlayOpen(false)}
                    item={selectedSpeaker} // Pasa el objeto inicial al overlay
                    onSave={(updatedItem) => handleSaveItem('speakers', updatedItem)} // Recibe el objeto actualizado
                  />
                  {filteredPowerBank.map((powerBank, index) => (
                    <tr key={`-${index}`} onClick={() => handleSelectPowerBank(powerBank)}>
                      <td>Power Bank </td>
                      <td>{powerBank.tagid}</td>
                      <td>{powerBank.serial_Number?.toUpperCase() || 'N/A'}</td>
                      <td>{'-'}</td>
                      <td>{powerBank.status}</td>
                      <td>{'-'}</td>
                      <td>{'-'}</td>
                      <td>{moment(powerBank.assignment_date).isValid() ? moment(powerBank.assignment_date).format("MM-DD-YYYY") : 'No Date'}</td>
                      <td onClick={(event) => event.stopPropagation()}>{''}</td>
                      <td onClick={(event) => event.stopPropagation()}>
                        <div class="checkbox-returned">
                          <label>
                          <input
                              type="checkbox"
                              name="condition"
                              value="Bad"
                              defaultChecked={powerBank.status?.toLowerCase() === "broken"}
                            />
                            Bad
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Good" />
                            Good
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Fair" />
                            Fair
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))}
                  <OverlayEdit
                    isOpen={isPowerBankOverlayOpen}
                    onClose={() => setIsPowerBankOverlayOpen(false)}
                    item={selectedPowerBank} // Pasa el objeto inicial al overlay
                    onSave={(updatedItem) => handleSaveItem('powerBanks', updatedItem)} // Recibe el objeto actualizado
                  />
                  {filteredtablets.map((tablet, index) => (
                    <tr key={`-${index}`} onClick={() => handleSelectTablet(tablet)}>
                      <td>Tablet </td>
                      <td>{tablet.tagid}</td>
                      <td>{tablet.serial_Number?.toUpperCase() || 'N/A'}</td>
                      <td>{tablet.password}</td>
                      <td>{tablet.status}</td>
                      <td>{tablet.charger_cable ? 'Yes' : 'No'}</td>
                      <td>{'-'}</td>
                      <td>{moment(tablet.assignment_date).isValid() ? moment(tablet.assignment_date).format("MM-DD-YYYY") : 'No Date'}</td>
                      <td onClick={(event) => event.stopPropagation()}>{''}</td>
                      <td onClick={(event) => event.stopPropagation()}>
                        <div class="checkbox-returned">
                          <label>
                          <input
                              type="checkbox"
                              name="condition"
                              value="Bad"
                              defaultChecked={tablet.status?.toLowerCase() === "broken"}
                            />
                            Bad
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Good" />
                            Good
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Fair" />
                            Fair
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))}
                  <OverlayEdit
                    isOpen={isTabletOverlayOpen}
                    onClose={() => setIsTabletOverlayOpen(false)}
                    item={selectedTablet} // Pasa el objeto inicial al overlay
                    onSave={(updatedItem) => handleSaveItem('tabletRemarkables', updatedItem)} // Recibe el objeto actualizado
                  />
                  {filteredExternalDisk.map((externoDisks, index) => (
                    <tr key={`-${index}`} onClick={() => handleSelectExternoDisks(externoDisks)}>
                      <td>Externo Disk</td>
                      <td>{externoDisks.tagid}</td>
                      <td>{externoDisks.serial_Number?.toUpperCase() || 'N/A'}</td>
                      <td>{'-'}</td>
                      <td>{externoDisks.status}</td>
                      <td>{externoDisks.charger_cable ? 'Yes' : 'No'}</td>
                      <td>{'-'}</td>
                      <td>{moment(externoDisks.assignment_date).isValid() ? moment(externoDisks.assignment_date).format("MM-DD-YYYY") : 'No Date'}</td>
                      <td onClick={(event) => event.stopPropagation()}>{''}</td>
                      <td onClick={(event) => event.stopPropagation()}>
                        <div class="checkbox-returned">
                          <label>
                          <input
                              type="checkbox"
                              name="condition"
                              value="Bad"
                              defaultChecked={externoDisks.status?.toLowerCase() === "broken"}
                            />
                            Bad
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Good" />
                            Good
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Fair" />
                            Fair
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))}
                  <OverlayEdit
                    isOpen={isExternoDiskOverlayOpen}
                    onClose={() => setIsExternoDiskOverlayOpen(false)}
                    item={selectedExternoDisk} // Pasa el objeto inicial al overlay
                    onSave={(updatedItem) => handleSaveItem('externalDisks', updatedItem)} // Recibe el objeto actualizado
                  />
                  {filteredSupplies.map((supplies, index) => (
                    <tr key={`-${index}`} onClick={() => handleSelectSupplies(supplies)}>
                      <td>Supplies</td>
                      <td>{supplies.tagId || 'N/A'}</td>
                      <td>{supplies.name}</td>
                      <td>{'-'}</td>
                      <td>{'use'}</td>
                      <td>{'-'}</td>
                      <td>{'-'}</td>
                      <td>{moment(supplies.assignment_date).isValid() ? moment(supplies.assignment_date).format("MM-DD-YYYY") : 'No Date'}</td>
                      <td onClick={(event) => event.stopPropagation()}>{''}</td>
                      <td onClick={(event) => event.stopPropagation()}>
                        <div class="checkbox-returned">
                          <label>
                            <input type="checkbox" name="condition" value="Bad" />
                            Bad
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Good" />
                            Good
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Fair" />
                            Fair
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))}
                  <OverlayEdit
                    isOpen={isSuppliesOverlayOpen}
                    onClose={() => setIsSuppliesOverlayOpen(false)}
                    item={selectedSupplies} // Pasa el objeto inicial al overlay
                    onSave={(updatedItem) => handleSaveItem('supplies', updatedItem)} // Recibe el objeto actualizado
                  />
                  {filteredClickCounter.map((clickCounter, index) => (
                    <tr key={`-${index}`}>
                      <td>Click Counter</td>
                      <td>{clickCounter.store}</td>
                      <td>{'-'}</td>
                      <td>{'-'}</td>
                      <td>{'use'}</td>
                      <td>{'-'}</td>
                      <td>{'-'}</td>
                      <td>{moment(clickCounter.assignment_date).isValid() ? moment(clickCounter.assignment_date).format("MM-DD-YYYY") : 'No Date'}</td>
                      <td onClick={(event) => event.stopPropagation()}>{''}</td>
                      <td onClick={(event) => event.stopPropagation()}>
                        <div class="checkbox-returned">
                          <label>
                            <input type="checkbox" name="condition" value="Bad" />
                            Bad
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Good" />
                            Good
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Fair" />
                            Fair
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))}
                  {filteredBag.map((bag, index) => (
                    <tr key={`-${index}`} onClick={() => handleSelectBag(bag)}>
                      <td>Bag</td>
                      <td>{bag.type === 'rolling' ? 'Rolling Bag' : (bag.type === 'backpak' ? 'Backpak' : null)}</td>
                      <td>{bag.serial_Number?.toUpperCase() || 'N/A'}</td>
                      <td>{'-'}</td>
                      <td>{bag.status}</td>
                      <td>{'-'}</td>
                      <td>{'-'}</td>
                      <td>{moment(bag.assignment_date).isValid() ? moment(bag.assignment_date).format("MM-DD-YYYY") : 'No Date'}</td>
                      <td onClick={(event) => event.stopPropagation()}>{''}</td>
                      <td onClick={(event) => event.stopPropagation()}>
                        <div class="checkbox-returned">
                          <label>
                            <input type="checkbox" name="condition" value="Bad" />
                            Bad
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Good" />
                            Good
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Fair" />
                            Fair
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))}
                  <OverlayEdit
                    isOpen={isBagOverlayOpen}
                    onClose={() => setIsBagOverlayOpen(false)}
                    item={selectedBag} // Pasa el objeto inicial al overlay
                    onSave={(updatedItem) => handleSaveItem('bags', updatedItem)} // Recibe el objeto actualizado
                  />
                  {filteredDeleteEquipmenteHistory.map((equipment_Removed, index) => (
                    <tr key={`-${index}`}>
                      <td>{equipment_Removed.Item}</td>
                      <td>{equipment_Removed.tagId}</td>
                      <td>{equipment_Removed.serial_Number?.toUpperCase() || 'N/A'}</td>
                      <td>{'-'}</td>
                      <td>Broken</td>
                      <td>{'-'}</td>
                      <td>{'-'}</td>
                      <td>{moment(equipment_Removed.assignment_date).isValid() ? moment(equipment_Removed.assignment_date).format("MM-DD-YYYY") : 'No Date'}</td>
                      <td onClick={(event) => event.stopPropagation()}>{''}</td>
                      <td onClick={(event) => event.stopPropagation()}>
                        <div class="checkbox-returned">
                          <label>
                            <input type="checkbox" name="condition" value="Bad" defaultChecked />
                            Bad
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Good" />
                            Good
                          </label>
                          <label>
                            <input type="checkbox" name="condition" value="Fair" />
                            Fair
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {filteredgeneralInventorys
                .filter(generalInventory => generalInventory.Item_Description && !generalInventory.Item_Description.includes('Access')) // Filtrar Item_Description contenga al menos Access
                .length > 0 && (
                  <table>
                    <thead>
                      <tr >
                        <th colSpan="9">Other Equipment</th>
                      </tr>
                      <tr>
                        <th>Item</th>
                        <th>TAG #</th>
                        <th>Brand</th>
                        <th>Serial #</th>
                        <th>Date</th>
                        <th>Initials</th>
                        <th>Returned</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredgeneralInventorys
                        .filter(generalInventory => generalInventory.Item_Description && !generalInventory.Item_Description.includes('Access')) // Aplicar el filtro también en el mapeo
                        .map((generalInventory, index) => (
                          <tr key={`-${index}`} onClick={() => handleSelectGeneralInventory(generalInventory)}>
                            <td>{generalInventory.Item_Description}</td>
                            <td>{generalInventory.tagid || 'No Tag ID'}</td>
                            <td>{generalInventory.brand || 'No Brand'}</td>
                            <td>{generalInventory.serial_Number.toUpperCase() || 'No Serial Number'}</td>
                            <td>{generalInventory.assignment_date || 'No Date'}</td>
                            <td onClick={(event) => event.stopPropagation()}>{''}</td>
                            <td onClick={(event) => event.stopPropagation()}>
                              <div class="checkbox-returned">
                                <label>
                                  <input type="checkbox" name="condition" value="Bad" />
                                  Bad
                                </label>
                                <label>
                                  <input type="checkbox" name="condition" value="Good" />
                                  Good
                                </label>
                                <label>
                                  <input type="checkbox" name="condition" value="Fair" />
                                  Fair
                                </label>
                              </div>
                            </td>
                          </tr>
                        ))}
                      <OverlayEdit
                        isOpen={isGeneralInventorylayOpen}
                        onClose={() => setIsGeneralInventoryOverlayOpen(false)}
                        item={selectedGeneralInventory} // Pasa el objeto inicial al overlay
                        onSave={(updatedItem) => handleSaveItem('generalInventories', updatedItem)} // Recibe el objeto actualizado
                      />
                    </tbody>
                  </table>)}

              {filteredgeneralInventorys
                .filter(
                  generalInventory =>
                    !generalInventory.brand && // Verifica que 'brand' sea null, undefined o vacío
                    generalInventory.Item_Description && // Asegura que 'Item_Description' no sea null o undefined
                    generalInventory.Item_Description.toLowerCase().includes('access') // Verifica que contenga 'access'
                )
                .length > 0 && (
                  <table>
                    <thead>
                      <tr>
                        <th colSpan="5">Health Collaborative Account</th>
                      </tr>
                      <tr>
                        <th>Item</th>
                        <th>Account</th>
                        <th>Password</th>
                        <th>Date</th>
                        <th>Initials</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredgeneralInventorys
                        .filter(generalInventory => !generalInventory.brand) // Filtrar de nuevo aquí
                        .map((generalInventory, index) => (
                          <tr key={`-${index}`}>
                            <td>{generalInventory.Item_Description || 'No Description'}</td>
                            <td>{generalInventory.tagid || 'No Account ID'}</td>
                            <td>{generalInventory.serial_Number || 'No Password'}</td>
                            <td>{generalInventory.assignment_date || 'No Date'}</td>
                            <td>{'Initials'}</td> {/* Ajusta esto según necesites */}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}

              <div className="notas_container">
                <label htmlFor="notes">Notes</label>
                <textarea
                  id="notes"
                  name="notes"
                  placeholder="Enter your notes here"
                  value={isEditing ? selectedEmployees.notes : newEmployees.notes}
                  onChange={(event) => {
                    const { value } = event.target;
                    if (isEditing) {
                      setSelectedEmployees((prev) => ({ ...prev, notes: value }));
                    } else {
                      setNewEmployees((prev) => ({ ...prev, notes: value }));
                    }
                  }}
                  rows={2} // Ajusta según el tamaño del área de texto que desees
                  className="input-field"
                />
                <span id="printNotes" className="printNotes" style={{ display: 'none' }}>
                  {isEditing ? selectedEmployees.notes : newEmployees.notes}
                </span>
              </div>


              {/* Tabla de Firma del Empleado */}
              <table>
                <thead>
                  <tr>
                    <th>Employee Name</th>
                    <th>Employee Signature</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input type="text" id="employeeName" placeholder="" />
                      <span id="printEmployeeName" ></span>
                    </td>
                    <td>
                      <input type="text" id="employeeSignature" placeholder="" />
                      <span id="printEmployeeSignature"></span>
                    </td>
                    <td>
                      <input type="date" id="employeeDate" />
                      <span id="printEmployeeDate" ></span>
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* Tabla de Firma del Supervisor */}
              <table>
                <thead>
                  <tr>
                    <th>Supervisor Name</th>
                    <th>Supervisor Signature</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input type="text" id="supervisorName" placeholder="" />
                      <span id="printSupervisorName" ></span>
                    </td>
                    <td>
                      <input type="text" id="supervisorSignature" placeholder="" />
                      <span id="printSupervisorSignature" ></span>
                    </td>
                    <td>
                      <input type="date" id="supervisorDate" />
                      <span id="printSupervisorDate" ></span>
                    </td>
                  </tr>
                </tbody>
              </table>



              <button className="print-btn" onClick={handlePrintOverlay}>Print</button>
            </div>
          </div>




        )
      }




    </div >

  );

};

export default Employees;
