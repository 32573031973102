import React, { useEffect, useRef, useState } from 'react';

const Section3 = ({
  formData,
  handleInputChange,
  clientSearchTerm,
  handleClientSearchChange,
  matchingClients,
  handleClientSelect,
  isFetchingClients
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const languageOptions = [
    "english",
    "spanish",
    "french",
    "german",
    "chinese",
    "japanese",
    "arabic",
    "pashto",
    "turkish",
    "nigerian",
    "dari",
    "other",
  ];

  const handleOtherLanguageChange = (e) => {
    handleInputChange({
      target: {
        name: 'otherLanguage',
        value: e.target.value.toLowerCase(),
      },
    });
  };

  const handleSearchInputChange = (e) => {
    handleInputChange(e);
    handleClientSearchChange(e);
    setIsDropdownVisible(true); // Show dropdown when typing
  };


  const handleInputChangeLowercase = (e) => {
    const { name, value } = e.target;
    // Set formData locally to be lowercase
    handleInputChange({ target: { name, value: value.toLowerCase() } });
  };

  // Detect clicks outside the dropdown to hide it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      {/* Section 3 */}
      <div className="form-section flyer-section">
        <div className="flyer-header">Client Information</div>
        <label className="required-asterisk">Client Name:</label>
        <input
          type="text"
          name="name"
          value={clientSearchTerm}
          onChange={handleSearchInputChange} // For typing with caps support
          onBlur={handleInputChangeLowercase} // Enforces lowercase only when moving out of the field
          placeholder="Your answer"
          required
          autoComplete="new-client-name"
        />
        {isDropdownVisible && matchingClients.length > 0 && (
          <ul ref={dropdownRef} className="client-suggestions">
            {matchingClients
              .slice() // Create a shallow copy to avoid mutating the original array
              .sort((a, b) => {
                const fullNameA = `${a.name.toLowerCase()} ${a.last_name.toLowerCase()}`;
                const fullNameB = `${b.name.toLowerCase()} ${b.last_name.toLowerCase()}`;
                if (fullNameA < fullNameB) return -1;
                if (fullNameA > fullNameB) return 1;
                return 0;
              })
              .map((client) => (
                <li
                  key={client.id}
                  onClick={() => {
                    handleClientSelect(client);
                    setIsDropdownVisible(false); // Hide dropdown on selection
                  }}
                >
                  {client.name} {client.last_name}
                </li>
              ))}
          </ul>
        )}

        {isFetchingClients && <p>Loading clients...</p>}
      </div>

      <div className="form-section">
        <label className="required-asterisk">Client Last Name:</label>
        <input
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={handleInputChange}
          placeholder="Your answer"
          required
        />
      </div>

      <div className="form-section">
        <label className="required-asterisk">Phone Number:</label>
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleInputChange}
          placeholder="Your answer"
          pattern="[0-9]*"
          inputMode="numeric"
          required
        />
      </div>
      <div className='form-section'>
        <label className="required-asterisk">Address:</label>
        <input
          className='long-text'
          type='text'
          name='address'
          value={formData.address}
          onChange={handleInputChange}
          placeholder="Your answer"
          required
        />
      </div>
      <div className='form-section'>
        <label className="required-asterisk">City</label>
        <input
          type='text'
          name='city'
          value={formData.city}
          onChange={handleInputChange}
          placeholder="Your answer"
          required
        />
      </div>
      {/* <div className='form-section'>
        <label className="required-asterisk">County</label>
        <input
          type='text'
          name='county'
          value={formData.county}
          onChange={handleInputChange}
          placeholder="Your answer"
          required
        />
      </div> */}
      <div className='form-section'>
        <label className="required-asterisk">County</label>
        <select
          name="county"
          value={formData.county}
          onChange={handleInputChange}
          required
        >
          <option value="" disabled>County</option>
          <option value="aransas">Aransas</option>
          <option value="atascosa">Atascosa</option>
          <option value="bandera">Bandera</option>
          <option value="bee">Bee</option>
          <option value="bexar">Bexar</option>
          <option value="brooks">Brooks</option>
          <option value="caldwell">Caldwell</option>
          <option value="cameron">Cameron</option>
          <option value="comal">Comal</option>
          <option value="deWitt">DeWitt</option>
          <option value="duval">Duval</option>
          <option value="frio">Frio</option>
          <option value="goliad">Goliad</option>
          <option value="gonzalez">Gonzalez</option>
          <option value="guadalupe">Guadalupe</option>
          <option value="hays">Hays</option>
          <option value="hidalgo">Hidalgo</option>
          <option value="jim Hogg">Jim Hogg</option>
          <option value="jim Wells">Jim Wells</option>
          <option value="karnes">Karnes</option>
          <option value="kenedy">Kenedy</option>
          <option value="kleberg">Kleberg</option>
          <option value="la Salle">La Salle</option>
          <option value="live Oak">Live Oak</option>
          <option value="maverick">Maverick</option>
          <option value="mcMullen">McMullen</option>
          <option value="medina">Medina</option>
          <option value="nueces">Nueces</option>
          <option value="refugio">Refugio</option>
          <option value="san Patricio">San Patricio</option>
          <option value="uvalde">Uvalde</option>
          <option value="willacy">Willacy</option>
          <option value="wilson">Wilson</option>
          <option value="zavala">Zavala</option>
          <option value="Other:">Other:</option>
        </select>
        {formData.county === 'Other:' && (
          <div>
            <label>Please specify:</label>
            <input
              type="text"
              name="other_county"
              value={formData.other_county}
              onChange={handleInputChange}
              required
            />
          </div>
        )}
      </div>
      <div className='form-section'>
        <label className="required-asterisk">Zip Code</label>
        <input
          type='number'
          name='zip_code'
          value={formData.zip_code}
          onChange={handleInputChange}
          placeholder="Your answer"
          min="0"
          required
        />
      </div>
      <div className='form-section'>
        <label className="required-asterisk">Email</label>
        <input
          type='email'
          name='email'
          value={formData.email}
          onChange={handleInputChange}
          placeholder="Your answer"
          required
        />
      </div>
      <div className="form-section">
        <label className="required-asterisk">Preferred Language</label>
        <div className="check-group">
          {languageOptions.map((language) => (
            <label key={language}>
              <input
                type="checkbox"
                name="prefered_lang"
                value={language}
                checked={formData.prefered_lang.includes(language)}
                onChange={handleInputChange}
              />
              {language.charAt(0).toUpperCase() + language.slice(1)} {/* Capitalize first letter */}
            </label>
          ))}
          {formData.prefered_lang.includes("other") && (
            <input
              type="text"
              name="otherLanguage"
              value={formData.otherLanguage || ""}
              onChange={handleOtherLanguageChange}
              placeholder="Your answer"
            />
          )}
        </div>
      </div>
      <div className='form-section'>
        <label className="required-asterisk">Is this a Commit Card?</label>
        <div className="radio-group">
          <label>
            <input
              type="radio"
              name="commitCard"
              value="yes"
              checked={formData.commitCard === 'yes'}
              onChange={handleInputChange}
              required
            />
            Yes
          </label>
          <label>
            <input
              type="radio"
              name="commitCard"
              value="no"
              checked={formData.commitCard === 'no'}
              onChange={handleInputChange}
              required
            />
            No
          </label>
        </div>
      </div>
    </>
  );
};

export default Section3;
