import React, { useState } from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { listUsersByTeamOne, getCallList } from '../../../graphql/queries';
import { UPDATE_CALL_LIST } from '../../../graphql/mutations';
import LoadingSpinner from '../../../components/layout/LoadingSpinner';
import './AssignModal.css';
import { capitalize } from '@mui/material';

const AssignModal = ({ onClose, selectedClients, refetchCallList }) => {
  const client = useApolloClient(); 
  const [selectedUser, setSelectedUser] = useState(null);
  const [assigning, setAssigning] = useState(false);

  // Query: fetch all users 
  const { data, loading } = useQuery(listUsersByTeamOne, {
    variables: { limit: 50 },
  });
  const users = data?.listUsers?.items || [];

  // Mutation: update call list
  const [updateCallList] = useMutation(UPDATE_CALL_LIST);

  // Handle Assign
  const handleAssign = async () => {
    if (!selectedUser || selectedClients.length === 0) return;
    setAssigning(true);

    const navigatorName = `${capitalize(selectedUser.first_name)} ${capitalize(selectedUser.last_name)}`;

    try {
      // For each selected ID, fetch the callList item to get the current contactRound:
      await Promise.all(
        selectedClients.map(async (callId) => {
          // 1) Fetch the call list item
          const { data: callData } = await client.query({
            query: getCallList,
            variables: { id: callId },
            fetchPolicy: 'network-only',
          });

          const existingCall = callData?.getCallList;
          if (!existingCall) return; // fallback guard

          // 2) Determine the "next" contactRound based on rules:
          //    - If null or '' => set to 0
          //    - If -1 => add 1 (becomes 0)
          //    - If > -1 => leave as is
          const currentRound = existingCall.contactRound;
          let newRound;

          if (currentRound === null || currentRound === '') {
            newRound = 0;
          } else if (currentRound === -1) {
            newRound = currentRound + 1; // e.g. -1 + 1 = 0
          } else {
            // If >= 0, leave as is
            newRound = currentRound;
          }
        //   console.log(`Assigning ${navigatorName} to ${callId} with round ${newRound}`);
          // 3) Perform the update mutation
          await updateCallList({
            variables: {
              input: {
                id: callId,
                navigator: navigatorName,
                contactRound: newRound
              },
            },
          });
        })
      );

      // Optionally refetch the call list to show changes
      if (refetchCallList) {
        await refetchCallList();
      }

      // Close modal
      onClose();
    } catch (error) {
      console.error('Error assigning calls:', error);
      alert('Failed to assign calls. Please try again.');
    } finally {
      setAssigning(false);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="call-list-assign modal-overlay">
      <div className="modal-content">
        <h2>Assign Client</h2>

        {/* List of users */}
        <div className="user-list">
          {[
            { id: 'unassigned', first_name: 'Unassigned', last_name: '', thc_email_id: '' },
            ...users
          ]
            .slice()
            .sort(
              (a, b) =>
                a.first_name.localeCompare(b.first_name) || 
                a.last_name.localeCompare(b.last_name)
            )
            .map((user) => (
              <div
                key={user.id}
                className={`user-item ${selectedUser?.id === user.id ? 'selected' : ''}`}
                onClick={() => setSelectedUser(user)}
              >
                <p>
                  <strong>
                    {capitalize(user.first_name)} {capitalize(user.last_name)}
                  </strong>
                </p>
                <p>{user.thc_email_id}</p>
                <button
                  className={`round-button ${
                    selectedUser?.id === user.id ? 'selected' : ''
                  }`}
                />
              </div>
            ))}
        </div>

        {/* Actions */}
        <div className="modal-actions">
          <button
            className="assign-btn"
            onClick={handleAssign}
            disabled={!selectedUser || assigning}
          >
            {assigning ? 'Assigning...' : 'Assign'}
          </button>
          <button className="close-btn" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AssignModal;
