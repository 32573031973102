import React, { useState } from 'react';
import './MergeDialog.css';

const MergeDialog = ({ items, onClose, onSave }) => {
  const [mergedData, setMergedData] = useState(() => ({
    ...items[0],
  }));

  const fields = [
    'name',
    'last_name',
    'phone',
    'email',
    'address',
    'city',
    'county',
    'zip_code',
    'prefered_lang',
    'insurance_picked',
    'plan',
    'payment_per_month',
    'last_contacted_date',
    'navigator',
    'callStatus',
  ];

  const handleFieldChange = (field, newValue) => {
    setMergedData((prev) => ({
      ...prev,
      [field]: newValue,
    }));
  };

  const handleSave = () => {
    // Show a confirmation dialog before proceeding
    const userConfirmed = window.confirm(
      'Are you sure you want to save the changes? This action is not reversible.'
    );

    if (userConfirmed) {
      onSave(mergedData); // Proceed with the save operation
    }
  };

  return (
    <div className="merge-dialog-backdrop" onClick={onClose}>
      <div className="merge-dialog-content" onClick={(e) => e.stopPropagation()}>
        <h2>Merge Records</h2>
        <div className="merge-fields">
          {fields.map((field) => (
            <div key={field} className="merge-field-row">
              <label className="merge-field-label">{field}:</label>
              <div className="merge-field-options">
                {items.map((item, index) => (
                  <label key={index} className="merge-field-option">
                    <input
                      type="radio"
                      name={`${field}-option`}
                      checked={mergedData[field] === item[field]}
                      onChange={() => handleFieldChange(field, item[field])}
                    />
                    {item[field] || <em>(empty)</em>}
                  </label>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="merge-dialog-actions">
          <button onClick={handleSave}>Save</button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default MergeDialog;
