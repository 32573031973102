import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { listBackupBatteries, listDeleteEquipmentHistories, listUsers } from '../../../graphql/queries';
import { createDeleteEquipmentHistory, createBackupBattery, deleteBackupBattery, updateBackupBattery } from '../../../graphql/mutations';
import './Backup_Battery.css';
import XLSX from 'xlsx-js-style';
import { v4 as uuidv4 } from 'uuid';
import { FaFileExcel } from 'react-icons/fa';
import Inventory_header from '../../../components/layout/Inventory_header';
import useFilteredData from '../../../components/layout/useFilteredDate';
import SearchBar from '../../../components/layout/SearchBar';
import { capitalize } from '../../../components/common/capitalize';

const Backup_Battery = () => {
    const [backupBattery, setBackupBattery] = useState([]);
    const [nextToken, setNextToken] = useState(null);
    const [showBackupBatteryAddOverlay, setShowBackupBatteryAddOverlay] = useState(false);
    const [selectedBackupBattery, setSelectedBackupBattery] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const [newBackupBattery, setNewBackupBattery] = useState({
        id: '',
        tagid: '',
        model: '',
        brand: '',
        serial_Number: '',
        price: '',
        store: '',
        purchase_date: '',
        assignment_date: '',
        warranty_date: '',
        returnable_date: '',
        available: true,
        status: 'inv',
        assigned_to: '',
        location: '',
        team: '',

    });
    const [sortConfig, setSortConfig] = useState(null);
    const [duplicateError, setDuplicateError] = useState('');
    const [priceError, setPriceError] = useState('');
    const [statusError, setStatusError] = useState('');
    const { loading1, error: userError, data: userData } = useQuery(listUsers);




    const handleSaveAsExcel = async () => {
        try {

            const backupToExport = filteredBackupBatterys.length > 0 ? filteredBackupBatterys : backupBattery;


            if (backupToExport.length > 0) {
                // Sort the BackupBatterys by Timestamp in ascending order (earliest first)
                const sortedBackupBattery = [...backupToExport].sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp));

                // Prepare the data for Excel export with custom column titles
                const worksheetData = sortedBackupBattery.map((backupBattery, index) => ({
                    '#': index + 1, // Agregar el número de fila
                    'TAG:': backupBattery.tagid,
                    'Brand:': backupBattery.brand,
                    'Available:': backupBattery.available ? 'Yes' : 'No',
                    'Serial Number:': backupBattery.serial_Number,
                    'Status:': backupBattery.status,
                    'Assigned to:': backupBattery.assigned_to,
                    'Location:': backupBattery.location,

                    // Add other fields as needed

                }));


                // Convert JSON to sheet starting from row 2
                const worksheet = XLSX.utils.json_to_sheet([]);
                let endCol = 0;

                XLSX.utils.sheet_add_aoa(worksheet, [['Backup Batteries']], { origin: 'A1' });

                if (worksheetData.length > 0) {
                    // Agrega los datos a partir de la fila A2
                    XLSX.utils.sheet_add_json(worksheet, worksheetData, { origin: 'A2', skipHeader: false });

                    // Define el rango de referencia (!ref)
                    const endRow = worksheetData.length + 1; // +1 para el título en A1
                    const endCol = Object.keys(worksheetData[0]).length - 1;
                    worksheet['!ref'] = XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: endRow, c: endCol } });
                } else {
                    console.error("No hay datos para exportar.");
                    return;
                }

                // Merge title cells across the columns
                worksheet['!merges'] = worksheet['!merges'] || [];
                worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 7 } }); // A1 to H1 for the added column

                // Style the title row
                worksheet['A1'].s = {
                    font: {
                        bold: true,
                        sz: 20, // Font size
                        color: { rgb: '1F4E79' } // Dark blue text color
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center'
                    }
                };

                // Apply styling for the header row (now starting at row 2)
                const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
                for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                    const cellAddress = XLSX.utils.encode_cell({ r: 1, c: col }); // Header row is now row 1
                    if (!worksheet[cellAddress]) continue;
                    worksheet[cellAddress].s = {
                        fill: {
                            fgColor: { rgb: '1F4E79' } // Dark blue background
                        },
                        font: {
                            bold: true,
                            color: { rgb: 'FFFFFF' } // White text
                        },
                        alignment: {
                            horizontal: 'center'
                        },
                        border: {
                            top: { style: 'thin', color: { rgb: '000000' } },
                            left: { style: 'thin', color: { rgb: '000000' } },
                            bottom: { style: 'thin', color: { rgb: '000000' } },
                            right: { style: 'thin', color: { rgb: '000000' } },
                        }
                    };
                }

                // Apply alternating row colors and borders starting from row 3
                for (let row = 2; row <= headerRange.e.r; row++) {
                    const isEvenRow = row % 2 === 0;
                    for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
                        if (!worksheet[cellAddress]) continue;

                        worksheet[cellAddress].s = {
                            fill: {
                                fgColor: { rgb: isEvenRow ? 'D3D3D3' : 'FFFFFF' } // Light gray for even rows, white for odd rows
                            },
                            border: {
                                top: { style: 'thin', color: { rgb: '000000' } },
                                left: { style: 'thin', color: { rgb: '000000' } },
                                bottom: { style: 'thin', color: { rgb: '000000' } },
                                right: { style: 'thin', color: { rgb: '000000' } },
                            },
                            alignment: {
                                horizontal: 'center'
                            }
                        };
                    }
                }
                // Adjust columns to fit content
                worksheet['!cols'] = [
                    { wch: 5 }, // Adjust width for each column
                    { wch: 10 },
                    { wch: 25 },
                    { wch: 10 },
                    { wch: 15 },
                    { wch: 10 },
                    { wch: 25 },
                    { wch: 15 }
                ];
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'BackupBatterys');

                // Write file
                XLSX.writeFile(workbook, 'BackupBatterysData.xlsx');
            } else {
                console.error('No data to export');
            }
        } catch (error) {
            console.error('Error exporting data to Excel:', error);
        }
    };

    const handleEditBackupBattery = (scanner) => {
        setSelectedBackupBattery(scanner);
        setIsEditing(true);
        setShowBackupBatteryAddOverlay(true);
    };

    const { loading, data, fetchMore } = useQuery(listBackupBatteries, {
        variables: { limit: 100 },
    });

    const [addBackupBattery] = useMutation(createBackupBattery, {
        refetchQueries: [{ query: listBackupBatteries, variables: { limit: 100 } }]
    });

    const [removeBackupBattery] = useMutation(deleteBackupBattery, {
        refetchQueries: [{ query: listBackupBatteries, variables: { limit: 100 } }]
    });
    const [updBackupBattery] = useMutation(updateBackupBattery, {
        refetchQueries: [{ query: listBackupBatteries, variables: { limit: 100 } }]
    });
    const [addDeleteEquipmentHistory] = useMutation(createDeleteEquipmentHistory, {
        refetchQueries: [{ query: listDeleteEquipmentHistories, variables: { limit: 100 } }]
    });
    const [fetchAllBackupBatterys, { loading: countLoading, data: AllBackupBatterysData }] = useLazyQuery(listBackupBatteries, {
        variables: { limit: 100 },
        fetchPolicy: "network-only",
    });


    useEffect(() => {
        const fetchAllBackupBatterys = async () => {
            let AllBackupBatterys = [];
            let currentNextToken = null;

            do {
                const { data } = await fetchMore({
                    variables: { limit: 100, nextToken: currentNextToken },
                });

                AllBackupBatterys = [...AllBackupBatterys, ...data.listBackupBatteries.items];
                currentNextToken = data.listBackupBatteries.nextToken;
            } while (currentNextToken);

            setBackupBattery(AllBackupBatterys);
            setNextToken(currentNextToken);
        };

        if (data) {
            fetchAllBackupBatterys();
        }
    }, [data, fetchMore]);

    const clearNewBackupBattery = () => {
        setNewBackupBattery({
            id: '',
            tagid: '',
            model: '',
            brand: '',
            serial_Number: '',
            price: '',
            store: '',
            purchase_date: '',
            assignment_date: '',
            warranty_date: '',
            returnable_date: '',
            available: true,
            status: 'inv',
            assigned_to: '',
            location: '',
            team: '',
        });
    };

    // Define loadMore function
    const loadMore = () => {
        fetchMore({
            variables: { limit: 100, nextToken },
            updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;
                setNextToken(fetchMoreResult.listBackupBatteries.nextToken);
                return {
                    listBackupBatteries: {
                        ...fetchMoreResult.listBackupBatteries,
                        items: [...prevResult.listBackupBatteries.items, ...fetchMoreResult.listBackupBatteries.items]
                    }
                };
            }
        });
    };

    const filteredBackupBatterys = useFilteredData(backupBattery || [], searchTerm, sortConfig);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let formattedValue = value;

        // Convert to lowercase and trim whitespace for specific fields
        if (['serial_Number'].includes(name)) {
            formattedValue = value.toLowerCase().trim();
        }

        if (isEditing) {
            setSelectedBackupBattery({
                ...selectedBackupBattery,
                [name]: formattedValue
            });
        } else {
            setNewBackupBattery({
                ...newBackupBattery,
                id: uuidv4(),
                [name]: formattedValue
            });
        }
    };

    const handleAddBackupBattery = () => {

        // Formatear la fecha en el formato YYYY-MM-DD si existe, o establecerla en null si está vacía
        const formattedPurchase = newBackupBattery.purchase_date
            ? new Date(newBackupBattery.purchase_date).toISOString().split('T')[0]
            : null;
        const formattedAssignment = newBackupBattery.assignment_date
            ? new Date(newBackupBattery.assignment_date).toISOString().split('T')[0]
            : null;

        const formattedWarranty = newBackupBattery.warranty_date
            ? new Date(newBackupBattery.warranty_date).toISOString().split('T')[0]
            : null;

        const formattedReturnable = newBackupBattery.returnable_date
            ? new Date(newBackupBattery.returnable_date).toISOString().split('T')[0]
            : null;


        // Validate that the price contains only digits and is a valid number
        let priceValue = null;
        if (newBackupBattery.price !== "" && newBackupBattery.price !== null && newBackupBattery.price != undefined) {
            priceValue = parseFloat(newBackupBattery.price);

            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');
                return;
            }
        }
        setPriceError('');

        // Check for duplicates based on `tagid` and `serial_Number`
        const isDuplicate = backupBattery.some(backupBattery =>
            backupBattery.tagid === newBackupBattery.tagid &&
            backupBattery.serial_Number === newBackupBattery.serial_Number
        );

        if (isDuplicate) {
            setDuplicateError('A backupBattery with the same TAG and Serial Number already exists.');
            console.log('Duplicate validation failed');
            return;
        } else {
            setDuplicateError('');
        }

        // Check availability first
        if (newBackupBattery.available === true) {

            // If available, status must be Inventario
            if (newBackupBattery.status !== 'inv') {
                setStatusError('If the Backup Battery is available, its status must be "Inventario".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // If available and status is Inventario, assigned_to must be empty or "N/A"
            if (newBackupBattery.assigned_to !== '' && newBackupBattery.assigned_to !== 'n/a') {
                setStatusError('If the Backup Battery is available and its status is "Inventario", the "Assigned to" field must be empty or "N/A".');
                console.log('Assigned_to validation failed');
                return; // Exit function if validation fails
            }

        } else if (newBackupBattery.available !== "") {
            // If not available, status must be one of the allowed statuses
            const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
            if (!allowedStatuses.includes(newBackupBattery.status)) {
                setStatusError('If the Backup Battery is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // Clear error if validation passes
            setStatusError('');
        }


        // If all validations pass, proceed with adding the backupBattery to the database
        addBackupBattery({
            variables: { input: { ...newBackupBattery, price: priceValue, purchase_date: formattedPurchase, warranty_date: formattedWarranty, returnable_date: formattedReturnable, assignment_date: formattedAssignment } }
        })
            .then(() => {
                clearNewBackupBattery();
                alert("The Backup Battery was an added success")
                setShowBackupBatteryAddOverlay(false);
                
            }).catch(error => {
                console.error("Error adding backupBattery:", error);
            });
    };

    const removeUnnecessaryFields = (data, fieldsToRemove) => {
        fieldsToRemove.forEach((field) => {
            delete data[field];
        });
        return data;
    };
    const handleDeleteBackupBattery = async () => {
        try {
            // Remover campos innecesarios
            const BackupBatteryData = removeUnnecessaryFields({ ...selectedBackupBattery }, [
                'warranty_date',
                'returnable_date',
                'status',
                'team',
            ]);

            // Lista de campos permitidos según el esquema GraphQL
            const allowedFields = [
                'id',
                'Item',
                'tagId',
                'tagid', // Agregado
                'serial_Number',
                'price',
                'brand',
                'model',
                'store',
                'purchase_date',
                'assignment_date',
                'available',
                'previous_employees',
                'assigned_to', // Agregado
                'location',
                'notes',
            ];

            // Filtrar los campos permitidos
            const cleanedNewBackupBattery = Object.fromEntries(
                Object.entries(BackupBatteryData).filter(([key]) => allowedFields.includes(key))
            );


            console.log("Antes de normalizar:", cleanedNewBackupBattery);

            // Normalizar claves alternativas
            cleanedNewBackupBattery.tagId = cleanedNewBackupBattery.tagId || cleanedNewBackupBattery.tagid;
            delete cleanedNewBackupBattery.tagid;

            cleanedNewBackupBattery.previous_employees =
                cleanedNewBackupBattery.previous_employees || cleanedNewBackupBattery.assigned_to;
            delete cleanedNewBackupBattery.assigned_to;

            console.log("Despues de normalizar:", cleanedNewBackupBattery);

            // Añadir valores clave predeterminados
            if (!cleanedNewBackupBattery.Item) {
                cleanedNewBackupBattery.Item = "Backup Battery"; // Establecer el valor predeterminado para `Item`
            }

            // Eliminar valores vacíos
            Object.keys(cleanedNewBackupBattery).forEach((key) => {
                if (cleanedNewBackupBattery[key] === "" || cleanedNewBackupBattery[key] === null) {
                    delete cleanedNewBackupBattery[key];
                }
            });

            // Agregar empleado a la tabla PreviousEmployee
            await addDeleteEquipmentHistory({
                variables: { input: cleanedNewBackupBattery },
            });

            // Eliminar el BackupBattery
            await removeBackupBattery({
                variables: { input: { id: cleanedNewBackupBattery.id } },
            });

            // Resetear estados
            setShowBackupBatteryAddOverlay(false);
            setIsEditing(false);
            setSelectedBackupBattery(null);

        } catch (error) {
            console.error("Error deleting BackupBattery", error);
        }
    };
    const handleUpdateBackupBattery = async () => {

        const formattedData_purchase = selectedBackupBattery.purchase_date ? new Date(selectedBackupBattery.purchase_date).toISOString().split('T')[0] : null;
        const formattedData_warranty = selectedBackupBattery.warranty_date ? new Date(selectedBackupBattery.warranty_date).toISOString().split('T')[0] : null;
        const formattedData_returnable = selectedBackupBattery.returnable_date ? new Date(selectedBackupBattery.returnable_date).toISOString().split('T')[0] : null;
        const formattedData_assignment = selectedBackupBattery.assignment_date ? new Date(selectedBackupBattery.assignment_date).toISOString().split('T')[0] : null;

        //Check Price
        let priceValue = null;
        if (selectedBackupBattery.price !== "" && selectedBackupBattery.price !== null && selectedBackupBattery.price != undefined) {
            priceValue = parseFloat(selectedBackupBattery.price);

            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');
                return;
            }
        }
        setPriceError('');

        // Check availability first
        if (selectedBackupBattery.available === 'true') {

            // If available, status must be Inventario
            if (selectedBackupBattery.status !== 'inv') {
                setStatusError('If the Backup Battery is available, its status must be "Inventario".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // If available and status is Inventario, assigned_to must be empty or "N/A"
            if (selectedBackupBattery.assigned_to !== '' && selectedBackupBattery.assigned_to !== 'n/a') {
                setStatusError('If the Backup Battery is available and its status is "Inventario", the "Assigned to" field must be empty or "N/A".');
                console.log('Assigned_to validation failed');
                return; // Exit function if validation fails
            }

        } else if (selectedBackupBattery.available === 'false') {
            // If not available, status must be one of the allowed statuses
            const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
            if (!allowedStatuses.includes(selectedBackupBattery.status)) {
                setStatusError('If the Backup Battery is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // Clear error if validation passes
            setStatusError('');
        }

        updBackupBattery({
            variables: {
                input: {
                    id: selectedBackupBattery.id,
                    tagid: selectedBackupBattery.tagid,
                    model: selectedBackupBattery.model,
                    brand: selectedBackupBattery.brand,
                    serial_Number: selectedBackupBattery.serial_Number,
                    price: priceValue,
                    store: selectedBackupBattery.store,
                    purchase_date: formattedData_purchase,
                    assignment_date: formattedData_assignment,
                    warranty_date: formattedData_warranty,
                    returnable_date: formattedData_returnable,
                    available: selectedBackupBattery.available,
                    status: selectedBackupBattery.status,
                    assigned_to: selectedBackupBattery.assigned_to,
                    location: selectedBackupBattery.location,
                    team: selectedBackupBattery.team,

                }
            }
        }).then(() => {
            setPriceError('');
            setStatusError('');
            setDuplicateError('');
            setShowBackupBatteryAddOverlay(false);

        }).catch(error => {
            console.error("Error updating BackupBattery:", error);
        });
    };


    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    //listUsers 
    const users = React.useMemo(() => {
        if (userData && userData.listUsers) {
            return userData.listUsers.items || [];
        }
        return [];
    }, [userData]);
    if (userError) return <p>Error loading users: {userError.message}</p>;
    if (loading1) return <p>Error loading users: {userError.message}</p>;


    const formatDate = (dateString) => {
        const options = { month: 'numeric', day: 'numeric', year: 'numeric' };
        const date = new Date(dateString);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

        return localDate.toLocaleDateString(undefined, options);
    };

    const formatPrice = (price) => {
        if (price === null || price === undefined || price === '') {
            return '';
        }
        const parsedPrice = parseFloat(price);
        return isNaN(parsedPrice) ? '' : parsedPrice.toFixed(2);
    };

    return (
        <div className="backupBattery-page">
            <header className="inventory-header">
                <h1>Backup Battery</h1>
            </header>
            <Inventory_header />
            <div className="inventory-search">
                <button onClick={handleSaveAsExcel} > <FaFileExcel /> Export </button>
            </div>

            <div className="searchBar">
                <button className="add-backupBattery-btn" onClick={() => {
                    clearNewBackupBattery();
                    setSelectedBackupBattery(null);
                    setIsEditing(false);
                    setShowBackupBatteryAddOverlay(true);
                }}>+ Add New Backup Battery</button>
                <SearchBar searchTerm={searchTerm} onSearch={setSearchTerm} />
            </div>
            <div className="backupBattery-table-container">
                <table className="backupBattery-table">
                    <thead>
                        <tr>
                            <th>#</th> {/* Columna para contar las filas */}

                            <th onClick={() => requestSort('tagid')}>
                                TAG
                                {sortConfig?.key === 'tagid' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('model')}>
                                Model
                                {sortConfig?.key === 'model' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('brand')}>
                                Brand
                                {sortConfig?.key === 'brand' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('serial_Number')}>
                                Serial Number
                                {sortConfig?.key === 'serial_Number' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('price')}>
                                Price
                                {sortConfig?.key === 'price' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('store')}>
                                Store
                                {sortConfig?.key === 'store' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('purchase_date')}>
                                Purchase Date
                                {sortConfig?.key === 'purchase_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('available')}>
                                Available
                                {sortConfig?.key === 'available' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('status')}>
                                Status
                                {sortConfig?.key === 'status' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('assigned_to')}>
                                Assigned To
                                {sortConfig?.key === 'assigned_to' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('assignment_date')}>
                                Assignment Date
                                {sortConfig?.key === 'assignment_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('warranty_date')}>
                                Warranty Date
                                {sortConfig?.key === 'warranty_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('returnable_date')}>
                                Returnable Date
                                {sortConfig?.key === 'returnable_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                        
                            <th onClick={() => requestSort('location')}>
                                Location
                                {sortConfig?.key === 'location' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('team')}>
                                Team
                                {sortConfig?.key === 'team' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredBackupBatterys.map((backupBattery, index) => (
                            <tr key={backupBattery.id} onClick={() => handleEditBackupBattery(backupBattery)} >
                                <td>{index + 1}</td> {/* Número de fila */}
                                <td className="backupBattery-tagid">{capitalize(backupBattery.tagid)}</td>
                                <td className="backupBattery-model">{capitalize(backupBattery.model.toLowerCase())}</td>
                                <td className="backupBattery-brand">{capitalize(backupBattery.brand.toLowerCase())}</td>
                                <td className="backupBattery-serial_Number">{backupBattery.serial_Number.toUpperCase()}</td>
                                <td className="backupBattery-price">${formatPrice(backupBattery.price)}</td>
                                <td className="backupBattery-store">{backupBattery.store}</td>
                                <td className="backupBattery-purchase_date">{backupBattery.purchase_date ? formatDate(backupBattery.purchase_date) : 'No Date'}</td>
                                <td className="backupBattery-available">{backupBattery.available ? 'Yes' : 'No'} </td>
                                <td className="backupBattery-status">
                                    {backupBattery.status === 'broken' ? 'Broken' :
                                        backupBattery.status === 'use' ? 'Use' :
                                            backupBattery.status === 'transfer' ? 'Transfer' :
                                                backupBattery.status === 'lost' ? 'Lost' :
                                                    backupBattery.status === 'inv' ? 'Inventario' :
                                                        'Inventario'} {/* Muestra 'Unknown' si el estado no coincide con ninguno de los anteriores */}
                                </td>
                                <td className="backupBattery-assigned_to">{backupBattery.assigned_to}</td>
                                <td className="backupBattery-assignment_date">{backupBattery.assignment_date ? formatDate(backupBattery.assignment_date) : 'No Date'}</td>
                                <td className="backupBattery-warranty_date">{backupBattery.warranty_date ? formatDate(backupBattery.warranty_date) : 'No Date'}</td>
                                <td className="backupBattery-returnable_date">{backupBattery.returnable_date ? formatDate(backupBattery.returnable_date) : 'No Date'}</td>
                                <td className="backupBattery-location">{backupBattery.location}</td>
                                <td className="backupBattery-team">{backupBattery.team}</td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {
                nextToken && !loading && (
                    <button onClick={loadMore}>Load More</button>
                )
            }


            {loading && <p>Loading...</p>}

            {
                showBackupBatteryAddOverlay && (

                    <div className="backupBattery_add_overlay" onClick={(e) => {
                        // Close overlay only if clicked outside the modal content
                        if (e.target.classList.contains("backupBattery_add_overlay")) {
                            setShowBackupBatteryAddOverlay(false);
                            clearNewBackupBattery();
                            setSelectedBackupBattery(null);
                            setIsEditing(false);
                        }
                    }}>
                        <div className="overlay-content-inventory">
                            <div className="header-row">
                                <h2 className="header-title">{isEditing ? 'Edit Backup Battery' : 'Add New Backup Battery'}</h2>
                                <button onClick={() => {
                                    setShowBackupBatteryAddOverlay(false);
                                    clearNewBackupBattery();
                                    setSelectedBackupBattery(null);
                                    setIsEditing(false);
                                }}>X</button>
                            </div>

                            <label>TAG:</label><input name="tagid" placeholder="TAG" value={isEditing ? selectedBackupBattery.tagid : newBackupBattery.tagid} onChange={handleInputChange} required />
                            <label>Model:</label><input name="model" placeholder="Model" value={isEditing ? selectedBackupBattery.model : newBackupBattery.model} onChange={handleInputChange} required />

                            <label>Brand:</label><input name="brand" placeholder="Brand" value={isEditing ? selectedBackupBattery.brand : newBackupBattery.brand} onChange={handleInputChange} />
                            <label>Serial Number:</label><input name="serial_Number" placeholder="Serial Number" value={isEditing ? selectedBackupBattery.serial_Number : newBackupBattery.serial_Number} onChange={handleInputChange} required />

                            <label>Price:</label><input name="price" placeholder="price" value={isEditing ? selectedBackupBattery.price : newBackupBattery.price} onChange={handleInputChange} />
                            <label>Store:</label><input name="store" placeholder="store" value={isEditing ? selectedBackupBattery.store : newBackupBattery.store} onChange={handleInputChange} />

                            <label htmlFor="purchase_date">Purchase Date:</label>
                            <input
                                type="Date"
                                id="purchase_date"
                                name="purchase_date"
                                value={isEditing ? selectedBackupBattery.purchase_date : newBackupBattery.purchase_date}
                                onChange={handleInputChange}

                            />

                            <label htmlFor="assignment_date">Assignment Date:</label>
                            <input
                                type="date"
                                id="assignment_date"
                                name="assignment_date"
                                value={isEditing ? selectedBackupBattery.assignment_date : newBackupBattery.assignment_date}
                                onChange={handleInputChange}

                            />
                            <label htmlFor="warranty_date">Warranty Date:</label>
                            <input
                                type="date"
                                id="warranty_date"
                                name="warranty_date"
                                value={isEditing ? selectedBackupBattery.warranty_date : newBackupBattery.warranty_date}
                                onChange={handleInputChange}


                            />
                            <label htmlFor="returnable_date">Returnable Date:</label>
                            <input
                                type="date"
                                id="returnable_date"
                                name="returnable_date"
                                value={isEditing ? selectedBackupBattery.returnable_date : newBackupBattery.returnable_date}
                                onChange={handleInputChange}

                            />

                            <label>Available:</label>

                            <select name="available" placeholder="available" value={isEditing ? selectedBackupBattery.available : newBackupBattery.available} onChange={handleInputChange} >
                                <option value="" disabled hidden className="placeholder-option">Select Availability</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>

                            </select>
                            <label>Status:</label>
                            <select name="status" placeholder="status" value={isEditing ? selectedBackupBattery.status : newBackupBattery.status} onChange={handleInputChange} >
                                <option value="" disabled hidden>Select Status</option>
                                <option value="use">Use</option>
                                <option value="broken">Broken</option>
                                <option value="transfer">Transfer</option>
                                <option value="lost">Lost</option>
                                <option value="inv">Inventario</option>

                            </select>
                            <label>Assigned To:</label>
                            <select
                                name="assigned_to"
                                value={isEditing ? selectedBackupBattery.assigned_to : newBackupBattery.assigned_to}
                                onChange={handleInputChange}
                            >
                                <option value="">Select an Employee</option>


                                {users.length > 0 ? (
                                    [...users].sort((a, b) => {
                                        const nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
                                        const nameB = `${b.first_name} ${b.last_name}`.toLowerCase();
                                        return nameA.localeCompare(nameB);
                                    }).map(user => (
                                        <option key={user.id} value={`${capitalize(user.first_name)} ${capitalize(user.last_name)}`}>
                                            {capitalize(user.first_name)} {capitalize(user.last_name)}
                                        </option>
                                    ))
                                ) : (

                                    <option value="">No users available</option>
                                )}
                                <option value="n/a">N/A</option> {/* Opción N/A */}
                            </select>


                            <label>Location:</label><input name="location" placeholder="location" value={isEditing ? selectedBackupBattery.location : newBackupBattery.location} onChange={handleInputChange} />
                            <label>Team:</label><input name="team" placeholder="team" value={isEditing ? selectedBackupBattery.team : newBackupBattery.team} onChange={handleInputChange} />

                            {duplicateError && <p className="error">{duplicateError}</p>} {/* Display duplicate error message */}
                            {priceError && <p className="error">{priceError}</p>} {/* Display price error message */}
                            {statusError && <p className="error">{statusError}</p>} {/* Display duplicate error message */}

                            <div className="button-group">
                                <button className="overlay-add-backupBattery" onClick={isEditing ? handleUpdateBackupBattery : handleAddBackupBattery} >
                                    {isEditing ? 'Update Backup Battery' : 'Add Backup Battery'}
                                </button>
                                {isEditing && (
                                    <button
                                        className="overlay-delet-backupBattery"
                                        onClick={() => {
                                            if (window.confirm('Are you sure you want to delete this backupBattery?')) {
                                                handleDeleteBackupBattery();
                                            }
                                        }}
                                    >
                                        Delete Backup Battery
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                )
            }

        </div >


    );










};

export default Backup_Battery;
