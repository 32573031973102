import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_CLIENTFIX2, DELETE_CLIENTFIX2 } from '../../../graphql/mutations';
import './DuplicatesModal.css';
import MergeDialog from './MergeDialog';

const DuplicatesModal = ({ duplicateGroups, onClose }) => {
  const [groups, setGroups] = useState(duplicateGroups);

  // MergeDialog control
  const [mergeGroupKey, setMergeGroupKey] = useState(null);
  const [mergeItems, setMergeItems] = useState(null);

  // 1. Hook up your mutations
  const [updateClientFix2] = useMutation(UPDATE_CLIENTFIX2);
  const [deleteClientFix2] = useMutation(DELETE_CLIENTFIX2);

  // Removes a single client from duplicates (UI only)
  const handleRemoveFromDuplicates = (groupKey, clientId) => {
    setGroups((prevGroups) => {
      const updatedGroups = prevGroups.map((group) => {
        if (group.groupKey === groupKey) {
          const updatedItems = group.items.filter((item) => item.id !== clientId);
          return { ...group, items: updatedItems };
        }
        return group;
      });
      return updatedGroups.filter((group) => group.items.length > 1);
    });
  };

  // Opens the MergeDialog for a specific group
  const handleOpenMergeDialog = (groupKey, items) => {
    setMergeGroupKey(groupKey);
    setMergeItems(items);
  };

  /**
   * Called by <MergeDialog> on "Save".
   * 1) Update the selected record (mergedData) in the DB
   * 2) Delete the OTHER records in that group
   * 3) Remove the group from local state
   */
  const handleMergeSave = async (mergedData) => {
    try {
      // 1) UPDATE the "winner" record
      const updateResponse = await updateClientFix2({
        variables: {
          input: {
            id: mergedData.id,
            name: mergedData.name,
            last_name: mergedData.last_name,
            email: mergedData.email,
            address: mergedData.address,
            city: mergedData.city,
            county: mergedData.county,
            zip_code: mergedData.zip_code,
            prefered_lang: mergedData.prefered_lang,
            insurance_picked: mergedData.insurance_picked,
            last_contacted_date: mergedData.last_contacted_date,
            navigator: mergedData.navigator,
            payment_per_month: mergedData.payment_per_month,
            phone: mergedData.phone,
            plan: mergedData.plan,
            callStatus: mergedData.callStatus,
          },
        },
      });

      const updatedClient = updateResponse?.data?.updateClientFix2;
      if (!updatedClient?.id) {
        console.error('Error: Mutation did not return an updated client ID.', updateResponse);
        return;
      }

      // 2) DELETE the OTHER duplicates in the group:
      //    i.e., items whose id != mergedData.id
      const itemsToDelete = mergeItems.filter((item) => item.id !== mergedData.id);

      await Promise.all(
        itemsToDelete.map((item) =>
          deleteClientFix2({
            variables: {
              input: { id: item.id },
            },
          })
        )
      );

      // 3) Remove the entire group from local state
      setGroups((prev) => prev.filter((g) => g.groupKey !== mergeGroupKey));

      // Close the MergeDialog
      setMergeGroupKey(null);
      setMergeItems(null);
    } catch (error) {
      console.error('Error merging and deleting duplicates:', error);
      // Optionally show a user-friendly error alert/notification
    }
  };

  // Closes MergeDialog without saving/merging
  const handleCloseMergeDialog = () => {
    setMergeGroupKey(null);
    setMergeItems(null);
  };

  return (
    <div className="duplicates-modal-backdrop" onClick={onClose}>
      <div
        className="duplicates-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <h2>Duplicates Found</h2>

        {groups.length === 0 ? (
          <p>No duplicates remaining.</p>
        ) : (
          <div className="duplicates-list">
            {groups.map((group) => {
              const { groupKey, items } = group;

              return (
                <div key={groupKey} className="duplicate-group">
                  <div className="duplicate-summary">
                    <p>
                      <strong>Phone Key:</strong> {groupKey} |{' '}
                      <strong>Count:</strong> {items.length}
                    </p>
                  </div>
                  <div className="duplicate-details">
                    {items.map((client) => (
                      <div className="duplicate-item" key={client.id}>
                        <h4>{client.name} {client.last_name}</h4>
                        <p><strong>ID:</strong> {client.id}</p>
                        <p><strong>Phone:</strong> {client.phone}</p>
                        {/* ... other fields as needed ... */}
                        <button
                          className="merge-btn"
                          onClick={() => handleOpenMergeDialog(groupKey, items)}
                        >
                          Merge (Choose Fields)
                        </button>
                        <button
                          className="remove-btn"
                          onClick={() => handleRemoveFromDuplicates(groupKey, client.id)}
                        >
                          Remove from Duplicates
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        )}

        <button className="close-modal-btn" onClick={onClose}>
          Close
        </button>
      </div>

      {/* Show the MergeDialog if the user wants to merge */}
      {mergeGroupKey && mergeItems && (
        <MergeDialog
          items={mergeItems}
          onClose={handleCloseMergeDialog}
          onSave={handleMergeSave}
        />
      )}
    </div>
  );
};

export default DuplicatesModal;
