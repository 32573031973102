import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { listDeleteEquipmentHistories, listExternalDisks, listUsers } from '../../../graphql/queries';
import { createDeleteEquipmentHistory, createExternalDisk, deleteExternalDisk, updateExternalDisk } from '../../../graphql/mutations';
import './External_Disk.css';
import XLSX from 'xlsx-js-style';
import { v4 as uuidv4 } from 'uuid';
import { FaFileExcel } from 'react-icons/fa';
import Inventory_header from '../../../components/layout/Inventory_header';
import useFilteredData from '../../../components/layout/useFilteredDate';
import SearchBar from '../../../components/layout/SearchBar';
import { capitalize } from '../../../components/common/capitalize';

const External_Disk = () => {
    const [externalDisk, setExternalDisk] = useState([]);
    const [nextToken, setNextToken] = useState(null);
    const [showExternalDiskAddOverlay, setShowExternalDiskAddOverlay] = useState(false);
    const [selectedExternalDisk, setSelectedExternalDisk] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const [newExternalDisk, setNewExternalDisk] = useState({
        id: '',
        tagid: '',
        model: '',
        brand: '',
        serial_Number: '',
        storage: '',
        descripcion: '',
        charger_cable: true,
        output_cable: true,
        price: '',
        store: '',
        purchase_date: '',
        assignment_date: '',
        warranty_date: '',
        returnable_date: '',
        available: true,
        status: 'inv',
        assigned_to: '',
        location: '',
        team: '',

    });
    const [sortConfig, setSortConfig] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [duplicateError, setDuplicateError] = useState('');
    const [priceError, setPriceError] = useState('');
    const [statusError, setStatusError] = useState('');
    const [storageError, setStorageError] = useState('');
    const { loading1, error: userError, data: userData } = useQuery(listUsers);

    const handleSaveAsExcel = async () => {
        try {
            const ExternalDiskToExport = filteredExternalDisks.length > 0 ? filteredExternalDisks : externalDisk;

            if (ExternalDiskToExport.length > 0) {
                const sortedExternalDisks = [...ExternalDiskToExport].sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp));

                const worksheetData = sortedExternalDisks.map((externalDisk, index) => ({
                    '#': index + 1, // Agregar el número de fila
                    'TAG': externalDisk.tagId,
                    'Brand': externalDisk.brand,
                    'Assigned To': externalDisk.assigned_to,
                    'Available': externalDisk.available ? 'Yes' : 'No',
                    'Serial Number': externalDisk.serial_Number,
                    'Status': externalDisk.status,
                    'Team': externalDisk.team,
                }));

                // Convert JSON to sheet starting from row 2
                const worksheet = XLSX.utils.json_to_sheet([]);
                let endCol = 0;

                XLSX.utils.sheet_add_aoa(worksheet, [['External Disks']], { origin: 'A1' });

                if (worksheetData.length > 0) {
                    // Agrega los datos a partir de la fila A2
                    XLSX.utils.sheet_add_json(worksheet, worksheetData, { origin: 'A2', skipHeader: false });

                    // Define el rango de referencia (!ref)
                    const endRow = worksheetData.length + 1; // +1 para el título en A1
                    const endCol = Object.keys(worksheetData[0]).length - 1;
                    worksheet['!ref'] = XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: endRow, c: endCol } });
                } else {
                    console.error("No hay datos para exportar.");
                    return;
                }

                // Merge title cells across the columns
                worksheet['!merges'] = worksheet['!merges'] || [];
                worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 7 } }); // A1 to H1 for the added column

                // Style the title row
                worksheet['A1'].s = {
                    font: {
                        bold: true,
                        sz: 20, // Font size
                        color: { rgb: '1F4E79' } // Dark blue text color
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center'
                    }
                };

                // Apply styling for the header row (now starting at row 2)
                const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
                for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                    const cellAddress = XLSX.utils.encode_cell({ r: 1, c: col }); // Header row is now row 1
                    if (!worksheet[cellAddress]) continue;
                    worksheet[cellAddress].s = {
                        fill: {
                            fgColor: { rgb: '1F4E79' } // Dark blue background
                        },
                        font: {
                            bold: true,
                            color: { rgb: 'FFFFFF' } // White text
                        },
                        alignment: {
                            horizontal: 'center'
                        },
                        border: {
                            top: { style: 'thin', color: { rgb: '000000' } },
                            left: { style: 'thin', color: { rgb: '000000' } },
                            bottom: { style: 'thin', color: { rgb: '000000' } },
                            right: { style: 'thin', color: { rgb: '000000' } },
                        }
                    };
                }

                // Apply alternating row colors and borders starting from row 3
                for (let row = 2; row <= headerRange.e.r; row++) {
                    const isEvenRow = row % 2 === 0;
                    for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
                        if (!worksheet[cellAddress]) continue;

                        worksheet[cellAddress].s = {
                            fill: {
                                fgColor: { rgb: isEvenRow ? 'D3D3D3' : 'FFFFFF' } // Light gray for even rows, white for odd rows
                            },
                            border: {
                                top: { style: 'thin', color: { rgb: '000000' } },
                                left: { style: 'thin', color: { rgb: '000000' } },
                                bottom: { style: 'thin', color: { rgb: '000000' } },
                                right: { style: 'thin', color: { rgb: '000000' } },
                            },
                            alignment: {
                                horizontal: 'center'
                            }
                        };
                    }
                }

                // Adjust columns to fit content
                // Adjust columns to fit content
                worksheet['!cols'] = [
                    { wch: 5 }, // Width for Row Number
                    { wch: 10 },
                    { wch: 15 },
                    { wch: 10 },
                    { wch: 15 },
                    { wch: 15 },
                    { wch: 5 },
                    { wch: 15 }
                ];

                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'ExternalDisks');

                // Export the file
                XLSX.writeFile(workbook, 'ExternalDisksData.xlsx');
            } else {
                console.error('No data to export');
            }
        } catch (error) {
            console.error('Error exporting data to Excel:', error);
        }
    };


    const handleEditExternalDisk = (externalDisk) => {
        setSelectedExternalDisk(externalDisk);
        setIsEditing(true);
        setShowExternalDiskAddOverlay(true);
    };

    const { loading, data, fetchMore } = useQuery(listExternalDisks, {
        variables: { limit: 100 },
    });

    const [addExternalDisk] = useMutation(createExternalDisk, {
        refetchQueries: [{ query: listExternalDisks, variables: { limit: 100 } }]
    });

    const [removeExternalDisk] = useMutation(deleteExternalDisk, {
        refetchQueries: [{ query: listExternalDisks, variables: { limit: 100 } }]
    });
    const [updExternalDisk] = useMutation(updateExternalDisk, {
        refetchQueries: [{ query: listExternalDisks, variables: { limit: 100 } }]
    });
    const [addDeleteEquipmentHistory] = useMutation(createDeleteEquipmentHistory, {
        refetchQueries: [{ query: listDeleteEquipmentHistories, variables: { limit: 100 } }]
    });

    const [fetchAllExternalDisks, { loading: countLoading, data: AllExternalDisksData }] = useLazyQuery(listExternalDisks, {
        variables: { limit: 100 },
        fetchPolicy: "network-only",
    });


    useEffect(() => {
        const fetchAllExternalDisks = async () => {
            let AllExternalDisks = [];
            let currentNextToken = null;

            do {
                const { data } = await fetchMore({
                    variables: { limit: 100, nextToken: currentNextToken },
                });

                AllExternalDisks = [...AllExternalDisks, ...data.listExternalDisks.items];
                currentNextToken = data.listExternalDisks.nextToken;
            } while (currentNextToken);

            setExternalDisk(AllExternalDisks);
            setNextToken(currentNextToken);
        };

        if (data) {
            fetchAllExternalDisks();
        }
    }, [data, fetchMore]);

    const clearNewExternalDisk = () => {
        setNewExternalDisk({
            id: '',
            tagid: '',
            model: '',
            brand: '',
            serial_Number: '',
            storage: '',
            descripcion: '',
            charger_cable: true,
            output_cable: true,
            price: '',
            store: '',
            purchase_date: '',
            assignment_date: '',
            warranty_date: '',
            returnable_date: '',
            available: true,
            status: 'inv',
            assigned_to: '',
            location: '',
            team: '',
        });
    };

    // Define loadMore function
    const loadMore = () => {
        fetchMore({
            variables: { limit: 100, nextToken },
            updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;
                setNextToken(fetchMoreResult.listExternalDisks.nextToken);
                return {
                    listExternalDisks: {
                        ...fetchMoreResult.listExternalDisks,
                        items: [...prevResult.listExternalDisks.items, ...fetchMoreResult.listExternalDisks.items]
                    }
                };
            }
        });
    };

    const filteredExternalDisks = useFilteredData(externalDisk || [], searchTerm, sortConfig);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let formattedValue = value;

        // Convert to lowercase and trim whitespace for specific fields
        if (['serial_Number'].includes(name)) {
            formattedValue = value.toLowerCase().trim();
        }

        if (isEditing) {
            setSelectedExternalDisk({
                ...selectedExternalDisk,
                [name]: formattedValue
            });
        } else {
            setNewExternalDisk({
                ...newExternalDisk,
                id: uuidv4(),
                [name]: formattedValue
            });
        }
    };

    const handleAddExternalDisk = () => {

        // Formatear la fecha en el formato YYYY-MM-DD si existe, o establecerla en null si está vacía
        const formattedPurchase = newExternalDisk.purchase_date
            ? new Date(newExternalDisk.purchase_date).toISOString().split('T')[0]
            : null;
        const formattedAssignment = newExternalDisk.assignment_date
            ? new Date(newExternalDisk.assignment_date).toISOString().split('T')[0]
            : null;

        const formattedWarranty = newExternalDisk.warranty_date
            ? new Date(newExternalDisk.warranty_date).toISOString().split('T')[0]
            : null;

        const formattedReturnable = newExternalDisk.returnable_date
            ? new Date(newExternalDisk.returnable_date).toISOString().split('T')[0]
            : null;

        // Validate that the price contains only digits and is a valid number
        let priceValue = null;

        if (newExternalDisk.price !== "" && newExternalDisk.price !== null && newExternalDisk.price != undefined) {
            priceValue = parseFloat(newExternalDisk.price);

            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');
                return;
            }
        }
        setPriceError('');

        // Check for duplicates based on `tagid` and `serial_Number`
        const isDuplicate = externalDisk.some(externalDisk =>
            externalDisk.tagid === newExternalDisk.tagid &&
            externalDisk.serial_Number === newExternalDisk.serial_Number
        );

        if (isDuplicate) {
            setDuplicateError('A external Disk with the same TAG and Serial Number already exists.');
            console.log('Duplicate validation failed');
            return;
        } else {
            setDuplicateError('');
        }

        // Check availability first
        if (newExternalDisk.available === true) {

            // If available, status must be Inventario
            if (newExternalDisk.status !== 'inv') {
                setStatusError('If the External Disk is available, its status must be "Inventario".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // If available and status is Inventario, assigned_to must be empty or "N/A"
            if (newExternalDisk.assigned_to !== '' && newExternalDisk.assigned_to !== 'n/a') {
                setStatusError('If the External Disk is available and its status is "Inventario", the "Assigned to" field must be empty or "N/A".');
                console.log('Assigned_to validation failed');
                return; // Exit function if validation fails
            }

        } else if (newExternalDisk.available !== "") {
            // If not available, status must be one of the allowed statuses
            const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
            if (!allowedStatuses.includes(newExternalDisk.status)) {
                setStatusError('If the External Disk is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // Clear error if validation passes
            setStatusError('');
        }

        //Validate Storage
        const storageValue = newExternalDisk.storage === '' || newExternalDisk.storage === null ? 0 : parseInt(newExternalDisk.storage, 10);
        const isStorageInteger = Number.isInteger(storageValue);

        if (!isStorageInteger) {
            setStorageError('The Storage must be an integer.');
            console.log('Storage validation failed');
        } else {
            setStorageError('');
        }

        // If all validations pass, proceed with adding the externalDisk to the database
        addExternalDisk({
            variables: { input: { ...newExternalDisk, storage: storageValue, price: priceValue, purchase_date: formattedPurchase, warranty_date: formattedWarranty, returnable_date: formattedReturnable, assignment_date: formattedAssignment } }
        })
            .then(() => {
                clearNewExternalDisk();
                alert("The External Disk was an added success")
                setShowExternalDiskAddOverlay(false);
            }).catch(error => {
                console.error("Error adding externalDisk:", error);
            });
    };

    const removeUnnecessaryFields = (data, fieldsToRemove) => {
        fieldsToRemove.forEach((field) => {
            delete data[field];
        });
        return data;
    };

    const handleDeleteExternalDisk = async () => {
        try {
            // Remover campos innecesarios
            const ExternalDiskData = removeUnnecessaryFields({ ...selectedExternalDisk }, [

                'storage',
                'descripcion',
                'charger_cable',
                'output_cable',
                'warranty_date',
                'returnable_date',
                'status',
                'team',

            ]);

            // Lista de campos permitidos según el esquema GraphQL
            const allowedFields = [
                'id',
                'Item',
                'tagId',
                'tagid', // Agregado
                'serial_Number',
                'price',
                'brand',
                'model',
                'store',
                'purchase_date',
                'assignment_date',
                'available',
                'previous_employees',
                'assigned_to', // Agregado
                'location',
                'notes',
            ];

            // Filtrar los campos permitidos
            const cleanedNewExternalDisk = Object.fromEntries(
                Object.entries(ExternalDiskData).filter(([key]) => allowedFields.includes(key))
            );


            console.log("Antes de normalizar:", cleanedNewExternalDisk);

            // Normalizar claves alternativas
            cleanedNewExternalDisk.tagId = cleanedNewExternalDisk.tagId || cleanedNewExternalDisk.tagid;
            delete cleanedNewExternalDisk.tagid;

            cleanedNewExternalDisk.previous_employees =
                cleanedNewExternalDisk.previous_employees || cleanedNewExternalDisk.assigned_to;
            delete cleanedNewExternalDisk.assigned_to;

            cleanedNewExternalDisk.notes =
                cleanedNewExternalDisk.notes || cleanedNewExternalDisk.Description;
            delete cleanedNewExternalDisk.Description;
            console.log("Despues de normalizar:", cleanedNewExternalDisk);

            // Añadir valores clave predeterminados
            if (!cleanedNewExternalDisk.Item) {
                cleanedNewExternalDisk.Item = "External Disk"; // Establecer el valor predeterminado para `Item`
            }

            // Eliminar valores vacíos
            Object.keys(cleanedNewExternalDisk).forEach((key) => {
                if (cleanedNewExternalDisk[key] === "" || cleanedNewExternalDisk[key] === null) {
                    delete cleanedNewExternalDisk[key];
                }
            });

            // Agregar empleado a la tabla PreviousEmployee
            await addDeleteEquipmentHistory({
                variables: { input: cleanedNewExternalDisk },
            });

            // Eliminar el ExternalDisk
            await removeExternalDisk({
                variables: { input: { id: cleanedNewExternalDisk.id } },
            });

            // Resetear estados
            setShowExternalDiskAddOverlay(false);
            setIsEditing(false);
            setSelectedExternalDisk(null);

        } catch (error) {
            console.error("Error deleting ExternalDisk", error);
        }
    };

    const handleUpdateExternalDisk = async () => {

        const formattedData_purchase = selectedExternalDisk.purchase_date ? new Date(selectedExternalDisk.purchase_date).toISOString().split('T')[0] : null;
        const formattedData_warranty = selectedExternalDisk.warranty_date ? new Date(selectedExternalDisk.warranty_date).toISOString().split('T')[0] : null;
        const formattedData_returnable = selectedExternalDisk.returnable_date ? new Date(selectedExternalDisk.returnable_date).toISOString().split('T')[0] : null;
        const formattedData_assignment = selectedExternalDisk.assignment_date ? new Date(selectedExternalDisk.assignment_date).toISOString().split('T')[0] : null;

        //Check Price
        let priceValue = null;
        if (selectedExternalDisk.price !== "" && selectedExternalDisk.price !== null && selectedExternalDisk.price != undefined) {
            priceValue = parseFloat(selectedExternalDisk.price);

            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');
                return;
            }
        }
        setPriceError('');

        // Check availability first
        if (selectedExternalDisk.available === 'true') {

            // If available, status must be Inventario
            if (selectedExternalDisk.status !== 'inv') {
                setStatusError('If the External Disk is available, its status must be "Inventario".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // If available and status is Inventario, assigned_to must be empty or "N/A"
            if (selectedExternalDisk.assigned_to !== '' && selectedExternalDisk.assigned_to !== 'n/a') {
                setStatusError('If the External Disk is available and its status is "Inventario", the "Assigned to" field must be empty or "N/A".');
                console.log('Assigned_to validation failed');
                return; // Exit function if validation fails
            }

        } else if (selectedExternalDisk.available === 'false') {
            // If not available, status must be one of the allowed statuses
            const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
            if (!allowedStatuses.includes(selectedExternalDisk.status)) {
                setStatusError('If the External Disk is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // Clear error if validation passes
            setStatusError('');
        }

        //Validate Storage
        const storageValue = selectedExternalDisk.storage === '' || selectedExternalDisk.storage === null ? 0 : parseInt(selectedExternalDisk.storage, 10);
        const isStorageInteger = Number.isInteger(storageValue);


        if (!isStorageInteger) {
            setStorageError('The Storage must be an integer.');
            console.log('Storage validation failed');
            return;
        } else {
            setStorageError('');
        }

        updExternalDisk({
            variables: {
                input: {
                    id: selectedExternalDisk.id,
                    tagid: selectedExternalDisk.tagid,
                    model: selectedExternalDisk.model,
                    brand: selectedExternalDisk.brand,
                    serial_Number: selectedExternalDisk.serial_Number,
                    storage: storageValue,
                    descripcion: selectedExternalDisk.descripcion,
                    charger_cable: selectedExternalDisk.charger_cable,
                    output_cable: selectedExternalDisk.output_cable,
                    price: priceValue,
                    store: selectedExternalDisk.store,
                    purchase_date: formattedData_purchase,
                    assignment_date: formattedData_assignment,
                    warranty_date: formattedData_warranty,
                    returnable_date: formattedData_returnable,
                    available: selectedExternalDisk.available,
                    status: selectedExternalDisk.status,
                    assigned_to: selectedExternalDisk.assigned_to,
                    location: selectedExternalDisk.location,
                    team: selectedExternalDisk.team,

                }
            }
        }).then(() => {
            setIsEditing(true);
            setShowExternalDiskAddOverlay(false);
            setPriceError('');
            setStatusError('');
            setDuplicateError('');

        }).catch(error => {
            console.error("Error updating ExternalDisk:", error);
        });
    };



    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    //listUsers 
    const users = React.useMemo(() => {
        if (userData && userData.listUsers) {
            return userData.listUsers.items || [];
        }
        return [];
    }, [userData]);
    if (userError) return <p>Error loading users: {userError.message}</p>;
    if (loading1) return <p>Error loading users: {userError.message}</p>;


    const formatDate = (dateString) => {
        const options = { month: 'numeric', day: 'numeric', year: 'numeric' };
        const date = new Date(dateString);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

        return localDate.toLocaleDateString(undefined, options);
    };
    const formatPrice = (price) => {
        if (price === null || price === undefined || price === '') {
            return '';
        }
        const parsedPrice = parseFloat(price);
        return isNaN(parsedPrice) ? '' : parsedPrice.toFixed(2);
    };


    return (
        <div className="externalDisk-page">

            <header className="inventory-header">
                <h1>External Disk</h1>
            </header>
            <Inventory_header />
            <div className="inventory-search">
                <button onClick={handleSaveAsExcel} > <FaFileExcel /> Export </button>

            </div>

            <div className="searchBar">
                <button className="add-externalDisk-btn" onClick={() => {
                    clearNewExternalDisk();
                    setSelectedExternalDisk(null);
                    setIsEditing(false);
                    setShowExternalDiskAddOverlay(true);
                }}>+ Add New External Disk</button>
                <SearchBar searchTerm={searchTerm} onSearch={setSearchTerm} />
            </div>

            <div className="externalDisk-table-container">
                <table className="externalDisk-table">

                    <thead>
                        <tr>
                            <th>#</th> {/* Columna para contar las filas */}

                            <th onClick={() => requestSort('tagid')}>
                                TAG
                                {sortConfig?.key === 'tagid' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('model')}>
                                Model
                                {sortConfig?.key === 'model' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('brand')}>
                                Brand
                                {sortConfig?.key === 'brand' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('serial_Number')}>
                                Serial Number
                                {sortConfig?.key === 'serial_Number' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('storage')}>
                                Storage
                                {sortConfig?.key === 'storage' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('descripcion')}>
                                Descripcion
                                {sortConfig?.key === 'descripcion' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('charger_cable')}>
                                Charger Cable
                                {sortConfig?.key === 'charger_cable' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('output_cable')}>
                                Output Cable
                                {sortConfig?.key === 'output_cable' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('price')}>
                                Price
                                {sortConfig?.key === 'price' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('store')}>
                                Store
                                {sortConfig?.key === 'store' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('purchase_date')}>
                                Purchase Date
                                {sortConfig?.key === 'purchase_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('available')}>
                                Available
                                {sortConfig?.key === 'available' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('status')}>
                                Status
                                {sortConfig?.key === 'status' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('assigned_to')}>
                                Assigned To
                                {sortConfig?.key === 'assigned_to' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('assignment_date')}>
                                Assignment Date
                                {sortConfig?.key === 'assignment_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('warranty_date')}>
                                Warranty Date
                                {sortConfig?.key === 'warranty_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('returnable_date')}>
                                Returnable Date
                                {sortConfig?.key === 'returnable_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('location')}>
                                Location
                                {sortConfig?.key === 'location' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('team')}>
                                Team
                                {sortConfig?.key === 'team' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {filteredExternalDisks.map((externalDisk, index) => (
                            <tr key={externalDisk.id} onClick={() => handleEditExternalDisk(externalDisk)} >
                                <td>{index + 1}</td> {/* Número de fila */}
                                <td className="externalDisk-tagid">{capitalize(externalDisk.tagid)}</td>
                                <td className="externalDisk-model">{capitalize(externalDisk.model.toLowerCase())}</td>
                                <td className="externalDisk-brand">{capitalize(externalDisk.brand.toLowerCase())}</td>
                                <td className="externalDisk-serial_Number">{externalDisk.serial_Number?.toUpperCase() || 'N/A'}</td>
                                <td className="externalDisk-storage">{externalDisk.storage + " GB"}</td>
                                <td className="externalDisk-descripcion">{externalDisk.descripcion}</td>
                                <td className="externalDisk-charger_cable">{externalDisk.charger_cable ? 'Yes' : 'No'} </td>
                                <td className="externalDisk-output_cable">{externalDisk.output_cable ? 'Yes' : 'No'} </td>
                                <td className="externalDisk-price">${formatPrice(externalDisk.price)}</td>
                                <td className="externalDisk-store">{externalDisk.store}</td>
                                <td className="externalDisk-purchase_date">{externalDisk.purchase_date ? formatDate(externalDisk.purchase_date) : 'No Date'}</td>
                                <td className="externalDisk-available">{externalDisk.available ? 'Yes' : 'No'} </td>
                                <td className="externalDisk-status">
                                    {externalDisk.status === 'broken' ? 'Broken' :
                                        externalDisk.status === 'use' ? 'Use' :
                                            externalDisk.status === 'transfer' ? 'Transfer' :
                                                externalDisk.status === 'lost' ? 'Lost' :
                                                    externalDisk.status === 'inv' ? 'Inventario' :
                                                        'Use'} {/* Muestra 'Unknown' si el estado no coincide con ninguno de los anteriores */}
                                </td>
                                <td className="externalDisk-assigned_to">{externalDisk.assigned_to}</td>
                                <td className="externalDisk-assignment_date">{externalDisk.assignment_date ? formatDate(externalDisk.assignment_date) : 'No Date'}</td>
                                <td className="externalDisk-warranty_date">{externalDisk.warranty_date ? formatDate(externalDisk.warranty_date) : 'No Date'}</td>
                                <td className="externalDisk-returnable_date">{externalDisk.returnable_date ? formatDate(externalDisk.returnable_date) : 'No Date'}</td>
                                <td className="externalDisk-location">{externalDisk.location}</td>
                                <td className="externalDisk-team">{externalDisk.team}</td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {nextToken && !loading && (
                <button onClick={loadMore}>Load More</button>
            )}


            {loading && <p>Loading...</p>}

            {showExternalDiskAddOverlay && (

                <div className="externalDisk_add_overlay" onClick={(e) => {
                    // Close overlay only if clicked outside the modal content
                    if (e.target.classList.contains("externalDisk_add_overlay")) {
                        setShowExternalDiskAddOverlay(false);
                        clearNewExternalDisk();
                        setSelectedExternalDisk(null);
                        setIsEditing(false);
                    }
                }}>
                    <div className="overlay-content-inventory">
                        <div className="header-row">
                            <h2 className="header-title">{isEditing ? 'Edit External Disk' : 'Add New External Disk'}</h2>
                            <button onClick={() => {
                                setShowExternalDiskAddOverlay(false);
                                clearNewExternalDisk();
                                setSelectedExternalDisk(null);
                                setIsEditing(false);
                            }}>X</button>
                        </div>

                        <label>TAG:</label><input name="tagid" placeholder="TAG" value={isEditing ? selectedExternalDisk.tagid : newExternalDisk.tagid} onChange={handleInputChange} required />
                        <label>Model:</label><input name="model" placeholder="Model" value={isEditing ? selectedExternalDisk.model : newExternalDisk.model} onChange={handleInputChange} required />

                        <label>Brand:</label><input name="brand" placeholder="Brand" value={isEditing ? selectedExternalDisk.brand : newExternalDisk.brand} onChange={handleInputChange} />
                        <label>Serial Number:</label><input name="serial_Number" placeholder="Serial Number" value={isEditing ? selectedExternalDisk.serial_Number : newExternalDisk.serial_Number} onChange={handleInputChange} required />
                        <label>Storage:</label><input name="storage" placeholder="Storage" value={isEditing ? selectedExternalDisk.storage : newExternalDisk.storage} onChange={handleInputChange} />
                        <label>Descripcion:</label><input name="descripcion" placeholder="Descripcion" value={isEditing ? selectedExternalDisk.descripcion : newExternalDisk.descripcion} onChange={handleInputChange} />

                        <label>Charger Cable:</label>
                        <select name="charger_cable" placeholder="charger_cable" value={isEditing ? selectedExternalDisk.charger_cable : newExternalDisk.charger_cable} onChange={handleInputChange} >
                            <option value="" disabled hidden className="placeholder-option">Select Y/N</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>

                        </select>
                        <label>Output Cable:</label>
                        <select name="output_cable" placeholder="output_cable" value={isEditing ? selectedExternalDisk.output_cable : newExternalDisk.output_cable} onChange={handleInputChange} >
                            <option value="" disabled hidden className="placeholder-option">Select Y/N</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>

                        </select>
                        <label>Price:</label><input name="price" placeholder="price" value={isEditing ? selectedExternalDisk.price : newExternalDisk.price} onChange={handleInputChange} />
                        <label>Store:</label><input name="store" placeholder="store" value={isEditing ? selectedExternalDisk.store : newExternalDisk.store} onChange={handleInputChange} />

                        <label htmlFor="purchase_date">Purchase Date:</label>
                        <input
                            type="Date"
                            id="purchase_date"
                            name="purchase_date"
                            value={isEditing ? selectedExternalDisk.purchase_date : newExternalDisk.purchase_date}
                            onChange={handleInputChange}

                        />

                        <label htmlFor="assignment_date">Assignment Date:</label>
                        <input
                            type="date"
                            id="assignment_date"
                            name="assignment_date"
                            value={isEditing ? selectedExternalDisk.assignment_date : newExternalDisk.assignment_date}
                            onChange={handleInputChange}

                        />
                        <label htmlFor="warranty_date">Warranty Date:</label>
                        <input
                            type="date"
                            id="warranty_date"
                            name="warranty_date"
                            value={isEditing ? selectedExternalDisk.warranty_date : newExternalDisk.warranty_date}
                            onChange={handleInputChange}


                        />
                        <label htmlFor="returnable_date">Returnable Date:</label>
                        <input
                            type="date"
                            id="returnable_date"
                            name="returnable_date"
                            value={isEditing ? selectedExternalDisk.returnable_date : newExternalDisk.returnable_date}
                            onChange={handleInputChange}

                        />

                        <label>Available:</label>

                        <select name="available" placeholder="available" value={isEditing ? selectedExternalDisk.available : newExternalDisk.available} onChange={handleInputChange} >
                            <option value="" disabled hidden className="placeholder-option">Select Y/N</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>

                        </select>
                        <label>Status:</label>
                        <select name="status" placeholder="status" value={isEditing ? selectedExternalDisk.status : newExternalDisk.status} onChange={handleInputChange} >
                            <option value="" disabled hidden>Select Status</option>
                            <option value="use">Use</option>
                            <option value="broken">Broken</option>
                            <option value="transfer">Transfer</option>
                            <option value="lost">Lost</option>
                            <option value="inv">Inventario</option>

                        </select>
                        <label>Assigned To:</label>
                        <select
                            name="assigned_to"
                            value={isEditing ? selectedExternalDisk.assigned_to : newExternalDisk.assigned_to}
                            onChange={handleInputChange}
                        >
                            <option value="">Select an Employee:</option>

                            {users.length > 0 ? (
                                [...users].sort((a, b) => {
                                    const nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
                                    const nameB = `${b.first_name} ${b.last_name}`.toLowerCase();
                                    return nameA.localeCompare(nameB);
                                }).map(user => (
                                    <option key={user.id} value={`${capitalize(user.first_name)} ${capitalize(user.last_name)}`}>
                                        {capitalize(user.first_name)} {capitalize(user.last_name)}
                                    </option>
                                ))
                            ) : (

                                <option value="">No users available</option>
                            )}
                            <option value="n/a">N/A</option> {/*N/A */}


                        </select>


                        <label>Location:</label><input name="location" placeholder="location" value={isEditing ? selectedExternalDisk.location : newExternalDisk.location} onChange={handleInputChange} />
                        <label>Team:</label><input name="team" placeholder="team" value={isEditing ? selectedExternalDisk.team : newExternalDisk.team} onChange={handleInputChange} />

                        {duplicateError && <p className="error">{duplicateError}</p>} {/* Display duplicate error message */}
                        {priceError && <p className="error">{priceError}</p>} {/* Display price error message */}
                        {statusError && <p className="error">{statusError}</p>} {/* Display duplicate error message */}
                        {storageError && <p className="error">{storageError}</p>} {/* Display Storage error message */}

                        <div className="button-group">
                            <button className="overlay-add-externalDisk" onClick={isEditing ? handleUpdateExternalDisk : handleAddExternalDisk} >
                                {isEditing ? 'Update External Disk' : 'Add External Disk'}
                            </button>
                            {isEditing && (
                                <button
                                    className="overlay-delet-externalDisk"
                                    onClick={() => {
                                        if (window.confirm('Are you sure you want to delete this externalDisk?')) {
                                            handleDeleteExternalDisk();
                                        }
                                    }}
                                >
                                    Delete External Disk
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}

        </div>


    );






























};

export default External_Disk;
